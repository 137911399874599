import { useState } from "react";
import { string } from "prop-types";
import { saveAs } from "file-saver";

import trackEvent from "../tracking/track-event";

import { Button, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function ResourceDownloadsButton({ link, title }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function handleClick(event) {
    event.preventDefault();

    try {
      if (!link || link === "#") {
        throw new Error("Missing link");
      }

      let fileName = link.split("/").pop();

      if (!fileName) {
        fileName = null;
      }

      setLoading(true);
      saveAs(link, fileName);
      setLoading(false);

      trackEvent({
        action: "training_resources_download",
        params: { link_text: title, link_url: link },
      });
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div className="resource-list--downloads--button">
      <Button
        buttonType="secondary-one"
        disabled={loading}
        onClick={handleClick}
        type="button"
      >
        {loading ? "Loading..." : "Download"}
      </Button>
      {!!error && (
        <Paragraph className="ua-text-danger ua-text-center" bodyStyle={4}>
          {error}
        </Paragraph>
      )}
    </div>
  );
}

ResourceDownloadsButton.propTypes = {
  title: string.isRequired,
  link: string.isRequired,
};
