export default function normalizeAgenciesResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    let agencies = data?.agencies || data?.data;

    if (
      (!agencies || !Array.isArray(agencies)) &&
      Object.keys(data).length > 3
    ) {
      agencies = Object.keys(data)
        .filter(
          (key) => !["message", "status", "success", "data"].includes(key)
        )
        .map((key) => ({
          id: parseInt(key, 10),
          name: data[key],
        }));
    }

    if (!agencies || !Array.isArray(agencies) || !agencies.length) {
      throw new Error("No agencies found");
    }

    agencies = agencies.sort(sortByName);

    let message = data.message || "Success!";

    return ["success", agencies, message];
  } catch (err) {
    return ["error", [], err.message || "Unexpected response"];
  }
}

function sortByName(a, b) {
  // Intl.Collator makes case-insensitive
  return new Intl.Collator("en", { sensitivity: "base" }).compare(
    a.name,
    b.name
  );
}
