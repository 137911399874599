import { useEffect, useMemo, useState } from "react";
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types";
import { useSelector } from "react-redux";

import getLevelName from "../../utilities/get-level-name";

import AccessAlt from "./AccessAlt";
import Projection from "./Projection";
import HeaderAlt from "./HeaderAlt";
import ReminderLevel1 from "./ReminderLevel1";
import Rewards from "./Rewards";
import StatusLoading from "./StatusLoading";
import StatusNotFound from "./StatusNotFound";
import TierRequirements from "./TierRequirements";

export default function StatusPageLevel1({
  agency_id,
  agencyName,
  catalog_year,
  catalog_year_next,
  logo,
  status,
  statusError,
  statusLoading,
}) {
  const email = useSelector(({ settings }) => settings?.uhc_reply_email) || "";

  const [active, setActive] = useState();
  const activeLabel = useMemo(() => getLevelName(active), [active]);

  // Update active tier with API response
  const { availableTiers = [], CurrentUATierClass: currentTier } =
    (!!status && status) || {};

  useEffect(() => {
    const currentTierIndex = availableTiers.findIndex(
      (tier) => tier === currentTier
    );

    if (currentTierIndex > -1 && currentTierIndex + 1 < availableTiers.length) {
      setActive(availableTiers[currentTierIndex + 1]);
    } else if (currentTierIndex > -1) {
      setActive(currentTier);
    } else {
      setActive(availableTiers[0]);
    }
  }, [agency_id, availableTiers, currentTier]);

  return (
    <>
      <HeaderAlt catalog_year_next={catalog_year_next} />
      {!statusError && statusLoading && <StatusLoading />}
      {!!statusError && !statusLoading && (
        <StatusNotFound agencyName={agencyName} logo={logo} />
      )}
      {!statusError && !statusLoading && !!availableTiers.length && (
        <TierRequirements
          active={active}
          activeLabel={activeLabel}
          activeIndex={availableTiers.indexOf(active)}
          agencyName={agencyName}
          className="ua-req__bg-gradient fluid-container ua-padding-jumbo-bottom"
          currentYear={catalog_year}
          logo={logo}
          nextYear={catalog_year_next}
          overlap={false}
          setActive={setActive}
          status={status}
        />
      )}
      {!statusError && !statusLoading && !!availableTiers.length && (
        <>
          <Projection
            className="ua-projection__padding-lg"
            ctaLink={`mailto:${email}?subject=Questions%20About%20%20UA%20Status%20Projection`}
            ctaText="Contact UA Support"
            ctaTrackingLabel="Email"
            nextYear={catalog_year_next}
            projectedClass={status.ProjectedUATierClass}
            projectedStatus={status.ProjectedUATierLabel}
          />
          <Rewards
            className="ua-rewards__padding-bottom-sm"
            headlineClass="ua-rewards--headline__lg"
            showLevel3={availableTiers.includes("level_3")}
          />
          <AccessAlt />
          <ReminderLevel1 />
        </>
      )}
    </>
  );
}

StatusPageLevel1.propTypes = {
  agency_id: oneOfType([number, string]),
  agencyName: string,
  catalog_year: number,
  catalog_year_next: number,
  logo: string,
  status: shape({
    availableTiers: arrayOf(string),
    CurrentUATierClass: string,
    ProjectedUATierClass: string,
    ProjectedUATierLabel: string,
  }),
  statusError: string,
  statusLoading: bool,
};
