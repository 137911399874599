import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../../data/constants/routes";
import useSelectedBenefits from "../../../features/shopping/use-selected-benefits";

import BonusInfoModal from "../../../features/shopping/BonusInfoModal";
import ShoppingPoints from "../../../features/shopping/ShoppingPoints";
import ShoppingSelection from "../../../features/shopping/ShoppingSelection";

import { Typography } from "@uhc-tempo/components";
const { H1, Paragraph } = Typography;

export default function ShoppingBonusChoose(props) {
  let navigate = useNavigate();

  const { pointsAvailable } = useSelector(({ shopping = {} }) => shopping);
  let { pointsRemaining } = useSelectedBenefits();

  function handleSubmit() {
    if (pointsAvailable === pointsRemaining) {
      return;
    }

    navigate(ROUTES.shoppingBonusReviewNow.path);
  }

  return (
    <section className="fluid-container page-main__fill ua-padding-xxxlg-vertical shopping-selection-page-bg">
      <div className="ua-grid ua-grid-gap-3-sm ua-grid-cols-12">
        <div className="ua-col-auto-span-12-sm ua-col-auto-span-3-md">
          <H1
            className="tds-padding-lg-vertical tds-margin-none-bottom"
            useLight
          >
            Shop now
          </H1>
          <Paragraph
            bodyStyle={1}
            className="ua-text-primary-three ua-margin-xlg-bottom ua-padding-xlg-bottom"
            useLight
          >
            Enjoy choosing perks that support your agency’s specific needs.
          </Paragraph>
          <ShoppingPoints
            bonus={true}
            handleSubmit={handleSubmit}
            pointsRemaining={pointsRemaining}
          >
            <BonusInfoModal />
          </ShoppingPoints>
        </div>
        <div className="ua-col-auto-span-12-sm ua-col-auto-span-9-md">
          <ShoppingSelection handleSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
}
