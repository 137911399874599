import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import PageLoadingIndicator from "../../components/page-loading-indicator/PageLoadingIndicator";
import TrainingList from "./TrainingList";

import { Form, Notification, Select, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

const TRAINING_TYPES = {
  all: "All",
  type0: "Classroom",
  type1: "Virtual",
  type2: "OnDemand",
  type3: "CE Credit",
};

export default function TrainingIndex() {
  const [dismissError, setDismissError] = useState(false);
  const [filter, setFilter] = useState("all");

  const {
    training: trainingBenefits,
    trainingError,
    trainingLoading,
  } = useSelector(({ training, trainingError, trainingLoading }) => ({
    training,
    trainingError,
    trainingLoading,
  }));

  const filteredBenefits = useMemo(() => {
    if (filter === "all" || !filter) {
      return [...trainingBenefits];
    }

    return trainingBenefits.filter(({ training_type }) =>
      training_type.includes(filter)
    );
  }, [filter, trainingBenefits]);

  function handleChange(event) {
    setFilter(event.target.value);
  }

  if (trainingLoading) {
    return <PageLoadingIndicator />;
  }

  if (!!trainingError) {
    return (
      <section className="fluid-container page-main__fill ua-padding-jumbo-vertical">
        <div className="ua-grid ua-grid-gap-3 ua-grid-cols-12">
          <div className="ua-col-span-1-12-sm ua-col-span-2-10-md ua-col-span-3-8-lg">
            <Notification
              className="ua-col-auto-span-12-sm ua-col-auto-span-12-md"
              dismissClickHandler={() => setDismissError(true)}
              isDismissed={dismissError}
              notificationType="error"
            >
              <Paragraph className="tds-margin-none">{trainingError}</Paragraph>
            </Notification>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
      <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
        <Form className="ua-border-bottom tds-padding-xxlg-bottom">
          <Select onChange={handleChange}>
            <Select.Option value="" disabled>
              -- Filter --
            </Select.Option>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="1">Virtual</Select.Option>
            <Select.Option value="2">OnDemand</Select.Option>
            {/* <Select.Option value="3">CE Credit</Select.Option>
            <Select.Option value="0">Classroom</Select.Option> */}
          </Select>
        </Form>
        {!filteredBenefits.length ? (
          <Paragraph bodyStyle={1} className="tds-padding-xxlg-top">
            No{" "}
            {filter !== "all" && !!TRAINING_TYPES[`type${filter}`]
              ? TRAINING_TYPES[`type${filter}`]
              : ""}{" "}
            training offerings found
          </Paragraph>
        ) : (
          <TrainingList trainingBenefits={filteredBenefits} />
        )}
      </div>
    </div>
  );
}
