import { useMemo } from "react";

import ROUTES from "../../data/constants/routes";

function getTrainingtRootPath() {
  const path = ROUTES.trainingDetails.path;
  return !!path ? path.split("/:")[0] : "";
}

export default function useTrainingtRootPath() {
  const rootPath = useMemo(() => getTrainingtRootPath(), []);
  return rootPath;
}
