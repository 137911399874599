import DOMPurify from "dompurify";
import parse from "html-react-parser";

export default function stripHtml(rawHtml, allowed = []) {
  try {
    const sanitized = DOMPurify.sanitize(rawHtml, { ALLOWED_TAGS: allowed });
    const parsed = parse(sanitized);
    const trimmed = parsed.trim();
    return trimmed;
  } catch (err) {
    return rawHtml;
  }
}
