import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSettings } from "../features/settings/SettingsRedux";
import { getStaticPages } from "../features/static-pages/StaticPagesRedux";
import { getBenefits, resetBenefits } from "../features/benefits/BenefitsRedux";
import { getTraining, resetTraining } from "../features/training/TrainingRedux";
import useAuthStatus from "../features/auth/use-auth-status";

export default function useLoadData() {
  const dispatch = useDispatch();
  const {
    agency,
    agency_id,
    hasTraining,
    showComingSoon,
    id: user_id,
  } = useSelector(({ user }) => user);
  const { level } = agency || "";
  const catalog_year = useSelector(
    ({ catalogYearCurrent }) => catalogYearCurrent.year
  );
  const shoppingRequired = useSelector(
    ({ shoppingRequired }) => shoppingRequired?.required
  );
  const authTokenLoading = useSelector(
    (state) => state.adminTokenLoading || state.userTokenLoading
  );
  const [hasAuth] = useAuthStatus();

  // Fetch settings and static pages from API on initial page load
  const hasStaticPages = useSelector(
    (state) => !state.staticPagesError && !!state.staticPages.length
  );

  useEffect(() => {
    dispatch(getSettings());

    if (!hasStaticPages) {
      dispatch(getStaticPages());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Don't fetch benefits from API on inital load when
  // redux state has been rehydrated, but still allow
  // benefits to be fetched when shopping is completed
  // or super admin is impersonating another user
  const [shouldFetchBenefits, setShouldFetchBenefits] = useState(false);
  const [shouldResetBenefits, setShouldResetBenefits] = useState(false);
  const [showingBenefitsForUser, setShowingBenefitsForUser] = useState(user_id);
  const [showingBenefitsForYear, setShowingBenefitsForYear] =
    useState(catalog_year);

  const allowedBenefits = useMemo(
    () =>
      shoppingRequired === false &&
      showComingSoon === false &&
      ["level_2", "level_3"].includes(level),
    [level, shoppingRequired, showComingSoon]
  );

  const hasBenefits = useSelector(
    (state) =>
      !state.benefitsError && !!state.benefits && !!state.benefits.length
  );

  const hasFetchedBenefits = useRef(hasBenefits);

  // Reset hasFetchedBenefits if auth status changes
  useEffect(() => {
    if (hasFetchedBenefits.current && !hasAuth) {
      hasFetchedBenefits.current = false;
    }
  }, [hasAuth]);

  // Update benefits upon initial user authentication
  useEffect(() => {
    if (!hasFetchedBenefits.current) {
      setShouldFetchBenefits(!authTokenLoading && allowedBenefits);
    }
  }, [authTokenLoading, allowedBenefits]);

  // Update benefits if user ID changes
  useEffect(() => {
    if (hasFetchedBenefits.current && user_id !== showingBenefitsForUser) {
      if (!!showingBenefitsForUser) {
        if (allowedBenefits) {
          setShouldFetchBenefits(true);
        } else {
          setShouldResetBenefits(true);
        }
      }

      setShowingBenefitsForUser(user_id);
    } else if (!hasFetchedBenefits.current && !!user_id) {
      setShowingBenefitsForUser(user_id);
    }
  }, [allowedBenefits, user_id, showingBenefitsForUser]);

  // Update benefits if catalog year changes
  useEffect(() => {
    if (hasFetchedBenefits.current && catalog_year !== showingBenefitsForYear) {
      if (!!showingBenefitsForYear) {
        if (allowedBenefits) {
          setShouldFetchBenefits(true);
        } else {
          setShouldResetBenefits(true);
        }
      }

      setShowingBenefitsForYear(catalog_year);
    } else if (!hasFetchedBenefits.current && !!catalog_year) {
      setShowingBenefitsForYear(catalog_year);
    }
  }, [allowedBenefits, catalog_year, showingBenefitsForYear]);

  // Fetch benefits from API
  useEffect(() => {
    if (!!agency_id && !!catalog_year && shouldFetchBenefits) {
      dispatch(getBenefits({ agency_id, catalog_year }));
      hasFetchedBenefits.current = true;
      setShouldFetchBenefits(false);
    }
  }, [agency_id, catalog_year, dispatch, shouldFetchBenefits]);

  // Reset benefits
  useEffect(() => {
    if (shouldResetBenefits) {
      dispatch(resetBenefits());
      setShouldResetBenefits(false);
    }
  }, [dispatch, shouldResetBenefits]);

  // Don't fetch trainings from API on inital load when
  // redux state has been rehydrated, but still allow
  // trainings to be fetched when shopping is completed
  // or super admin is impersonating another user
  const [shouldFetchTraining, setShouldFetchTraining] = useState(false);
  const [shouldResetTraining, setShouldResetTraining] = useState(false);

  const allowedTraining = useMemo(
    () =>
      shoppingRequired === false &&
      showComingSoon === false &&
      ["level_2", "level_3"].includes(level) &&
      !!hasTraining,
    [hasTraining, level, shoppingRequired, showComingSoon]
  );

  const hasTrainingBenefits = useSelector(
    (state) => !!state.training && !!state.training.length
  );

  // Update traingings upon initial user authentication
  useEffect(() => {
    setShouldFetchTraining(
      !authTokenLoading && allowedTraining && !hasTrainingBenefits
    );
    setShouldResetTraining(
      !authTokenLoading && !allowedTraining && !!hasTrainingBenefits
    );
  }, [allowedTraining, authTokenLoading, hasTrainingBenefits]);

  // Fetch trainings
  useEffect(() => {
    if (!!agency_id && !!catalog_year && shouldFetchTraining) {
      dispatch(getTraining({ agency_id, catalog_year }));
      setShouldFetchTraining(false);
    }
  }, [agency_id, catalog_year, dispatch, shouldFetchTraining]);

  // Reset trainings
  useEffect(() => {
    if (shouldResetTraining) {
      dispatch(resetTraining());
      setShouldResetTraining(false);
    }
  }, [dispatch, shouldResetTraining]);
}
