import { createPortal } from "react-dom";
import { node, string } from "prop-types";

import usePortalTarget from "./use-portal-target";

export default function Portal({ children, className, id }) {
  const portalTarget = usePortalTarget(id, className);

  return createPortal(children, portalTarget);
}

Portal.propTypes = {
  children: node.isRequired,
  className: string,
  id: string.isRequired,
};
