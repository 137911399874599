import overviewImgX1 from "../../assets/img/dcsm-overview.jpg";
import overviewImgX2 from "../../assets/img/dcsm-overview@2x.jpg";

import { IconCare, IconTennisShoe } from "@uhc-tempo/icons";
import { BrandIcon, Typography } from "@uhc-tempo/components";
const { H5, Paragraph } = Typography;

export default function DcsmAlt() {
  return (
    <div className="ua-teaser--overview--outer ua-teaser--overview--outer__bg-gradient ua-margin-xxlg-bottom">
      <section className="ua-teaser--overview ua-teaser--overview__margin-sm">
        <h2 className="ua-teaser--overview--heading ua-teaser--overview--heading__lg ua-margin-lg-bottom">
          Unlock Preferred status, gain a star team member.
        </h2>
        <div className="ua-text-center ua-padding-xxxlg-bottom ua-width-80-lg ua-margin-auto">
          <Paragraph className="ua-font-size-h5">
            Upon reaching United Advantage Preferred status, you gain the
            invaluable support of a Dedicated Client Service Manager (DCSM). Far
            beyond being a mere point of contact at UnitedHealthcare, your DCSM
            will provide swift, service-oriented responses that will impress
            your clients.
          </Paragraph>
        </div>
        <div className="ua-teaser--overview--cards">
          <div className="ua-teaser--overview--cards--text">
            <div className="ua-teaser--overview--cards--text--content">
              <BrandIcon
                className="ua-teaser--overview--cards--text--content--icon"
                icon={IconCare}
                size="EXTRA_SMALL"
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <div>
                <H5 headingLevel={3}>Our promise</H5>
                <Paragraph className="ua-margin-none">
                  With Preferred status, you have our promise your Dedicated
                  Client Service Manager (DCSM) will deliver an exceptional
                  service experience for you and your UnitedHealthcare clients.
                  We promise to understand your unique business needs.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="ua-teaser--overview--cards--text">
            <div className="ua-teaser--overview--cards--text--content">
              <BrandIcon
                className="ua-teaser--overview--cards--text--content--icon"
                icon={IconTennisShoe}
                size="EXTRA_SMALL"
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <div>
                <H5 headingLevel={3}>The perfect pairing</H5>
                <Paragraph className="ua-margin-none">
                  Experience and training. Every DCSM completes a rigorous,
                  12-week training program before they begin working with any
                  agencies.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="ua-teaser--overview--cards--photos ua-teaser--slider">
            <div className="ua-teaser--slider--slides">
              <figure className="ua-teaser--slider--slides--slide">
                <img
                  alt=""
                  src={overviewImgX1}
                  srcSet={`${overviewImgX1} 1x, ${overviewImgX2} 2x`}
                />
                <figcaption>
                  <span className="ua-teaser--slider--slides--slide--points">
                    <strong>Valued Support</strong>
                  </span>{" "}
                  <span className="ua-teaser--slider--slides--slide--caption">
                    Frees up your team’s time!
                  </span>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
