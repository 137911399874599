import { arrayOf, bool, shape, string } from "prop-types";

import LottiePlayer from "../../../components/lottie-player/lottie-player";
import LottieWorksHeading from "../../../assets/lottie/search.json";
import HtmlContent from "../../../components/html-content/HtmlContent";
import { Typography } from "@uhc-tempo/components";
const { H5, Paragraph } = Typography;

export default function PerkHowItWorks({
  introContent,
  isNumbered = false,
  blurbs,
}) {
  if (!blurbs.length) return null;

  const colCount = blurbs.length > 3 ? 3 : blurbs.length;
  const colCountClassMap = {
    1: "ua-col-span-1-6-sm ua-col-span-3-2-md",
    2: "ua-col-auto-span-6-sm ua-col-auto-span-3-md",
    3: "ua-col-auto-span-6-sm ua-col-auto-span-2-md",
  };

  return (
    <div className="ua-col-span-1-6-sm ua-col-span-1-6-md perk-details__how-it-works">
      <div className="ua-display-flex ua-grid-gap-3-sm ua-align-items-center">
        <LottiePlayer
          animationData={LottieWorksHeading}
          options={{ keepLastFrame: true }}
          style={{ height: "56px", marginBottom: "40px" }}
        />
      </div>
      <div className="perk-details__blurb-large">
        <HtmlContent rawHtml={introContent} />
      </div>
      <div className="ua-grid ua-grid-cols-6 perk-details__how-it-works__blurbs">
        {blurbs.map((item, index) => (
          <div
            className={`${colCountClassMap[colCount]} perk-details__how-it-works__blurb`}
            key={index}
          >
            {isNumbered && (
              <div className="perk-details__items-numbered">{index + 1}</div>
            )}
            <H5 className="ua-text-center">{item.title}</H5>
            <Paragraph className="ua-text-center tds-text__paragraph-large">
              {item.content}
            </Paragraph>
          </div>
        ))}
      </div>
    </div>
  );
}

PerkHowItWorks.propTypes = {
  introContent: string,
  isNumbered: bool,
  blurbs: arrayOf(
    shape({
      title: string,
      content: string,
    })
  ),
};
