import { forwardRef } from "react";
import { motion } from "framer-motion";

import formatNumber from "../../../utilities/format-number";
import PriceTag from "../../../assets/img/price-tag.svg";

import { Typography } from "@uhc-tempo/components";
const { H1, H2 } = Typography;

function PerksHeroComponent(
  { curtainValue, hasMultipleAmounts = false, heroImage, points, title },
  ref
) {
  return (
    <div
      className="perks-hero"
      style={{ backgroundImage: `url(${heroImage})` }}
      ref={ref}
    >
      <div className="ua-grid ua-grid-cols-6 ua-grid-gap-3-sm perks-hero__content-area">
        <div className="ua-col-span-1-6-sm ua-col-span-2-4-md ua-text-center ua-text-primary-one perks-hero__page-title">
          <motion.div
            className="ua-bg-primary-three ua-border-color-primary-two perks-hero__page-title__curtain"
            style={{
              scale: curtainValue,
            }}
          />
          <div className="perks-hero__page-title__content">
            <H1 className="ua-font-size-display-1">{title}</H1>
            {!!points && (
              <div className="ua-display-flex ua-grid-gap-1-sm ua-align-items-center ua-justify-content-center perks-hero__points">
                <img src={PriceTag} alt="Price tag" width="19" height="19" />
                <H2 className="ua-font-sans ua-font-size-h5 ua-text-accent-six">
                  {hasMultipleAmounts && <span>Starting at</span>}{" "}
                  {formatNumber(points)}{" "}
                  <span className="ua-font-weight-normal">pts</span>
                </H2>
              </div>
            )}
            <svg
              className="perks-hero__scroll-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="18"
              viewBox="0 0 30 18"
              fill="none"
            >
              <path
                d="M29.3407 1.40956C29.5497 1.61827 29.7155 1.86613 29.8286 2.13898C29.9418 2.41183 30 2.7043 30 2.99967C30 3.29504 29.9418 3.58751 29.8286 3.86036C29.7155 4.13321 29.5497 4.38107 29.3407 4.58978L16.5898 17.3407C16.3811 17.5497 16.1332 17.7155 15.8604 17.8286C15.5875 17.9418 15.295 18 14.9997 18C14.7043 18 14.4118 17.9418 14.139 17.8286C13.8661 17.7155 13.6183 17.5497 13.4096 17.3407L0.658649 4.58978C0.449832 4.38096 0.284192 4.13306 0.171182 3.86023C0.0581716 3.5874 1.28555e-06 3.29498 1.31137e-06 2.99967C1.33719e-06 2.70436 0.0581717 2.41194 0.171182 2.13911C0.284193 1.86628 0.449832 1.61837 0.658649 1.40956C0.867466 1.20074 1.11537 1.0351 1.3882 0.922086C1.66103 0.809076 1.95345 0.750909 2.24877 0.750909C2.54408 0.750909 2.83649 0.809076 3.10932 0.922086C3.38216 1.0351 3.63006 1.20074 3.83887 1.40956L14.9997 12.5674L26.1605 1.40956C26.3692 1.20055 26.617 1.03474 26.8899 0.921608C27.1627 0.808477 27.4552 0.750248 27.7506 0.750248C28.0459 0.750248 28.3384 0.808477 28.6113 0.921608C28.8841 1.03474 29.132 1.20055 29.3407 1.40956Z"
                fill="#dadbdc"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

const PerksHero = forwardRef(PerksHeroComponent);

export default PerksHero;
