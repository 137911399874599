import { arrayOf, number, oneOfType, shape, string } from "prop-types";

import { Link, Typography } from "@uhc-tempo/components";

import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";
import HtmlContent from "../../components/html-content/HtmlContent";
import TrainingActionGroup from "./TrainingActionGroup";
import TrainingType from "./TrainingType";
import trackEvent from "../tracking/track-event";
import useTrainingtRootPath from "./use-training-root-path";

const { H6 } = Typography;

export default function TrainingList({ trainingBenefits = [] }) {
  const trainingRootPath = useTrainingtRootPath();

  function handleClick(benefit) {
    trackEvent({
      action: "training_details_learn_more",
      params: { training_id: benefit?.id, training_name: benefit?.name },
    });
  }

  return (
    <div>
      {trainingBenefits.map((benefit) => (
        <div
          key={benefit?.id}
          className="ua-border-bottom tds-padding-xxlg-top tds-padding-sm-bottom ua-display-block ua-display-flex-md ua-justify-content-flex-end"
        >
          <div
            className="tds-padding-xxlg-right ua-margin-lg-bottom"
            style={{ flex: 1 }}
          >
            <div className="ua-display-flex-lg tds-margin-md-bottom ua-align-items-flex-start">
              <H6 className="tds-margin-none-bottom tds-margin-md-right">
                {benefit.name}
              </H6>
              <TrainingType trainingType={benefit?.training_type} />
            </div>
            {!!benefit?.description && (
              <HtmlContent rawHtml={benefit?.description} />
            )}
            <TempoRouterLink
              component={Link}
              onClick={() => handleClick(benefit)}
              text="Learn more"
              to={`${trainingRootPath}/${benefit.slug}`}
            />
          </div>
          <div style={{ flexBasis: "375px" }}>
            <TrainingActionGroup benefit={benefit} />
          </div>
        </div>
      ))}
    </div>
  );
}

TrainingList.propTypes = {
  trainingBenefits: arrayOf(
    shape({
      description: string,
      id: oneOfType([number, string]),
      name: string,
      training_type: arrayOf(string),
    })
  ),
};
