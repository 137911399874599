import { array, string } from "prop-types";
import ResourceListItem from "./ResourceListItem";

import { Typography } from "@uhc-tempo/components";
const { H2 } = Typography;

export default function ResourceList({ slug, name, resources }) {
  return (
    <div id={slug} key={slug} className="tds-margin-xxlg-vertical">
      <H2
        className="ua-border-md-bottom ua-border-color-primary-one ua-padding-md-bottom ua-margin-none"
        headingStyle={3}
      >
        {name}
      </H2>
      {!!resources &&
        resources.map((resource, i) => (
          <ResourceListItem
            key={resource.slug}
            isLast={i + 1 === resources.length}
            {...resource}
          />
        ))}
    </div>
  );
}

ResourceList.propTypes = {
  name: string,
  resources: array,
  slug: string,
};
