import { bool, func, string } from "prop-types";
import Check from "@mui/icons-material/Check";

import { UtilityIcon } from "@uhc-tempo/components";

export default function ShoppingFilterAndSortAction({
  active,
  baseClassName,
  description,
  label,
  onBlur,
  onClick,
}) {
  function getActionClassName() {
    let actionClassName = `${baseClassName}__action`;

    if (active) {
      actionClassName += ` ${baseClassName}__action--active`;
    }

    return actionClassName;
  }

  return (
    <button
      className={getActionClassName()}
      onBlur={onBlur}
      onClick={onClick}
      role="menuitem"
      type="button"
    >
      <span className={`${baseClassName}__indicator`}>
        {active && (
          <UtilityIcon
            color="currentColor"
            icon={Check}
            size="small"
            title="active"
          />
        )}
      </span>
      <span className="ua-text-sentence-case__inline">{label}</span>
      {!!description && (
        <>
          {" "}
          <span className="ua-text-lower">({description})</span>
        </>
      )}
    </button>
  );
}

ShoppingFilterAndSortAction.propTypes = {
  active: bool,
  baseClassName: string.isRequired,
  description: string,
  label: string.isRequired,
  onBlur: func.isRequired,
  onClick: func.isRequired,
};
