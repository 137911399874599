export default function scrollIfNeeded(el) {
  if (!el) {
    return;
  }

  const bounds = el.getBoundingClientRect();

  if (
    bounds.top < 0 ||
    bounds.top >= (window.innerHeight || document.documentElement.clientHeight)
  ) {
    el.scrollIntoView({ behavior: "smooth" });
  }
}
