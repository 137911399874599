export default function normalizeStaticPagesResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    if (!data.data || !Array.isArray(data.data)) {
      throw new Error("Missing static pages");
    }

    return ["success", data.data];
  } catch (err) {
    return ["error"];
  }
}
