import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setImpersonate } from "./AdminRedux";

export default function useImpersonateState() {
  const catalogYearCurrent = useSelector(
    (state) => state.catalogYearCurrent || {}
  );
  const user = useSelector((state) => state.user);
  const impersonateState = useSelector((state) => state.impersonate);

  const defaultState = useMemo(
    () => getDefaultState(catalogYearCurrent, user),
    [catalogYearCurrent, user]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!impersonateState.changed) {
      dispatch(setImpersonate(defaultState));
    }
  }, [defaultState, dispatch, impersonateState]);

  return impersonateState;
}

function getDefaultState(catalogYearCurrent, user) {
  return {
    viewCatalogYear: catalogYearCurrent.year,
    viewMarket: !!user?.agency?.market_id
      ? parseInt(user.agency.market_id, 10)
      : "",
    viewTier: user?.agency?.level || "",
    viewAgency: !!user?.agency_id ? parseInt(user.agency_id, 10) : "",
    viewUser:
      !!user?.id && !user?.capabilities.includes("canImpersonate")
        ? parseInt(user.id, 10)
        : "",
  };
}
