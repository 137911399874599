import { useEffect, useMemo, useState } from "react";
import { func } from "prop-types";

import { Breadcrumbs, Notification, Typography } from "@uhc-tempo/components";

import ROUTES from "../../../data/constants/routes";
import TrainingActionGroup from "../../../features/training/TrainingActionGroup";
import HtmlContent from "../../../components/html-content/HtmlContent";
import NotFound from "../../not-found";
import PageLoadingIndicator from "../../../components/page-loading-indicator/PageLoadingIndicator";
import useTrainingFromParam from "../../../features/training/use-training-from-param";
import TrainingType from "../../../features/training/TrainingType";
import TempoRouterLink from "../../../components/tempo-router-link/TempoRouterLink";

const { H1, H2, H3, Paragraph } = Typography;

export default function TrainingDetails({ handlePageView }) {
  const [dismissError, setDismissError] = useState(false);

  const [training, trainingLoading, trainingError] = useTrainingFromParam();
  const { name: trainingName } = training || {};

  const trainingDataHeading = useMemo(
    () => getTrainingDataHeading(training),
    [training]
  );

  useEffect(() => {
    if (!!trainingName && !!handlePageView) {
      handlePageView(trainingName);
    }
  }, [handlePageView, trainingName]);

  if (trainingLoading) {
    return <PageLoadingIndicator />;
  }

  if (!!trainingError) {
    return (
      <section className="fluid-container page-main__fill ua-padding-jumbo-vertical">
        <div className="ua-grid ua-grid-gap-3 ua-grid-cols-12">
          <div className="ua-col-span-1-12-sm ua-col-span-2-10-md ua-col-span-3-8-lg">
            <Notification
              className="ua-col-auto-span-12-sm ua-col-auto-span-12-md"
              dismissClickHandler={() => setDismissError(true)}
              isDismissed={dismissError}
              notificationType="error"
            >
              <Paragraph className="tds-margin-none">{trainingError}</Paragraph>
            </Notification>
          </div>
        </div>
      </section>
    );
  }

  if (!training) {
    return <NotFound />;
  }

  return (
    <section className="page-main__fill ua-bg-tri-supporting-one-ten-forty-white ua-padding-jumbo-vertical">
      <div className="benefit-details">
        {(!!training?.heading || !!training?.title) && (
          <div className="benefit-details--heading">
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
              <div className="ua-col-span-1-12-sm ua-col-span-1-7-md ua-col-span-1-8-lg">
                <H1 className="ua-margin-xsm-bottom">
                  {training?.heading || training?.title}
                </H1>
              </div>
            </div>
          </div>
        )}
        {(!!training.name || !!training.description) && (
          <div className="benefit-details--content ua-padding-xlg-bottom">
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12 ua-margin-lg-bottom">
              <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
                <Breadcrumbs>
                  <TempoRouterLink
                    component={Breadcrumbs.Breadcrumb}
                    to={ROUTES.dashboard.path}
                  >
                    Home
                  </TempoRouterLink>
                  <TempoRouterLink
                    component={Breadcrumbs.Breadcrumb}
                    to={ROUTES.training.path}
                  >
                    Training &amp; Education
                  </TempoRouterLink>
                  <Breadcrumbs.Breadcrumb>
                    {trainingName}
                  </Breadcrumbs.Breadcrumb>
                </Breadcrumbs>
              </div>
            </div>
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12 ua-margin-lg-bottom">
              <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
                {!!training?.name && (
                  <H2 className="ua-margin-sm-bottom" headingStyle={3}>
                    {training.name}
                  </H2>
                )}
                {!!training?.training_type && (
                  <TrainingType trainingType={training.training_type} />
                )}
              </div>
            </div>
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
              <div className="ua-col-span-1-12-sm ua-col-span-1-8-md">
                {!!training?.description && (
                  <HtmlContent rawHtml={training.description} />
                )}
              </div>
            </div>
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
              <div className="ua-col-span-1-12-sm ua-col-span-1-5-md">
                {!!training.training_data &&
                  !!training.training_data.length && (
                    <div>
                      <H3>{trainingDataHeading}</H3>
                      <TrainingActionGroup benefit={training} />
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
        {!!training.best_for && (
          <div className="benefit-details--content benefit-details--benefit ua-padding-none-vertical">
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-md ua-grid-cols-8">
              <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
                <H3>Best for</H3>
                <div className="shopping-details-modal--content__content-override">
                  <HtmlContent rawHtml={training.best_for} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!!training.features && (
          <div className="benefit-details--content benefit-details--benefit ua-padding-none-vertical">
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-md ua-grid-cols-8">
              <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
                <H3>Features</H3>
                <div className="shopping-details-modal--content__content-override">
                  <HtmlContent rawHtml={training.features} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!!training.how_it_works && (
          <div className="benefit-details--content benefit-details--benefit ua-padding-none-vertical">
            <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-md ua-grid-cols-8">
              <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
                <H3>How it works</H3>
                <div className="shopping-details-modal--content__content-override">
                  <HtmlContent rawHtml={training.how_it_works} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

function getTrainingDataHeading(training) {
  let heading = "Registration";

  // trainingType 1 = Virtual
  // trainingType 2 = On Demand
  if (
    !!training &&
    !!training.training_data &&
    !!training.training_data.length &&
    !!training.training_type &&
    !!training.training_type.length &&
    !training.training_type.includes("1")
  ) {
    const { action, topic } = training.training_data[0];

    if (
      ["external", "internal"].includes(action) ||
      (training.training_type.includes("2") && action === "contact")
    ) {
      heading = !!topic ? "Topics" : "Get Started";
    }
  }

  return heading;
}

TrainingDetails.propTypes = {
  handlePageView: func.isRequired,
};
