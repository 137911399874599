import { func, number, string } from "prop-types";

export default function ShoppingCart({
  className = "",
  handleSubmit,
  id = "cart",
  quantity = 0,
}) {
  return (
    <button
      className={`shopping-cart ${className}`}
      disabled={!quantity}
      onClick={handleSubmit}
      type="button"
    >
      <svg
        aria-describedby={id}
        role="img"
        viewBox="0 0 47 41"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title id={id}>Cart: {quantity}</title>
        <path d="M13,37a3,3,0,1,1-3-3A3,3,0,0,1,13,37Zm12-3a3,3,0,1,0,3,3A3,3,0,0,0,25,34ZM45.5,12a11,11,0,0,1-11,11,10.88,10.88,0,0,1-6.7-2.3L25.45,25a3,3,0,0,1-2.62,1.54H11.65L10.3,29l0,.17a.37.37,0,0,0,.37.38H28v3H10a3,3,0,0,1-3-3,2.89,2.89,0,0,1,.38-1.44l2-3.67L4,13H1V10H5.91l1.41,3H23.55c0-.33-.05-.66-.05-1a11,11,0,0,1,22,0Zm-1,0a10,10,0,1,0-10,10A10,10,0,0,0,44.5,12Z" />
        <text x="34.75" y="16.25" textAnchor="middle">
          {quantity}
        </text>
      </svg>
    </button>
  );
}

ShoppingCart.propTypes = {
  className: string,
  handleSubmit: func.isRequired,
  id: string,
  quantity: number,
};
