import { number, string } from "prop-types";
import { useSelector } from "react-redux";

import trackLinkClick from "../tracking/track-link-click";

import { Button } from "@uhc-tempo/components";

import ChatBubbleX1 from "../../assets/img/ChatBubble_Illustration_CMYK-question-mark@1x.png";
import ChatBubbleX2 from "../../assets/img/ChatBubble_Illustration_CMYK-question-mark@2x.png";

export default function Projection({
  agencyLevel,
  className,
  ctaLink,
  ctaText,
  ctaTrackingEvent = "ua_status_cta",
  ctaTrackingLabel = "Help understanding status projection",
  nextYear,
  projectedClass,
  projectedStatus,
}) {
  const { first_name } = useSelector((state) => state.user) || {};

  function trackClick() {
    if (ctaLink) {
      trackLinkClick({
        event: ctaTrackingEvent,
        label: ctaTrackingLabel,
        link: ctaLink,
      });
    }
  }

  const baseClass = "ua-projection";
  let sectionClass = `fluid-container ${baseClass}`;

  if (!!className) {
    sectionClass += ` ${className}`;
  }

  return (
    <section className={sectionClass}>
      <div className="ua-projection--callout">
        <div className="ua-projection--graphic">
          <img
            alt="chat bubble illustration"
            src={ChatBubbleX1}
            srcSet={`${ChatBubbleX1} 1x, ${ChatBubbleX2} 2x`}
            width={267}
            height={160}
          />
        </div>
        <div className="ua-projection--text">
          <h2 className="ua-projection--text--headline">
            <span>
              {nextYear} Projection:{" "}
              <span className={`ua-text-upper ua-text-${projectedClass}`}>
                {projectedStatus}
              </span>
            </span>
          </h2>
          {agencyLevel === "non-ua" ? (
            <p className="ua-projection--text--lead">
              For help understanding your United <br />
              Advantage scorecard, please contact <br />
              your local market rep.
            </p>
          ) : (
            <p className="ua-projection--text--lead">
              {first_name}, do you want help understanding
              <br /> your agency’s status projection?
            </p>
          )}
          {!!ctaLink && !!ctaText && (
            <Button
              buttonType="secondary-one"
              href={ctaLink}
              onClick={trackClick}
              rel="noopener noreferrer"
              target="_blank"
            >
              {ctaText}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
}

Projection.propTypes = {
  agencyLevel: string,
  className: string,
  ctaLink: string,
  ctaText: string,
  ctaTrackingEvent: string,
  ctaTrackingLabel: string,
  nextYear: number,
  projectedClass: string,
  projectedStatus: string,
};
