import AccessAltSlider from "./AccessAltSlider";

import { IconBrain, IconGift } from "@uhc-tempo/icons";
import { BrandIcon, Typography } from "@uhc-tempo/components";
const { H5, Paragraph } = Typography;

export default function AccessAlt() {
  return (
    <div className="ua-teaser--overview--outer ua-teaser--overview--outer__bg-gradient">
      <section className="ua-teaser--overview ua-teaser--overview__margin-sm">
        <h2 className="ua-teaser--overview--heading ua-teaser--overview--heading__lg">
          Premier status sneak peek –{" "}
          <span className="ua-text-nowrap">unlock a world of choice.</span>
        </h2>
        <div className="ua-teaser--overview--cards">
          <div className="ua-teaser--overview--cards--text">
            <div className="ua-teaser--overview--cards--text--content">
              <BrandIcon
                className="ua-teaser--overview--cards--text--content--icon"
                icon={IconGift}
                size="EXTRA_SMALL"
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <div>
                <H5 headingLevel={3}>Customize your UA&nbsp;experience</H5>
                <Paragraph className="ua-margin-none">
                  When you reach Premier status you’re in the driver‘s seat with
                  how your agency is rewarded — shop from 24 unique perks in
                  areas like travel, advertising, technology, training,
                  and more.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="ua-teaser--overview--cards--text">
            <div className="ua-teaser--overview--cards--text--content">
              <BrandIcon
                className="ua-teaser--overview--cards--text--content--icon"
                icon={IconBrain}
                size="EXTRA_SMALL"
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <div>
                <H5 headingLevel={3}>The new Advantage Coach</H5>
                <Paragraph className="ua-margin-none">
                  Premier also gives you direct access to the UA Advantage
                  Coach. They will help you make the most of all the exciting
                  new features of UA.
                </Paragraph>
              </div>
            </div>
          </div>
          <AccessAltSlider className="ua-teaser--overview--cards--photos" />
        </div>
      </section>
    </div>
  );
}
