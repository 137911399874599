import { useSelector } from "react-redux";
import ROUTES from "../../data/constants/routes";

import { Typography } from "@uhc-tempo/components";

import InviteUsersForm from "../../features/user/InviteUsers";

const { H1, Paragraph } = Typography;

export default function Invite() {
  const marketName = useSelector(({ user }) => user.agency.market.name);

  return (
    <section className="fluid-container ua-padding-xxxxlg-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-3-8-lg ua-col-span-2-10-md ua-col-span-1-12-sm">
          <H1 className="tds-margin-md-bottom">{ROUTES.invite.title}</H1>
          <Paragraph>
            Let everyone enjoy the benefits of your top-performing
            agency&rsquo;s status.
          </Paragraph>
          <Paragraph className="tds-margin-none-bottom tds-margin-xlg-top">
            <strong>
              You provide first names and emails and we&rsquo;ll do the rest.
            </strong>
          </Paragraph>
          <Paragraph bodyStyle={3} className="tds-margin-xxlg-bottom">
            Remember this is only for team members in the {marketName}.
          </Paragraph>
          <InviteUsersForm />
        </div>
      </div>
    </section>
  );
}
