import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ROUTES from "../../../data/constants/routes";
import { updateShoppingMethod } from "../../../features/shopping/ShoppingRedux";
import trackEvent from "../../../features/tracking/track-event";

import MastheadAlt from "../../../components/masthead-alt/MastheadAlt";
import VimeoBackground from "../../../components/vimeo-player/VimeoBackground";

import { Button } from "@uhc-tempo/components";

export default function ShoppingBonusIntro(props) {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const { first_name } = useSelector((state) => state.user) || {};
  const bgImage =
    useSelector(({ settings }) => settings?.shopping_bonus_intro_bg_image) ||
    "";
  const bgVideo =
    useSelector(({ settings }) => settings?.shopping_bonus_intro_bg_video) ||
    "";

  const clicked = useRef(false);

  const handleClick = useCallback(
    ({ skipRedirect = false }) => {
      clicked.current = true;
      dispatch(updateShoppingMethod("bonus"))
        .unwrap()
        .then((response) => {
          trackEvent({ action: "shopping_method_bonus" });

          if (!skipRedirect) {
            navigate(ROUTES.shoppingBonusChoose.path);
          }
        });
    },
    [dispatch, navigate]
  );

  // Just in case the user doesn't use the CTA button
  // to navigate away from this page
  useEffect(() => {
    return () => {
      if (!clicked.current) {
        handleClick({ skipRedirect: true });
      }
    };
  }, [handleClick]);

  return (
    <>
      <MastheadAlt component="header" />
      <section className="ua-shopping-intro">
        <div className="ua-shopping-intro--content">
          <h1 className="ua-shopping-intro--h1">
            Congrats on earning more points!
          </h1>
          <h2 className="ua-shopping-intro--h2">
            {!!first_name ? `${first_name}, i` : "I"}t’s here!{" "}
            <span className="ua-text-nowrap">Bonus shopping starts now.</span>
          </h2>
          <p className="ua-shopping-intro--text">
            Working together.{" "}
            <span className="ua-text-no-wrap">Succeeding together.</span>
          </p>
          <Button buttonType="primary-two" onClick={handleClick} size="lg">
            Shop Now
          </Button>
        </div>
        <VimeoBackground
          id="shopping-intro"
          poster={bgImage}
          vimeoUrl={bgVideo}
        />
      </section>
    </>
  );
}
