import { useSelector } from "react-redux";
import AccountCircle from "@mui/icons-material/AccountCircle";

import ProfileMenu from "./ProfileMenu";
import PopupMenu from "../../components/popup-menu/PopupMenu";

import { Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function AccountNavigation() {
  const user = useSelector((state) => state.user);

  return (
    !!user && (
      <div className="page-header__user">
        <div className="ua-display-flex">
          <div className="ua-text-right">
            <PopupMenu
              label={`${user?.first_name} ${user?.last_name}`}
              icon={AccountCircle}
            >
              <ProfileMenu />
            </PopupMenu>
            {!!user?.agency && (
              <>
                <Paragraph
                  bodyStyle={4}
                  className="tds-margin-none-bottom tds-margin-xlg-right"
                >
                  {user?.agency?.name}
                </Paragraph>
                <Paragraph
                  bodyStyle={4}
                  className="tds-margin-none-bottom tds-margin-xlg-right"
                >
                  UA Agency ID: {user?.agency?.ra_number}
                </Paragraph>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
}
