import ROUTES from "../../data/constants/routes";

import { Button, Typography } from "@uhc-tempo/components";

import CompassImgX1 from "../../assets/img/Illustration_Compass_RGB@1x.png";
import CompassImgX2 from "../../assets/img/Illustration_Compass_RGB@2x.png";

import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";

const { H1, Paragraph } = Typography;

export default function NotFound() {
  return (
    <section className="fluid-container page-main__fill ua-padding-jumbo-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-3-3-lg ua-col-span-2-10-md ua-col-span-1-12-sm ua-text-center">
          <img
            src={CompassImgX1}
            srcSet={`${CompassImgX1} 1x, ${CompassImgX2} 2x`}
            width="302"
            height="302"
            alt="compass"
          />
        </div>

        <div className="ua-col-span-6-5-lg ua-col-span-2-10-md ua-col-span-1-12-sm ua-align-self-center">
          <H1 className="ua-text-center">
            Oops, the page you’re looking for cannot be found.
          </H1>
          <Paragraph className="ua-text-center">
            <TempoRouterLink component={Button} to={ROUTES.default.path}>
              Take me home
            </TempoRouterLink>
          </Paragraph>
        </div>
      </div>
    </section>
  );
}
