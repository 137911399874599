import { useState } from "react";
import { string } from "prop-types";
import Loop from "@mui/icons-material/Loop";
import SaveAlt from "@mui/icons-material/SaveAlt";
import { saveAs } from "file-saver";

import { Link, Typography, UtilityIcon } from "@uhc-tempo/components";

import trackEvent from "../tracking/track-event";

const { Paragraph } = Typography;

export default function ResourceDownload({ link, linkText }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function handleClick(event) {
    event.preventDefault();

    try {
      if (!link || link === "#") {
        throw new Error("Missing link");
      }

      let fileName = link.split("/").pop();

      if (!fileName) {
        fileName = null;
      }

      setLoading(true);
      saveAs(link, fileName);
      setLoading(false);

      trackEvent({
        action: "training_resources_materials_download",
        params: { link_text: linkText, link_url: link },
      });
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div className="resource-list--materials--download">
      {loading ? (
        <Link
          className="ua-font-weight-normal ua-text-gray-five"
          noIcon
          disabled
        >
          <UtilityIcon
            className="ua-margin-md-right"
            color="currentColor"
            icon={Loop}
            size="medium"
            title="Loading"
          />
          <span className="ua-display-block">Loading…</span>
        </Link>
      ) : (
        <Link
          className="ua-font-weight-normal"
          href={link}
          onClick={handleClick}
          noIcon
        >
          <UtilityIcon
            className="ua-margin-md-right"
            color="currentColor"
            icon={SaveAlt}
            size="medium"
            title="Download"
          />
          <span className="ua-display-block">{linkText}</span>
        </Link>
      )}
      {!!error && (
        <Paragraph className="ua-text-danger" bodyStyle={4}>
          {error}
        </Paragraph>
      )}
    </div>
  );
}

ResourceDownload.propTypes = {
  link: string.isRequired,
  linkText: string.isRequired,
};
