import Bugsnag from "@bugsnag/js";
import { HOST_ENV } from "../constants/urls";
import { UA_API_EP_AUTH_LOGIN } from "./ua-api-definitions";
import { store } from "../redux/store";
import { logout } from "../../features/auth/AuthRedux";

export default function processError(error) {
  const [statusCode, message, errors] = parseError(error);

  if (HOST_ENV === "development") {
    console.error(error.toJSON());
  }

  if (
    (!statusCode && !["Canceled", "Request aborted"].includes(message)) ||
    (!!statusCode && statusCode >= 500)
  ) {
    Bugsnag.notify(error);
  }

  if (
    statusCode === 401 &&
    error.config &&
    error.config.url !== UA_API_EP_AUTH_LOGIN
  ) {
    store.dispatch(logout("expired"));
  }

  return Promise.reject({
    status: "error",
    message,
    errors,
  });
}

function parseError(error) {
  const statusCode =
    (!!error && !!error.response && error.response.status) || null;
  const [message, errors = []] = parseErrorMessage(error, statusCode);

  return [statusCode, message, errors];
}

function parseErrorMessage(error, statusCode) {
  try {
    if (!error) {
      return ["Unknown error"];
    }

    if (typeof error === "string") {
      return [error];
    }

    const errorData =
      error.data ||
      (!!error.response && error.response.data) ||
      (!!error.response && error.response.error && error.response);

    if (
      !!errorData &&
      // Ignore raw HTML responses and move onto the status code
      (typeof errorData !== "string" ||
        (typeof errorData === "string" && !/<html/g.test(errorData)))
    ) {
      return getErrorMessageFromData(errorData);
    }

    if (!!statusCode) {
      return [getErrorMessageFromStatusCode(statusCode)];
    }

    if (!!error.message) {
      return [error.message];
    }
  } catch (err) {}

  return ["Unrecognized error"];
}

function getErrorMessageFromData(errorData) {
  if (typeof errorData === "string") {
    return [errorData];
  }

  if (!!errorData.message || !!errorData.error) {
    let message = errorData.message || errorData.error;
    let errors = [];

    if (!!errorData.errors) {
      errors = Array.isArray(errorData.errors)
        ? errors.concat(errorData.errors)
        : errors.concat(Object.values(errorData.errors));
    }

    if (!!message && typeof message === "object" && !Array.isArray(message)) {
      try {
        message = Object.values(message).join(", ");
      } catch (err) {
        message = "Error";
      }
    }

    return [message, errors];
  }
}

function getErrorMessageFromStatusCode(statusCode) {
  switch (statusCode) {
    case 400:
      return "Bad Request";
    case 401:
      return "Unauthorized Request";
    case 403:
      return "Forbidden Request";
    case 404:
      return "Request Not Found";
    case 405:
      return "Method Not Allowed";
    case 429:
      return "Too Many Requests";
    case 500:
      return "Server Error";
    case 503:
      return "Service Unavailable";
    default:
      return `${statusCode} Error`;
  }
}
