import { number } from "prop-types";

import heroImgX1 from "../../assets/img/hero-status-alt.jpg";
import heroImgX2 from "../../assets/img/hero-status-alt@2x.jpg";

export default function HeaderAlt({ catalog_year_next }) {
  return (
    <header className="ua-teaser--header ua-teaser--header__no-masthead">
      <h1 className="ua-teaser--header--heading">
        <span className="ua-teaser--header--heading--small">
          Track your {catalog_year_next} United Advantage tier status
        </span>{" "}
        <span className="ua-teaser--header--heading--large">
          Your Agency Scorecard
        </span>
      </h1>

      <div className="ua-teaser--header--image-bg">
        <img
          alt="Dedicated Client Service Manager (DCSM)"
          className="align-center"
          src={heroImgX1}
          srcSet={`${heroImgX1} 1x, ${heroImgX2} 2x`}
          width={1440}
          height={520}
        />
      </div>
    </header>
  );
}

HeaderAlt.propTypes = {
  catalog_year_next: number,
};
