import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import UA_API, { UA_API_EP_SCORECARD } from "../../data/ua-api/ua-api";
import { isLogout } from "../auth/AuthRedux";
import normalizeStatusDataResponse from "./normalize-status-data-response";

const initialState = { availableTiers: [], tiers: {} };

export const getStatus = createAsyncThunk(
  "status/data",
  async function fetchStatus({ agency_id, catalog_year }, { rejectWithValue }) {
    try {
      const response = await UA_API.get(
        `${UA_API_EP_SCORECARD}?agency_id=${agency_id}&catalog_year=${catalog_year}`
      );

      let [statusDataStatus, statusData, message] =
        normalizeStatusDataResponse(response);

      if (statusDataStatus === "error") {
        throw new Error(message);
      }

      return statusData;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const statusReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getStatus.fulfilled, (state, action) => action.payload)
    .addMatcher(isLogout, (state, action) => initialState);
});

export const statusErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getStatus.pending, (state, action) => null)
    .addCase(getStatus.fulfilled, (state, action) => null)
    .addCase(getStatus.rejected, (state, action) => action.payload);
});

export const statusLoadingReducer = createReducer(true, (builder) => {
  builder
    .addCase(getStatus.pending, (state, action) => true)
    .addCase(getStatus.fulfilled, (state, action) => false)
    .addCase(getStatus.rejected, (state, action) => false);
});
