import {
  createAction,
  createAsyncThunk,
  createReducer,
  isAnyOf,
} from "@reduxjs/toolkit";

import UA_API, { UA_API_EP_USERS } from "../../data/ua-api/ua-api";
import { isLogout } from "../auth/AuthRedux";
import normalizeUserResponse from "./normalize-user-response";

const initialState = {};

export const getUser = createAsyncThunk(
  "user/get",
  async function fetchUser({ id, isAdmin = false }, { rejectWithValue }) {
    try {
      const response = await UA_API.get(`${UA_API_EP_USERS}/${id}`);
      let [status, user, message] = normalizeUserResponse(response);

      if (status === "error") {
        throw new Error(message);
      }

      // Force coming soon to false because this is an admin session
      if (isAdmin) {
        user.showComingSoon = false;
      }

      return user;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const setUser = createAction("user/set");

export const putUser = createAsyncThunk(
  "user/put",
  async function putUser(data, { getState, rejectWithValue }) {
    try {
      const id = data.id;
      const response = await UA_API.put(`${UA_API_EP_USERS}/${id}`, data);
      let [status, user, message] = normalizeUserResponse(response);

      if (status === "error") {
        throw new Error(message);
      }

      try {
        const { user: prevUser } = getState();

        if (
          !!user?.id &&
          !!prevUser?.id &&
          user.id === prevUser.id &&
          !user.hasOwnProperty("showComingSoon")
        ) {
          user.showComingSoon = prevUser.showComingSoon;
        }
      } catch (err) {}

      return { user, message };
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const updateUser = createAction("user/update");

const isSuccess = isAnyOf(setUser, getUser.fulfilled);
const isFailure = isAnyOf(getUser.rejected, putUser.rejected);

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(putUser.fulfilled, (state, action) => ({ ...action.payload.user }))
    .addCase(updateUser, (state, action) => ({ ...state, ...action.payload }))
    .addMatcher(isSuccess, (state, action) => ({ ...action.payload }))
    .addMatcher(isFailure, (state, action) => state)
    .addMatcher(isLogout, (state, action) => initialState);
});
