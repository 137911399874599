import { node, string } from "prop-types";

import isInternalLink from "../../utilities/is-internal-link";
import trackLinkClick from "../tracking/track-link-click";

import { Button, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function ResourceLink({ link, linkText = "Learn more" }) {
  const isLinkInternal = isInternalLink(link);

  function handleClick() {
    trackLinkClick({ event: "training_resources_link", label: linkText, link });
  }

  if (!link) {
    return null;
  }

  if (link === "#") {
    return (
      <Paragraph bodyStyle={1} className="ua-margin-none" useItalic>
        {linkText}
      </Paragraph>
    );
  }

  return (
    <Button
      href={link}
      rel={isLinkInternal ? "" : "noopener noreferrer"}
      target={isLinkInternal ? "" : "_blank"}
      onClick={handleClick}
    >
      {linkText}
    </Button>
  );
}

ResourceLink.propTypes = {
  link: string,
  linkText: node,
};
