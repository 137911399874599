import { useMemo } from "react";
import {
  bool,
  element,
  func,
  node,
  oneOfType,
  number,
  string,
} from "prop-types";

import formatNumber from "../../utilities/format-number";
import getLevelName from "../../utilities/get-level-name";

export default function Requirement({ children, index, ...props }) {
  const isFirst = index === 0;
  const number = index + 1;

  return (
    <>
      {index > 0 && <Separator />}
      <Criteria {...props} number={number} name={children} />
      <Target {...props} isFirst={isFirst} />
      <Current {...props} />
      <Remaining {...props} />
    </>
  );
}

function Criteria({ hideIndex, number, name, showInfo }) {
  return (
    <div className="ua-req--criteria">
      <div className="ua-req--criteria--label" id={`tier-criteria-${number}`}>
        {!hideIndex && <div className="ua-req--criteria--number">{number}</div>}
        <div className="ua-req--criteria--text">
          <h4 className="ua-req--criteria--text--name">
            <strong>{name}</strong>
          </h4>
          {!!showInfo && (
            <button
              className="ua-req--criteria--text--info"
              onClick={showInfo}
              type="button"
            >
              What does this mean?
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function Target({ isFirst, label, labelTarget, target, tier }) {
  const firstClass = isFirst ? "ua-req--target__first" : "";
  const textColor = useMemo(
    () => (tier === "level_3" ? "primary-three" : tier),
    [tier]
  );

  return (
    <div className={`ua-req--target ${firstClass} ua-text-${textColor}`}>
      {isFirst && (
        <svg
          className="ua-req--target--pointer"
          height={28}
          role="presentation"
          viewBox="0 0 56 28"
          width={56}
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="0 0 28 28 56 0 0 0" />
        </svg>
      )}
      <p className="ua-req--target--text">
        <span className="ua-req--target--text--value">
          {formatNumber(target)}
        </span>{" "}
        <span>
          <strong>{labelTarget}</strong> {label}
        </span>
      </p>
    </div>
  );
}

function Current({ current, label, labelCurrent, showSign }) {
  return (
    <div aria-describedby="tier-has" className="ua-req--current">
      <p className="ua-req--current--text">
        <span className="ua-req--current--text--value">
          {showSign && current > 0 && "+"}
          {formatNumber(current)}
        </span>{" "}
        <span>
          <strong>{labelCurrent}</strong> {label}
        </span>
      </p>
    </div>
  );
}

function Remaining({ remaining, tier }) {
  const tierLabel = useMemo(() => getLevelName(tier).toLowerCase(), [tier]);

  return remaining <= 0 ? (
    <div aria-describedby="tier-needs" className="ua-req--remaining">
      <p className="ua-req--remaining--text">
        <svg
          height={35}
          role="presentation"
          viewBox="0 0 35 35"
          width={35}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17.5" cy="17.5" r="17.5" />
          <path d="M13.77,21.42l-4.56-4.56-1.55,1.54,6.11,6.11,13.12-13.13-1.54-1.54-11.58,11.57Z" />
        </svg>
        <span>
          <strong>maintain</strong> {tier !== "level_1" && "for"} {tierLabel}
        </span>
      </p>
    </div>
  ) : (
    <div className="ua-req--remaining">
      <p className="ua-req--remaining--text">
        <span className="ua-req--remaining--text--value">
          {formatNumber(remaining)}
        </span>{" "}
        <span>
          <strong>to go</strong> for {tierLabel}
        </span>
      </p>
    </div>
  );
}

function PlusIcon() {
  return (
    <div className="ua-req--sep--plus">
      <svg
        height={18}
        role="presentation"
        viewBox="0 0 18 18"
        width={18}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0,10.76H7.24v7.24h3.48v-7.24h7.28v-3.48h-7.28V0h-3.48V7.28H0v3.48Z" />
      </svg>
    </div>
  );
}

function Separator() {
  return (
    <>
      <div className="ua-req--sep ua-req--sep__criteria" role="presentation" />
      <div className="ua-req--sep ua-req--sep__target" role="presentation">
        <PlusIcon />
      </div>
      <div className="ua-req--sep ua-req--sep__current" role="presentation" />
      <div className="ua-req--sep ua-req--sep__remaining" role="presentation">
        <PlusIcon />
      </div>
    </>
  );
}

Requirement.propTypes = {
  children: oneOfType([element, func, node]),
  current: number,
  hideIndex: bool,
  index: number,
  label: string,
  labelCurrent: string,
  labelTarget: string,
  remaining: number,
  showInfo: func,
  showSign: bool,
  target: number,
  tier: string,
};
