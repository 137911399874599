import { useEffect } from "react";
import { func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getStatus } from "../../features/status/StatusRedux";
import routes from "../../data/constants/routes";
import { SCREEN_MEDIUM } from "../../data/constants/media-queries";
import useUserAgency from "../../features/user/use-user-agency";
import useMatchMedia from "../../features/page-layout/use-match-media";

import StatusMobileDisclaimer from "../../features/status/StatusMobileDisclaimer";
import StatusPageLevel1 from "../../features/status/StatusPageLevel1";
import StatusPageLevel2AndUp from "../../features/status/StatusPageLevel2AndUp";
import StatusPageNon from "../../features/status/StatusPageNon";

export default function Status({ handlePageView }) {
  const dispatch = useDispatch();

  const {
    id: agency_id,
    level: agencyLevel,
    logo,
    name: agencyName,
    ra_number,
  } = useUserAgency();
  const { next: catalog_year_next, year: catalog_year } = useSelector(
    (state) => state.catalogYearCurrent || {}
  );

  const status = useSelector((state) => state.status);
  const statusError = useSelector((state) => state.statusError);
  const statusLoading = useSelector((state) => state.statusLoading);

  const isScreenMd = useMatchMedia(SCREEN_MEDIUM);

  // Set page title with next catalog year
  useEffect(() => {
    if (!!handlePageView) {
      handlePageView(`${catalog_year_next} ${routes.status.title}`);
    }
  }, [catalog_year_next, handlePageView]);

  // Load data from API, but only if not a mobile device and
  // RA number doesn't match previous response
  const status_ra_number = !!status && status.RewardAccountNumber;
  const shouldLoad =
    isScreenMd &&
    (!status_ra_number || `${ra_number}` !== `${status_ra_number}`);

  useEffect(() => {
    if (shouldLoad && !!agency_id && !!catalog_year) {
      dispatch(getStatus({ agency_id, catalog_year }));
    }
  }, [agency_id, catalog_year, dispatch, shouldLoad]);

  if (!isScreenMd) {
    return (
      <StatusMobileDisclaimer
        agencyName={agencyName}
        catalog_year_next={catalog_year_next}
        logo={logo}
      />
    );
  }

  if (!!agencyLevel && ["level_2", "level_3"].includes(agencyLevel)) {
    return (
      <StatusPageLevel2AndUp
        agency_id={agency_id}
        agencyName={agencyName}
        catalog_year={catalog_year}
        catalog_year_next={catalog_year_next}
        logo={logo}
        status={status}
        statusError={statusError}
        statusLoading={statusLoading}
      />
    );
  }

  if (agencyLevel === "level_1") {
    return (
      <StatusPageLevel1
        agency_id={agency_id}
        agencyName={agencyName}
        catalog_year={catalog_year}
        catalog_year_next={catalog_year_next}
        logo={logo}
        status={status}
        statusError={statusError}
        statusLoading={statusLoading}
      />
    );
  }

  if (agencyLevel === "non-ua") {
    return (
      <StatusPageNon
        agencyName={agencyName}
        catalog_year={catalog_year}
        catalog_year_next={catalog_year_next}
        logo={logo}
        status={status}
        statusError={statusError}
        statusLoading={statusLoading}
      />
    );
  }

  return null;
}

Status.propTypes = {
  handlePageView: func.isRequired,
};
