import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../data/constants/routes";

export default function useTrainingAvailable() {
  const checkInProgress = useSelector((state) => state.trainingLoading);
  const trainingAvailable = useSelector(({ user }) => !!user?.hasTraining);
  const navigate = useNavigate();

  useEffect(() => {
    if (!trainingAvailable) {
      navigate(ROUTES.default.path);
    }
  }, [trainingAvailable, navigate]);

  return [checkInProgress, trainingAvailable];
}
