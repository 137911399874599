import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { useMemo } from "react";
import { sortOptionsObj } from "./use-filtered-and-sorted-benefits";

const sortOptionsArray = !!sortOptionsObj ? Object.values(sortOptionsObj) : [];

const hasMostPopularSelector = createSelector(
  (state) => state.shopping?.benefits || [],
  (benefits) => benefits.some((benefit) => !!benefit.most_popular)
);

export default function useShoppingSortOptions() {
  const hasMostPopular = useSelector((state) => hasMostPopularSelector(state));

  const sortOptions = useMemo(
    () =>
      hasMostPopular
        ? sortOptionsArray
        : sortOptionsArray.filter((option) => option.value !== "popular"),
    [hasMostPopular]
  );

  return sortOptions;
}
