import { array, number, oneOfType, string } from "prop-types";

import TrainingDate from "./TrainingDate";
import TrainingButton from "./TrainingButton";

export default function TrainingActionGroup({ benefit = {} }) {
  const {
    training_data: trainingData = [],
    id,
    catalog_year_id: catalogYearId,
    name,
    training_type,
  } = benefit;

  if (!trainingData || !trainingData.length) {
    return null;
  }

  return (
    <div className="ua-display-block">
      {trainingData.map((trainingSession, i) => (
        <div
          className="ua-display-flex ua-align-items-center tds-margin-md-bottom"
          key={`training-session-${id}-${i}`}
        >
          <TrainingDate
            training={trainingSession}
            className="ua-width-50 ua-align-items-center"
          />
          <div className="ua-display-flex ua-justify-content-flex-end ua-width-50 tds-padding-md-left">
            <TrainingButton
              catalogYearId={catalogYearId}
              id={id}
              name={name}
              trainingSession={trainingSession}
              trainingType={training_type}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

TrainingActionGroup.propTypes = {
  catalog_year_id: oneOfType([number, string]),
  id: oneOfType([number, string]),
  name: string,
  training_data: array,
};
