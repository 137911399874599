import CheckmarkIllustration from "../../assets/img/checkmark-illustration.svg";
import EmailIllustration from "../../assets/img/email-illustration.svg";
import PhoneIllustration from "../../assets/img/phone-illustration.svg";

import { Typography } from "@uhc-tempo/components";
const { H1, Paragraph } = Typography;

export default function ShoppingConfirmation(props) {
  return (
    <section className="fluid-container page-main__fill ua-padding-jumbo-vertical ua-bg-primary-one shopping-confirmation">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-3-8-md ua-col-span-4-6-lg shopping-confirmation__grid-padding">
          <div className="ua-bg-primary-three ua-border ua-border-radius-sm ua-border-color-gray-four shopping-confirmation__card-padding">
            <div className="ua-margin-auto ua-text-center">
              <img
                src={CheckmarkIllustration}
                alt="Thumbs up"
                width="64"
                height="64"
              />
              <H1
                className="ua-margin-jumbo-bottom ua-margin-lg-top ua-font-size-xlg"
                headingStyle={2}
              >
                You&apos;re all set!
              </H1>
            </div>
            <div className="shopping-confirmation__content">
              <Paragraph className="ua-text-primary-one ua-font-weight-bold ua-font-size-h6 ua-margin-lg-bottom">
                What&apos;s next?
              </Paragraph>
              <div className="ua-display-flex ua-grid-gap-2-sm ua-align-items-flex-start">
                <img
                  src={EmailIllustration}
                  alt="Thumbs up"
                  width="48"
                  height="48"
                />
                <Paragraph>
                  <strong>Keep an eye on your inbox.</strong>
                  <br />
                  You&apos;ll be receiving a shopping completed confirmation
                  email.
                </Paragraph>
              </div>
              <div className="ua-display-flex ua-grid-gap-2-sm ua-margin-lg-top ua-align-items-flex-start">
                <img
                  src={PhoneIllustration}
                  alt="Thumbs up"
                  width="48"
                  height="48"
                />
                <Paragraph>
                  <strong>Your UA Advocate will reach out soon.</strong>
                  <br />
                  We&apos;re here to help you unlock the exciting potential of
                  your perks.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
