export default function getPathFromUrl(url) {
  try {
    const isMailto = url.includes("mailto:");
    if (!!url && (/^\//.test(url) || isMailto)) {
      return url;
    }

    const { pathname, search } = new URL(url);

    return `${pathname}${search}`;
  } catch (err) {
    return url;
  }
}
