import useUserAgency from "../../features/user/use-user-agency";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";

import { Card, Typography } from "@uhc-tempo/components";
const { H1 } = Typography;

export default function HomeWithoutPerks() {
  const { logo, name } = useUserAgency();

  return (
    <section className="fluid-container ua-padding-xxxxlg-vertical home--before-perks">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-4-6-lg ua-col-span-3-8-md ua-col-span-1-12-sm ua-justify-content-center ua-align-items-center ua-padding-xxxxlg-vertical">
          <Card
            border
            layout="custom"
            className="ua-text-center ua-border-radius-sm ua-box-shadow"
          >
            <AgencyLogo
              className="home--card__img"
              name={name}
              src={logo}
              width={288}
              height={137}
            />
            <div className="home--card__content">
              {
                <H1 className="tds-padding-xxlg-vertical ua-border-top ua-border-bottom">
                  Coming soon
                </H1>
              }
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
}
