import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import throttle from "lodash.throttle";

import trackEvent from "./track-event";

export default function TrackPageScroll() {
  const activeScrollDepth = useRef(0);

  const { pathname } = useLocation();

  const handleScroll = useMemo(
    () => throttle(() => detectScrollDepth(activeScrollDepth), 500),
    [activeScrollDepth]
  );

  useEffect(() => {
    if (!!document) {
      document.addEventListener("scroll", handleScroll, true);
    }

    return () => {
      if (!!document) {
        document.removeEventListener("scroll", handleScroll, true);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    activeScrollDepth.current = 0;
  }, [pathname]);

  return null;
}

function detectScrollDepth(activeScrollDepth) {
  try {
    const rootElement = document?.documentElement || document?.body;
    const viewportHeight = window?.innerHeight;

    if (!rootElement || !viewportHeight) {
      throw new Error("Not a browser");
    }

    const scrollDepths = [0, 25, 50, 75, 90];
    const scrollTop = rootElement.scrollTop;
    const scrollHeight = rootElement.scrollHeight;
    const viewportOffset = scrollHeight - viewportHeight;
    const scrollPercent = (scrollTop / viewportOffset) * 100;

    const scrollDepth = scrollDepths.reduce(
      (depth, current) => (scrollPercent >= current ? current : depth),
      scrollDepths[0]
    );

    if (scrollDepth > activeScrollDepth.current) {
      activeScrollDepth.current = scrollDepth;
      trackEvent({
        action: "scroll",
        params: { percent_scrolled: scrollDepth },
      });
    }
  } catch (err) {}
}
