import { useEffect, useId, useState } from "react";
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";

import formatNumber from "../../utilities/format-number";
import useModalScrollbarOffset from "../../utilities/use-modal-scrollbar-offset";

import HtmlContent from "../../components/html-content/HtmlContent";
import Portal from "../../components/portal/Portal";
import ShoppingBenefitPoints from "./ShoppingBenefitPoints";

import { Button, Form, Modal, Typography } from "@uhc-tempo/components";
const { Select } = Form;
const { Content, Footer, Header, Title } = Modal;
const { H2 } = Typography;

export default function ShoppingSelectAmount({
  benefit,
  disabled,
  handleSelect,
  inProgress,
  pointsRemaining,
  setViewSelectAmount,
}) {
  const {
    id,
    multipleAmounts,
    multipleDescription,
    multipleTitle,
    name,
    qty: value,
  } = benefit || {};

  const [amount, setAmount] = useState(value);
  const [initialAmount, setInitialAmount] = useState(value);

  const [modalOpen, toggleModalOpen] = useModalScrollbarOffset(false);
  const uid = useId();

  function handleClose() {
    setViewSelectAmount(null);
  }

  function handleInput(event) {
    const { target: input } = event || {};
    const value = !!input ? parseInt(input.value, 10) : 0;

    setAmount(value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleSelect(id, name, amount);
    handleClose();
  }

  function isDisabledOption(value) {
    const isDisabled =
      (!initialAmount && value > pointsRemaining) ||
      (!!initialAmount && value > pointsRemaining + initialAmount);
    return isDisabled;
  }

  useEffect(() => {
    toggleModalOpen(!!id);
  }, [id, toggleModalOpen]);

  useEffect(() => {
    setAmount(value);
    setInitialAmount(value);
  }, [value]);

  return (
    <Portal id={`shopping-select-amount-${uid}`}>
      {modalOpen && !!multipleAmounts && multipleAmounts.length && (
        <Modal
          contentId={`shopping-select-amount-modal-${uid}`}
          handleClose={handleClose}
        >
          <Header className="ua-border-radius-top-sm ua-bg-supporting-one-opacity-ten ua-padding-md-bottom">
            <Title>How many points do you want to use?</Title>
          </Header>
          <Form noValidate onSubmit={handleSubmit}>
            <Content className="ua-modal__content-full-width ua-padding-jumbo-bottom ua-padding-xxxlg-top">
              <div className="ua-grid ua-grid-gap-2-sm ua-grid-cols-12">
                <div className="ua-col-auto-span-12-sm ua-col-auto-span-3-md">
                  <ShoppingBenefitPoints points={multipleAmounts[0]} starting />
                </div>
                <div className="ua-col-auto-span-12-sm ua-col-auto-span-6-md">
                  <div className="ua-display-flex ua-align-items-flex-start">
                    <div className="ua-overflow-hidden">
                      {!!multipleTitle && (
                        <H2
                          className="ua-margin-xsm-bottom ua-padding-sm-top"
                          headingStyle={6}
                        >
                          {multipleTitle}
                        </H2>
                      )}
                      {!!multipleDescription && (
                        <HtmlContent
                          paragraphBodyStyle={3}
                          paragraphMargin="ua-margin-sm-bottom"
                          rawHtml={multipleDescription}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="ua-col-auto-span-12-sm ua-col-auto-span-3-md">
                  <Select
                    aria-label="Points amount:"
                    containerProps={{
                      className:
                        "tds-form__select ua-display-block ua-width-100",
                    }}
                    name="points_amount"
                    onBlur={handleInput}
                    onChange={handleInput}
                    required
                    value={amount}
                  >
                    <Select.Option value={0}>0 pts</Select.Option>
                    {multipleAmounts.map((value, i) => (
                      <Select.Option
                        disabled={isDisabledOption(value)}
                        key={`points-amount-${value}`}
                        value={value}
                      >
                        {formatNumber(value)} pts
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Content>
            <Footer className="ua-justify-content-flex-end">
              <Button
                buttonType={disabled ? "disabled" : "secondary-one"}
                className="tds-margin-md-right"
                disabled={disabled}
                onClick={handleClose}
                type="button"
              >
                Cancel
              </Button>
              <Button
                buttonType={disabled ? "disabled" : "primary-one"}
                disabled={disabled}
                loading={inProgress}
                type="submit"
              >
                {inProgress ? "Saving…" : "Save selection"}
              </Button>
            </Footer>
          </Form>
        </Modal>
      )}
    </Portal>
  );
}

ShoppingSelectAmount.propTypes = {
  benefit: shape({
    id: oneOfType([number, string]),
    multipleAmounts: arrayOf(number),
    multipleDescription: string,
    multipleTitle: string,
    value: number,
  }),
  disabled: bool,
  handleSelect: func.isRequired,
  inProgress: bool,
  pointsRemaining: number,
  setViewSelectAmount: func.isRequired,
};
