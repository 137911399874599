import { useMemo } from "react";
import {
  bool,
  element,
  func,
  oneOfType,
  node,
  number,
  string,
} from "prop-types";
import dayjs from "dayjs";

import formatNumber from "../../utilities/format-number";
import useUserAgency from "../user/use-user-agency";

import { Button, Typography } from "@uhc-tempo/components";
const { H2, Paragraph } = Typography;

export default function ShoppingPoints({
  bonus,
  children,
  deadline,
  deadlineLabel,
  handleSubmit,
  pointsRemaining = 0,
}) {
  const { logo, name } = useUserAgency();
  const deadlineFormatted = useMemo(() => formatDate(deadline), [deadline]);
  const pointsRemainingFormatted = useMemo(
    () => formatNumber(pointsRemaining),
    [pointsRemaining]
  );

  const headingClass = bonus ? "ua-margin-none-bottom" : "";
  const buttonClass = bonus
    ? "ua-margin-xxlg-bottom ua-margin-sm-top"
    : "ua-margin-xxlg-vertical";

  return (
    <div className="tds-padding-xxlg ua-border ua-border-radius-sm ua-border-color-gray-four ua-box-shadow ua-bg-primary-three">
      {!!logo && (
        <Paragraph className="ua-text-center">
          <img src={logo} alt={name} height={102} width={214} />
        </Paragraph>
      )}
      <H2
        className={`ua-display-flex ua-flex-column ua-align-items-center ua-justify-content-center ua-text-center ${headingClass}`}
      >
        <span
          className="ua-padding-md-bottom ua-padding-lg-horizontal ua-display-block ua-border-bottom ua-border-color-success ua-text-success"
          style={{ lineHeight: "1" }}
        >
          {pointsRemainingFormatted} pts
        </span>
        <strong
          className="tds-text__paragraph tds-text__paragraph-large ua-padding-sm-top ua-display-block ua-text-success"
          style={{ lineHeight: "1" }}
        >
          {bonus ? "Bonus points available" : "Points available"}
        </strong>
      </H2>
      <Paragraph className={`ua-text-center ${buttonClass}`}>
        <Button
          buttonType="primary-three"
          className="ua-auto-height ua-line-height-1"
          onClick={handleSubmit}
        >
          Checkout
        </Button>
      </Paragraph>
      {children}
      {!!deadlineFormatted && (
        <Paragraph bodyStyle={3} className="ua-text-center tds-padding-md-top">
          {deadlineLabel}{" "}
          <strong className="tds-text__paragraph--bold ua-text-nowrap">
            {deadlineFormatted}
          </strong>
        </Paragraph>
      )}
    </div>
  );
}

function formatDate(dateString) {
  try {
    if (!dateString) {
      throw new Error("Date undefined");
    }

    return dayjs(dateString).format("MMMM D, YYYY");
  } catch (err) {
    return dateString;
  }
}

ShoppingPoints.propTypes = {
  bonus: bool,
  children: oneOfType([element, func, node]),
  deadline: string,
  deadlineLabel: string,
  handleSubmit: func.isRequired,
  pointsRemaining: number,
};
