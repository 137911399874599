import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../data/constants/routes";
import UA_API, { UA_API_SHOPPING_EP_FINALIZE } from "../../data/ua-api/ua-api";
import trackEvent from "../tracking/track-event";
import useModalScrollbarOffset from "../../utilities/use-modal-scrollbar-offset";

import clockIllustration from "../../assets/img/clock-illustration.svg";

import ButtonInline from "../../components/button-inline/ButtonInline";
import Portal from "../../components/portal/Portal";

import { Button, Modal, Notification, Typography } from "@uhc-tempo/components";
const { Content, Header, Footer, Title } = Modal;
const { Paragraph } = Typography;

export default function BonusInfoModal() {
  const [formError, setFormError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [modalOpen, toggleModalOpen] = useModalScrollbarOffset(false);

  const navigate = useNavigate();

  function displayError(errorMessage) {
    setFormError([errorMessage]);
    setInProgress(false);
  }

  function handleModalClose(action = "close") {
    toggleModalOpen();
    trackEvent({
      action: `shopping_bonus_info_modal_${action}`,
    });
  }

  function handleModalOpen() {
    toggleModalOpen();
    trackEvent({
      action: "shopping_bonus_info_modal_open",
    });
  }

  async function handleSave() {
    setInProgress(true);

    try {
      const { data = {} } = await UA_API.put(UA_API_SHOPPING_EP_FINALIZE, {
        selections: [],
      });

      if (!!data && data.status === "success") {
        trackEvent({
          action: "shopping_finalize",
          params: { method: "bonus_later" },
        });
        navigate(ROUTES.shoppingBonusConfirmationLater.path);
      } else {
        displayError("Unexpected response");
      }
    } catch (err) {
      displayError(err.message || "Error");
    }
  }

  return (
    <>
      <Paragraph bodyStyle={3} className="ua-margin-sm-bottom ua-text-center">
        <strong className="tds-text__paragraph--bold ua-text-primary-two">
          or
        </strong>{" "}
        <ButtonInline className="button-inline__alt" onClick={handleModalOpen}>
          save points for next year
        </ButtonInline>
      </Paragraph>
      {modalOpen && (
        <Portal id="bonus-info-modal">
          <Modal handleClose={() => handleModalClose()}>
            <Header>
              <div className="ua-bonus-info--header">
                <img
                  alt="clock illustration"
                  height={87}
                  src={clockIllustration}
                  width={135}
                />
                <Title className="ua-bonus-info--title">
                  Make shopping next year even better.
                </Title>
              </div>
            </Header>
            <Content className="ua-modal__content-full-width">
              <div className="ua-padding-lg-horizontal">
                <div className="ua-border-bottom ua-border-top ua-border-color-gray-five">
                  <div className="ua-margin-xsm-horizontal ua-padding-xxxlg-bottom ua-padding-xxxxlg-horizontal ua-padding-xxlg-top">
                    <Paragraph
                      bodyStyle={3}
                      className="ua-line-height-1_5 ua-margin-none-bottom"
                    >
                      By clicking the “Save points” button below, your agency’s
                      United Advantage bonus points will be applied to next year
                      and this will close out the bonus shopping experience. If
                      your agency does not make United Advantage status next
                      year, you’ll still be able to use these bonus points.
                    </Paragraph>
                    {!!formError && (
                      <Notification
                        className="tds-margin-xlg-top"
                        dismissClickHandler={() => setFormError(null)}
                        isDismissed={!formError}
                        notificationType="error"
                      >
                        {formError}
                      </Notification>
                    )}
                  </div>
                </div>
              </div>
            </Content>
            <Footer className="ua-justify-content-space-between ua-padding-xxxxlg-bottom ua-padding-md-top">
              <Button
                buttonType="secondary-one"
                className="ua-margin-lg-horizontal"
                onClick={() => handleModalClose("close_button")}
                type="button"
              >
                Back to shopping
              </Button>
              <Button
                buttonType={inProgress ? "disabled" : "primary-one"}
                className="ua-margin-lg-right"
                disabled={inProgress}
                loading={inProgress}
                onClick={handleSave}
                type="button"
              >
                {inProgress ? "Saving…" : "Save points"}
              </Button>
            </Footer>
          </Modal>
        </Portal>
      )}
    </>
  );
}
