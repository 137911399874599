import { useEffect, useMemo } from "react";
import { func } from "prop-types";
import { useParams } from "react-router-dom";

import useMetaTag from "../../utilities/use-meta-tag";

import Embed from "../../components/embed/embed";
import MastheadAlt from "../../components/masthead-alt/MastheadAlt";
import NotFound from "../not-found";

const content = {
  westdm: {
    ceo: "David Milich",
    preferred: "1_5j11tkcg",
    premier: "1_f6wfsj8g",
  },
  eastgr: {
    ceo: "Greg Reidy",
    preferred: "1_w4l23u1h",
    premier: "1_go5xwqqj",
  },
};

export default function ThankYou({ handlePageView }) {
  useMetaTag({ name: "robots", content: "noindex, nofollow" });

  const { region, level } = useParams();

  const { isFound, pageTitle, video } = useMemo(() => {
    if (!content[region]?.ceo || !content[region]?.[level]) {
      return { isFound: false, pageTitle: "Not Found", video: null };
    } else {
      return {
        isFound: true,
        pageTitle: `A special message from our regional CEO – ${content[region].ceo}`,
        video: `https://cdnapisec.kaltura.com/p/2297431/sp/229743100/embedIframeJs/uiconf_id/40040682/partner_id/2297431?iframeembed=true&playerId=kaltura_player&entry_id=${content[region][level]}&flashvars[controlBarContainer.plugin]=true&flashvars[controlBarContainer.hover]=true`,
      };
    }
  }, [region, level]);

  useEffect(() => {
    if (!!handlePageView && !!pageTitle) {
      handlePageView(pageTitle);
    }
  }, [handlePageView, pageTitle]);

  if (!isFound) {
    return <NotFound />;
  }

  return (
    <div className="ua-recap ua-bg-primary-one ua-full-height">
      <header className="ua-recap--header">
        <MastheadAlt className="ua-recap--header--masthead" />
      </header>
      <div className="ua-recap--content sixteen-by-nine">
        <Embed title={pageTitle} url={video} />
      </div>
    </div>
  );
}

ThankYou.propTypes = {
  handlePageView: func.isRequired,
};
