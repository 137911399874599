import { AGENCY_LEVELS } from "../data/constants/agency_levels";

export default function getLevelName(level = "") {
  switch (`${level}`.toLowerCase()) {
    case "level_1":
    case "gold":
    case "preferred":
      return AGENCY_LEVELS.level_1;
    case "level_2":
    case "platinum":
    case "premier":
      return AGENCY_LEVELS.level_2;
    case "level_3":
    case "diamond":
    case "premier+":
      return AGENCY_LEVELS.level_3;
    default:
      return "Non-UA";
  }
}
