import { array, bool, string } from "prop-types";

import HtmlContent from "../../components/html-content/HtmlContent";
import ResourceDownloads from "./ResourceDownloads";
import ResourceLink from "./ResourceLink";
import ResourceMaterials from "./ResourceMaterials";
import ResourceMeta from "./ResourceMeta";
import ResourceThumbnail from "./ResourceThumbnail";
import ResourceWhy from "./ResourceWhy";

import { Typography } from "@uhc-tempo/components";
const { H3 } = Typography;

export default function ResourceListItem({
  description,
  downloads,
  isLast,
  link,
  linkText = "Learn more",
  materials,
  meta,
  slug,
  tag,
  thumbnail,
  title,
  video,
  why,
}) {
  const isTwoColumn = !!thumbnail || !!meta;
  const firstColumnClass = isTwoColumn
    ? "ua-col-span-1-12-sm ua-col-span-1-8-md ua-padding-xlg-right"
    : "ua-col-span-1-12";

  let itemClass = "resource-list--item";

  if (!isLast) {
    itemClass += " ua-border-bottom ua-border-color-gray-two";
  } else {
    itemClass += " ua-padding-md-bottom";
  }

  return (
    <div id={slug} className={itemClass}>
      <div className="ua-grid ua-grid-cols-12 ua-grid-gap-3-sm">
        <div className={firstColumnClass}>
          <H3 headingStyle={4} className="ua-margin-sm-bottom ua-line-height-1">
            {!!tag && (
              <span className="ua-display-block ua-font-size-paragraph-default ua-font-weight-bold ua-margin-sm-bottom ua-text-supporting-three ua-text-upper">
                {tag}{" "}
              </span>
            )}
            <HtmlContent rawHtml={title} />
          </H3>
          {!!description && (
            <div className="tds-text__paragraph tds-text__paragraph-large ua-margin-lg-bottom">
              <HtmlContent rawHtml={description} />
            </div>
          )}
          <ResourceWhy why={why} />
          <ResourceMaterials materials={materials} />
          <ResourceLink link={link} linkText={linkText} />
        </div>
        {isTwoColumn && (
          <div className="ua-col-span-1-12-sm ua-col-span-9-4-md">
            <ResourceThumbnail
              thumbnail={thumbnail}
              title={title}
              video={video}
            />
            <ResourceMeta meta={meta} />
          </div>
        )}
      </div>
      <ResourceDownloads downloads={downloads} />
    </div>
  );
}

ResourceListItem.propTypes = {
  description: string,
  downloads: array,
  isLast: bool,
  link: string,
  linkText: string,
  materials: array,
  meta: array,
  slug: string,
  tag: string,
  thumbnail: string,
  title: string,
  video: string,
  why: array,
};
