export default function normalizeShoppingSelections(selections) {
  try {
    // Sometimes the API returns JSON, so check for that first
    if (!!selections && typeof selections === "string") {
      selections = JSON.parse(selections);
    }

    if (!selections || !Array.isArray(selections)) {
      throw new Error("Missing selections");
    }

    selections = selections.reduce((clean, selection) => {
      // For backwards compatibility, used to be an array of IDs
      if (typeof selection !== "object") {
        selection = { id: selection, qty: 1 };
      }

      let { id, qty } = selection;

      id = parseInt(id, 10);
      qty = parseInt(qty, 10);

      // Omit selections that don't match the expected shape
      if (!!id && !isNaN(id) && !!qty && !isNaN(id)) {
        clean.push({ id, qty });
      }

      return clean;
    }, []);

    return ["success", selections, "Success"];
  } catch (err) {
    return ["error", null, err.message || "Unexpected selections data"];
  }
}
