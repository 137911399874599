import { number, string } from "prop-types";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";

import { Card, Typography } from "@uhc-tempo/components";
const { H1, Paragraph } = Typography;

export default function StatusMobileDisclaimer({
  catalog_year_next,
  logo,
  agencyName,
}) {
  return (
    <section className="fluid-container ua-status">
      <header className="ua-padding-xxxlg-top">
        <H1 className="ua-margin-sm-bottom">
          {catalog_year_next} United Advantage Status Projection
        </H1>
        <Paragraph bodyStyle={1} className="ua-margin-none">
          Track your agency’s {catalog_year_next} United Advantage tier status
        </Paragraph>
      </header>
      <div className="ua-margin-auto ua-margin-jumbo-bottom ua-max-width-90 ua-padding-xxxxlg-top">
        <Card
          border
          layout="custom"
          className="ua-text-center ua-border-radius-sm ua-box-shadow ua-padding-xlg-top"
        >
          <AgencyLogo
            className="ua-display-block ua-margin-auto"
            name={agencyName}
            src={logo}
            width={288}
            height={137}
          />
          <div className="ua-padding-xxxlg-bottom ua-padding-xlg-top">
            <Paragraph bodyStyle={2} className="ua-text-primary-one" useBold>
              For the best experience,{" "}
              <span className="ua-text-nowrap">please open this page</span>{" "}
              <span className="ua-text-nowrap">on a computer or tablet.</span>
            </Paragraph>
          </div>
        </Card>
      </div>
    </section>
  );
}

StatusMobileDisclaimer.propTypes = {
  agencyName: string,
  catalog_year_next: number,
  logo: string,
};
