import ShoppingFinalize from "../../features/shopping/ShoppingFinalize";

import { Typography } from "@uhc-tempo/components";
const { H1 } = Typography;

export default function ShoppingReview(props) {
  return (
    <section className="fluid-container page-main__fill ua-padding-jumbo-vertical ua-bg-primary-one-extra-light">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-1-12-sm ua-col-span-2-10-md ua-col-span-3-8-lg">
          <H1 className="ua-text-center">Review and submit</H1>
          <ShoppingFinalize />
        </div>
      </div>
    </section>
  );
}
