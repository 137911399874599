import UHCLogoStackedLight from "../../assets/img/UHC_logo_stacked_light.svg";

import { Typography } from "@uhc-tempo/components";
const { H6 } = Typography;

export default function PageHeaderReverse() {
  return (
    <header className="page-header__reverse">
      <div className="page-header__reverse-masthead ua-display-flex ua-margin-auto">
        <div className="page-header__brand">
          <img
            src={UHCLogoStackedLight}
            width="154"
            height="48"
            alt="United Healthcare"
          />
        </div>
        <div className="page-header__reverse-subtitle ua-display-flex ua-align-items-center">
          <H6 useLight className="nav-brand tds-margin-none-bottom">
            United&nbsp;Advantage&reg;
          </H6>
        </div>
      </div>
    </header>
  );
}
