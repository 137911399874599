import { useRef, useState } from "react";
import { number, shape, string } from "prop-types";
import { useDispatch } from "react-redux";

import ShoppingSelectAmount from "../shopping/ShoppingSelectAmount";
import ShoppingSelectQuantity from "../shopping/ShoppingSelectQuantity";
import ShoppingBenefitSelectButton from "../shopping/ShoppingBenefitSelectButton";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import trackEvent from "../tracking/track-event";
import { updateShoppingSelections } from "../shopping/ShoppingRedux";
import useSelectedBenefits from "../shopping/use-selected-benefits";

import { Notification, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function BenefitsFooterAddToCartButton({ benefit }) {
  const { allowMultiple, id, name, points } = benefit;
  const errorAlert = useRef();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [selectionInProgress, setSelectionInProgress] = useState(0);
  const [viewSelectAmount, setViewSelectAmount] = useState(null);
  const [viewSelectQuantity, setViewSelectQuantity] = useState(null);
  const { selections, pointsRemaining } = useSelectedBenefits();

  function displayError(errorMessage) {
    setError(errorMessage);
    setSelectionInProgress(0);

    setTimeout(() => {
      scrollIfNeeded(errorAlert.current);
    }, 50);
  }

  function handleRemove() {
    setError(null);
    setSelectionInProgress(id);

    const update = selections.filter((selection) => selection.id !== id);

    trackSelections("benefit_remove");
    updateSelections(update);
  }

  function handleSelect() {
    setError(null);
    setSelectionInProgress(id);

    const update = [...selections, { id, qty: 1 }];

    trackSelections("benefit_selection");
    updateSelections(update);
  }

  function handleSelectMultiple(id, name, qty) {
    setError(null);
    setSelectionInProgress(id);

    let action;
    let update = selections.filter((selection) => selection.id !== id);

    if (!!qty) {
      action = "benefit_selection";
      update.push({ id, qty });
    } else {
      action = "benefit_remove";
    }

    trackSelections(action);
    updateSelections(update);
  }

  function handleViewSelectAmount() {
    if (selectionInProgress) {
      return;
    }

    trackEvent({
      action: "shopping_benefit_select_amount",
      relatedId: id,
      params: { benefit_id: id, benefit_name: name, triggered_from: "detail" },
    });

    const selected = selections.find((selection) => selection.id === id);
    // sometimes qty means amount :shrug:
    const qty = !!selected && !!selected.qty ? selected.qty : 0;

    setViewSelectAmount({ ...benefit, qty });
  }

  function handleViewSelectQuantity() {
    if (selectionInProgress) {
      return;
    }

    trackEvent({
      action: "shopping_benefit_select_quantity",
      relatedId: id,
      params: { benefit_id: id, benefit_name: name, triggered_from: "detail" },
    });

    const selected = selections.find((selection) => selection.id === id);
    const qty = !!selected && !!selected.qty ? selected.qty : 0;

    setViewSelectQuantity({ ...benefit, qty });
  }

  function trackSelections(action) {
    trackEvent({
      action,
      relatedId: id,
      params: {
        selections,
        benefit_id: id,
        benefit_name: name,
        triggered_from: "detail",
      },
    });
  }

  function onRemove() {
    if (!benefit) {
      return;
    }

    if (allowMultiple === "quantity") {
      handleViewSelectQuantity();
    } else if (allowMultiple === "amount") {
      handleViewSelectAmount();
    } else {
      handleRemove();
    }
  }

  function onSelect() {
    if (!benefit) {
      return;
    }

    if (allowMultiple === "quantity") {
      handleViewSelectQuantity();
    } else if (allowMultiple === "amount") {
      handleViewSelectAmount();
    } else {
      handleSelect();
    }
  }

  function updateSelections(update) {
    dispatch(updateShoppingSelections(update))
      .unwrap()
      .then((response) => {
        setSelectionInProgress(0);
      })
      .catch((err) => {
        displayError(err.message || "Error");
      });
  }

  return (
    <>
      {!!error && (
        <div ref={errorAlert}>
          <Notification
            className="tds-margin-xxlg-bottom"
            dismissClickHandler={() => setError(null)}
            isDismissed={!error}
            notificationType="error"
          >
            <Paragraph className="tds-margin-none">{error}</Paragraph>
          </Notification>
        </div>
      )}
      <div className="ua-display-block">
        <ShoppingBenefitSelectButton
          allowMultiple={allowMultiple}
          disabled={!!selectionInProgress}
          loading={id === selectionInProgress}
          isSelected={selections.some((selection) => selection.id === id)}
          onRemove={onRemove}
          onSelect={onSelect}
          size="lg"
          text="Add to cart"
          unavailable={pointsRemaining < points}
        />
        <ShoppingSelectAmount
          benefit={viewSelectAmount}
          disabled={!!selectionInProgress}
          handleSelect={handleSelectMultiple}
          inProgress={!!viewSelectAmount && id === selectionInProgress}
          pointsRemaining={pointsRemaining}
          setViewSelectAmount={setViewSelectAmount}
        />
        <ShoppingSelectQuantity
          benefit={viewSelectQuantity}
          disabled={!!selectionInProgress}
          handleSelect={handleSelectMultiple}
          inProgress={!!viewSelectQuantity && id === selectionInProgress}
          pointsRemaining={pointsRemaining}
          setViewSelectQuantity={setViewSelectQuantity}
        />
      </div>
    </>
  );
}

BenefitsFooterAddToCartButton.propTypes = {
  benefit: shape({
    allowMultiple: string,
    id: number,
    points: number,
  }),
};
