import { useRef, useState } from "react";
import { string } from "prop-types";

import UA_API, { UA_API_EP_REGISTER_AGENCY } from "../../data/ua-api/ua-api";
import { US_STATES } from "../../data/constants/us-states";

import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import trackEvent from "../tracking/track-event";
import useRecaptcha, { RecaptchaDisclaimer } from "../recaptcha/use-recaptcha";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";

import { Button, Form, Notification, Typography } from "@uhc-tempo/components";
const { ErrorMessage, Group, Input, Label, LargeErrorMessage, Select } = Form;
const { Paragraph } = Typography;

const initData = {
  first_name: "",
  last_name: "",
  email: "",
  agency_name: "",
  work_street_address: "",
  work_street_address_2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  mobile_phone: "",
};

export default function RequestAgencyIdForm({ className = "", ...rest }) {
  const [data, setData] = useState(initData);
  const [errors, setErrors] = useState(initData);
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const formErrorAlert = useRef();
  const formSuccessAlert = useRef();

  const [recaptchaLoading, recaptchaError, getRecaptchaToken] = useRecaptcha();

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }

    const error = validateInput(input);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  }

  function displayError(errorMessage) {
    setFormError(errorMessage);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function displaySuccess(successMessage) {
    trackEvent({ action: "sign_up", params: { method: "Request Agency ID" } });
    setData(initData);
    setFormError(null);
    setFormSuccess(successMessage);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formSuccessAlert.current);
    }, 50);
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 50);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    setInProgress(true);
    requestRecaptchaToken();
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  async function postData(recaptchaToken) {
    try {
      const response = await UA_API.post(UA_API_EP_REGISTER_AGENCY, {
        ...data,
        "g-recaptcha-response": recaptchaToken,
      });

      if (!!response.data && response.data.status === "success") {
        displaySuccess(response.data.message || "Success!");
      } else {
        displayError("Unexpected response");
      }
    } catch (err) {
      displayError(err.message || "Error");
    }
  }

  function requestRecaptchaToken() {
    if (recaptchaLoading) {
      displayError("Please wait, reCAPTCHA is loading");
      return;
    }

    if (recaptchaError) {
      displayError(recaptchaError);
      return;
    }

    getRecaptchaToken("request_agency_id")
      .then((recaptchaToken) => {
        if (!recaptchaToken) {
          displayError("Missing reCAPTCHA token");
          return;
        }

        postData(recaptchaToken);
      })
      .catch((err) => displayError(err || "reCAPTCHA error"));
  }

  function dismissSuccessNotification() {
    setFormSuccess(null);
    setFormError(null);
  }

  return (
    <Form className={className} noValidate onSubmit={handleSubmit}>
      {!!formError && (
        <div ref={formErrorAlert}>
          <LargeErrorMessage className="tds-margin-xxlg-bottom">
            {formError}
          </LargeErrorMessage>
        </div>
      )}
      {!!formSuccess && (
        <div ref={formSuccessAlert}>
          <div ref={formSuccessAlert}>
            <Notification
              className="tds-margin-xxlg-bottom"
              dismissClickHandler={() => dismissSuccessNotification()}
              notificationType="success"
            >
              <Paragraph className="tds-margin-none">{formSuccess}</Paragraph>
            </Notification>
          </div>
        </div>
      )}
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="first_name"
          state={getFormElementState(errors.first_name)}
        >
          <Label>First name*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="first_name"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.first_name}
          />
          {!!errors.first_name && (
            <ErrorMessage>{errors.first_name}</ErrorMessage>
          )}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="last_name"
          state={getFormElementState(errors.last_name)}
        >
          <Label>Last name*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="last_name"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.last_name}
          />
          {!!errors.last_name && (
            <ErrorMessage>{errors.last_name}</ErrorMessage>
          )}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="agency_name"
          state={getFormElementState(errors.agency_name)}
        >
          <Label>Agency name*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="agency_name"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.agency_name}
          />
          {!!errors.agency_name && (
            <ErrorMessage>{errors.agency_name}</ErrorMessage>
          )}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="email"
          state={getFormElementState(errors.email)}
        >
          <Label>Email*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="email"
            onBlur={handleInput}
            onChange={handleInput}
            required
            pattern={validationPatterns.email}
            type="email"
            value={data.email}
          />
          {!!errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="work_street_address"
          state={getFormElementState(errors.work_street_address)}
        >
          <Label>Agency address*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="work_street_address"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.work_street_address}
          />
          {!!errors.work_street_address && (
            <ErrorMessage>{errors.work_street_address}</ErrorMessage>
          )}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="work_street_address"
          state={getFormElementState(errors.work_street_address_2)}
        >
          <Label>Agency address 2</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="work_street_address_2"
            onBlur={handleInput}
            onChange={handleInput}
            type="text"
            value={data.work_street_address_2}
          />
          {!!errors.work_street_address_2 && (
            <ErrorMessage>{errors.work_street_address_2}</ErrorMessage>
          )}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="city"
          state={getFormElementState(errors.city)}
        >
          <Label>City*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="city"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.city}
          />
          {!!errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="state"
          state={getFormElementState(errors.state)}
        >
          <Label>State*</Label>
          <Select
            containerProps={{
              className: "tds-form__select ua-display-block ua-width-100",
            }}
            name="state"
            onBlur={handleInput}
            onChange={handleInput}
            required
            value={data.state}
          >
            <Select.Option value="">Select a state</Select.Option>
            {US_STATES.map(({ abbreviation, name }, i) => (
              <Select.Option key={abbreviation} value={abbreviation}>
                {name}
              </Select.Option>
            ))}
          </Select>
          {!!errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="zip"
          state={getFormElementState(errors.zip)}
        >
          <Label>Zip*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="zip"
            onBlur={handleInput}
            onChange={handleInput}
            pattern={validationPatterns.zip}
            required
            type="text"
            value={data.zip}
          />
          {!!errors.zip && <ErrorMessage>{errors.zip}</ErrorMessage>}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="phone"
          state={getFormElementState(errors.phone)}
        >
          <Label>Business phone*</Label>
          <Input
            className="ua-display-block ua-width-100"
            name="phone"
            onBlur={handleInput}
            onChange={handleInput}
            pattern={validationPatterns.tel}
            required
            type="tel"
            value={data.phone}
          />
          {!!errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="mobile_phone"
          state={getFormElementState(errors.mobile_phone)}
        >
          <Label>Mobile phone</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="mobile_phone"
            onBlur={handleInput}
            onChange={handleInput}
            pattern={validationPatterns.tel}
            type="tel"
            value={data.mobile_phone}
          />
          {!!errors.mobile_phone && (
            <ErrorMessage>{errors.mobile_phone}</ErrorMessage>
          )}
        </Group>
      </div>
      <RecaptchaDisclaimer />
      <div className="tds-padding-xxlg-top">
        <Button
          buttonType={inProgress ? "disabled" : "primary-one"}
          className="tds-margin-xxlg-bottom ua-display-block ua-width-60 ua-margin-auto"
          loading={inProgress}
          type="submit"
        >
          {inProgress ? "Requesting…" : "Request Agency ID"}
        </Button>
      </div>
    </Form>
  );
}

RequestAgencyIdForm.propTypes = {
  className: string,
};
