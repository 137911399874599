import { GA_MEASUREMENT_ID } from "../../data/constants/site";
import { HOST_ENV } from "../../data/constants/urls";

export default function setUserProperties(userProperties) {
  try {
    if (!userProperties) {
      throw new Error("User properties not defined");
    }

    if (!window.gtag) {
      throw new Error("Google Analytics not loaded");
    }

    if (!GA_MEASUREMENT_ID) {
      throw new Error("Google Analytics property not defined");
    }

    if (!!userProperties?.ua_user_id) {
      window.gtag("config", GA_MEASUREMENT_ID, {
        send_page_view: false,
        user_id: userProperties.ua_user_id,
      });
    }

    window.gtag("set", "user_properties", userProperties);

    if (HOST_ENV !== "production") {
      console.log("ga user_properties set", userProperties);
    }
  } catch (err) {
    if (HOST_ENV !== "production") {
      console.warn("ga user_properties not set", err?.message);
    }
  }
}
