import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storageLocal from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import {
  adminTokenLoadingReducer,
  authReducer,
  userTokenLoadingReducer,
} from "../../features/auth/AuthRedux";
import {
  settingsReducer,
  settingsErrorReducer,
} from "../../features/settings/SettingsRedux";
import {
  bonusShoppingRequiredReducer,
  shoppingReducer,
  shoppingHiddenReducer,
  shoppingMethodReducer,
  shoppingRequiredReducer,
  shoppingSelectionsReducer,
  shoppingFilterSortReducer,
} from "../../features/shopping/ShoppingRedux";
import {
  staticPagesReducer,
  staticPagesErrorReducer,
  staticPagesLoadingReducer,
} from "../../features/static-pages/StaticPagesRedux";
import {
  statusReducer,
  statusErrorReducer,
  statusLoadingReducer,
} from "../../features/status/StatusRedux";
import {
  trainingReducer,
  trainingErrorReducer,
  trainingLoadingReducer,
} from "../../features/training/TrainingRedux";
import { userReducer } from "../../features/user/UserRedux";
import { catalogYearCurrentReducer } from "../../features/catalogs/CatalogsRedux";
import {
  benefitsReducer,
  benefitTypesReducer,
  benefitsErrorReducer,
  benefitsLoadingReducer,
} from "../../features/benefits/BenefitsRedux";
import {
  agenciesReducer,
  agenciesErrorReducer,
  agenciesLoadingReducer,
  agencyUsersReducer,
  agencyUsersErrorReducer,
  agencyUsersLoadingReducer,
  catalogYearsReducer,
  catalogYearsErrorReducer,
  catalogYearsLoadingReducer,
  impersonateReducer,
  marketsReducer,
  marketsErrorReducer,
  marketsLoadingReducer,
} from "../../features/admin/AdminRedux";

const authPersistConfig = {
  key: "auth",
  storage: storageLocal,
  blacklist: ["message"],
};

const catalogYearCurrentConfig = {
  key: "catalogYearCurrent",
  storage: storageLocal,
};

const shoppingRequiredConfig = {
  key: "shoppingRequired",
  storage: storageLocal,
};

const userPersistConfig = {
  key: "user",
  storage: storageLocal,
};

const basePersistConfig = {
  key: "base",
  storage: storageSession,
  blacklist: [
    "auth",
    "catalogYearCurrent",
    "shoppingRequired",
    "statusError",
    "user",
  ],
};

const baseReducer = combineReducers({
  adminTokenLoading: adminTokenLoadingReducer,
  agencies: agenciesReducer,
  agenciesError: agenciesErrorReducer,
  agenciesLoading: agenciesLoadingReducer,
  agencyUsers: agencyUsersReducer,
  agencyUsersError: agencyUsersErrorReducer,
  agencyUsersLoading: agencyUsersLoadingReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  benefits: benefitsReducer,
  benefitTypes: benefitTypesReducer,
  benefitsError: benefitsErrorReducer,
  benefitsLoading: benefitsLoadingReducer,
  bonusShoppingRequired: bonusShoppingRequiredReducer,
  catalogYearCurrent: persistReducer(
    catalogYearCurrentConfig,
    catalogYearCurrentReducer
  ),
  catalogYears: catalogYearsReducer,
  catalogYearsError: catalogYearsErrorReducer,
  catalogYearsLoading: catalogYearsLoadingReducer,
  impersonate: impersonateReducer,
  markets: marketsReducer,
  marketsError: marketsErrorReducer,
  marketsLoading: marketsLoadingReducer,
  settings: settingsReducer,
  settingsError: settingsErrorReducer,
  shopping: shoppingReducer,
  shoppingHidden: shoppingHiddenReducer,
  shoppingMethod: shoppingMethodReducer,
  shoppingRequired: persistReducer(
    shoppingRequiredConfig,
    shoppingRequiredReducer
  ),
  shoppingSelections: shoppingSelectionsReducer,
  shoppingFilterSort: shoppingFilterSortReducer,
  staticPages: staticPagesReducer,
  staticPagesError: staticPagesErrorReducer,
  staticPagesLoading: staticPagesLoadingReducer,
  status: statusReducer,
  statusError: statusErrorReducer,
  statusLoading: statusLoadingReducer,
  training: trainingReducer,
  trainingError: trainingErrorReducer,
  trainingLoading: trainingLoadingReducer,
  user: persistReducer(userPersistConfig, userReducer),
  userTokenLoading: userTokenLoadingReducer,
});

const rootReducer = persistReducer(basePersistConfig, baseReducer);

export default rootReducer;
