import { useCallback } from "react";
import { arrayOf, number, oneOfType, shape, string } from "prop-types";

import { UA_API_URL_STORAGE } from "../../../data/ua-api/ua-api-definitions";
import FlipCard from "../../../components/flip-card/FlipCard";
import LottiePlayer from "../../../components/lottie-player/lottie-player";
import LottieOptionsHeading from "../../../assets/lottie/star.json";
import trackEvent from "../../../features/tracking/track-event";

export default function PerkOptions({ benefit_id, benefit_name, cards }) {
  if (cards.length <= 0) return null;

  return (
    <div className="ua-col-span-1-6-sm ua-col-span-1-6-md">
      <div className="ua-display-flex ua-grid-gap-3-sm ua-align-items-center">
        <LottiePlayer
          animationData={LottieOptionsHeading}
          options={{ keepLastFrame: true }}
          style={{ height: "56px", marginBottom: "40px" }}
        />
      </div>
      <div className="ua-grid ua-grid-cols-1 ua-grid-cols-2-md ua-grid-gap-3-sm">
        {cards.map((card, index) => (
          <TrackedCard
            benefit_id={benefit_id}
            benefit_name={benefit_name}
            card={card}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

// Add analytics tracking to flip card
function TrackedCard({ benefit_id, benefit_name, card }) {
  const card_title = card.title;

  const trackCard = useCallback(() => {
    trackEvent({
      action: "shopping_benefit_card_click",
      relatedId: benefit_id,
      params: {
        benefit_id,
        benefit_name,
        card_title,
        triggered_from: "detail",
      },
    });
  }, [benefit_id, benefit_name, card_title]);

  return (
    <FlipCard
      {...card}
      image={`${UA_API_URL_STORAGE}/${card.image}`}
      onFlipStart={trackCard}
    />
  );
}

PerkOptions.propTypes = {
  benefit_id: oneOfType([number, string]),
  benefit_name: string,
  cards: arrayOf(
    shape({
      image: string,
      title: string,
      content: string,
    }).isRequired
  ),
};
