import trackLinkClick from "../tracking/track-link-click";

import thumbX1 from "../../assets/img/thumb-dcsm-brainshark.jpg";
import thumbX2 from "../../assets/img/thumb-dcsm-brainshark@2x.jpg";

export default function DcsmVideo() {
  const dcsmLink = "https://www.brainshark.com/uhc/vu?pi=zHIzLiG3ozl0Luz0";

  function trackClick() {
    trackLinkClick({
      event: "ua_status_dcsm",
      label: "Brainshark video",
      link: dcsmLink,
    });
  }

  return (
    <div className="ua-status-video">
      <h2 className="ua-teaser--overview--heading ua-teaser--overview--heading__lg ua-margin-xxxlg-bottom">
        Discover the power of a DCSM.
      </h2>
      <a
        className="ua-status-video--inner"
        href={dcsmLink}
        onClick={trackClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="Technology subsidies, Travel awards, Advertising packages"
          src={thumbX1}
          srcSet={`${thumbX1} 1x, ${thumbX2} 2x`}
          width={800}
          height={449}
        />
      </a>
    </div>
  );
}
