import { bool, string } from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiLogout } from "@mdi/js";

import ROUTES from "../../data/constants/routes";
import { logout } from "./AuthRedux";

export default function LogoutButton({
  className = "",
  label = "logout",
  showIcon = false,
  ...rest
}) {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  function handleClick(event) {
    event.preventDefault();
    dispatch(logout()).then(() => {
      navigate(ROUTES.login.path);
    });
  }

  return (
    <button
      {...rest}
      className={`logout-button ${className}`}
      onClick={handleClick}
      type="button"
    >
      {showIcon && (
        <Icon
          path={mdiLogout}
          title="Logout"
          size={1}
          className="ua-text-primary-two login-button--icon"
        />
      )}
      {label}
    </button>
  );
}

LogoutButton.propTypes = {
  className: string,
  label: string,
  showIcon: bool,
};
