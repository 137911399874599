import { useEffect } from "react";
import { bool, func } from "prop-types";

import trackEvent from "../tracking/track-event";

import NetChangeExample from "./NetChangeExample";
import Portal from "../../components/portal/Portal";

import { Modal, Typography } from "@uhc-tempo/components";
const { Content, Header, Title } = Modal;
const { Paragraph } = Typography;

export default function ModalNetChangeMedical({ handleOpen, open, ...rest }) {
  useEffect(() => {
    if (open) {
      trackEvent({ action: "status_net_change_medical_overlay" });
    }
  }, [open]);

  return (
    <Portal id="net-change-medical">
      {open && (
        <Modal contentId="net-change-medical-modal" handleClose={handleOpen}>
          <Header>
            <Title className="ua-font-weight-normal ua-padding-sm-top">
              Medical net change
            </Title>
          </Header>
          <Content>
            <Paragraph>
              Medical net change is calculated by subtracting the number of
              cancelled medical memberships from the number of sold medical
              memberships (new sales). This number is{" "}
              <strong className="tds-text__paragraph--bold">
                always changing
              </strong>{" "}
              based on your agency’s evolving book of business with UHC.
            </Paragraph>
          </Content>
          <NetChangeExample {...rest} enrolled={10000} type="medical" />
        </Modal>
      )}
    </Portal>
  );
}

ModalNetChangeMedical.propTypes = {
  handleOpen: func.isRequired,
  open: bool,
};
