import { element, func, node, oneOfType, string } from "prop-types";

import { Modal } from "@uhc-tempo/components";

import HtmlContent from "../../components/html-content/HtmlContent";
import Portal from "../../components/portal/Portal";
import trackEvent from "../tracking/track-event";
import useModalScrollbarOffset from "../../utilities/use-modal-scrollbar-offset";

export default function ResourceVideo({ children, link, title }) {
  const [modalOpen, toggleModalOpen] = useModalScrollbarOffset(false);

  function handleClick() {
    if (!modalOpen) {
      trackEvent({
        action: "training_resources_video",
        params: { video_title: title, video_url: link },
      });
    }

    toggleModalOpen();
  }

  return (
    <>
      <button
        className="resource-list--video"
        onClick={handleClick}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 46 46"
          role="presentation"
        >
          <circle cx="22.845" cy="22.845" r="22.845" />
          <path d="M30.4763,20.9522c1.3333.7698,1.3334,2.6943,0,3.4641l-10.1905,5.8835c-1.3333.7698-3-.1924-3-1.732v-11.767c0-1.5396,1.6667-2.5019,3-1.7321l10.1905,5.8835Z" />
        </svg>
        {children}
      </button>
      {modalOpen && (
        <Portal id="video-modal">
          <Modal
            handleClose={toggleModalOpen}
            className="ua-video-modal-width-override"
          >
            <Modal.Header>
              <Modal.Title>
                <HtmlContent rawHtml={title} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Content className="ua-video-modal-width-override--content">
              <div className="ua-display-flex">
                <div className="ua-responsive-embed">
                  <iframe
                    title="Video"
                    className="ua-responsive-embed--item"
                    src={`${link}&flashvars[autoPlay]=true&flashvars[autoMute]=false`}
                    allowFullScreen
                  />
                </div>
              </div>
            </Modal.Content>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </Portal>
      )}
    </>
  );
}

ResourceVideo.propTypes = {
  children: oneOfType([element, func, node]),
  title: string.isRequired,
  link: string.isRequired,
};
