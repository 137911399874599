import Bugsnag from "@bugsnag/js";
import { element, func, node, oneOfType } from "prop-types";

import ErrorView from "./ErrorView";

export default function ErrorBoundary({ children }) {
  const BugsnagBoundary = Bugsnag.getPlugin("react");

  // You can also provide an onError callback to run just on errors caught by
  // the error boundary. Maybe you want to attach some of the current state from
  // whatever model/store you're using (e.g redux)
  function handleError(event) {}

  return (
    <BugsnagBoundary FallbackComponent={ErrorView} onError={handleError}>
      {children}
    </BugsnagBoundary>
  );
}

ErrorBoundary.propTypes = {
  component: oneOfType([element, func, node]),
};
