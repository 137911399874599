import { array, number, oneOfType, string } from "prop-types";
import trackLinkClick from "../../../features/tracking/track-link-click";
import LottieFAQsHeading from "../../../assets/lottie/question.json";

import LottiePlayer from "../../../components/lottie-player/lottie-player";
import ShoppingDetailFaqs from "../../../features/shopping/ShoppingDetailFaqs";

import { Typography, Link } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function PerksFAQs({ benefit_id, benefit_name, faqs }) {
  return (
    <div className="ua-col-span-1-6-sm ua-col-span-1-6-md">
      <div className="ua-display-flex ua-grid-gap-3-sm ua-align-items-center">
        <LottiePlayer
          animationData={LottieFAQsHeading}
          options={{ keepLastFrame: true }}
          style={{ height: "56px", marginBottom: "40px" }}
        />
      </div>
      <Paragraph
        className="perk-details__blurb-large"
        style={{ marginBottom: "1.5rem" }}
      >
        Read our FAQs or{" "}
        <Link
          href={`mailto:unitedadvantage@uhc.com`}
          className="perk-details__blurb-large"
          onClick={() =>
            trackLinkClick({
              benefit_name: benefit_name,
              event: "email_support",
              label: "Email Support",
              link: `mailto:unitedadvantage@uhc.com`,
              relatedId: benefit_id,
              triggered_from: "faq",
            })
          }
          rel="noopener noreferrer"
          target="_blank"
          noIcon
        >
          email us
        </Link>{" "}
        with any questions.
      </Paragraph>

      <ShoppingDetailFaqs compact faqs={faqs} transparent />
    </div>
  );
}

PerksFAQs.propTypes = {
  benefit_id: oneOfType([number, string]),
  benefit_name: string,
  faqs: array,
};
