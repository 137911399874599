import { bool, string } from "prop-types";
import { Typography } from "@uhc-tempo/components";
const { Link, Paragraph } = Typography;

export default function RecaptchaDisclaimer({
  className,
  useItalic = false,
  useLight = false,
}) {
  const typographyProps = { useItalic, useLight };

  return (
    <Paragraph className={className} {...typographyProps} bodyStyle={3}>
      This site is protected by reCAPTCHA and the Google{" "}
      <Link
        {...typographyProps}
        href="https://policies.google.com/privacy"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Privacy Policy
      </Link>{" "}
      and{" "}
      <Link
        {...typographyProps}
        href="https://policies.google.com/terms"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Terms of Service
      </Link>{" "}
      apply.
    </Paragraph>
  );
}

RecaptchaDisclaimer.propTypes = {
  className: string,
  useItalic: bool,
  useLight: bool,
};
