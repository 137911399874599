import { useEffect, useState, useRef } from "react";
import { bool, func, oneOfType, number, string } from "prop-types";
import Player from "@vimeo/player";

import { Notification, Typography } from "@uhc-tempo/components";

import trackEvent from "../../features/tracking/track-event";
import useUserProfile from "../../features/user/use-user-profile";
import getVimeoIdFromUrl from "../../utilities/get-vimeo-id-from-url";

const { Paragraph } = Typography;
const playerEvents = [
  "ended",
  "loaded",
  "pause",
  "play",
  "playbackratechange",
  "seeked",
];

export default function VimeoPlayer({
  autoplay = false,
  controls = true,
  benefitId,
  fullscreen = true,
  loop = false,
  muted = false,
  onVideoEnd,
  vimeoUrl = "",
}) {
  const videoId = getVimeoIdFromUrl(vimeoUrl);
  const { id: user_id = 0 } = useUserProfile();
  const playerElement = useRef();
  const [error, setError] = useState(false);

  useEffect(() => {
    let player;
    if (!!videoId && playerElement?.current) {
      player = new Player(playerElement?.current, {
        autoplay,
        controls,
        fullscreen,
        loop,
        muted,
        id: parseInt(videoId),
      });
      player.ready().catch(() => setError(true));

      let videoTitle = "";
      player.getVideoTitle().then((title) => (videoTitle = title));

      playerEvents.forEach((event) => {
        player.on(event, (data) => {
          delete data.id;
          if (data.playbackRate) {
            data.playback_rate = data.playbackRate;
            delete data.playbackRate;
          }
          const params = {
            benefit_id: benefitId,
            video_id: parseInt(videoId),
            video_title: videoTitle,
            user_id,
            ...data,
          };
          trackEvent({
            action: `video_player_${event}`,
            relatedId: benefitId,
            params,
          });

          if (event === "ended" && onVideoEnd) {
            onVideoEnd();
          }
        });
      });
    }

    return () => {
      player?.destroy();
    };
  }, [
    autoplay,
    benefitId,
    controls,
    fullscreen,
    loop,
    muted,
    onVideoEnd,
    user_id,
    videoId,
  ]);

  return error ? (
    <Notification
      dismissClickHandler={() => setError(false)}
      isDismissed={!error}
      notificationType="error"
    >
      <Paragraph className="tds-margin-none">
        There was a problem loading this video
      </Paragraph>
    </Notification>
  ) : (
    <div className="ua-responsive-embed" id={videoId} ref={playerElement} />
  );
}

VimeoPlayer.propTypes = {
  autoplay: bool,
  benefitId: oneOfType([number, string]),
  controls: bool,
  fullscreen: bool,
  loop: bool,
  muted: bool,
  onVideoEnd: func,
  vimeoUrl: string.isRequired,
};
