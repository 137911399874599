import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@mdi/react";
import { mdiEye, mdiMenuDown, mdiMenuUp } from "@mdi/js";

import useClickOutside from "../../components/popup-menu/use-click-outside";
import { SCREEN_SMALL } from "../../data/constants/media-queries";
import useMatchMedia from "../page-layout/use-match-media";

import ImpersonateUser from "./ImpersonateUser";

import { Typography } from "@uhc-tempo/components";
const { H5 } = Typography;

export default function AdminBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const isScreenSm = useMatchMedia(SCREEN_SMALL);
  const { capabilities, hasAuth } = useSelector((state) => state.auth);
  const canImpersonate =
    !!hasAuth && !!capabilities && capabilities.includes("canImpersonate");

  const adminBarRef = useRef();

  function handleClickOutside() {
    if (isScreenSm) {
      setMenuOpen(false);
    }
  }

  function handleMenu() {
    setMenuOpen((prevState) => !prevState);
  }

  useClickOutside(adminBarRef, handleClickOutside);

  useEffect(() => {
    if (canImpersonate) {
      document.body.classList.add("admin");
    } else {
      document.body.classList.remove("admin");
    }

    return () => {
      document.body.classList.remove("admin");
    };
  }, [canImpersonate]);

  if (!canImpersonate) {
    return null;
  }

  return (
    <div
      className={`admin-bar ${menuOpen ? "admin-bar--open" : ""}`}
      ref={adminBarRef}
    >
      <div className="ua-display-flex ua-grid-gap-2-md ua-align-items-stretch-sm ua-align-items-center-md ua-flex-column-sm">
        <div className="ua-display-flex ua-align-items-center">
          <H5 className="ua-margin-none ua-text-supporting-four ua-line-height-1">
            Agency{" "}
            <span className="ua-text-newline-md">
              view <Icon path={mdiEye} size="1em" />
            </span>
          </H5>
          {isScreenSm && (
            <button
              className="drawer--menu-button ua-text-supporting-four ua-margin-auto-left ua-padding-none ua-cursor-pointer"
              onClick={handleMenu}
              type="button"
            >
              {menuOpen ? (
                <Icon
                  path={mdiMenuUp}
                  size="3em"
                  title="Close agency view menu"
                />
              ) : (
                <Icon
                  path={mdiMenuDown}
                  size="3em"
                  title="Open agency view menu"
                />
              )}
            </button>
          )}
        </div>
        {(!isScreenSm || (isScreenSm && menuOpen)) && (
          <div className="admin-bar--menu ua-flex-grow">
            <ImpersonateUser />
          </div>
        )}
      </div>
    </div>
  );
}
