export const formatPrecision = (value, precision = 2, trim = true) => {
  if (typeof value === "string" || value instanceof String) {
    value = value.replace(/,/g, "");
  }

  value = parseFloat(value);
  value = value.toFixed(precision).toString();

  if (trim) {
    value = value.replace(/.00$/, "");
  }

  return value;
};

export const convertDecimalToPercent = (decimal) => {
  return Math.ceil(decimal * 100);
};

export default function formatNumber(value, precision = 2, trim = true) {
  const number = formatPrecision(value, precision, trim);
  let parts = number.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
