import trackEvent from "./track-event";
import isInternalLink from "../../utilities/is-internal-link";
import isMailLink from "../../utilities/is-mail-link";

export default function trackLinkClick({
  benefit_name,
  event,
  label,
  link,
  relatedId,
  triggered_from,
}) {
  try {
    // The event parameter is not actually being for an actual event,
    // but rather an event action string
    let action = event || "click";
    let benefitAction = "benefit_internallinkclick";
    let params = {
      link_text: label,
      link_url: link,
    };

    if (isMailLink(link)) {
      benefitAction = "benefit_mailto";
      params.link_classes = "email";
      params.outbound = true;
    } else if (!isInternalLink(link)) {
      benefitAction = "benefit_externallinkclick";
      params.outbound = true;
    }

    // Only use benefit action if there's a related ID
    // and the event action is not defined
    if (!!relatedId && !event) {
      action = benefitAction;
    }

    if (!!relatedId) {
      params.benefit_id = relatedId;
    }

    if (!!benefit_name) {
      params.benefit_name = benefit_name;
    }

    if (!!triggered_from) {
      params.triggered_from = triggered_from;
    }

    trackEvent({ action, relatedId, params });
  } catch (err) {}
}
