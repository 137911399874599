import { string } from "prop-types";

import LottiePlayer from "../../../components/lottie-player/lottie-player";
import LottieGiftHeading from "../../../assets/lottie/gift.json";

import HtmlContent from "../../../components/html-content/HtmlContent";

import { Typography } from "@uhc-tempo/components";
const { H4 } = Typography;

export default function PerkFeatures({
  features,
  sidebarContent,
  sidebarTitle,
}) {
  return (
    <div className="ua-col-span-1-6-sm ua-col-span-1-6-md">
      <div className="ua-display-flex ua-grid-gap-3-sm ua-align-items-center">
        <LottiePlayer
          animationData={LottieGiftHeading}
          options={{ keepLastFrame: true }}
          style={{ height: "56px", marginBottom: "40px" }}
        />
      </div>
      <div className="ua-grid ua-grid-cols-6 ua-grid-gap-3-sm">
        <div className="ua-col-span-1-6-sm ua-col-span-1-4-md perk-details__features">
          <HtmlContent rawHtml={features} />
        </div>
        {sidebarContent && (
          <div className="ua-col-span-1-6-sm ua-col-span-5-2-md">
            <div className="perk-details__sidebar-right">
              <H4 className="ua-text-gray-seven">{sidebarTitle}</H4>
              <HtmlContent rawHtml={sidebarContent} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

PerkFeatures.propTypes = {
  features: string,
  sidebarContent: string,
  sidebarTitle: string,
};
