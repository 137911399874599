import { useEffect, useMemo, useState } from "react";
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types";

import getLevelName from "../../utilities/get-level-name";
import useCoachSettings from "../coach/use-coach-settings";

import Access from "./Access";
import Projection from "./Projection";
import Rewards from "./Rewards";
import StatusLoading from "./StatusLoading";
import StatusNotFound from "./StatusNotFound";
import TierRequirements from "./TierRequirements";

import { Typography } from "@uhc-tempo/components";
const { H1, Paragraph } = Typography;

export default function StatusPageLevel2AndUp({
  agency_id,
  agencyName,
  catalog_year,
  catalog_year_next,
  logo,
  status,
  statusError,
  statusLoading,
}) {
  const { site_link } = useCoachSettings();

  const [active, setActive] = useState();
  const activeLabel = useMemo(() => getLevelName(active), [active]);

  // Update active tier with API response
  const { availableTiers = [], CurrentUATierClass: currentTier } =
    (!!status && status) || {};

  useEffect(() => {
    if (availableTiers.includes(currentTier)) {
      setActive(currentTier);
    } else {
      setActive(availableTiers[0]);
    }
  }, [agency_id, availableTiers, currentTier]);

  return (
    <>
      <section className="fluid-container ua-status">
        <header className="ua-padding-xxxlg-top">
          <H1 className="ua-margin-sm-bottom">
            {catalog_year_next} United Advantage Status Projection
          </H1>
          <Paragraph bodyStyle={1} className="ua-margin-none">
            Track your agency’s {catalog_year_next} United Advantage tier status
          </Paragraph>
        </header>
        {!statusError && statusLoading && <StatusLoading />}
        {!!statusError && !statusLoading && (
          <StatusNotFound agencyName={agencyName} logo={logo} />
        )}
        {!statusError && !statusLoading && !!availableTiers.length && (
          <TierRequirements
            active={active}
            activeLabel={activeLabel}
            activeIndex={availableTiers.indexOf(active)}
            agencyName={agencyName}
            className="ua-req__no-overlap"
            currentYear={catalog_year}
            logo={logo}
            nextYear={catalog_year_next}
            setActive={setActive}
            status={status}
          />
        )}
      </section>
      {!statusError && !statusLoading && !!availableTiers.length && (
        <>
          <Projection
            className="ua-projection__bg-gradient"
            ctaLink={site_link}
            ctaText="Get help from your Advantage Coach"
            ctaTrackingEvent="advantage_coach_meeting"
            nextYear={catalog_year_next}
            projectedClass={status.ProjectedUATierClass}
            projectedStatus={status.ProjectedUATierLabel}
          />
          <Access tier={active} />
          <Rewards showLevel3={availableTiers.includes("level_3")} />
        </>
      )}
    </>
  );
}

StatusPageLevel2AndUp.propTypes = {
  agency_id: oneOfType([number, string]),
  agencyName: string,
  catalog_year: number,
  catalog_year_next: number,
  logo: string,
  status: shape({
    availableTiers: arrayOf(string),
    CurrentUATierClass: string,
    ProjectedUATierClass: string,
    ProjectedUATierLabel: string,
  }),
  statusError: string,
  statusLoading: bool,
};
