import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Edit from "@mui/icons-material/Edit";

import ROUTES from "../../data/constants/routes";
import UA_API, { UA_API_SHOPPING_EP_FINALIZE } from "../../data/ua-api/ua-api";
import { getBenefits } from "../benefits/BenefitsRedux";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import trackEvent from "../tracking/track-event";
import { updateUser } from "../user/UserRedux";
import useSelectedBenefits from "./use-selected-benefits";
import useUserAgency from "../user/use-user-agency";

import ShoppingBenefitSummaryItem from "./ShoppingBenefitSummaryItem";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";

import {
  Button,
  Notification,
  Typography,
  UtilityIcon,
} from "@uhc-tempo/components";
const { Link, Paragraph } = Typography;

export default function ShoppingFinalize(props) {
  const method = useSelector((state) => state.shoppingMethod);
  const { id: agency_id, logo, name } = useUserAgency();
  const { selections, selectedBenefits } = useSelectedBenefits();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const catalog_year = useSelector(
    ({ catalogYearCurrent }) => catalogYearCurrent.year
  );

  const [inProgress, setInProgress] = useState(false);
  const [formError, setFormError] = useState(null);
  const changeLink = useMemo(() => getChangeLink(method), [method]);

  const formErrorAlert = useRef();

  function displayError(errorMessage) {
    setFormError([errorMessage]);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  async function handleSubmit() {
    setInProgress(true);

    const confirmation =
      method === "bonus"
        ? ROUTES.shoppingBonusConfirmationNow.path
        : ROUTES.shoppingConfirmation.path;

    try {
      const { data = {} } = await UA_API.put(UA_API_SHOPPING_EP_FINALIZE, {
        selections,
      });

      const { catalog_year: catalog_year_obj = {}, status = "" } = data;

      if (status === "success") {
        trackEvent({ action: "shopping_finalize", params: { method } });
        dispatch(getBenefits({ agency_id, catalog_year }));

        // hasTraining is normally defined by the login response, but
        // we need to update based on the user's finalized selections
        const { training_benefit_id } = catalog_year_obj;

        if (
          !!training_benefit_id &&
          selections.some((s) => `${s.id}` === `${training_benefit_id}`)
        ) {
          dispatch(updateUser({ hasTraining: true }));
        } else if (method !== "bonus") {
          dispatch(updateUser({ hasTraining: false }));
        }

        navigate(confirmation);
      } else {
        displayError("Unexpected response");
      }
    } catch (err) {
      displayError(err.message || "Error");
    }
  }

  useEffect(() => {
    if (!selectedBenefits.length) {
      if (method === "bonus") {
        navigate(ROUTES.shoppingBonusIntro.path);
      } else {
        navigate(ROUTES.shopping.path);
      }
    }
  }, [method, selectedBenefits]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ua-bg-primary-three ua-border ua-border-radius-sm ua-border-color-gray-four ua-padding-jumbo-horizontal">
      <div className="ua-margin-auto ua-padding-jumbo-horizontal ua-padding-lg-top ua-padding-jumbo-bottom">
        {!!formError && (
          <div ref={formErrorAlert}>
            <Notification
              className="tds-margin-xxlg-bottom"
              dismissClickHandler={() => setFormError(null)}
              isDismissed={!formError}
              notificationType="error"
            >
              {formError}
            </Notification>
          </div>
        )}
        {!!logo && (
          <Paragraph className="ua-text-center ua-margin-md-bottom">
            <img src={logo} alt={name} height={118} width={247} />
          </Paragraph>
        )}
        <div className="ua-border-bottom ua-border-top ua-border-color-primary-one ua-margin-xxxxlg-bottom ua-padding-md-bottom ua-padding-xxxxlg-top">
          {selectedBenefits.map(
            ({
              allowMultiple,
              id,
              most_popular,
              home_excerpt,
              qty,
              selectedValue,
              title,
            }) => (
              <ShoppingBenefitSummaryItem
                allowMultiple={allowMultiple}
                home_excerpt={home_excerpt}
                key={`benefit-${id}`}
                most_popular={most_popular}
                qty={qty}
                selectedValue={selectedValue}
                title={title}
              />
            )
          )}
        </div>
        <div className="ua-display-flex ua-justify-content-flex-end ua-align-items-center ua-grid-gap-3-sm">
          <TempoRouterLink
            component={Link}
            to={changeLink}
            className="tds-margin-none ua-font-weight-normal"
          >
            <UtilityIcon
              className="tds-margin-xsm-right"
              color="currentColor"
              icon={Edit}
              size="small"
            />
            Change or edit perks
          </TempoRouterLink>
          <Button
            buttonType={inProgress ? "disabled" : "primary-one"}
            disabled={inProgress}
            loading={inProgress}
            onClick={handleSubmit}
            type="button"
          >
            {inProgress ? "Submitting…" : "Submit agency picks"}
          </Button>
        </div>
      </div>
    </div>
  );
}

function getChangeLink(method) {
  switch (method) {
    case "bonus":
      return ROUTES.shoppingBonusChoose.path;
    case "choose":
    default:
      return ROUTES.shoppingChoose.path;
  }
}
