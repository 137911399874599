export const SITE_TITLE = import.meta.env.VITE_SITE_TITLE || "United Advantage";
export const SITE_TITLE_SEPARATOR = " | ";

export const SITE_BUGSNAG_KEY = import.meta.env.VITE_BUGSNAG_KEY || null;

export const RECAPTCHA_SITE_KEY =
  import.meta.env.VITE_RECAPTCHA_SITE_KEY || null;

export const SITE_VERSION = import.meta.env.VITE_SITE_VERSION || null;

export const NETSTUDY_TOKEN = import.meta.env.VITE_NETSTUDY_TOKEN || null;

export const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID || null;
