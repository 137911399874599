import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const selectStaticPage = createSelector(
  (state) => state.staticPages,
  (_, slug) => slug,
  (staticPages, slug) => staticPages.find((page) => `/${page.slug}` === slug)
);

export default function useStaticPage(slug) {
  const staticPage = useSelector((state) => selectStaticPage(state, slug));
  return staticPage;
}
