import ROUTES from "../../data/constants/routes";

import { Typography } from "@uhc-tempo/components";
import EditProfileForm from "../../features/user/EditProfileForm";

const { H1 } = Typography;

export default function Profile() {
  return (
    <section className="fluid-container ua-padding-xxxxlg-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-3-8-lg ua-col-span-2-10-md ua-col-span-1-12-sm">
          <H1>{ROUTES.profile.title}</H1>
          <EditProfileForm />
        </div>
      </div>
    </section>
  );
}
