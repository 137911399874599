import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { loginWithAdminToken, loginWithUserToken, logout } from "./AuthRedux";

export default function useAuthToken() {
  const { access_token: currentToken } = useSelector(({ auth = {} }) => auth);

  const dispatch = useDispatch();
  const locationSearch = window.location.search;

  useEffect(() => {
    const { access_token, admin, admin_user_id, agency_id, user_id } =
      getAuthToken(locationSearch);

    if (!!access_token) {
      let dispatchAuthTokenLogin;

      if (admin) {
        dispatchAuthTokenLogin = () => {
          dispatch(
            loginWithAdminToken({
              access_token,
              admin_user_id,
              agency_id,
              user_id,
            })
          );
        };
      } else {
        dispatchAuthTokenLogin = () =>
          dispatch(loginWithUserToken({ access_token, user_id }));
      }

      const dispatchLoginAfterLogout = () => {
        setTimeout(() => {
          dispatchAuthTokenLogin();
        }, 500);
      };

      if (!!currentToken && currentToken !== access_token) {
        dispatch(logout())
          .unwrap()
          .then(dispatchLoginAfterLogout)
          .catch(dispatchLoginAfterLogout);
      } else if (!currentToken) {
        dispatchAuthTokenLogin();
      }
    }
  }, [currentToken, dispatch, locationSearch]);
}

function getAuthToken(locationSearch) {
  let access_token, admin, admin_user_id, agency_id, user_id;

  try {
    const urlParams = new URLSearchParams(locationSearch);
    access_token = urlParams.get("token");
    admin = urlParams.get("admin");
    agency_id = urlParams.get("agency_id");
    user_id = urlParams.get("user_id");

    if (access_token) {
      const { sub } = jwtDecode(access_token);

      if (!sub) {
        throw new Error("No user specified");
      }

      if (!!admin && ["1", "true"].includes(admin.toString())) {
        admin = true;
        admin_user_id = sub;
      } else {
        admin = false;
        user_id = sub;
      }

      // Clean up token from URL
      // Not using react-router so that this hook
      // can be fired before router is loaded
      if (!!window?.history?.replaceState) {
        urlParams.delete("token");
        urlParams.delete("admin");
        urlParams.delete("agency_id");
        urlParams.delete("user_id");

        const updatedParams = urlParams.toString();
        const replaceSearch = !!updatedParams ? `?${updatedParams}` : "";
        const replaceUrl = window.location.pathname + replaceSearch;

        window.history.replaceState(null, "", replaceUrl);
      }
    }
  } catch (err) {
    console.error(err);
    return { error: err?.message || "Error" };
  }

  return { access_token, admin, admin_user_id, agency_id, user_id };
}
