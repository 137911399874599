import { arrayOf, object } from "prop-types";

import ResourceMaterialsDownload from "./ResourceMaterialsDownload";

import { Typography } from "@uhc-tempo/components";
const { H4 } = Typography;

export default function ResourceMaterials({ materials }) {
  if (!materials || !materials.length) {
    return null;
  }

  return (
    <div>
      <H4 className="ua-margin-sm-bottom tds-text__paragraph-large">
        Materials
      </H4>
      {materials.map((item, i) => (
        <ResourceMaterialsDownload key={`materials-${i}`} {...item} />
      ))}
    </div>
  );
}

ResourceMaterials.propTypes = {
  materials: arrayOf(object),
};
