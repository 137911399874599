import UA_API from "../../data/ua-api/ua-api";
import { UA_API_EP_TRACK } from "../../data/ua-api/ua-api-definitions";

export default async function sendToTrackingAPI(
  action,
  params = {},
  relatedId = null
) {
  const postData = {
    relatedId,
    action,
    data: params,
  };

  try {
    const response = await UA_API.post(UA_API_EP_TRACK, postData);
    const {
      data: { status },
    } = response;
    if (status !== "saved") {
      throw new Error("Internal analytics failed to save");
    }
  } catch (err) {
    console.error(err);
  }
}
