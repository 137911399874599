import { createAction, createReducer } from "@reduxjs/toolkit";

import { isLogout } from "../auth/AuthRedux";

const initialState = { id: null, next: null, year: null };

export const setCatalogYearCurrent = createAction("catalogYearCurrent/set");

export const catalogYearCurrentReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setCatalogYearCurrent, (state, action) => {
        let { id, year } = action.payload || {};
        year = parseInt(`${year}`.trim(), 10);
        const next = !!year && !isNaN(year) ? year + 1 : initialState.next;

        return { id, next, year };
      })
      .addMatcher(isLogout, (state, action) => initialState);
  }
);
