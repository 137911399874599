export default function sendToGA4(name, params = {}) {
  let shouldLog;

  try {
    shouldLog = !!params?.traffic_type && params.traffic_type === "internal";

    if (!window.gtag) {
      throw new Error("Google Analytics is not loaded");
    }

    if (!name) {
      throw new Error("No event name specified");
    }

    window.gtag("event", name, params);

    if (shouldLog) {
      console.log("sent to ga", name, params);
    }
  } catch (err) {
    if (shouldLog) {
      console.warn(err?.message || "GA error");
    }
  }
}
