import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import UA_API, { UA_API_EP_SETTINGS } from "../../data/ua-api/ua-api";

import normalizeSettingsResponse from "./normalize-settings-response";

const initialState = null;

export const getSettings = createAsyncThunk(
  "settings/get",
  async function fetchSettings() {
    try {
      const response = await UA_API.get(UA_API_EP_SETTINGS);
      let [settingsStatus, settings] = normalizeSettingsResponse(response);

      if (settingsStatus === "error") {
        throw new Error("Unexpected response");
      }

      return settings;
    } catch (err) {}
  }
);

export const settingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getSettings.fulfilled, (state, action) => ({
    ...action.payload,
  }));
});

export const settingsErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getSettings.pending, (state, action) => true)
    .addCase(getSettings.fulfilled, (state, action) => false)
    .addCase(getSettings.rejected, (state, action) => action.payload);
});
