import setHotjarIdentity from "./set-hotjar-identity";
import setUserProperties from "./set-user-properties";
import trackEvent from "./track-event";

export default function trackLogin(
  userProperties,
  eventUserProperties,
  method = "UA API Form"
) {
  eventUserProperties =
    !!eventUserProperties && typeof eventUserProperties === "object"
      ? eventUserProperties
      : {};
  let params = { method, ...eventUserProperties };
  setHotjarIdentity(userProperties);
  setUserProperties(userProperties);
  trackEvent({ action: "login", params });
}
