import normalizeShoppingSelections from "./normalize-shopping-selections";

export default function normalizeShoppingSelectionsResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    const { message, benefits } = data;

    const [selectionsStatus, selections, selectionsMessage] =
      normalizeShoppingSelections(benefits);

    if (selectionsStatus === "error") {
      throw new Error(selectionsMessage);
    }

    return ["success", selections, message || "Success"];
  } catch (err) {
    return ["error", null, err.message || "Unexpected response"];
  }
}
