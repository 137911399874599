import { useState } from "react";
import { useDispatch } from "react-redux";

import { AGENCY_ROLES } from "../../data/constants/agency-roles";
import getFormElementState from "../../utilities/get-form-element-state";
import UA_API, { UA_API_EP_CONTACT } from "../../data/ua-api/ua-api";
import { updateUser } from "../user/UserRedux";
import useUserAgency from "../user/use-user-agency";
import useUserProfile from "../user/use-user-profile";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";

import Portal from "../../components/portal/Portal";

import {
  Accordion,
  AccordionGroup,
  Button,
  Form,
  Modal,
  Notification,
  Typography,
} from "@uhc-tempo/components";
const {
  ErrorMessage,
  Group,
  Input,
  Label,
  LargeErrorMessage,
  Select,
  Textarea,
} = Form;
const { Content, Footer, Header } = Modal;
const { H3, H5, Paragraph } = Typography;

const initData = {
  agency_role: "",
  email: "",
  first_name: "",
  last_name: "",
  message: "",
  mobile_phone: "",
  phone: "",
};

export default function CallToActionModalForm({
  active,
  modalData,
  onClose,
  onSuccess,
  name,
  submitUrl = UA_API_EP_CONTACT,
}) {
  const userProfile = useUserProfile();
  const {
    name: agency_name,
    ra_number: agency_ra_number,
    levelName: agency_level,
  } = useUserAgency();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState(initData);
  const [data, setData] = useState(() => ({
    ...initData,
    agency_role: userProfile.agency_role,
    email: userProfile.email,
    first_name: userProfile.first_name,
    last_name: userProfile.last_name,
    mobile_phone: userProfile.mobile_phone,
    phone: userProfile.phone,
  }));
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const { notify, waitlist } = modalData;
  const userMetaData = {
    agency_name,
    agency_ra_number,
    agency_level,
    user_id: userProfile.id,
  };

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }
    const error = validateInput(input);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  }

  function dismissSuccessNotification() {
    setFormSuccess(null);
    setFormError(null);
  }

  function displayError(errorMessage = "Error") {
    setFormError(`${errorMessage}`);
    setInProgress(false);
  }

  function displaySuccess(successMessage = "Success!") {
    onSuccess();
    setFormError(null);
    setFormSuccess(`${successMessage}`);
    setInProgress(false);

    setTimeout(() => handleClose(), 2000);
  }

  function handleClose() {
    setData((prevData) => ({ ...prevData, message: "" }));
    setFormSuccess(null);
    onClose();
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 50);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    // Tempo Accordion component is toggled by a button without
    // a type property and does not provide a method to set that
    // property, so it is treated as a submit button when used
    // inside a form. The form must therefore check if the submit
    // event was triggered from the accordion before proceeding.
    if (isTriggeredByAccordion(event)) {
      return;
    }

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    setInProgress(true);

    try {
      const postData = {
        ...modalData,
        ...userMetaData,
        ...data,
      };
      const response = await UA_API.post(submitUrl, postData);

      if (!!response.data && response.data.status === "success") {
        displaySuccess(response.data.message || "Success!");
      } else {
        displayError("Unexpected response");
      }

      dispatch(
        updateUser({
          agency_role: data.agency_role,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          mobile_phone: data.mobile_phone,
          phone: data.phone,
        })
      );
    } catch (err) {
      displayError(err.message || "Error");
    }
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  return active ? (
    <Portal id="call-to-action-modal">
      <Modal handleClose={handleClose}>
        <Header>
          <H3>{name}</H3>
        </Header>
        <Form noValidate onSubmit={handleSubmit}>
          <Content>
            <CallToActionText
              name={name}
              notifiy={notify}
              waitlist={waitlist}
            />

            {!!formError && (
              <LargeErrorMessage className="tds-margin-xxlg-bottom">
                {formError}
              </LargeErrorMessage>
            )}

            {!!formSuccess && (
              <Notification
                className="tds-margin-xxlg-bottom"
                dismissClickHandler={() => dismissSuccessNotification()}
                notificationType="success"
              >
                <Paragraph className="tds-margin-none">{formSuccess}</Paragraph>
              </Notification>
            )}

            <div>
              <AccordionGroup className="ua-accordion-form">
                <Accordion title="View your information">
                  <H5 className="tds-margin-none-bottom">{agency_name}</H5>
                  <Paragraph>AGENCY ID# {agency_ra_number}</Paragraph>
                  <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
                    <Group
                      className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
                      groupId="first_name"
                      state={getFormElementState(errors.first_name)}
                    >
                      <Label>First name*</Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        name="first_name"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required
                        type="text"
                        value={data.first_name}
                      />
                      {!!errors.first_name && (
                        <ErrorMessage>{errors.first_name}</ErrorMessage>
                      )}
                    </Group>
                    <Group
                      className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
                      groupId="last_name"
                      state={getFormElementState(errors.last_name)}
                    >
                      <Label>Last name*</Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        name="last_name"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required
                        type="text"
                        value={data.last_name}
                      />
                      {!!errors.last_name && (
                        <ErrorMessage>{errors.last_name}</ErrorMessage>
                      )}
                    </Group>
                  </div>
                  <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
                    <Group
                      className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
                      groupId="phone"
                      state={getFormElementState(errors.phone)}
                    >
                      <Label>Business phone*</Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        name="phone"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required
                        pattern={validationPatterns.tel}
                        type="tel"
                        value={data.phone}
                      />
                      {!!errors.phone && (
                        <ErrorMessage>{errors.phone}</ErrorMessage>
                      )}
                    </Group>
                    <Group
                      className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
                      groupId="phone"
                      state={getFormElementState(errors.phone)}
                    >
                      <Label>Mobile phone</Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        name="mobile_phone"
                        onBlur={handleInput}
                        onChange={handleInput}
                        pattern={validationPatterns.tel}
                        type="tel"
                        value={data.mobile_phone}
                      />
                      {!!errors.mobile_phone && (
                        <ErrorMessage>{errors.mobile_phone}</ErrorMessage>
                      )}
                    </Group>
                  </div>
                  <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between tds-margin-lg-bottom">
                    <Group
                      className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
                      groupId="email"
                      state={getFormElementState(errors.email)}
                    >
                      <Label>Email*</Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        name="email"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required
                        pattern={validationPatterns.email}
                        type="email"
                        value={data.email}
                      />
                      {!!errors.email && (
                        <ErrorMessage>{errors.email}</ErrorMessage>
                      )}
                    </Group>
                    <Group
                      className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
                      groupId="agency_role"
                      state={getFormElementState(errors.agency_role)}
                    >
                      <Label>Select a role*</Label>
                      <Select
                        containerProps={{
                          className:
                            "tds-form__select ua-display-block ua-width-100",
                        }}
                        name="agency_role"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required
                        value={data.agency_role}
                      >
                        <Select.Option value="">Select a role</Select.Option>
                        {AGENCY_ROLES.map((value, i) => (
                          <Select.Option key={value} value={value}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Group>
                  </div>
                </Accordion>
              </AccordionGroup>
            </div>
            <Group
              className="tds-margin-sm-bottom tds-margin-xxlg-top"
              groupId="message"
              state={getFormElementState(errors.message)}
            >
              <Label>
                Message / note to UHC &mdash; United Advantage Program*
              </Label>
              <Textarea
                autoCapitalize="none"
                autoCorrect="off"
                name="message"
                onBlur={handleInput}
                onChange={handleInput}
                placeholder="Type message here"
                style={{ maxWidth: "unset" }}
                value={data.message}
                required
              />
              {!!errors.message && (
                <ErrorMessage>{errors.message}</ErrorMessage>
              )}
            </Group>
          </Content>
          <Footer>
            <div className="ua-display-flex ua-width-100 ua-align-items-center">
              <Paragraph
                bodyStyle={2}
                className="tds-margin-none-bottom ua-flex-grow"
              >
                Typical response time: 24 hours
              </Paragraph>
              <div className="ua-display-flex ua-align-items-flex-end">
                <Button
                  buttonType="secondary-one"
                  className="tds-margin-md-right"
                  onClick={handleClose}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  buttonType={inProgress ? "disabled" : "primary-one"}
                  loading={inProgress}
                  type="submit"
                >
                  {inProgress ? "Processing..." : "Submit"}
                </Button>
              </div>
            </div>
          </Footer>
        </Form>
      </Modal>
    </Portal>
  ) : null;
}

function CallToActionText({ name, notifiy, waitlist }) {
  if (notifiy) {
    return (
      <Paragraph>
        Please complete this form to be notified by email when registration for{" "}
        <span className="tds-text__paragraph--bold">{name}</span> becomes
        available. If needed, you can update your contact information below.
      </Paragraph>
    );
  }

  if (waitlist) {
    return (
      <Paragraph>
        Please complete this form to be notified by email if a space opens up
        for <span className="tds-text__paragraph--bold">{name}</span>. If
        needed, you can update your contact information below.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      To get started, click the submit button. A member of the United Advantage
      team will contact you within 24 hours to answer questions and walk you
      through the process. If you’d like to add a note, please do so below.
    </Paragraph>
  );
}

function isTriggeredByAccordion(event) {
  return (
    !!event &&
    !!event.nativeEvent &&
    !!event.nativeEvent.submitter &&
    event.nativeEvent.submitter.classList.contains("tds-accordion__header")
  );
}
