import { useEffect, useRef } from "react";
import { func } from "prop-types";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useScroll, useTransform } from "framer-motion";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";

import BenefitDetailsFooter from "../../features/benefits/BenefitsDetailsFooter";
import PerksHero from "./training/PerksHero";
import ROUTES from "../../data/constants/routes";
import trackLinkClick from "../../features/tracking/track-link-click";
import useFilteredAndSortedBenefits from "../../features/shopping/use-filtered-and-sorted-benefits";

import PerkHighlightVideo from "./PerkPageComponents/PerkHighlightVideo";
import PerkFeatures from "./PerkPageComponents/PerkFeatures";
import PerkHowItWorks from "./PerkPageComponents/PerkHowItWorks";
import PerkOptions from "./PerkPageComponents/PerkOptions";
import PerksFAQs from "./PerkPageComponents/PerksFAQs";

export default function PerkDetails({ handlePageView }) {
  const { benefitSlug } = useParams();
  const navigate = useNavigate();
  const filterBy = useSelector((state) => state.shoppingFilterSort?.filterBy);
  const sortBy = useSelector((state) => state.shoppingFilterSort?.sortBy);

  const sortedAndFilteredBenefits = useFilteredAndSortedBenefits({
    filterBy,
    sortBy,
  });

  const benefit = sortedAndFilteredBenefits.filter(
    (benefit) => benefit.slug === benefitSlug
  )[0];
  const benefitIndex = sortedAndFilteredBenefits.findIndex(
    (benefit) => benefit.slug === benefitSlug
  );

  const headerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: headerRef,
    offset: ["1 0.9", "1 0"],
  });
  const curtainValue = useTransform(scrollYProgress, [0, 1], [1, 5]);

  const handleBackTracking = () => {
    trackLinkClick({
      benefit_name: benefit.name,
      event: "benefit_linkclick_back",
      label: "Back to all perks link",
      link: ROUTES.shoppingChoose.path,
      relatedId: benefit.id,
      triggered_from: "detail",
    });
  };

  function handleBackButtonClick() {
    navigate(ROUTES.shoppingChoose.path);
    handleBackTracking();
  }

  useEffect(() => {
    if (!!benefit && !!benefit.name && !!handlePageView) {
      handlePageView(benefit?.name);
    }
  }, [benefit, handlePageView]);

  if (!benefit) return null;

  return (
    <section className="page-main__fill">
      <button
        className="ua-bg-supporting-one ua-text-primary-three perk-details__back-button"
        onClick={handleBackButtonClick}
      >
        <Icon path={mdiArrowLeft} size={1} />
        <div className="ua-font-sans ua-font-weight-normal ua-text-primary-three">
          All perks
        </div>
      </button>
      <PerksHero
        curtainValue={curtainValue}
        heroImage={benefit.image || benefit.main_image}
        hasMultipleAmounts={!!benefit.multipleAmounts}
        points={benefit.points}
        ref={headerRef}
        title={benefit.heading || benefit.title}
      />
      <div className="ua-grid ua-grid-cols-6 perk-details">
        {!!benefit.features && (
          <PerkFeatures
            features={benefit.features}
            sidebarContent={benefit.sidebar_content}
            sidebarTitle={benefit.sidebar_title}
          />
        )}

        {!!benefit.options_cards && (
          <PerkOptions
            benefit_id={benefit.id}
            benefit_name={benefit.name}
            cards={benefit.options_cards}
          />
        )}

        {!!benefit.highlight_video && (
          <PerkHighlightVideo
            id={benefit.id}
            videoLabel={benefit.highlight_video_cta}
            videoUrl={benefit.highlight_video}
          />
        )}

        {!!benefit.how_it_works_blurbs && (
          <PerkHowItWorks
            blurbs={benefit.how_it_works_blurbs}
            introContent={benefit.how_it_works_intro_content}
            isNumbered={benefit.how_it_works_is_numbered}
          />
        )}

        {!!benefit.faqs && (
          <PerksFAQs
            benefit_id={benefit.id}
            benefit_name={benefit.name}
            faqs={benefit.faqs}
          />
        )}
      </div>
      <BenefitDetailsFooter
        benefit={benefit}
        benefitIndex={benefitIndex}
        handleBackTracking={handleBackTracking}
      />
    </section>
  );
}

PerkDetails.propTypes = {
  handlePageView: func.isRequired,
};
