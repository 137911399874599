import { useCallback, useEffect, useRef, useState } from "react";
import { string } from "prop-types";
import {
  AnimatePresence,
  motion,
  useReducedMotion,
  useInView,
} from "framer-motion";
import { wrap } from "popmotion";

let interval;
const transitionDelay = 2500;

export default function AccessAltSlider({ className }) {
  const count = !!slides ? slides.length : 0;
  const [active, setActive] = useState(0);

  const sliderRef = useRef(null);
  const isInView = useInView(sliderRef);
  const prefersReducedMotion = useReducedMotion();

  const navigateToSlide = useCallback(
    (slide) => {
      setActive((prev) => wrap(0, count, prev + slide));
    },
    [count]
  );

  const start = useCallback(() => {
    if (!prefersReducedMotion) {
      interval = setInterval(() => navigateToSlide(1), transitionDelay);
    }
  }, [navigateToSlide, prefersReducedMotion]);

  const stop = useCallback(() => {
    if (!!interval) {
      clearInterval(interval);
    }
  }, []);

  function goToSlide(slide) {
    stop();
    setActive(slide);
  }

  function handleDrag(e, { offset }) {
    stop();
    navigateToSlide(offset.x > 0 ? -1 : 1);
  }

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  useEffect(() => {
    if (isInView) {
      start();
    } else {
      stop();
    }
  }, [isInView, start, stop]);

  if (!count) {
    return null;
  }

  return (
    <div
      className={`ua-teaser--slider ${className}`}
      aria-live="polite"
      aria-atomic={true}
      ref={sliderRef}
    >
      <div className="ua-teaser--slider--slides">
        <AnimatePresence>
          <motion.figure
            className="ua-teaser--slider--slides--slide"
            key={slides[active].caption}
            transition={{
              opacity: { duration: 1 },
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            drag="x"
            dragElastic={0}
            dragPropagation={true}
            dragSnapToOrigin={true}
            dragTransition={{ duration: 0 }}
            onDragEnd={handleDrag}
          >
            <img
              alt={`${slides[active].caption}, ${slides[active].points} points`}
              src={slides[active].x1}
              srcSet={`${slides[active].x1} 1x, ${slides[active].x2} 2x`}
              onDrag={(e) => e.preventDefault()}
            />
            <figcaption>
              <span className="ua-teaser--slider--slides--slide--points">
                <strong>{slides[active].points}</strong> points
              </span>{" "}
              <span className="ua-teaser--slider--slides--slide--caption">
                {slides[active].caption}
              </span>
            </figcaption>
          </motion.figure>
        </AnimatePresence>
      </div>
      <div className="ua-teaser--slider--page">
        {slides.map((slide, index) => (
          <button
            className="ua-teaser--slider--page--button"
            disabled={active === index}
            key={`ua-teaser--slider--page--button--${index}`}
            onClick={() => goToSlide(index)}
          >
            <span className="ua-visually-hidden">{index + 1}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

AccessAltSlider.propTypes = {
  className: string,
};

const slides = [
  {
    caption: "Award Travel",
    points: "2,500-15,000",
    x1: "/uploads/teaser/2023/ua-2023-overview-15-award-travel-awaits.jpg",
    x2: "/uploads/teaser/2023/ua-2023-overview-15-award-travel-awaits@2x.jpg",
  },
  {
    caption: "Technology Subsidy",
    points: "5,000-15,000",
    x1: "/uploads/teaser/2023/ua-2023-overview-02-technology-subsidy.jpg",
    x2: "/uploads/teaser/2023/ua-2023-overview-02-technology-subsidy@2x.jpg",
  },
  {
    caption: "Team Building Event",
    points: "5,000-15,000",
    x1: "/uploads/teaser/2023/ua-2023-overview-11-team-building-event.jpg",
    x2: "/uploads/teaser/2023/ua-2023-overview-11-team-building-event@2x.jpg",
  },
  {
    caption: "Deeper Access",
    points: "5,000-10,000",
    x1: "/uploads/teaser/2023/ua-2023-overview-01-deeper-access.jpg",
    x2: "/uploads/teaser/2023/ua-2023-overview-01-deeper-access@2x.jpg",
  },
  {
    caption: "Comprehensive Training Package",
    points: "2,500",
    x1: "/uploads/teaser/2023/ua-2023-overview-05-training.jpg",
    x2: "/uploads/teaser/2023/ua-2023-overview-05-training@2x.jpg",
  },
];
