import { Typography } from "@uhc-tempo/components";
const { H6 } = Typography;

export default function NavBar({ children }) {
  return (
    <nav
      aria-label="Main Navigation"
      className="navbar ua-display-flex ua-align-items-center"
    >
      <H6 useLight className="nav-brand tds-margin-none-bottom">
        United Advantage&reg;
      </H6>
      {children}
    </nav>
  );
}
