import { arrayOf, string } from "prop-types";
import Check from "@mui/icons-material/Check";

import HtmlContent from "../../components/html-content/HtmlContent";

import { Typography, UtilityIcon } from "@uhc-tempo/components";
const { H4 } = Typography;

export default function ResourceWhy({ why }) {
  if (!why || !why.length) {
    return null;
  }

  return (
    <div className="ua-margin-xxlg-bottom">
      <H4 className="ua-margin-sm-bottom tds-text__paragraph-large">
        Why take this course
      </H4>
      {why.map((item, i) => (
        <div
          className="ua-display-flex ua-margin-sm-bottom tds-text__paragraph"
          key={`why-${i}`}
        >
          <UtilityIcon
            className="ua-margin-md-right"
            color="primaryCTA"
            icon={Check}
            size="medium"
            title="check"
          />
          <div>
            <HtmlContent rawHtml={item} />
          </div>
        </div>
      ))}
    </div>
  );
}

ResourceWhy.propTypes = {
  why: arrayOf(string),
};
