import { useRef, useState } from "react";
import { func, number, string } from "prop-types";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import sanitizeForParam from "../../utilities/sanitize-for-param";
import { sortOptionsObj } from "./use-filtered-and-sorted-benefits";
import useShoppingBenefitTypes from "./use-shopping-benefit-types";
import useShoppingSortOptions from "./use-shopping-sort-options";
import trackEvent from "../tracking/track-event";

import ShoppingFilterAndSortAction from "./ShoppingFilterAndSortAction";

import { UtilityIcon } from "@uhc-tempo/components";

export default function ShoppingFilterAndSort({
  baseClassName = "shopping-filter-and-sort",
  baseId = "filter-and-sort",
  currentFilterBy,
  currentSortBy = sortOptionsObj.customOrder.value,
  defaultSortBy = sortOptionsObj.customOrder.value,
  onFilter,
  onSort,
}) {
  const [expanded, setExpanded] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSort, setSelectedSort] = useState("");

  const shoppingBenefitTypes = useShoppingBenefitTypes();
  const shoppingSortOptions = useShoppingSortOptions();

  const menu = useRef();
  const toggleButton = useRef();

  function handleBlur() {
    setTimeout(() => {
      if (
        !!menu.current &&
        !menu.current.contains(document.activeElement) &&
        expanded
      ) {
        toggle();
      }
    }, 50);
  }

  function handleFilter(filterBy, name) {
    const nextFilterBy =
      !!filterBy && filterBy !== currentFilterBy ? filterBy : undefined;
    const action = !!nextFilterBy
      ? `shopping_filter_${sanitizeForParam(name)}`
      : "shopping_filter_clear";
    const filterName = shoppingBenefitTypes.filter(
      (item) => item.id === filterBy
    )[0].name;

    setSelectedFilter(filterName);
    onFilter(nextFilterBy);
    toggle();

    trackEvent({ action });
  }

  function handleSort(sortBy) {
    const nextSortBy =
      !!sortBy && sortBy !== currentSortBy ? sortBy : defaultSortBy;

    if (currentSortBy === defaultSortBy && nextSortBy === defaultSortBy) {
      toggle();
    } else {
      const action =
        nextSortBy !== defaultSortBy
          ? `shopping_sort_${sortBy}`
          : "shopping_sort_clear";
      const sortName = shoppingSortOptions.filter(
        (item) => item.value === sortBy
      )[0].label;

      setSelectedSort(sortName);
      onSort(nextSortBy);
      toggle();
      trackEvent({ action });
    }
  }

  function resetAll() {
    if (!!currentFilterBy || !!currentSortBy) {
      setSelectedFilter("");
      setSelectedSort("");
      onFilter(undefined);
      onSort(undefined);
      trackEvent({ action: "shopping_filter_and_sort_reset" });
    }

    toggle();
  }

  function toggle() {
    if (!!toggleButton.current) {
      toggleButton.current.focus();
    }

    setExpanded(!expanded);
  }

  return (
    <div className={baseClassName} role="none">
      <button
        aria-expanded={expanded}
        aria-haspopup="true"
        className={`${baseClassName}__toggle`}
        id={baseId}
        onBlur={handleBlur}
        onClick={toggle}
        ref={toggleButton}
        role="menuitem"
        type="button"
      >
        {!selectedFilter && !selectedSort ? (
          <span>- - Filter and sort - -</span>
        ) : (
          <span>
            {selectedFilter}
            {!!selectedFilter && !!selectedSort && <span>, </span>}{" "}
            {selectedSort}
          </span>
        )}
        <UtilityIcon
          className={`${baseClassName}__toggle--icon`}
          color="primaryCTA"
          icon={expanded ? ExpandLess : ExpandMore}
          size="small"
          title="Toggle filter and sort menu"
        />
      </button>
      <ul
        aria-labelledby={baseId}
        className={`${baseClassName}__menu`}
        ref={menu}
        role="menu"
        tabIndex="-1"
      >
        <li role="none">
          <div
            className={`${baseClassName}__section-heading ua-display-flex ua-justify-content-space-between ua-grid-gap-3-sm`}
          >
            <span>Filter</span>
            <button
              className={`${baseClassName}__reset`}
              onClick={resetAll}
              type="button"
            >
              Reset
            </button>
          </div>
          <ul className={`${baseClassName}__menu2`} role="menu" tabIndex="-1">
            {shoppingBenefitTypes.map(({ description, id, name }) => (
              <li key={`sort-${id}`} role="none">
                <ShoppingFilterAndSortAction
                  active={id === currentFilterBy}
                  baseClassName={baseClassName}
                  description={description}
                  label={name}
                  onBlur={handleBlur}
                  onClick={() => handleFilter(id, name)}
                />
              </li>
            ))}
          </ul>
        </li>
        <li role="none">
          <div className={`${baseClassName}__section-heading`}>Sort</div>
          <ul className={`${baseClassName}__menu2`} role="menu" tabIndex="-1">
            {shoppingSortOptions.map(({ label, value }) => (
              <li key={`sort-${value}`} role="none">
                <ShoppingFilterAndSortAction
                  active={value === currentSortBy}
                  baseClassName={baseClassName}
                  label={label}
                  onBlur={handleBlur}
                  onClick={() => handleSort(value)}
                />
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}

ShoppingFilterAndSort.propTypes = {
  baseClassName: string,
  baseId: string,
  currentFilterBy: number,
  currentSortBy: string,
  defaultSortBy: string,
  onFilter: func.isRequired,
  onSort: func.isRequired,
};
