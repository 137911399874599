import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { SITE_BUGSNAG_KEY, SITE_VERSION } from "./data/constants/site";
import { HOST_ENV } from "./data/constants/urls";
import { store, persistor } from "./data/redux/store";

import App from "./app";
import ErrorBoundary from "./features/error/ErrorBoundary";

import "./assets/scss/main.scss";

const container = document.getElementById("root");
const root = createRoot(container);

Bugsnag.start({
  apiKey: SITE_BUGSNAG_KEY,
  appType: "frontend",
  appVersion: SITE_VERSION,
  enabledReleaseStages: ["production", "staging"],
  logger: null,
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: HOST_ENV,
});

root.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>
  </ErrorBoundary>
);
