import ROUTES from "../../data/constants/routes";
import getApiStorageUrl from "../../utilities/get-api-storage-url";
import stripHtml from "../../utilities/strip-html";
import uniqueArray from "../../utilities/unique-array";

function sortBenefits(benefits) {
  return uniqueArray(benefits).sort(sortByCustomOrder);
}

function sortByCustomOrder(a, b) {
  if (a.sort_order === b.sort_order) {
    return sortByTitle(a, b);
  } else if (!a.sort_order && a.sort_order !== 0) {
    return 1;
  } else if (!b.sort_order && b.sort_order !== 0) {
    return -1;
  } else {
    return a.sort_order - b.sort_order;
  }
}

function sortByTitle(a, b) {
  // Intl.Collator makes case-insensitive
  return new Intl.Collator("en", { sensitivity: "base" }).compare(
    a.title,
    b.title
  );
}

function sortBenefitTypesByOrder(benefitTypes) {
  return benefitTypes.sort((a, b) =>
    a.order < b.order ? -1 : a.order > b.order ? 1 : 0
  );
}

export default function normalizeBenefitsResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    const { data: unsortedBenefits, benefitTypes: unsortedBenefitTypes } = data;
    let benefits = sortBenefits(unsortedBenefits);
    const benefitTypes = sortBenefitTypesByOrder(unsortedBenefitTypes);

    if (!!benefits.length) {
      benefits = [...benefits].map((benefit) => {
        if (!!benefit.home_excerpt) {
          benefit.home_excerpt = stripHtml(benefit.home_excerpt);
        }

        try {
          if (!!benefit.slug && benefit.slug.includes("training")) {
            benefit.slug = ROUTES.training.path.split("/").pop();
          }
        } catch (err) {}

        // Just in case the old allowMultiple field values sneak through
        try {
          if (benefit.allowMultiple === "0") {
            benefit.allowMultiple = "none";
          } else if (benefit.allowMultiple === "1") {
            if (!!benefit.maxQuantity) {
              benefit.allowMultiple = "quantity";
            } else if (!!benefit.multipleAmounts) {
              benefit.allowMultiple = "amount";
            } else {
              benefit.allowMultiple = "none";
            }
          }
        } catch (err) {}

        // Make sure qty is a number
        try {
          const qty = parseInt(benefit?.qty, 10);
          benefit.qty = !!qty && !isNaN(qty) ? qty : 0;
        } catch (err) {}

        // Make sure media URLs are correct
        try {
          if (!!benefit.image) {
            benefit.image = getApiStorageUrl(benefit.image);
          }

          if (!!benefit.main_image) {
            benefit.main_image = getApiStorageUrl(benefit.main_image);
          }

          if (!!benefit.resource_pdf) {
            benefit.resource_pdf = getApiStorageUrl(benefit.resource_pdf);
          }

          if (!!benefit.share_pdf) {
            benefit.share_pdf = getApiStorageUrl(benefit.share_pdf);
          }
        } catch (err) {}

        return benefit;
      });
    }

    const benefitsObj = { benefits, benefitTypes };

    return ["success", benefitsObj];
  } catch (err) {
    return ["error"];
  }
}
