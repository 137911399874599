import { arrayOf, object } from "prop-types";

import ResourceDownloadsItem from "./ResourceDownloadsItem";

export default function ResourceDownloads({ downloads }) {
  if (!downloads || !downloads.length) {
    return null;
  }

  return (
    <div className="ua-grid ua-grid-cols-1-sm ua-grid-cols-2-md ua-grid-gap-2-sm ua-padding-sm-top">
      {downloads.map((item, i) => (
        <ResourceDownloadsItem key={`download-${i}`} {...item} />
      ))}
    </div>
  );
}

ResourceDownloads.propTypes = {
  downloads: arrayOf(object),
};
