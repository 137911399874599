export default function normalizeShoppingHiddenResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    const { message, hiddenBenefits: hiddenFromServerJson } = data;
    const hiddenFromServer = JSON.parse(hiddenFromServerJson);

    if (!Array.isArray(hiddenFromServer)) {
      throw new Error("Missing hidden");
    }

    return ["success", hiddenFromServer, message || "Success"];
  } catch (err) {
    return ["error", null, err.message || "Unexpected response"];
  }
}
