import normalizeUserResponse from "../user/normalize-user-response";

export default function normalizeAuthResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    const { access_token, currentYear } = data;
    if (!access_token) {
      throw new Error("Missing access token");
    }

    const [userStatus, user, userMessage] = normalizeUserResponse(response);

    if (userStatus === "error") {
      throw new Error(userMessage || "Missing user");
    }

    const auth = {
      access_token,
      capabilities: user.capabilities,
    };

    const shoppingRequired = user?.shoppingRequired
      ? user.shoppingRequired
      : false;

    const bonusShoppingRequired =
      !shoppingRequired && data?.bonusShoppingRequired
        ? data.bonusShoppingRequired
        : false;

    let message = data?.message || "Success!";

    return [
      "success",
      auth,
      user,
      currentYear,
      shoppingRequired,
      bonusShoppingRequired,
      message,
    ];
  } catch (err) {
    return [
      "error",
      null,
      null,
      null,
      null,
      null,
      err.message || "Unexpected response",
    ];
  }
}
