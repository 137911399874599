import { useState, useEffect } from "react";

export default function useMatchMedia(mediaQuery) {
  const [isMatched, setIsMatched] = useState(
    window.matchMedia ? window.matchMedia(mediaQuery).matches : false
  );

  useEffect(() => {
    const media = window.matchMedia && window.matchMedia(mediaQuery);

    const eventListener = (event) => setIsMatched(event.matches);

    if (media) {
      media.addEventListener("change", eventListener);
    }

    return () => {
      if (media) {
        media.removeEventListener("change", eventListener);
      }
    };
  }, [mediaQuery]);

  return isMatched;
}
