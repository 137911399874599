export default function uniqueArray(arr) {
  return arr.filter((b, index) => {
    return (
      index ===
      arr.findIndex((obj) => {
        return JSON.stringify(obj) === JSON.stringify(b);
      })
    );
  });
}
