import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import ROUTES from "../../data/constants/routes";
import useAuthStatus from "../../features/auth/use-auth-status";

import LoginForm from "../../features/auth/LoginForm";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";

import { Button, Notification, Typography } from "@uhc-tempo/components";
const { H1, Link, Paragraph } = Typography;

export default function Login() {
  let [hasAuth, authMessage] = useAuthStatus();
  const [showMessage, setShowMessage] = useState(!!authMessage);
  let { state: locationState = {} } = useLocation();
  let redirect = (!!locationState && locationState.from) || ROUTES.default.path;
  const adminTokenLoading = useSelector(
    ({ adminTokenLoading }) => adminTokenLoading
  );
  const userTokenLoading = useSelector(
    ({ userTokenLoading }) => userTokenLoading
  );

  useEffect(() => {
    if (!!authMessage) {
      setShowMessage(true);
    }
  }, [authMessage]);

  if (adminTokenLoading || userTokenLoading) {
    return null;
  }

  if (hasAuth) {
    return <Navigate to={redirect} />;
  }

  return (
    <section className="fluid-container page-main__fill ua-bg-supporting-one-opacity-ten ua-padding-jumbo-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="auth-content ua-col-span-3-8-lg ua-col-span-2-10-md ua-col-span-1-12-sm">
          <H1 className="ua-text-center" headingStyle={2}>
            Login to United Advantage®
          </H1>
          <Paragraph
            bodyStyle={1}
            className="ua-text-center tds-margin-xxlg-bottom"
          >
            Where UnitedHealthcare partners with top-performing{" "}
            <span className="ua-text-newline-md">
              agencies to offer exclusive&nbsp;perks.
            </span>
          </Paragraph>
          <div className="auth-content__inner tds-padding-xxlg-top">
            {!!showMessage && (
              <Notification
                className="tds-margin-xxlg-bottom"
                dismissClickHandler={() => setShowMessage(false)}
              >
                <Paragraph className="tds-margin-none">{authMessage}</Paragraph>
              </Notification>
            )}
            <LoginForm authMessage={authMessage} />
            <footer className="ua-text-center">
              <TempoRouterLink
                buttonType="secondary-one"
                className="tds-margin-xxlg-bottom ua-display-block"
                component={Button}
                to={ROUTES.register.path}
              >
                New user? Register now
              </TempoRouterLink>
              <Paragraph>
                Forgot{" "}
                <TempoRouterLink
                  component={Link}
                  to={ROUTES.forgotPassword.path}
                >
                  password
                </TempoRouterLink>
                ?
              </Paragraph>
            </footer>
          </div>
        </div>
      </div>
    </section>
  );
}
