import { useRef, useState } from "react";
import { string } from "prop-types";
import { useDispatch } from "react-redux";

import { putUser } from "./UserRedux";
import useUserProfile from "./use-user-profile";
import trackEvent from "../tracking/track-event";
import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";
import { AGENCY_ROLES } from "../../data/constants/agency-roles";

import { Button, Form, Notification, Typography } from "@uhc-tempo/components";
const { ErrorMessage, Group, Input, Label, LargeErrorMessage, Select } = Form;
const { Paragraph } = Typography;

const initData = {
  id: 0,
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  mobile_phone: "",
  agency_role: "",
  password: "",
  password_confirmation: "",
};

export default function EditProfileForm({ className = "" }) {
  const userProfile = useUserProfile();

  const [data, setData] = useState({ ...initData, ...userProfile });
  const [errors, setErrors] = useState(initData);
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const formErrorAlert = useRef();
  const formSuccessAlert = useRef();

  const dispatch = useDispatch();

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }

    if (field === "password_confirmation" && input.value !== data.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password_confirmation: "Passwords do not match",
      }));
    } else {
      const error = validateInput(input);
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    }
  }

  function displayError(errorMessage = "Error") {
    setFormError(`${errorMessage}`);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function displaySuccess(successMessage = "Success!") {
    setFormError(null);
    setFormSuccess(`${successMessage}`);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formSuccessAlert.current);
    }, 50);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    setInProgress(true);

    const postData = { ...data };

    if (!postData.password_confirmation) {
      delete postData.password;
    }
    delete postData.password_confirmation;

    dispatch(putUser(postData))
      .unwrap()
      .then(({ message = "Success!" }) => {
        trackEvent({ action: "edit_profile" });
        displaySuccess(message);
      })
      .catch((err) => {
        displayError(err);
        setInProgress(false);
      });
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 50);
    }
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  function dismissSuccessNotification() {
    setFormSuccess(null);
    setFormError(null);
  }

  return (
    <Form className={className} noValidate onSubmit={handleSubmit}>
      {!!formError && (
        <div ref={formErrorAlert}>
          <LargeErrorMessage className="tds-margin-xxlg-bottom">
            {formError}
          </LargeErrorMessage>
        </div>
      )}
      {!!formSuccess && (
        <div ref={formSuccessAlert}>
          <Notification
            className="tds-margin-xxlg-bottom"
            dismissClickHandler={() => dismissSuccessNotification()}
            notificationType="success"
          >
            <Paragraph className="tds-margin-none">{formSuccess}</Paragraph>
          </Notification>
        </div>
      )}
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="first_name"
          state={getFormElementState(errors.first_name)}
        >
          <Label>First name*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="first_name"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.first_name}
          />
          {!!errors.first_name && (
            <ErrorMessage>{errors.first_name}</ErrorMessage>
          )}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="last_name"
          state={getFormElementState(errors.last_name)}
        >
          <Label>Last name*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="last_name"
            onBlur={handleInput}
            onChange={handleInput}
            required
            type="text"
            value={data.last_name}
          />
          {!!errors.last_name && (
            <ErrorMessage>{errors.last_name}</ErrorMessage>
          )}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="phone"
          state={getFormElementState(errors.phone)}
        >
          <Label>Business phone*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="phone"
            onBlur={handleInput}
            onChange={handleInput}
            required
            pattern={validationPatterns.tel}
            type="tel"
            value={data.phone}
          />
          {!!errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="mobile_phone"
          state={getFormElementState(errors.mobile_phone)}
        >
          <Label>Mobile phone</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="mobile_phone"
            onBlur={handleInput}
            onChange={handleInput}
            pattern={validationPatterns.tel}
            type="tel"
            value={data.mobile_phone}
          />
          {!!errors.mobile_phone && (
            <ErrorMessage>{errors.mobile_phone}</ErrorMessage>
          )}
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between tds-margin-lg-bottom">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="email"
          state={getFormElementState(errors.email)}
        >
          <Label>Email*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="email"
            onBlur={handleInput}
            onChange={handleInput}
            required
            pattern={validationPatterns.email}
            type="email"
            value={data.email}
          />
          {!!errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="agency_role"
          state={getFormElementState(errors.agency_role)}
        >
          <Label>Select a role*</Label>
          <Select
            containerProps={{
              className: "tds-form__select ua-display-block ua-width-100",
            }}
            name="agency_role"
            onBlur={handleInput}
            onChange={handleInput}
            required
            value={data.agency_role}
          >
            <Select.Option value="">Select a role</Select.Option>
            {AGENCY_ROLES.map((value, i) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Group>
      </div>
      <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between ua-border-top tds-padding-xxlg-top tds-margin-lg-bottom">
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="password"
          state={getFormElementState(errors.password)}
        >
          <Label>New password*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="password"
            onBlur={handleInput}
            onChange={handleInput}
            type="password"
            value={data.password}
          />
          {!!errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
        </Group>
        <Group
          className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
          groupId="password_confirmation"
          state={getFormElementState(errors.password_confirmation)}
        >
          <Label>Confirm password*</Label>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            className="ua-display-block ua-width-100"
            name="password_confirmation"
            onBlur={handleInput}
            onChange={handleInput}
            type="password"
            value={data.password_confirmation}
          />
          {!!errors.password_confirmation && (
            <ErrorMessage>{errors.password_confirmation}</ErrorMessage>
          )}
        </Group>
      </div>
      <div className="tds-padding-xxlg-top ua-display-flex ua-justify-content-end ua-border-top">
        <Button
          buttonType={inProgress ? "disabled" : "primary-one"}
          className="tds-margin-xxlg-bottom ua-display-block"
          loading={inProgress}
          type="submit"
        >
          {inProgress ? "Processing..." : "Save"}
        </Button>
      </div>
    </Form>
  );
}

EditProfileForm.propTypes = {
  className: string,
};
