export default function getLevelClass(level = "") {
  switch (`${level}`.toLowerCase()) {
    case "level_1":
    case "gold":
    case "preferred":
      return "level_1";
    case "level_2":
    case "platinum":
    case "premier":
      return "level_2";
    case "level_3":
    case "diamond":
    case "premier+":
      return "level_3";
    default:
      return "non_ua";
  }
}
