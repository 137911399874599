import { useCallback, useEffect, useRef, useState } from "react";

// The UHC Tempo Modal component does not account for the
// viewport scrollbar disappearing when setting the body
// element's overflow to hidden, which causes a content reflow.
// This hook prevents a content reflow by managing the modal
// open state and applying a scrollbar padding offset before
// the UHC Tempo Modal component is rendered.
export default function useModalScrollbarOffset(init = false) {
  const [open, setOpen] = useState(init);
  const initOverflowY = useRef(document.body.style.overflowY);

  const toggle = useCallback((nextOpen) => {
    if (typeof nextOpen !== "undefined" && typeof nextOpen !== "object") {
      handleOffset(nextOpen, initOverflowY.current);
      setOpen(nextOpen);
    } else {
      setOpen((prevOpen) => {
        handleOffset(!nextOpen, initOverflowY.current);
        return !prevOpen;
      });
    }
  }, []);

  useEffect(() => {
    const resetOverflowY = initOverflowY.current;

    return () => {
      document.body.style.setProperty("overflow-y", resetOverflowY);
      document.body.style.removeProperty("padding-right");
    };
  }, []);

  return [open, toggle];
}

function handleOffset(isOpen, resetOverflowY) {
  if (isOpen) {
    const scrollbarWidth = getScrollbarWidth();

    if (!!scrollbarWidth) {
      document.body.style.setProperty("overflow-y", "hidden");
      document.body.style.setProperty("padding-right", `${scrollbarWidth}px`);
    }
  } else {
    document.body.style.setProperty("overflow-y", resetOverflowY);
    document.body.style.removeProperty("padding-right");
  }
}

function getScrollbarWidth() {
  try {
    return Math.abs(window.innerWidth - document.documentElement.clientWidth);
  } catch (err) {
    return 0;
  }
}
