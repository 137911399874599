import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";

const selectTrainingBySlug = createSelector(
  (state) => state.training,
  (_, slug) => slug,
  (training, slug) => training.find((training) => training.slug === slug)
);

export default function useTrainingFromParam() {
  const { trainingSlug } = useParams();
  const training = useSelector((state) =>
    selectTrainingBySlug(state, trainingSlug)
  );
  const trainingLoading = useSelector((state) => state.trainingLoading);
  const trainingError = useSelector((state) => state.trainingError);
  return [training, trainingLoading, trainingError];
}
