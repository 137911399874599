import useAuthStatus from "../auth/use-auth-status";
import UHCLogoStackedX2 from "../../assets/img/UHC_logo_stacked@2x.png";
import {
  SCREEN_MEDIUM,
  SCREEN_SMALL,
} from "../../data/constants/media-queries";
import { usePageLayoutContext } from "./PageLayout";

import AccountNavigation from "../navigation/AccountNavigation";
import NavBar from "../navigation/NavBar";
import MainMenu from "../navigation/MainMenu";
import MobileNavigation from "../navigation/MobileNavigation";
import useMatchMedia from "./use-match-media";
import LogoutButton from "../auth/LogoutButton";

export default function PageHeader() {
  const [hasAuth] = useAuthStatus();
  const isScreenMd = useMatchMedia(SCREEN_MEDIUM);
  const isScreenSm = useMatchMedia(SCREEN_SMALL);

  const { hideHeader } = usePageLayoutContext();

  if (hideHeader) {
    return null;
  }

  return (
    <header className="page-header">
      <div className="page-header__masthead">
        <div className="page-header__brand">
          <img
            src={UHCLogoStackedX2}
            width="154"
            height="48"
            alt="United Healthcare"
          />
        </div>
        {hasAuth && isScreenMd && <AccountNavigation />}
        {hasAuth && isScreenSm && (
          <LogoutButton
            label="Logout"
            className="mobile-logout--button"
            showIcon
          />
        )}
      </div>
      <NavBar>
        {isScreenMd ? (
          <MainMenu className="desktop-nav ua-flex-grow" />
        ) : (
          <MobileNavigation />
        )}
      </NavBar>
    </header>
  );
}
