import { useCallback } from "react";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";
import formatNumber from "../../utilities/format-number";
import trackEvent from "../tracking/track-event";
import useBenefitRootPath from "../benefits/use-benefit-root-path";
import { Button, Typography } from "@uhc-tempo/components";
const { H6 } = Typography;

export default function ShoppingFeaturedBenefit({
  benefit,
  hasMultipleAmounts,
}) {
  const benefitRootPath = useBenefitRootPath();

  const { id: benefit_id, name: benefit_name } = benefit;

  const handleViewDetail = useCallback(() => {
    trackEvent({
      action: "shopping_benefit_learn_more",
      relatedId: benefit_id,
      params: { benefit_id, benefit_name, triggered_from: "callout" },
    });
  }, [benefit_id, benefit_name]);

  if (!benefit) return null;

  return (
    <div className="shopping-featured-benefit__card ua-display-flex ua-flex-column ua-bg-primary-three ua-border-radius-md">
      {benefit.image ? (
        <div
          className="shopping-featured-benefit__card__image ua-border-radius-top-md"
          style={{
            backgroundImage: `url(${benefit.image})`,
          }}
        />
      ) : null}
      <div className="shopping-featured-benefit__card__tag ua-bg-primary-two ua-text-primary-three ua-font-sans ua-width-fit-content">
        {benefit.name}
      </div>
      <div className="shopping-featured-benefit__card__content ua-padding-lg-horizontal ua-padding-xxlg-bottom ua-flex-grow ua-display-flex ua-flex-column">
        <div className="shopping-featured-benefit__card__label ua-text-accent-one ua-font-sans">
          {hasMultipleAmounts && <span>Starting at</span>}{" "}
          {formatNumber(benefit.points)} pts
        </div>
        <H6 className="ua-line-clamp-sm-2 ua-flex-grow">
          {benefit.featuredCallout}
        </H6>
        <TempoRouterLink
          buttonType={`secondary-one`}
          className="ua-text-nowrap ua-width-fit-content"
          component={Button}
          onClick={handleViewDetail}
          to={`${benefitRootPath}/${benefit.slug}`}
        >
          Learn More
        </TempoRouterLink>
      </div>
    </div>
  );
}
