import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import ROUTES from "../../data/constants/routes";
import { getShoppingData } from "../../features/shopping/ShoppingRedux";
import useMatchMedia from "../../features/page-layout/use-match-media";
import { SCREEN_MEDIUM } from "../../data/constants/media-queries";

import ShoppingMobileDisclaimer from "./mobile-disclaimer";

import { Notification, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function Shopping(props) {
  const isMounted = useRef(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const isScreenMd = useMatchMedia(SCREEN_MEDIUM);

  useEffect(() => {
    return () => {
      if (isMounted?.current) {
        isMounted.current = false;
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getShoppingData())
      .unwrap()
      .then(({ method }) => {
        if (method === "choose" && pathname !== ROUTES.shoppingChoose.path) {
          navigate(ROUTES.shoppingChoose.path);
        }
        if (isMounted?.current) {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!!isMounted.current) {
          setError(err || "Error");
          setLoading(false);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isScreenMd) {
    return <ShoppingMobileDisclaimer />;
  }

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <section className="fluid-container page-main__fill ua-padding-jumbo-vertical">
        <div className="ua-grid ua-grid-gap-3 ua-grid-cols-12">
          <div className="ua-col-span-1-12-sm ua-col-span-2-10-md ua-col-span-3-8-lg">
            <Notification
              dismissClickHandler={() => setError(error)}
              isDismissed={!error}
              notificationType="error"
            >
              <Paragraph className="tds-margin-none">{error}</Paragraph>
            </Notification>
          </div>
        </div>
      </section>
    );
  }

  return <Outlet />;
}
