import { bool, func, node, string } from "prop-types";

export default function ButtonInline({
  children,
  className = "",
  disabled,
  onClick,
  type = "button",
}) {
  return (
    <button
      className={`button-inline ${className}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
}

ButtonInline.propTypes = {
  children: node.isRequired,
  className: string,
  disabled: bool,
  onClick: func,
  type: string,
};
