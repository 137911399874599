export default function normalizeSettingsResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    if (!data.data || !Array.isArray(data.data)) {
      throw new Error("Missing settings");
    }

    let settings = {};

    data.data.forEach(({ key, value }) => {
      if (!!key && !!value) {
        settings[key] = value;
      }
    });

    if (!Object.keys(settings).length) {
      throw new Error("No settings returned");
    }

    try {
      if (!!settings?.advantage_coach_classes) {
        settings.advantage_coach_classes = JSON.parse(
          settings.advantage_coach_classes
        );
      }
    } catch (err) {
      settings.advantage_coach_classes = [];
    }

    return ["success", settings];
  } catch (err) {
    return ["error"];
  }
}
