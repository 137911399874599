import { useEffect, useMemo } from "react";
import { bool, func, number, shape, string } from "prop-types";

import formatNumber from "../../utilities/format-number";
import trackEvent from "../tracking/track-event";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";
import Portal from "../../components/portal/Portal";

import { Modal, Typography } from "@uhc-tempo/components";
import dayjs from "dayjs";
const { Content, Header, Title } = Modal;
const { Paragraph } = Typography;

export default function ModalNetChangeCalculated({
  agencyName,
  currentYear,
  handleOpen,
  logo,
  open,
  status,
}) {
  const referenceTimespan = useMemo(() => {
    try {
      if (!currentYear) {
        throw new Error("Current year undefined");
      }

      const thisYear = dayjs(`${currentYear}-01-01`);
      const lastYear = thisYear.clone().subtract(1, "year").format("M/D/YY");
      const nextYear = thisYear.clone().add(1, "year").format("M/D/YY");
      return (
        <>
          {" "}
          from <span className="ua-text-nowrap">{lastYear}</span> to{" "}
          <span className="ua-text-nowrap">{nextYear}</span>
        </>
      );
    } catch (err) {
      return "";
    }
  }, [currentYear]);

  useEffect(() => {
    if (open) {
      trackEvent({ action: "status_net_change_calc_overlay" });
    }
  }, [open]);

  return (
    <Portal id="net-change-calculated">
      {open && (
        <Modal
          className="ua-modal-nc"
          contentId="net-change-calculated-modal"
          handleClose={handleOpen}
        >
          <Header className="ua-modal-nc--header">
            <AgencyLogo
              className="ua-modal-nc--header--logo"
              name={agencyName}
              src={logo}
              width={175}
              height={83}
            />
            <div className="ua-modal-nc--header--content">
              <Title>How is net change calculated?</Title>
              <Paragraph
                className="ua-margin-none ua-text-gray-five"
                useItalic={true}
              >
                Your agency’s numbers{" "}
                <strong className="tds-text__paragraph--bold">
                  as of {status.DataDateSlash}
                </strong>
              </Paragraph>
            </div>
          </Header>
          <Content className="ua-modal-nc--content">
            <div className="ua-modal-nc--content--calc">
              <Calculation
                cancelled={status.MedicalCancelsMembers}
                result={status.MedicalNetChangeMembers}
                sold={status.MedicalSalesMembers}
              >
                Medical net change
              </Calculation>
              <Calculation
                cancelled={status.SpecialtyCancelsMembers}
                result={status.SpecialtyNetChangeMembers}
                sold={status.SpecialtySalesMembers}
              >
                Specialty net change
              </Calculation>
            </div>
            <Paragraph className="ua-text-primary-one ua-text-left">
              <strong>Tier requirements</strong> reference measurements specific
              to your agency{referenceTimespan}. Also, for the United Advantage
              program, UHC does not include attrition changes in the UA
              calculations.
            </Paragraph>
          </Content>
        </Modal>
      )}
    </Portal>
  );
}

function Calculation({ cancelled, children, result, sold }) {
  return (
    <>
      <h3>{children}</h3>
      <div className="ua-modal-nc--content--calc--sold">
        <strong>{formatNumber(sold)}</strong> <span>sold membership</span>
      </div>
      <div className="ua-modal-nc--content--calc--op">&minus;</div>
      <div className="ua-modal-nc--content--calc--cancel">
        <strong>{formatNumber(cancelled)}</strong> cancelled membership
      </div>
      <div className="ua-modal-nc--content--calc--op"> = </div>
      <div className="ua-modal-nc--content--calc--result">
        <strong>
          {result > 0 && "+"}
          {formatNumber(result)}
        </strong>
        <span>net change</span>
      </div>
    </>
  );
}

ModalNetChangeCalculated.propTypes = {
  agencyName: string,
  currentYear: number,
  handleOpen: func.isRequired,
  logo: string,
  open: bool,
  status: shape({
    DataDateSlash: string,
    MedicalCancelsMembers: number,
    MedicalNetChangeMembers: number,
    MedicalSalesMembers: number,
    MedicalStartingMembers: number,
    SpecialtyCancelsMembers: number,
    SpecialtyNetChangeMembers: number,
    SpecialtySalesMembers: number,
    SpecialtyStartingMembers: number,
  }),
};
