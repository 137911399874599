import { useRef, useState } from "react";
import { string } from "prop-types";
import {
  UtilityIcon,
  Button,
  Form,
  Notification,
  Typography,
  Modal,
} from "@uhc-tempo/components";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSelector } from "react-redux";
import cloneDeep from "lodash.clonedeep";

import UA_API, { UA_API_EP_USERS_INVITE } from "../../data/ua-api/ua-api";

import ROUTES from "../../data/constants/routes";
import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";

import Portal from "../../components/portal/Portal";
import trackEvent from "../tracking/track-event";
import useModalScrollbarOffset from "../../utilities/use-modal-scrollbar-offset";
const { Checkbox, ErrorMessage, Group, Input, Label, LargeErrorMessage } = Form;
const { Paragraph, Link } = Typography;

const initData = [
  { first_name: "", email: "" },
  { first_name: "", email: "" },
  { first_name: "", email: "" },
];

export default function InviteUsersForm({ className = "" }) {
  const [data, setData] = useState(cloneDeep(initData));
  const [errors, setErrors] = useState(cloneDeep(initData));
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [modalOpen, toggleModalOpen] = useModalScrollbarOffset(false);

  const formErrorAlert = useRef();
  const formSuccessAlert = useRef();
  const { agency, first_name: inviter_name } = useSelector(({ user }) => user);
  const { ra_number, id: agencyId, name: agency_name, market } = agency;

  function checkInput(input, i) {
    const field = input.name;
    const fieldIndex = parseInt(input.dataset.fieldIndex, 10);

    if (!field) {
      return;
    }

    const error = validateInput(input);

    const fieldErrors = [...errors];
    fieldErrors[fieldIndex][field] = error || "";
    setErrors(fieldErrors);
  }

  function displayError(errorMessage) {
    setFormError([errorMessage]);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function displaySuccess(successMessage) {
    setErrors(cloneDeep(initData));
    setData(cloneDeep(initData));
    setFormError(null);
    setFormSuccess(successMessage);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formSuccessAlert.current);
    }, 50);
  }

  async function handleSubmit(event) {
    setInProgress(true);

    const validatedUsers = data.filter(
      (user) => !!user.email && !!user.first_name
    );

    const postData = {
      ra_number,
      agency_name,
      agencyId,
      inviter_name,
      users: validatedUsers,
    };

    try {
      const response = await UA_API.post(UA_API_EP_USERS_INVITE, postData);

      if (!!response.data && response.data.status === "success") {
        displaySuccess(response.data.message || "Success!");
        trackEvent({ action: "invite_users" });
      } else {
        displayError("Unexpected response");
      }
    } catch (err) {
      displayError(err.message || "Error");
    }

    toggleModalOpen();
  }

  function handleInput(event, i) {
    const { target: input } = event;
    const field = input.name;
    const fieldIndex = parseInt(input.dataset.fieldIndex, 10);

    if (!field) {
      return;
    }

    const users = [...data];
    users[fieldIndex][field] = event.target.value;
    setData(users);

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input, i);
      }, 50);
    }
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  function dismissSuccessNotification() {
    setFormSuccess(null);
    setFormError(null);
  }

  function addTeamMember(event) {
    event.preventDefault();

    let users = [...data];
    users.push({ first_name: "", email: "" });
    let fieldErrors = [...errors];
    fieldErrors.push({ first_name: "", email: "" });

    setData(users);
    setErrors(fieldErrors);
  }

  function removeTeamMember(event, i) {
    event.preventDefault();

    let users = [...data];
    users.splice(i, 1);
    setData(users);

    let fieldErrors = [...errors];
    fieldErrors.splice(i, 1);
    setErrors(fieldErrors);
  }

  function openModal(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    toggleModalOpen();
  }

  return (
    <>
      <Form className={className} noValidate onSubmit={openModal}>
        {!!formError && (
          <div ref={formErrorAlert}>
            <LargeErrorMessage className="tds-margin-xxlg-bottom">
              {formError}
            </LargeErrorMessage>
          </div>
        )}
        {!!formSuccess && (
          <div ref={formSuccessAlert}>
            <Notification
              className="tds-margin-xxlg-bottom"
              dismissClickHandler={() => dismissSuccessNotification()}
              notificationType="success"
            >
              <Paragraph className="tds-margin-none">
                Your team members have been invited.
              </Paragraph>
              <TempoRouterLink
                component={Link}
                to={ROUTES.default.path}
                className="tds-margin-xxlg-top"
              >
                Take me home
              </TempoRouterLink>
            </Notification>
          </div>
        )}
        {data &&
          data.map((field, i) => (
            <div className="ua-display-flex ua-flex-wrap" key={`key_${i}`}>
              {errors[i] && (
                <>
                  <div className="ua-display-flex-md ua-flex-wrap ua-flex-grow tds-margin-md-bottom">
                    <Group
                      className="tds-margin-sm-bottom ua-flex-grow tds-margin-xlg-right"
                      groupId="first_name"
                      state={getFormElementState(errors[i].first_name)}
                    >
                      <Label>
                        First name
                        {(i === 0 || !!field.email || !!field.first_name) &&
                          "*"}
                      </Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        data-field-index={i}
                        name="first_name"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required={i === 0 || !!field.email}
                        type="text"
                        value={field.first_name}
                      />
                      {!!errors[i].first_name && (
                        <ErrorMessage>{errors[i].first_name}</ErrorMessage>
                      )}
                    </Group>

                    <Group
                      className="tds-margin-sm-bottom ua-flex-grow tds-margin-xlg-right"
                      groupId="email"
                      state={getFormElementState(errors[i].email)}
                    >
                      <Label>
                        Email
                        {(i === 0 || !!field.first_name || !!field.email) &&
                          "*"}
                      </Label>
                      <Input
                        autoCapitalize="none"
                        autoCorrect="off"
                        className="ua-display-block ua-width-100"
                        data-field-index={i}
                        name="email"
                        onBlur={handleInput}
                        onChange={handleInput}
                        required={i === 0 || !!field.first_name}
                        pattern={validationPatterns.email}
                        type="email"
                        value={field.email}
                      />
                      {!!errors[i].email && (
                        <ErrorMessage>{errors[i].email}</ErrorMessage>
                      )}
                    </Group>
                  </div>
                  <div className="ua-align-self-flext-start ua-text-right ua-cursor-pointer tds-margin-xxlg-top">
                    <UtilityIcon
                      icon={RemoveCircleIcon}
                      color="alert"
                      onClick={(event) => removeTeamMember(event, i)}
                      title="Remove item"
                      description="Remove this item from the list"
                      size="medium"
                    />
                  </div>
                </>
              )}
            </div>
          ))}

        <div className="ua-text-left tds-padding-lg-top">
          <Button
            buttonType="secondary-one"
            type="button"
            onClick={(event) => addTeamMember(event)}
          >
            Add new team member
          </Button>
        </div>

        <div className="tds-margin-xxlg-top tds-padding-xxlg-top ua-border-top ua-display-flex ua-justify-content-flex-end">
          <Button
            buttonType="primary-one"
            className="tds-margin-xxlg-bottom ua-display-block"
            type="submit"
          >
            Send invite(s)
          </Button>
        </div>
      </Form>
      {modalOpen && (
        <Portal id="invite-confirmation">
          <Modal handleClose={toggleModalOpen}>
            <Modal.Header>
              <Modal.Title>Ready to send</Modal.Title>
            </Modal.Header>
            <Modal.Content>
              <Group groupName="accepted" aria-labelledby="accepted">
                <Checkbox
                  label={`Yes, all team members are in the ${market.name} market.`}
                  id="is-accepted"
                  value={isAccepted}
                  onChange={() => setIsAccepted(!isAccepted)}
                />
              </Group>
            </Modal.Content>
            <Modal.Footer className="ua-justify-content-flex-end">
              <Button
                buttonType="secondary-one"
                className="tds-margin-xxlg-bottom tds-margin-md-right"
                onClick={toggleModalOpen}
              >
                Cancel
              </Button>
              <Button
                buttonType={
                  inProgress || !isAccepted ? "disabled" : "primary-one"
                }
                className="tds-margin-xxlg-bottom"
                loading={inProgress}
                onClick={handleSubmit}
              >
                {inProgress ? "Processing..." : "Send invite(s)"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Portal>
      )}
    </>
  );
}

InviteUsersForm.propTypes = {
  className: string,
};
