import { HOST_ENV } from "../../data/constants/urls";

import getLevelName from "../../utilities/get-level-name";

export default function setHotjarIdentity(userProperties) {
  try {
    if (!userProperties) {
      throw new Error("User properties not defined");
    }

    if (HOST_ENV !== "development" && !window.hj) {
      throw new Error("Hotjar not loaded");
    }

    const {
      agency_id,
      agency_name,
      agency_role,
      has_picked_benefits,
      has_training,
      level,
      market,
      region,
      ua_user_id,
      user_role,
      user_type,
    } = userProperties;

    const levelName = getLevelName(level);
    const hasPickedBenefits = !!has_picked_benefits ? "Yes" : "No";
    const hasTraining = !!has_training ? "Yes" : "No";

    const identity = {
      "Agency ID": agency_id,
      "Agency name": agency_name,
      "Agency role": agency_role,
      "Agency level": levelName,
      "Has picked benefits": hasPickedBenefits,
      "Has training": hasTraining,
      Market: market,
      Region: region,
      "User role": user_role,
      "User type": user_type,
    };

    if (HOST_ENV !== "development") {
      window.hj("identify", ua_user_id, identity);
    }

    if (HOST_ENV !== "production") {
      console.log("Hotjar identity", ua_user_id, identity);
    }
  } catch (err) {
    if (HOST_ENV !== "production") {
      console.warn("Hotjar identity not set", err?.message);
    }
  }
}
