import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useBenefitRootPath from "../benefits/use-benefit-root-path";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";
import trackEvent from "../tracking/track-event";

import { Card, Link, Notification, Typography } from "@uhc-tempo/components";
const { H3, Paragraph } = Typography;

export default function HomeBenefitsCards() {
  const [dismissError, setDismissError] = useState(false);

  const benefits = useSelector((state) => state.benefits);
  const benefitsError = useSelector((state) => state.benefitsError);
  const benefitRootPath = useBenefitRootPath();

  const cardCount = benefits?.length;

  const colSpan = useMemo(() => {
    switch (cardCount) {
      case 1:
        return ["ua-col-span-4-6-md ua-col-span-4-6-lg"];
      case 2:
        return ["ua-col-span-3-4-md", "ua-col-span-7-4-md"];
      case 4:
        return ["ua-col-auto-span-6-md ua-col-auto-span-3-lg"];
      default:
        return ["ua-col-auto-span-6-md ua-col-auto-span-4-lg"];
    }
  }, [cardCount]);

  function handleClick(id) {
    trackEvent({
      action: "click_dashboard_learn_more",
      relatedId: id,
      params: { benefit_id: id },
    });
  }

  if (!!benefitsError) {
    return (
      <Notification
        className="ua-col-auto-span-12-sm ua-col-auto-span-12-md"
        dismissClickHandler={() => setDismissError(true)}
        isDismissed={dismissError}
        notificationType="error"
      >
        <Paragraph className="tds-margin-none">{benefitsError}</Paragraph>
      </Notification>
    );
  }

  if (!cardCount) {
    return null;
  }

  return (
    <>
      {benefits?.map((item, i) => (
        <div
          key={item.id}
          className={`ua-col-auto-span-12-sm ${
            cardCount === 2 ? colSpan[i] : colSpan[0]
          }`}
        >
          <Card
            alt={item.title}
            border
            cardType="block"
            className="ua-border-radius-sm ua-box-shadow ua-overflow-hidden home--card__equal-height"
            contentBlockImage={item.image}
            layout="custom"
          >
            <H3>{item.title}</H3>
            <Paragraph bodyStyle={2}>{item.home_excerpt}</Paragraph>
            <TempoRouterLink
              component={Link}
              onClick={() => handleClick(item.id)}
              text="Learn more"
              to={`${benefitRootPath}/${item.slug}`}
            />
          </Card>
        </div>
      ))}
    </>
  );
}
