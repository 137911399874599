import {
  createAction,
  createAsyncThunk,
  createReducer,
  isAnyOf,
} from "@reduxjs/toolkit";

import UA_API, {
  UA_API_EP_AGENCIES,
  UA_API_EP_CATALOG_YEARS,
  UA_API_EP_MARKETS,
  UA_API_EP_USERS,
} from "../../data/ua-api/ua-api";
import { isLogout } from "../auth/AuthRedux";
import normalizeAgenciesResponse from "./normalize-agencies";
import normalizeAgencyUsersResponse from "./normalize-agency-users";
import normalizeCatalogYearsResponse from "./normalize-catalog-years";
import normalizeMarketsResponse from "./normalize-markets";

export const getCatalogYears = createAsyncThunk(
  "admin/catalog-years",
  async function fetchCatalogYears(_, { rejectWithValue }) {
    try {
      const response = await UA_API.get(UA_API_EP_CATALOG_YEARS);

      let [status, catalog_years, message] =
        normalizeCatalogYearsResponse(response);

      if (status === "error") {
        throw new Error(message);
      }

      return catalog_years;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const getMarkets = createAsyncThunk(
  "admin/markets",
  async function fetchMarkets(_, { rejectWithValue }) {
    try {
      const response = await UA_API.get(UA_API_EP_MARKETS);

      let [status, markets, message] = normalizeMarketsResponse(response);

      if (status === "error") {
        throw new Error(message);
      }

      return markets;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const getAgencies = createAsyncThunk(
  "admin/agencies",
  async function ({ market_id, tier }, { rejectWithValue }) {
    try {
      let request_url = UA_API_EP_AGENCIES;
      const queryParams = new URLSearchParams();

      market_id =
        !!market_id || market_id === "0" || market_id === 0
          ? parseInt(market_id, 10)
          : null;

      if (market_id !== null && !isNaN(market_id)) {
        queryParams.set("market_id", market_id);
      }

      if (!!tier) {
        queryParams.set("tier", encodeURIComponent(tier));
      }

      const queryParamsString = queryParams.toString();

      if (!!queryParamsString) {
        request_url += `?${queryParamsString}`;
      }

      const response = await UA_API.get(request_url);

      let [status, agencies, message] = normalizeAgenciesResponse(response);

      if (status === "error") {
        throw new Error(message);
      }

      return agencies;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const getAgencyUsers = createAsyncThunk(
  "admin/agency-users",
  async function fetchAgencyUsers({ agency_id }, { rejectWithValue }) {
    try {
      const response = await UA_API.get(
        `${UA_API_EP_USERS}?agency_id=${agency_id}`
      );

      let [status, users, message] = normalizeAgencyUsersResponse(response);

      if (status === "error") {
        throw new Error(message);
      }

      return users;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const clearAgencyUsers = createAction("admin/agency-users/clear");

export const setImpersonate = createAction("admin/impersonate");

export const catalogYearsReducer = createReducer([], (builder) => {
  builder
    .addCase(getCatalogYears.fulfilled, (state, action) => [...action.payload])
    .addMatcher(isLogout, (state, action) => []);
});

export const catalogYearsErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getCatalogYears.pending, (state, action) => null)
    .addCase(getCatalogYears.fulfilled, (state, action) => null)
    .addCase(getCatalogYears.rejected, (state, action) => action.payload)
    .addMatcher(isLogout, (state, action) => null);
});

export const catalogYearsLoadingReducer = createReducer(false, (builder) => {
  builder
    .addCase(getCatalogYears.pending, (state, action) => true)
    .addCase(getCatalogYears.fulfilled, (state, action) => false)
    .addCase(getCatalogYears.rejected, (state, action) => false)
    .addMatcher(isLogout, (state, action) => false);
});

export const marketsReducer = createReducer([], (builder) => {
  builder
    .addCase(getMarkets.fulfilled, (state, action) => [...action.payload])
    .addMatcher(isLogout, (state, action) => []);
});

export const marketsErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getMarkets.pending, (state, action) => null)
    .addCase(getMarkets.fulfilled, (state, action) => null)
    .addCase(getMarkets.rejected, (state, action) => action.payload)
    .addMatcher(isLogout, (state, action) => null);
});

export const marketsLoadingReducer = createReducer(false, (builder) => {
  builder
    .addCase(getMarkets.pending, (state, action) => true)
    .addCase(getMarkets.fulfilled, (state, action) => false)
    .addCase(getMarkets.rejected, (state, action) => false)
    .addMatcher(isLogout, (state, action) => false);
});

export const agenciesReducer = createReducer([], (builder) => {
  builder
    .addCase(getAgencies.fulfilled, (state, action) => [...action.payload])
    .addCase(getAgencies.rejected, (state, action) => [])
    .addMatcher(isLogout, (state, action) => []);
});

export const agenciesErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getAgencies.rejected, (state, action) => action.payload)
    .addMatcher(
      isAnyOf(getAgencies.pending, getAgencies.fulfilled),
      (state, action) => null
    )
    .addMatcher(isLogout, (state, action) => null);
});

export const agenciesLoadingReducer = createReducer(false, (builder) => {
  builder
    .addCase(getAgencies.pending, (state, action) => true)
    .addMatcher(
      isAnyOf(getAgencies.fulfilled, getAgencies.rejected),
      (state, action) => false
    )
    .addMatcher(isLogout, (state, action) => false);
});

export const agencyUsersReducer = createReducer([], (builder) => {
  builder
    .addCase(getAgencyUsers.fulfilled, (state, action) => [...action.payload])
    .addMatcher(
      isAnyOf(getAgencyUsers.rejected, clearAgencyUsers),
      (state, action) => []
    )
    .addMatcher(isLogout, (state, action) => []);
});

export const agencyUsersErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getAgencyUsers.rejected, (state, action) => action.payload)
    .addMatcher(
      isAnyOf(
        getAgencies.rejected,
        getAgencyUsers.pending,
        getAgencyUsers.fulfilled,
        clearAgencyUsers
      ),
      (state, action) => null
    )
    .addMatcher(isLogout, (state, action) => null);
});

export const agencyUsersLoadingReducer = createReducer(false, (builder) => {
  builder
    .addCase(getAgencyUsers.pending, (state, action) => true)
    .addMatcher(
      isAnyOf(
        getAgencies.rejected,
        getAgencyUsers.fulfilled,
        getAgencyUsers.rejected,
        clearAgencyUsers
      ),
      (state, action) => false
    )
    .addMatcher(isLogout, (state, action) => false);
});

const initialImpersonateState = {
  viewCatalogYear: "",
  viewMarket: "",
  viewTier: "",
  viewAgency: "",
  viewUser: "",
  changed: false,
};

export const impersonateReducer = createReducer(
  initialImpersonateState,
  (builder) => {
    builder
      .addCase(setImpersonate, (state, action) => ({
        ...state,
        ...action.payload,
        changed: true,
      }))
      .addMatcher(isLogout, (state, action) => initialImpersonateState);
  }
);
