import { useMemo } from "react";
import { arrayOf, number, oneOfType, shape, string } from "prop-types";

import trackEvent from "../tracking/track-event";

import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";
import TrainingCallToActionModalForm from "./TrainingCallToActionModalForm";

import { Button } from "@uhc-tempo/components";

export default function TrainingButton({
  catalogYearId,
  id,
  name,
  trainingSession,
  trainingType,
}) {
  const { action, linkFormatted } = trainingSession || {};

  const [analyticsAction, buttonType, label, params] = useMemo(
    () => getMeta(action, id, linkFormatted, name, trainingType),
    [action, id, linkFormatted, name, trainingType]
  );

  function trackClick() {
    trackEvent({
      action: analyticsAction,
      params,
    });
  }

  if (action === "internal") {
    return (
      <TempoRouterLink
        buttonType={buttonType}
        component={Button}
        onClick={trackClick}
        to={params.link_url}
      >
        {label}
      </TempoRouterLink>
    );
  }

  if (action === "external") {
    return (
      <Button
        buttonType={buttonType}
        href={params.link_url}
        onClick={trackClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {label}
      </Button>
    );
  }

  if (["contact", "notify", "waitlist"].includes(action)) {
    return (
      <TrainingCallToActionModalForm
        buttonType={buttonType}
        catalogYearId={catalogYearId}
        id={id}
        label={label}
        name={name}
        trackClick={trackClick}
        trainingSession={trainingSession}
      />
    );
  }

  return null;
}

function getMeta(action, id, linkFormatted, name, trainingType) {
  let analyticsAction = "training_linkclick";
  let buttonType = "primary-one";
  let label = "Access";
  const params = { training_id: id, training_name: name };

  const isOnDemand = trainingType.includes("2");
  const isVirtual = trainingType.includes("1");
  const isWaitlist = ["notify", "waitlist"].includes(action);

  if ((action === "contact" && !isOnDemand) || (isVirtual && !isWaitlist)) {
    // trainingType 1 = Virtual
    // trainingType 2 = On Demand
    label = "Register";
  } else if (isWaitlist && !isOnDemand) {
    label = "Join wait list";
    buttonType = "secondary-one";
  }

  if (action === "external") {
    analyticsAction = "training_externallinkclick";
    params.link_url = linkFormatted;
    params.outbound = true;
  } else if (action === "internal") {
    analyticsAction = "training_internallinkclick";
    params.link_url = linkFormatted;
  }

  if (!!params.link_url) {
    params.link_text = label;
  }

  return [analyticsAction, buttonType, label, params];
}

TrainingButton.propTypes = {
  catalogYearId: oneOfType([number, string]),
  id: oneOfType([number, string]),
  name: string,
  trainingSession: shape({
    action: string,
    date_override: string,
    end_date: string,
    link: string,
    linkFormatted: string,
    linkType: string,
    start_date: string,
    status: string,
  }),
  trainingType: arrayOf(string),
};
