import { bool, string } from "prop-types";

import { AGENCY_LEVELS } from "../../data/constants/agency_levels";
import trackLinkClick from "../tracking/track-link-click";

import { BrandIcon, Button } from "@uhc-tempo/components";
import { IconAward, IconPhone, IconStar } from "@uhc-tempo/icons";

export default function Rewards({ className, headlineClass, showLevel3 }) {
  const dcsmLink = "https://www.brainshark.com/uhc/vu?pi=zHIzLiG3ozl0Luz0";

  function trackClick() {
    trackLinkClick({
      event: "ua_status_dcsm",
      label: "Brainshark video",
      link: dcsmLink,
    });
  }

  const baseClass = "ua-rewards";
  let sectionClass = `fluid-container ${baseClass}`;

  if (!!className) {
    sectionClass += ` ${className}`;
  }

  let hClass = `${baseClass}--headline`;

  if (!!headlineClass) {
    hClass += ` ${headlineClass}`;
  }

  return (
    <section className={sectionClass}>
      <h2 className={hClass}>United Advantage status has its rewards.</h2>
      <div className="ua-rewards--perks">
        <div className="ua-rewards--perks--tier ua-rewards--perks--tier__level_1">
          <h3>
            <strong>{AGENCY_LEVELS.level_1}</strong> Perks
          </h3>
          <div className="ua-rewards--perks--tier--text">
            <h4>
              <BrandIcon
                icon={IconPhone}
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <span>Dedicated Client Service Manager (DCSM)</span>
            </h4>
            <p className="ua-rewards--perks--tier--text--paragraph ua-rewards--perks--tier--text--paragraph__muted">
              Your DCSM is your direct contact for all services related
              inquiries <span className="ua-text-nowrap">(2-1000 lives)</span>{" "}
              outside of the new business and/or renewal process.
            </p>
            <p className="ua-rewards--perks--tier--text--paragraph">
              <Button
                buttonType="secondary-one"
                href={dcsmLink}
                onClick={trackClick}
                rel="noopener noreferrer"
                target="_blank"
              >
                Watch Video
              </Button>
            </p>
          </div>
        </div>
        <div className="ua-rewards--perks--tier ua-rewards--perks--tier__level_2">
          <h3>
            <strong>{AGENCY_LEVELS.level_2}</strong> Perks
          </h3>
          <div className="ua-rewards--perks--tier--text">
            <h4>
              <BrandIcon
                icon={IconPhone}
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <span>Dedicated Client Service Manager (DCSM)</span>
            </h4>
            <hr />
            <h4>
              <BrandIcon
                icon={IconAward}
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <span>Points to shop for perks</span>
            </h4>
          </div>
        </div>
        {showLevel3 && (
          <div className="ua-rewards--perks--tier ua-rewards--perks--tier__level_3">
            <h3>
              <strong>{AGENCY_LEVELS.level_3}</strong> Perks
            </h3>
            <div className="ua-rewards--perks--tier--text">
              <h4>
                <BrandIcon
                  icon={IconPhone}
                  styleVariation="TWO_TONE_DARK_CIRCLE"
                />
                <span>Dedicated Client Service Manager (DCSM)</span>
              </h4>
              <hr />
              <h4>
                <BrandIcon
                  icon={IconStar}
                  styleVariation="TWO_TONE_DARK_CIRCLE"
                />
                <span>Double the points to shop for perks</span>
              </h4>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

Rewards.propTypes = {
  className: string,
  headlineClass: string,
  showLevel3: bool,
};
