import Loop from "@mui/icons-material/Loop";

import { Card, UtilityIcon, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function StatusLoading(props) {
  return (
    <div className="ua-margin-auto ua-margin-jumbo-bottom ua-max-width-90 ua-padding-xxxxlg-top">
      <Card
        border
        layout="custom"
        className="ua-text-center ua-border-radius-sm ua-box-shadow ua-padding-xlg-top ua-status-message"
      >
        <div className="ua-padding-xxxlg-bottom ua-padding-xlg-top">
          <Paragraph bodyStyle={1} className="ua-text-primary-one">
            <UtilityIcon
              className="tds-margin-xsm-right"
              color="currentColor"
              icon={Loop}
            />
            Loading…
          </Paragraph>
        </div>
      </Card>
    </div>
  );
}
