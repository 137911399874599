import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AGENCY_LEVELS } from "../../data/constants/agency_levels";
import {
  clearAgencyUsers,
  getAgencies,
  getAgencyUsers,
  getCatalogYears,
  getMarkets,
  setImpersonate,
} from "./AdminRedux";
import { getUser } from "../user/UserRedux";
import { setCatalogYearCurrent } from "../catalogs/CatalogsRedux";
import {
  setBonusShoppingRequired,
  setShoppingRequired,
} from "../shopping/ShoppingRedux";
import useImpersonateState from "./use-impersonate-state";
import useUserProfile from "../user/use-user-profile";

import { Button, Form, Select } from "@uhc-tempo/components";
const { Group } = Form;

const agencyLevels = Object.keys(AGENCY_LEVELS).map((level) => ({
  id: level,
  name: AGENCY_LEVELS[level],
}));

export default function ImpersonateUser() {
  const { year: catalogYear } = useSelector(
    (state) => state.catalogYearCurrent || {}
  );
  const catalogYears = useSelector((state) => state.catalogYears);
  const catalogYearsError = useSelector((state) => state.catalogYearsError);
  const catalogYearsLoading = useSelector((state) => state.catalogYearsLoading);
  const markets = useSelector((state) => state.markets);
  const marketsError = useSelector((state) => state.marketsError);
  const marketsLoading = useSelector((state) => state.marketsLoading);
  const agencies = useSelector((state) => state.agencies);
  const agenciesError = useSelector((state) => state.agenciesError);
  const agenciesLoading = useSelector((state) => state.agenciesLoading);
  const agencyUsers = useSelector((state) => state.agencyUsers);
  const agencyUsersError = useSelector((state) => state.agencyUsersError);
  const agencyUsersLoading = useSelector((state) => state.agencyUsersLoading);
  const { id: userId } = useUserProfile();

  const { viewCatalogYear, viewMarket, viewTier, viewAgency, viewUser } =
    useImpersonateState();
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState(false);

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";

    if (!value) {
      return;
    }

    switch (field) {
      case "viewMarket":
        dispatch(
          setImpersonate({ viewMarket: value, viewAgency: "", viewUser: "" })
        );
        dispatch(getAgencies({ market_id: value, tier: viewTier }));
        dispatch(clearAgencyUsers());
        break;
      case "viewTier":
        dispatch(
          setImpersonate({ viewTier: value, viewAgency: "", viewUser: "" })
        );
        dispatch(getAgencies({ market_id: viewMarket, tier: value }));
        dispatch(clearAgencyUsers());
        break;
      case "viewAgency":
        dispatch(setImpersonate({ viewAgency: value, viewUser: "" }));
        dispatch(getAgencyUsers({ agency_id: value }));
        break;
      default:
        dispatch(setImpersonate({ [field]: value }));
        break;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!viewCatalogYear || !viewUser) {
      return false;
    }

    setInProgress(true);

    dispatch(setBonusShoppingRequired(false));
    dispatch(setShoppingRequired(false));

    dispatch(getUser({ id: viewUser, isAdmin: true }))
      .unwrap()
      .then(() => {
        dispatch(setCatalogYearCurrent({ year: viewCatalogYear }));
        setInProgress(false);
      })
      .catch((err) => {
        console.error(err);
        setInProgress(false);
      });
  }

  useEffect(() => {
    if (!catalogYearsError && !catalogYearsLoading && !catalogYears.length) {
      dispatch(getCatalogYears());
    }
  }, [catalogYears, catalogYearsError, catalogYearsLoading, dispatch]);

  useEffect(() => {
    if (!marketsError && !marketsLoading && !markets.length) {
      dispatch(getMarkets());
    }
  }, [dispatch, markets, marketsError, marketsLoading]);

  useEffect(() => {
    if (
      !!viewMarket &&
      !!viewTier &&
      !agenciesError &&
      !agenciesLoading &&
      !agencies.length
    ) {
      dispatch(getAgencies({ market_id: viewMarket, tier: viewTier }));
    }
  }, [
    agencies,
    agenciesError,
    agenciesLoading,
    dispatch,
    viewMarket,
    viewTier,
  ]);

  useEffect(() => {
    if (
      !!viewAgency &&
      !agencyUsersError &&
      !agencyUsersLoading &&
      !agencyUsers.length
    ) {
      dispatch(getAgencyUsers({ agency_id: viewAgency }));
    }
  }, [agencyUsers, agencyUsersError, agencyUsersLoading, dispatch, viewAgency]);

  const buttonDisabled =
    inProgress ||
    !viewUser ||
    (`${viewUser}` === `${userId}` &&
      `${viewCatalogYear}` === `${catalogYear}`);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div className="ua-display-flex ua-grid-gap-1-sm ua-align-items-stretch-sm ua-align-items-center-md ua-flex-column-sm">
        <ImpersonateField
          disabled={!catalogYears.length}
          error={catalogYearsError}
          groupId="viewCatalogYear"
          handleInput={handleInput}
          loading={catalogYearsLoading}
          options={catalogYears}
          placeholder="Year"
          style={{ maxWidth: 100, minWidth: 80 }}
          value={viewCatalogYear}
        />
        <ImpersonateField
          className="ua-flex-grow"
          disabled={!markets.length}
          error={marketsError}
          groupId="viewMarket"
          handleInput={handleInput}
          loading={marketsLoading}
          options={markets}
          placeholder="Market"
          style={{ width: "17.32%" }}
          value={viewMarket}
        />
        <ImpersonateField
          disabled={
            !catalogYears.length || !markets.length || !agencyLevels.length
          }
          groupId="viewTier"
          handleInput={handleInput}
          options={agencyLevels}
          placeholder="Tier"
          style={{ maxWidth: 110, minWidth: 110 }}
          value={viewTier}
        />
        <ImpersonateField
          className="ua-flex-grow"
          disabled={!agencies.length}
          error={agenciesError}
          groupId="viewAgency"
          handleInput={handleInput}
          loading={agenciesLoading}
          options={agencies}
          placeholder="Agency"
          style={{ width: "30.5%" }}
          value={viewAgency}
        />
        <ImpersonateField
          className="ua-flex-grow"
          disabled={!agencyUsers.length}
          error={agencyUsersError}
          groupId="viewUser"
          handleInput={handleInput}
          loading={agencyUsersLoading}
          options={agencyUsers}
          placeholder="User"
          style={{ width: "18%" }}
          value={viewUser}
        />
        <div style={{ minWidth: 136 }}>
          <Button
            buttonType={buttonDisabled ? "disabled" : "primary-three"}
            disabled={buttonDisabled}
            className="ua-display-block ua-width-100"
            loading={inProgress}
            type="submit"
          >
            {inProgress ? "Loading…" : "Impersonate"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

function ImpersonateField({
  error,
  className = "",
  disabled,
  groupId,
  handleInput,
  loading,
  options,
  placeholder,
  value,
  ...rest
}) {
  let defaultText = `— ${placeholder} —`;

  if (loading) {
    defaultText = "Loading…";
  } else if (!!error) {
    defaultText = error;
  }

  return (
    <Group
      {...rest}
      className={`ua-form__group ${className}`}
      groupId={groupId}
    >
      <Select
        className={!value || !options.length ? "impersonate-placeholder" : ""}
        containerProps={{ className: "tds-form__select ua-width-100" }}
        disabled={disabled}
        name={groupId}
        onChange={handleInput}
        value={value}
      >
        <Select.Option value="">{defaultText}</Select.Option>
        {!loading &&
          !error &&
          options.map(({ id, name }) => (
            <Select.Option key={`${groupId}-${id}`} value={id}>
              {name}
            </Select.Option>
          ))}
      </Select>
    </Group>
  );
}
