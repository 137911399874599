import { string } from "prop-types";

import ResourceVideo from "./ResourceVideo";

export default function ResourceThumbnail({ video, thumbnail, title }) {
  if (!thumbnail) {
    return null;
  }

  return (
    <div className="ua-margin-sm-bottom">
      {!!video ? (
        <ResourceVideo link={video} title={title}>
          <Thumbnail thumbnail={thumbnail} title={title} />
        </ResourceVideo>
      ) : (
        <Thumbnail thumbnail={thumbnail} title={title} />
      )}
    </div>
  );
}

function Thumbnail({ thumbnail, title }) {
  return (
    <img
      className="ua-display-block"
      src={thumbnail}
      alt={title}
      width={300}
      height={160}
    />
  );
}

ResourceThumbnail.propTypes = {
  thumbnail: string,
  title: string,
  video: string,
};
