import { useMemo } from "react";
import { string, object, number } from "prop-types";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import DOMPurify from "dompurify";
import { Typography } from "@uhc-tempo/components";

import getCleanUploadUrl from "../../utilities/get-clean-upload-url";
import getPathFromUrl from "../../utilities/get-path-from-url";
import isInternalLink from "../../utilities/is-internal-link";
import trackLinkClick from "../../features/tracking/track-link-click";

import TempoRouterLink from "../tempo-router-link/TempoRouterLink";

const { H1, H2, H3, H4, H5, H6, Paragraph, Link } = Typography;

const DEFAULT_HEADING_OVERRIDES = {
  h1: 6,
  h2: 6,
  h3: 6,
  h4: 6,
  h5: 6,
  h6: 6,
};
export default function HtmlContent({
  rawHtml = "",
  headingOverrides = DEFAULT_HEADING_OVERRIDES,
  paragraphBodyStyle = 2,
  paragraphMargin = "ua-margin-xxlg-bottom",
}) {
  const content = useMemo(() => {
    const headings = { ...DEFAULT_HEADING_OVERRIDES, ...headingOverrides };
    return getContent(rawHtml, headings, paragraphBodyStyle, paragraphMargin);
  }, [rawHtml, headingOverrides, paragraphBodyStyle, paragraphMargin]);

  if (!content) {
    return null;
  }

  return <>{content}</>;
}

HtmlContent.propTypes = {
  rawHtml: string.isRequired,
  headingOverrides: object,
  paragraphBodyStyle: number,
  paragraphMargin: string,
};

function getContent(
  rawHtml,
  headingOverrides,
  paragraphBodyStyle,
  paragraphMargin
) {
  return parseContent(
    sanitizeContent(rawHtml),
    headingOverrides,
    paragraphBodyStyle,
    paragraphMargin
  );
}

function parseContent(
  rawHtml,
  headingOverrides,
  paragraphBodyStyle,
  paragraphMargin
) {
  const options = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) return;

      const props = attributesToProps(attribs);
      const { class: className = "", href, ...rest } = props;

      switch (name) {
        case "h1":
          return (
            <H1
              {...rest}
              className={className}
              headingStyle={headingOverrides.h1}
            >
              {domToReact(children, options)}
            </H1>
          );
        case "h2":
          return (
            <H2
              {...rest}
              className={className}
              headingStyle={headingOverrides.h2}
            >
              {domToReact(children, options)}
            </H2>
          );
        case "h3":
          return (
            <H3
              {...rest}
              className={className}
              headingStyle={headingOverrides.h3}
            >
              {domToReact(children, options)}
            </H3>
          );
        case "h4":
          return (
            <H4
              {...rest}
              className={className}
              headingStyle={headingOverrides.h4}
            >
              {domToReact(children, options)}
            </H4>
          );
        case "h5":
          return (
            <H5
              {...rest}
              className={className}
              headingStyle={headingOverrides.h5}
            >
              {domToReact(children, options)}
            </H5>
          );
        case "h6":
          return (
            <H6
              {...rest}
              className={className}
              headingStyle={headingOverrides.h6}
            >
              {domToReact(children, options)}
            </H6>
          );
        case "p":
          return (
            <Paragraph
              bodyStyle={paragraphBodyStyle}
              className={`${className} ${paragraphMargin}`}
              {...rest}
            >
              {domToReact(children, options)}
            </Paragraph>
          );
        case "a":
          let url = href;
          const uploadUrl = getCleanUploadUrl(href);

          if (!!uploadUrl) {
            url = uploadUrl;
          }

          return !!url && isInternalLink(url) ? (
            <TempoRouterLink
              className={className}
              component={Typography.Link}
              onClick={trackClick}
              to={getPathFromUrl(url)}
            >
              {domToReact(children, options)}
            </TempoRouterLink>
          ) : (
            <Link href={url} onClick={trackClick} {...rest}>
              {domToReact(children, options)}
            </Link>
          );
        case "strong":
        case "b":
          return (
            <span
              {...rest}
              className={`${className} tds-text__paragraph--bold`}
            >
              {domToReact(children, options)}
            </span>
          );
        case "em":
        case "i":
          return (
            <span {...rest} className={`${className} tds-typography--italic`}>
              {domToReact(children, options)}
            </span>
          );
        case "small":
          return (
            <span
              {...rest}
              className={`${className} tds-text__paragraph--small`}
            >
              {domToReact(children, options)}
            </span>
          );
        case "ul":
          return (
            <ul
              {...rest}
              className={`${className} tds-text__paragraph ${paragraphMargin}`}
            >
              {domToReact(children, options)}
            </ul>
          );
        case "ol":
          return (
            <ol
              {...rest}
              className={`${className} tds-text__paragraph ${paragraphMargin}`}
            >
              {domToReact(children, options)}
            </ol>
          );
        case "br":
          return <br />;
        default:
          const Tag = name;
          return !!children && Array.isArray(children) && !!children.length ? (
            <Tag className={className} {...rest}>
              {domToReact(children, options)}
            </Tag>
          ) : (
            <Tag className={className} {...rest} />
          );
      }
    },
  };

  return parse(rawHtml, options);
}

function sanitizeContent(rawHtml) {
  return DOMPurify.sanitize(rawHtml, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["target"],
    FORCE_BODY: true,
  });
}

function trackClick(e) {
  const target = e?.target;
  const link = !!target && target?.href;
  const label = !!target && target?.innerText;

  if (!!link) {
    trackLinkClick({
      link,
      label,
      triggered_from: "wysiwyg",
    });
  }
}
