import { useMemo } from "react";
import { bool, number, oneOfType, string } from "prop-types";

import getTitleClass from "./get-title-class";
import ShoppingBenefitPoints from "./ShoppingBenefitPoints";

import { Typography } from "@uhc-tempo/components";
const { H2, Paragraph } = Typography;

export default function ShoppingBenefitSummaryItem({
  allowMultiple,
  most_popular,
  home_excerpt,
  qty,
  selectedValue,
  title,
}) {
  const isMultiple = allowMultiple === "quantity" || allowMultiple === "amount";
  const titleClass = useMemo(
    () => getTitleClass(most_popular, false, false, isMultiple),
    [isMultiple, most_popular]
  );

  return (
    <div className="ua-grid ua-grid-gap-2-sm ua-grid-cols-12 ua-padding-xxlg-bottom">
      <div className="ua-col-auto-span-12-sm ua-col-auto-span-3-md">
        <ShoppingBenefitPoints points={selectedValue} />
      </div>
      <div className="ua-col-auto-span-12-sm ua-col-auto-span-9-md">
        <H2
          className={`tds-margin-xsm-bottom shopping-benefit__title--stacked ${titleClass}`}
          headingStyle={6}
        >
          {title}
          {allowMultiple === "quantity" && (
            <>
              {" "}
              <span className="ua-badge ua-margin-xsm-left">QTY: {qty}</span>
            </>
          )}
        </H2>
        <Paragraph className="ua-display-flex tds-margin-none">
          <span className="tds-margin-sm-right">
            <span className="ua-line-clamp-sm-1">{home_excerpt}</span>
          </span>
        </Paragraph>
      </div>
    </div>
  );
}

ShoppingBenefitSummaryItem.propTypes = {
  allowMultiple: string,
  most_popular: oneOfType([bool, number]),
  home_excerpt: string,
  qty: number,
  selectedValue: number,
  title: string,
};
