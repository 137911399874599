import { useSelector } from "react-redux";

import trackLinkClick from "../tracking/track-link-click";

import { Button, Typography } from "@uhc-tempo/components";
const { H3, Paragraph } = Typography;

export default function ReminderLevel1() {
  const email = useSelector(({ settings }) => settings?.uhc_reply_email) || "";
  const ctaLink =
    !!email &&
    `mailto:${email}?subject=Questions%20About%20%20UA%20Status%20Projection`;

  function trackClick() {
    if (ctaLink) {
      trackLinkClick({
        event: "Contact UA Support (preferred)",
        label: "Email",
        link: ctaLink,
      });
    }
  }

  return (
    <div className="fluid-container ua-text-center ua-padding-xjumbo-bottom ua-padding-xxjumbo-top">
      <H3>Keeping pushing. You got this!</H3>
      <Paragraph className="ua-text-primary-one ua-font-size-h4 ua-margin-lg-bottom">
        Your agency’s scorecard is updated monthly – first Monday of each month.{" "}
        <span className="ua-display-block-lg">
          Check back to track your agency’s progress towards Premier.
        </span>
      </Paragraph>
      {!!ctaLink && (
        <Paragraph className="ua-margin-lg-bottom ua-padding-sm-top">
          <Button
            buttonType="secondary-one"
            href={ctaLink}
            onClick={trackClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            Questions? Contact UA Support
          </Button>
        </Paragraph>
      )}
    </div>
  );
}
