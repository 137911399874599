import { useEffect, useMemo, useState } from "react";
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types";

import getLevelName from "../../utilities/get-level-name";

import DcsmAlt from "./DcsmAlt";
import DcsmVideo from "./DcsmVideo";
import HeaderAlt from "./HeaderAlt";
import ReminderNon from "./ReminderNon";
import StatusLoading from "./StatusLoading";
import StatusNotFound from "./StatusNotFound";
import TierRequirementsAlt from "./TierRequirementsAlt";

export default function StatusPageNon({
  agency_id,
  agencyName,
  catalog_year,
  catalog_year_next,
  logo,
  status,
  statusError,
  statusLoading,
}) {
  const [active, setActive] = useState();
  const activeLabel = useMemo(() => getLevelName(active), [active]);

  // Update active tier with API response
  const { availableTiers = [], CurrentUATierClass: currentTier } =
    (!!status && status) || {};

  useEffect(() => {
    const currentTierIndex = availableTiers.findIndex(
      (tier) => tier === currentTier
    );

    if (currentTierIndex > -1 && currentTierIndex + 1 < availableTiers.length) {
      setActive(availableTiers[currentTierIndex + 1]);
    } else if (currentTierIndex > -1) {
      setActive(currentTier);
    } else {
      setActive(availableTiers[0]);
    }
  }, [agency_id, availableTiers, currentTier]);

  return (
    <>
      <HeaderAlt catalog_year_next={catalog_year_next} />
      {!statusError && statusLoading && <StatusLoading />}
      {!!statusError && !statusLoading && (
        <StatusNotFound agencyName={agencyName} logo={logo} />
      )}
      {!statusError && !statusLoading && !!active && (
        <TierRequirementsAlt
          active={active}
          activeLabel={activeLabel}
          agencyName={agencyName}
          className="ua-req__bg-gradient fluid-container ua-padding-jumbo-bottom"
          currentYear={catalog_year}
          logo={logo}
          nextYear={catalog_year_next}
          status={status}
        />
      )}
      {!statusError && !statusLoading && !!availableTiers.length && (
        <>
          <DcsmAlt />
          <DcsmVideo />
          <ReminderNon />
        </>
      )}
    </>
  );
}

StatusPageNon.propTypes = {
  agency_id: oneOfType([number, string]),
  agencyName: string,
  catalog_year: number,
  catalog_year_next: number,
  logo: string,
  status: shape({
    availableTiers: arrayOf(string),
    ProjectedUATierClass: string,
    ProjectedUATierLabel: string,
  }),
  statusError: string,
  statusLoading: bool,
};
