import getEnvironment from "../../utilities/get-environment";

export const URL_ORIGIN =
  window &&
  window.location &&
  `${window.location.protocol}//${window.location.hostname}` +
    (window.location.port ? `:${window.location.port}` : "");

export const HOST_ENV = getEnvironment(URL_ORIGIN);

export const URL_PRODUCTION = import.meta.env.VITE_URL_PRODUCTION;
export const URL_STAGING = import.meta.env.VITE_URL_STAGING;
