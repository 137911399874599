import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../data/constants/routes";
import useSelectedBenefits from "../../features/shopping/use-selected-benefits";

import ShoppingPoints from "../../features/shopping/ShoppingPoints";
import ShoppingSelection from "../../features/shopping/ShoppingSelection";

import { Typography } from "@uhc-tempo/components";
const { H1 } = Typography;

export default function ShoppingChoose(props) {
  let navigate = useNavigate();

  const { dateClosed, pointsAvailable } = useSelector(
    ({ shopping = {} }) => shopping
  );
  let { pointsRemaining } = useSelectedBenefits();

  function handleSubmit() {
    if (pointsAvailable === pointsRemaining) {
      return;
    }

    navigate(ROUTES.shoppingReview.path);
  }

  return (
    <section className="fluid-container page-main__fill ua-padding-xxxlg-vertical shopping-selection-page-bg">
      <div className="ua-grid ua-grid-gap-3-sm ua-grid-cols-12">
        <div className="ua-col-auto-span-12-sm ua-col-auto-span-3-md">
          <H1
            className="ua-font-size-display-0-md tds-padding-lg-vertical tds-margin-xxlg-bottom"
            useLight
          >
            Shop now
          </H1>
          <ShoppingPoints
            deadline={dateClosed}
            deadlineLabel="Submit perks by"
            handleSubmit={handleSubmit}
            pointsRemaining={pointsRemaining}
          />
        </div>
        <div className="ua-col-auto-span-12-sm ua-col-auto-span-9-md">
          <ShoppingSelection handleSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
}
