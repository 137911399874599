import ROUTES from "../data/constants/routes";

export default function pathAuthorized(pathname, authenticated) {
  let isRestricted = false;

  try {
    for (const route in ROUTES) {
      if (ROUTES[route].path === pathname) {
        isRestricted = ROUTES[route].restricted;
        break;
      }
    }
  } catch (err) {}

  return !isRestricted || (isRestricted && authenticated);
}
