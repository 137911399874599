import { useEffect, useRef } from "react";

export default function usePortalTarget(id, className) {
  const portalTarget = useRef(null);

  useEffect(() => {
    return () => {
      portalTarget.current.remove();
    };
  }, []);

  function getPortalTarget(id, className) {
    if (!portalTarget.current) {
      portalTarget.current =
        document.getElementById(id) || createPortalTarget(id, className);
    }

    return portalTarget.current;
  }

  return !!id ? getPortalTarget(id, className) : null;
}

function createPortalTarget(id, className) {
  const portalRoot = document.getElementById("portal-root");
  let portalTarget = document.createElement("div");
  portalTarget.setAttribute("id", id);

  if (className) {
    portalTarget.classList.add(className);
  }

  portalRoot.appendChild(portalTarget);
  return portalTarget;
}
