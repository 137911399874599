import { HOST_ENV } from "../../data/constants/urls";

import sendToGA4 from "./send-to-ga4";
import sendToTrackingAPI from "./send-to-tracking-api";
import { store } from "../../data/redux/store";

export default function trackEvent({ action, relatedId, params = {} }) {
  try {
    // Don't override eventUserProperties included with login event
    if (!params?.event_user_ua_user_id) {
      const { auth } = store.getState();

      // Include user properties with event params
      // because user properties alone are not currently
      // reliable for filtering GA4 explorations
      if (!!auth?.eventUserProperties) {
        params = { ...params, ...auth.eventUserProperties };
      }
    }

    if (
      HOST_ENV !== "production" ||
      (!!params?.event_user_user_type &&
        params.event_user_user_type === "internal")
    ) {
      params.traffic_type = "internal";
      params.debug_mode = true;
    }
  } catch (err) {}

  sendToGA4(action, params);
  sendToTrackingAPI(action, params, relatedId);
}
