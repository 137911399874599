import { element, func, node, oneOfType } from "prop-types";

export default function HowButton({ children, showInfo }) {
  return (
    <button className="ua-req--how" onClick={showInfo} type="button">
      <svg
        height={48}
        role="presentation"
        viewBox="0 0 48 48"
        width={48}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#002677" />
        <path
          d="M33.32,7H15.68c-1.47,0-2.68,1.19-2.68,2.64v28.71c0,1.45,1.21,2.64,2.68,2.64h17.64c1.47,0,2.68-1.19,2.68-2.64V9.64c0-1.46-1.21-2.64-2.68-2.64Zm-14.57,20.78c1.32,0,2.4,.84,2.4,1.89s-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89,1.07-1.89,2.4-1.89Zm-2.4-3.78c0-1.04,1.07-1.89,2.4-1.89s2.4,.84,2.4,1.89-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89Zm2.4,9.44c1.32,0,2.4,.84,2.4,1.89s-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89,1.07-1.89,2.4-1.89Zm5.75-5.67c1.32,0,2.4,.84,2.4,1.89s-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89,1.07-1.89,2.4-1.89Zm-2.4-3.78c0-1.04,1.07-1.89,2.4-1.89s2.4,.84,2.4,1.89-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89Zm2.4,9.44c1.32,0,2.4,.84,2.4,1.89s-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89,1.07-1.89,2.4-1.89Zm5.75-5.67c1.32,0,2.4,.84,2.4,1.89s-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89,1.07-1.89,2.4-1.89Zm-2.4-3.78c0-1.04,1.07-1.89,2.4-1.89s2.4,.84,2.4,1.89-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89Zm2.4,9.44c1.32,0,2.4,.84,2.4,1.89s-1.08,1.89-2.4,1.89-2.4-.84-2.4-1.89,1.07-1.89,2.4-1.89Z"
          fill="#fff"
        />
        <rect x="14" y="12" width="21" height="8" fill="#00bed5" />
      </svg>
      <span>{children}</span>
    </button>
  );
}

HowButton.propTypes = {
  children: oneOfType([element, func, node]),
  showInfo: func,
};
