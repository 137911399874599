import { array, func, number, objectOf, shape, string } from "prop-types";

import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";

import trackEvent from "../tracking/track-event";
import useModalScrollbarOffset from "../../utilities/use-modal-scrollbar-offset";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";
import HowButton from "./HowButton";
import ModalNetChangeCalculated from "./ModalNetChangeCalculated";
import ModalNetChangeMedical from "./ModalNetChangeMedical";
import ModalNetChangeSpecialty from "./ModalNetChangeSpecialty";
import Requirement from "./Requirement";

import { Typography, UtilityIcon } from "@uhc-tempo/components";
const { H2, Paragraph } = Typography;

export default function TierRequirements({
  active,
  activeLabel,
  activeIndex,
  agencyName,
  className,
  currentYear,
  logo,
  nextYear,
  setActive,
  status,
}) {
  const [showNetChangeCalc, toggleNetChangeCalc] =
    useModalScrollbarOffset(false);
  const [showNetChangeMed, toggleNetChangeMed] = useModalScrollbarOffset(false);
  const [showNetChangeSpec, toggleNetChangeSpec] =
    useModalScrollbarOffset(false);

  const activeData = (!!status && status?.tiers[active]) || {};
  const hasNetChange =
    !!activeData.ReqMedicalNetChangePercent ||
    !!activeData.ReqSpecialtyNetChangePercent;

  function handleNext() {
    const nextTier =
      status.availableTiers[activeIndex + 1] || status.availableTiers[0];
    setActive(nextTier);
    trackEvent({
      action: "status_tier_requirements_nav",
      params: { link_text: nextTier, method: "next" },
    });
  }

  function handlePrev() {
    const prevTier =
      status.availableTiers[activeIndex - 1] ||
      status.availableTiers[status.availableTiers.length - 1];
    setActive(prevTier);
    trackEvent({
      action: "status_tier_requirements_nav",
      params: { link_text: prevTier, method: "prev" },
    });
  }

  const baseClass = "ua-req";
  let sectionClass = baseClass;

  if (!!className) {
    sectionClass += ` ${className}`;
  }

  return (
    <>
      <div
        aria-describedby="tier-active"
        aria-live="polite"
        className={sectionClass}
        id="tier-requirements"
        role="region"
      >
        <div className={`${baseClass}--inner`}>
          <H2 className="ua-req--headline" id="tier-headline">
            Tier Requirement(s)
          </H2>
          <div className="ua-req--tier">
            <div className="ua-req--tier--status">
              <button
                aria-controls="tier-requirements"
                aria-label="Previous"
                className="ua-req--tier--nav"
                onClick={handlePrev}
                type="button"
              >
                <UtilityIcon
                  color="primaryLight"
                  icon={NavigateBefore}
                  size="small"
                  title="Previous"
                />
              </button>
              <h3
                id="tier-active"
                className={`ua-req--tier--level ua-text-${active}`}
              >
                <strong>{activeLabel}</strong> Status
              </h3>
              <button
                aria-controls="tier-requirements"
                aria-label="next"
                className="ua-req--tier--nav"
                onClick={handleNext}
                type="button"
              >
                <UtilityIcon
                  color="primaryLight"
                  icon={NavigateNext}
                  size="small"
                  title="Next"
                />
              </button>
            </div>
          </div>
          <div className="ua-req--has">
            <AgencyLogo name={agencyName} src={logo} width={240} height={114} />
            <Paragraph className="ua-req--date" id="tier-has">
              your agency <strong>HAS</strong>{" "}
              <em className="ua-text-gray-six">as of {status.DataDateFull}</em>
            </Paragraph>
          </div>
          <div className="ua-req--needs">
            <Paragraph className="ua-req--date" id="tier-needs">
              your agency <strong>NEEDS</strong>{" "}
              <em>by January 1{!!nextYear && `, ${nextYear}`}</em>
            </Paragraph>
          </div>
          <Requirement
            current={status.MedicalMembers}
            index={0}
            label="membership"
            labelCurrent="inforce"
            labelTarget="inforce"
            remaining={activeData.NeedMembers}
            target={activeData.ReqMembers}
            tier={active}
          >
            UHC Membership
          </Requirement>
          {!!activeData.ReqMedicalNetChangePercent && (
            <Requirement
              current={status.MedicalNetChangeMembers}
              index={1}
              label="net change"
              labelCurrent={status.MedicalNetChangePercentFormatted}
              labelTarget={activeData.ReqMedicalNetChangePercentFormatted}
              remaining={activeData.NeedMedicalNetChange}
              showInfo={toggleNetChangeMed}
              showSign={true}
              target={activeData.ReqMedicalNetChangeMembers}
              tier={active}
            >
              <strong>Medical</strong> <small>Net Change</small>
            </Requirement>
          )}
          {!!activeData.ReqSpecialtyNetChangePercent && (
            <Requirement
              current={status.SpecialtyNetChangeMembers}
              index={2}
              label="net change"
              labelCurrent={status.SpecialtyNetChangePercentFormatted}
              labelTarget={activeData.ReqSpecialtyNetChangePercentFormatted}
              remaining={activeData.NeedSpecialtyNetChange}
              showInfo={toggleNetChangeSpec}
              showSign={true}
              target={activeData.ReqSpecialtyNetChangeMembers}
              tier={active}
            >
              <strong>Specialty</strong> <small>Net Change</small>
            </Requirement>
          )}
          {hasNetChange && (
            <HowButton showInfo={toggleNetChangeCalc}>
              How is our agency’s{" "}
              <span className="ua-text-nowrap">
                <strong>net change</strong> calculated?
              </span>
            </HowButton>
          )}
        </div>
      </div>
      {!!activeData.ReqMedicalNetChangePercent && (
        <ModalNetChangeMedical
          delta={activeData.ReqMedicalNetChangePercentDelta}
          formatted={activeData.ReqMedicalNetChangePercentFormatted}
          handleOpen={toggleNetChangeMed}
          open={showNetChangeMed}
          ratio={activeData.ReqMedicalNetChangePercent}
          tier={activeLabel}
        />
      )}
      {!!activeData.ReqSpecialtyNetChangePercent && (
        <ModalNetChangeSpecialty
          delta={activeData.ReqSpecialtyNetChangePercentDelta}
          formatted={activeData.ReqSpecialtyNetChangePercentFormatted}
          handleOpen={toggleNetChangeSpec}
          open={showNetChangeSpec}
          ratio={activeData.ReqSpecialtyNetChangePercent}
          tier={activeLabel}
        />
      )}
      {hasNetChange && (
        <ModalNetChangeCalculated
          agencyName={agencyName}
          currentYear={currentYear}
          handleOpen={toggleNetChangeCalc}
          logo={logo}
          open={showNetChangeCalc}
          status={status}
        />
      )}
    </>
  );
}

TierRequirements.propTypes = {
  active: string,
  activeLable: string,
  activeIndex: number,
  agencyName: string,
  className: string,
  logo: string,
  nextYear: number,
  setActive: func.isRequired,
  status: shape({
    availableTiers: array,
    DataDateFull: string,
    CurrentUATier: string,
    CurrentUATierClass: string,
    MedicalMembers: number,
    MedicalNetChangeMembers: number,
    MedicalNetChangePercentFormatted: string,
    ProjectedUATier: string,
    ProjectedUATierClass: string,
    SpecialtyNetChangeMembers: number,
    SpecialtyNetChangePercentFormatted: string,
    tiers: objectOf(
      shape({
        NeedMembers: number,
        NeedSpecialtyNetChange: number,
        ReqMedicalNetChangeMembers: number,
        ReqMedicalNetChangePercent: number,
        ReqMedicalNetChangePercentDelta: string,
        ReqMedicalNetChangePercentFormatted: string,
        NeedMedicalNetChange: number,
        ReqMembers: number,
        ReqSpecialtyNetChangeMembers: number,
        ReqSpecialtyNetChangePercent: number,
        ReqSpecialtyNetChangePercentDelta: string,
        ReqSpecialtyNetChangePercentFormatted: string,
      })
    ),
  }),
};
