import ROUTES from "../../../data/constants/routes";

import TempoRouterLink from "../../../components/tempo-router-link/TempoRouterLink";

import ForgotPasswordForm from "../../../features/auth/ForgotPasswordForm";

import { Typography } from "@uhc-tempo/components";
const { H1, Link, Paragraph } = Typography;

export default function ForgotPassword(props) {
  return (
    <section className="fluid-container page-main__fill ua-bg-supporting-one-opacity-ten ua-padding-jumbo-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="auth-content ua-col-span-3-8-lg ua-col-span-2-10-md ua-col-span-1-12-sm">
          <H1 className="ua-text-center" headingStyle={2}>
            {ROUTES.forgotPassword.title}
          </H1>
          <Paragraph
            bodyStyle={1}
            className="ua-text-center tds-margin-xxlg-bottom"
          >
            To <strong>request a password reset</strong>, please enter your
            email address{" "}
            <span className="ua-text-newline-md">
              associated with your{" "}
              <span className="ua-text-nowrap">United Advantage</span> account.
            </span>
          </Paragraph>
          <div className="auth-content__inner tds-padding-xxlg-top">
            <ForgotPasswordForm />
            <footer className="ua-text-center">
              <Paragraph>
                <TempoRouterLink
                  className="tds-margin-xlg-horizontal"
                  component={Link}
                  to={ROUTES.register.path}
                >
                  Register for access
                </TempoRouterLink>
                <TempoRouterLink
                  className="tds-margin-xlg-horizontal"
                  component={Link}
                  to={ROUTES.login.path}
                >
                  Back to login page
                </TempoRouterLink>
              </Paragraph>
            </footer>
          </div>
        </div>
      </div>
    </section>
  );
}
