import { UA_API_URL_STORAGE } from "../data/ua-api/ua-api-definitions";

export default function getCleanStorageUrl(url) {
  if (!url) {
    return "";
  }

  const regEx = new RegExp(`${UA_API_URL_STORAGE}/`, "gi");
  const clean = url.replace(regEx, "");

  return `${UA_API_URL_STORAGE}/${clean}`;
}
