import sendToGA4 from "./send-to-ga4";
import { HOST_ENV } from "../../data/constants/urls";

/**
 * Reference:
 * https://create-react-app.dev/docs/measuring-performance/
 * https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
 */
export default function trackWebVitals({ name, delta, value, id }) {
  if (!window.gtag || HOST_ENV === "development") {
    return;
  }

  sendToGA4(name, {
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
  });
}
