import Loop from "@mui/icons-material/Loop";

import { Typography, UtilityIcon } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function PageLoadingIndicator() {
  return (
    <section className="fluid-container ua-padding-xxxxlg-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-1-12-sm ua-col-span-2-10-md">
          <Paragraph
            bodyStyle={1}
            className="ua-padding-xxxxlg-vertical ua-text-center"
          >
            <UtilityIcon
              className="tds-margin-xsm-right"
              color="currentColor"
              icon={Loop}
            />
            Loading…
          </Paragraph>
        </div>
      </div>
    </section>
  );
}
