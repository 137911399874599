export default function getTitleClass(
  most_popular,
  unavailable,
  isSelected,
  isMultiple,
  isStacked = true
) {
  const titleClassBase = "shopping-benefit__title";
  const titleClassStacked = `${titleClassBase}--${
    isStacked ? "stacked" : "inline"
  }`;
  let titleClass = `${titleClassStacked}`;

  if (!!most_popular) {
    titleClass += ` ${titleClassStacked}--popular`;
  }

  if (!most_popular && isMultiple) {
    titleClass += ` ${titleClassStacked}--multiple`;
  }

  if (unavailable && !isSelected) {
    titleClass += ` ${titleClassBase}--unavailable`;
  }

  return titleClass;
}
