import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import UA_API, { UA_API_EP_BENEFITS } from "../../data/ua-api/ua-api";
import { isLogout } from "../auth/AuthRedux";
import normalizeBenefitsResponse from "./normalize-benefits-response";

const initialStateBenefits = [];
const initialStateBenefitTypes = [];

export const getBenefits = createAsyncThunk(
  "benefits/get",
  async function fetchBenefits(data, { rejectWithValue }) {
    const { agency_id, catalog_year } = data;
    try {
      const response = await UA_API.get(
        `${UA_API_EP_BENEFITS}?agency_id=${agency_id}&catalog_year=${catalog_year}`
      );
      const [benefitsStatus, benefitsObj] = normalizeBenefitsResponse(response);
      if (benefitsStatus === "error") {
        throw new Error("Error fetching benefits");
      }
      return benefitsObj;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const resetBenefits = createAction("benefits/reset");

export const benefitsReducer = createReducer(
  initialStateBenefits,
  (builder) => {
    builder
      .addCase(
        getBenefits.fulfilled,
        (state, action) => action.payload.benefits
      )
      .addCase(resetBenefits, (state, action) => initialStateBenefits)
      .addMatcher(isLogout, (state, action) => initialStateBenefits);
  }
);

export const benefitTypesReducer = createReducer(
  initialStateBenefitTypes,
  (builder) => {
    builder
      .addCase(
        getBenefits.fulfilled,
        (state, action) => action.payload.benefitTypes
      )
      .addMatcher(isLogout, (state, action) => initialStateBenefitTypes);
  }
);

export const benefitsErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getBenefits.pending, (state, action) => null)
    .addCase(getBenefits.fulfilled, (state, action) => null)
    .addCase(getBenefits.rejected, (state, action) => action.payload);
});

export const benefitsLoadingReducer = createReducer(true, (builder) => {
  builder
    .addCase(getBenefits.pending, (state, action) => true)
    .addCase(getBenefits.fulfilled, (state, action) => false)
    .addCase(getBenefits.rejected, (state, action) => false);
});
