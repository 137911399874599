import { arrayOf, bool, shape, string } from "prop-types";

import HtmlContent from "../../components/html-content/HtmlContent";

import { Accordion, AccordionGroup } from "@uhc-tempo/components";

export default function ShoppingDetailFaqs({
  className = "ua-margin-sm-bottom",
  compact,
  faqs = [],
  transparent,
}) {
  if (!faqs.length) {
    return null;
  }

  let combinedClass = className;

  if (compact) {
    combinedClass += " ua-accordion-compact";
  }

  if (transparent) {
    combinedClass += " ua-accordion-transparent";
  }

  return (
    <AccordionGroup allowMultipleOpen={false} className={combinedClass}>
      {faqs.map(({ question, answer }, index) => (
        <Accordion key={`${question} ${index}`} title={question}>
          <HtmlContent rawHtml={answer} />
        </Accordion>
      ))}
    </AccordionGroup>
  );
}

ShoppingDetailFaqs.propTypes = {
  className: string,
  compact: bool,
  faqs: arrayOf(
    shape({
      question: string.isRequired,
      answer: string.isRequired,
    })
  ),
  transparent: bool,
};
