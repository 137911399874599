import { Typography, BrandIcon } from "@uhc-tempo/components";
import { IconLightBulb } from "@uhc-tempo/icons";

import ROUTES from "../../../data/constants/routes";
import useTrainingAvailable from "../../../features/training/use-training-available";

import PageLoadingIndicator from "../../../components/page-loading-indicator/PageLoadingIndicator";
import TrainingIndex from "../../../features/training/TrainingIndex";

const { H1, Paragraph } = Typography;

export default function Training() {
  const [checkInProgress, trainingAvailable] = useTrainingAvailable();

  if (checkInProgress || !trainingAvailable) {
    return <PageLoadingIndicator />;
  }

  return (
    <section>
      <div className="fluid-container ua-padding-xxxxlg-vertical ua-bg-supporting-one-opacity-ten">
        <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
          <div className="ua-col-span-1-12-sm ua-col-span-1-12-md">
            <div className="ua-display-flex">
              <BrandIcon
                size="MEDIUM"
                styleVariation="TWO_TONE_LIGHT_CIRCLE"
                icon={IconLightBulb}
              />
              <div className="tds-margin-lg-left tds-margin-md-top">
                <H1 className="tds-margin-md-bottom">
                  {ROUTES.training.title}
                </H1>
                <Paragraph bodyStyle={1}>
                  UnitedHealthcare partners with you to better understand your
                  clients&rsquo; needs and the changing health benefit
                  landscape. Enroll in one of the below training offerings, and
                  even earn continuing education credits for your efforts.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fluid-container ua-padding-xxxxlg-vertical">
        <TrainingIndex />
      </div>
    </section>
  );
}
