import getFormattedCallToActionLink from "../call-to-action/get-formatted-call-to-action-link";
import uniqueArray from "../../utilities/unique-array";

export default function normalizeTrainingResponse(response, user) {
  try {
    const { data } = response;
    let { status, message, success, data: trainings } = data || {};

    if (!data || !status || (!!status && status !== "success")) {
      throw new Error("Incorrect response format");
    }

    if (!trainings || !Array.isArray(trainings) || !trainings.length) {
      if (!!message && !success) {
        throw new Error(message);
      } else {
        throw new Error("No training data");
      }
    }

    trainings = uniqueArray(trainings).map((training) =>
      formatTraining(training, user)
    );

    return ["success", trainings, message || "success"];
  } catch (err) {
    return ["error", null, err.message || "Unexpected response"];
  }
}

function formatTraining(training, user) {
  if (!!training.training_data && !!training.training_data.length) {
    if (training.training_data.length > 1) {
      training.training_data.sort(sortByStartDate);
    }

    const hasAlternative =
      training.training_data.length > 1 &&
      training.training_data.some(({ status }) => status === "open");

    training.training_data = training.training_data.map(
      (trainingSession, i) => {
        const isLast = i + 1 === training.training_data.length;
        return formatTraingingSession(
          trainingSession,
          training.name,
          hasAlternative,
          isLast,
          user
        );
      }
    );
  }

  return training;
}

function formatTraingingSession(
  trainingSession,
  trainingName,
  hasAlternative,
  isLast,
  user
) {
  const [linkType, linkFormatted] = getFormattedCallToActionLink(
    trainingSession.link,
    user
  );

  trainingSession.action = getAction(
    hasAlternative,
    isLast,
    linkType,
    trainingName,
    trainingSession.status
  );
  trainingSession.linkFormatted = linkFormatted;
  trainingSession.linkType = linkType;

  return trainingSession;
}

function getAction(hasAlternative, isLast, linkType, name, status) {
  const isNotify = status === "notify";
  const isWaitList = ["closed", "full"].includes(status);
  const isUnavailable = isNotify || isWaitList;

  if (!isLast && isUnavailable && hasAlternative) {
    return null;
  }

  if (isNotify) {
    return "notify";
  }

  if (isWaitList) {
    return "waitlist";
  }

  const isOpen = status === "open";

  if (isOpen && linkType === "internal") {
    return "internal";
  }

  if (isOpen && linkType === "external") {
    return "external";
  }

  return isOpen ? "contact" : null;
}

function sortByStartDate(a, b) {
  if (a.start_date === b.start_date) {
    return 0;
  } else if (!a.start_date) {
    return 1;
  } else if (!b.start_date) {
    return -1;
  } else {
    return a.start_date - b.start_date;
  }
}
