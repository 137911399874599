import { createAsyncThunk, createReducer, isAnyOf } from "@reduxjs/toolkit";

import UA_API, {
  UA_API_AUTHORIZE,
  UA_API_DEAUTHORIZE,
  UA_API_EP_AUTH_LOGIN,
  UA_API_EP_AUTH_LOGOUT,
} from "../../data/ua-api/ua-api";

import {
  setBonusShoppingRequired,
  setShoppingRequired,
} from "../shopping/ShoppingRedux";
import {
  getAgencies,
  getAgencyUsers,
  getCatalogYears,
  getMarkets,
} from "../admin/AdminRedux";
import { getUser, setUser } from "../user/UserRedux";
import { setCatalogYearCurrent } from "../catalogs/CatalogsRedux";
import normalizeAuthResponse from "./normalize-auth-response";
import formatUserProperties from "./format-user-properties";
import trackLogin from "../tracking/track-login";

const initialState = {
  access_token: null,
  capabilities: [],
  eventUserProperties: null,
  message: null,
  hasAuth: false,
  userProperties: null,
};

const AUTH_TYPES = {
  login: "auth/login",
  loginWithAdminToken: "auth/token-admin",
  loginWithUserToken: "auth/token-user",
  logout: "auth/logout",
};

export const login = createAsyncThunk(
  AUTH_TYPES.login,
  async function handleLogin(
    {
      data = {},
      forceBonusShopping = undefined,
      forcePrompt = false,
      forceShoppingRequired = undefined,
      forceShowComingSoon = false,
    },
    { dispatch, rejectWithValue }
  ) {
    try {
      let requestUrl = UA_API_EP_AUTH_LOGIN;

      if (forceBonusShopping !== undefined) {
        requestUrl += `?forceBonusShopping=${forceBonusShopping}`;
      } else if (forcePrompt) {
        requestUrl += "?forcePrompt=true";
      } else if (forceShoppingRequired !== undefined) {
        requestUrl += `?forceShoppingRequired=${forceShoppingRequired}`;
      } else if (forceShowComingSoon) {
        requestUrl += "?forceShowComingSoon=true";
      }

      const response = await UA_API.post(requestUrl, data);
      let [
        authStatus,
        auth,
        user,
        currentYear,
        shoppingRequired,
        bonusShoppingRequired,
        authMessage,
      ] = normalizeAuthResponse(response);

      if (authStatus === "error") {
        throw new Error(authMessage || "Unexpected response");
      }

      // TEMPORARY WORKAROUND FOR BONUS SHOPPING TESTING
      // UNTIL API SUPPORTS TEST METHOD
      if (forceBonusShopping !== undefined) {
        bonusShoppingRequired = !!forceBonusShopping;
      }

      // TEMPORARY WORKAROUND FOR COMING SOON TESTING
      // UNTIL API SUPPORTS TEST METHOD
      if (forceShowComingSoon) {
        user.showComingSoon = true;
      }

      // TEMPORARY WORKAROUND FOR SHOPPING REQUIRED TESTING
      // UNTIL API SUPPORTS TEST METHOD
      if (forceShoppingRequired !== undefined) {
        shoppingRequired = forceShoppingRequired;
      }

      // Save authenticated user properties for analytics
      const [userProperties, eventUserProperties] = formatUserProperties(user);

      auth.userProperties = userProperties;
      auth.eventUserProperties = eventUserProperties;

      UA_API_AUTHORIZE(auth.access_token);
      dispatch(setBonusShoppingRequired(bonusShoppingRequired));
      dispatch(setShoppingRequired(shoppingRequired));
      dispatch(setUser(user));
      dispatch(
        setCatalogYearCurrent({
          year: currentYear,
        })
      );

      trackLogin(auth.userProperties, auth.eventUserProperties);

      return auth;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const loginWithAdminToken = createAsyncThunk(
  AUTH_TYPES.loginWithAdminToken,
  async function handleLoginWithAdminToken(
    { access_token, admin_user_id, agency_id, user_id },
    { dispatch, rejectWithValue }
  ) {
    try {
      if (!access_token) {
        throw new Error("No access token provided");
      }

      if (!admin_user_id) {
        throw new Error("No admin user specified");
      }

      const auth = { access_token };

      UA_API_AUTHORIZE(access_token);

      const catalog_years = await dispatch(getCatalogYears())
        .unwrap()
        .catch((err) => {
          throw new Error(err);
        });

      const catalogYearCurrent = !!catalog_years.length
        ? catalog_years[catalog_years.length - 1]?.name
        : new Date().getFullYear();

      await dispatch(
        setCatalogYearCurrent({
          year: catalogYearCurrent,
        })
      );

      const admin_user = await dispatch(
        getUser({ id: admin_user_id, isAdmin: true })
      )
        .unwrap()
        .catch((err) => {
          throw new Error(err);
        });

      if (!admin_user || !admin_user?.capabilities) {
        throw new Error("Admin user not found");
      }

      auth.capabilities = [...admin_user.capabilities];

      // Save authenticated user properties for analytics
      const [userProperties, eventUserProperties] =
        formatUserProperties(admin_user);
      auth.userProperties = userProperties;
      auth.eventUserProperties = eventUserProperties;

      let impersonate_user;

      if (!!user_id) {
        try {
          impersonate_user = await dispatch(
            getUser({ id: user_id, isAdmin: true })
          )
            .unwrap()
            .catch((err) => {
              throw new Error(err);
            });
        } catch (err) {
          impersonate_user = undefined;
        }
      }

      await dispatch(getMarkets());

      if (!impersonate_user && !!agency_id) {
        try {
          const agencyUsers = await dispatch(getAgencyUsers({ agency_id }))
            .unwrap()
            .catch((err) => {
              throw new Error(err);
            });
          impersonate_user = await dispatch(
            getUser({ id: agencyUsers[0]?.id, isAdmin: true })
          ).unwrap();
        } catch (err) {}
      }

      const market_id = !!impersonate_user
        ? impersonate_user?.agency?.market_id
        : admin_user?.agency?.market_id;
      const tier = !!impersonate_user
        ? impersonate_user?.agency?.level
        : admin_user?.agency?.level;

      await dispatch(getAgencies({ market_id, tier }));

      if (!!impersonate_user) {
        await dispatch(setShoppingRequired(false));
      } else {
        await dispatch(getAgencyUsers({ agency_id: admin_user?.agency_id }));
      }

      trackLogin(auth.userProperties, auth.eventUserProperties, "UA API Token");

      return auth;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const loginWithUserToken = createAsyncThunk(
  AUTH_TYPES.loginWithUserToken,
  async function handleLoginWithUserToken(
    { access_token, user_id },
    { dispatch, rejectWithValue }
  ) {
    try {
      if (!access_token) {
        throw new Error("No access token provided");
      }

      if (!user_id) {
        throw new Error("No user specified");
      }

      const auth = { access_token };

      UA_API_AUTHORIZE(access_token);

      const user = await dispatch(getUser({ id: user_id }))
        .unwrap()
        .catch((err) => {
          throw new Error(err);
        });

      auth.capabilities = [...user.capabilities];

      // Save authenticated user properties for analytics
      const [userProperties, eventUserProperties] = formatUserProperties(user);
      auth.userProperties = userProperties;
      auth.eventUserProperties = eventUserProperties;

      const year = user?.currentYear || new Date().getFullYear();
      dispatch(setCatalogYearCurrent({ year }));

      const shoppingRequired = user?.shoppingRequired || false;
      dispatch(setShoppingRequired(shoppingRequired));

      const bonusShoppingRequired = user?.bonusShoppingRequired || false;
      dispatch(setBonusShoppingRequired(bonusShoppingRequired));

      trackLogin(auth.userProperties, auth.eventUserProperties, "UA API Token");

      return auth;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const logout = createAsyncThunk(
  AUTH_TYPES.logout,
  async function handleLogout(trigger, { getState, rejectWithValue }) {
    let message = "Logged out";

    try {
      let { auth } = getState();

      if (!!auth?.access_token) {
        const response = await UA_API.get(UA_API_EP_AUTH_LOGOUT);

        if (!trigger && !!response?.data?.message) {
          message = response.data.message;
        }
      }

      UA_API_DEAUTHORIZE();

      return message;
    } catch (err) {
      UA_API_DEAUTHORIZE();
      return rejectWithValue(message);
    }
  }
);

export function isLogout(action) {
  return [
    `${AUTH_TYPES.login}/rejected`,
    `${AUTH_TYPES.loginWithAdminToken}/rejected`,
    `${AUTH_TYPES.loginWithUserToken}/rejected`,
    `${AUTH_TYPES.logout}/fulfilled`,
    `${AUTH_TYPES.logout}/rejected`,
  ].some((type) => type === action.type);
}

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(
      isAnyOf(
        login.fulfilled,
        loginWithAdminToken.fulfilled,
        loginWithUserToken.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
        hasAuth: true,
        message: null,
      })
    )
    .addMatcher(isLogout, (state, action) => ({
      ...initialState,
      message: action.payload,
    }));
});

export const adminTokenLoadingReducer = createReducer(false, (builder) => {
  builder
    .addCase(loginWithAdminToken.pending, (state, action) => true)
    .addCase(loginWithAdminToken.fulfilled, (state, action) => false)
    .addMatcher(isLogout, (state, action) => false);
});

export const userTokenLoadingReducer = createReducer(false, (builder) => {
  builder
    .addCase(loginWithUserToken.pending, (state, action) => true)
    .addCase(loginWithUserToken.fulfilled, (state, action) => false)
    .addMatcher(isLogout, (state, action) => false);
});
