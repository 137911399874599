import { useMemo, useState } from "react";
import { bool, func, number, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import trackEvent from "../tracking/track-event";
import { updateShoppingHidden } from "./ShoppingRedux";

import ButtonInline from "../../components/button-inline/ButtonInline";

import { Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

const hideBaseClass = "shopping-benefit--hide";

export default function ShoppingBenefitHideButton({ id, name, onError, show }) {
  const hidden = useSelector((state) => state.shoppingHidden || []);
  const [inProgress, setInProgress] = useState(false);
  const visibilityClass = useMemo(
    () => (show || inProgress ? `${hideBaseClass}__visible` : ""),
    [show, inProgress]
  );

  const dispatch = useDispatch();

  function handleHide() {
    setInProgress(true);

    const update = [...new Set([...hidden, id])];

    dispatch(updateShoppingHidden(update))
      .unwrap()
      .then((response) => {
        setInProgress(false);

        trackEvent({
          action: "benefit_hide",
          relatedId: id,
          params: {
            benefit_id: id,
            benefit_name: name,
          },
        });
      })
      .catch((err) => {
        setInProgress(false);
        if (!!onError) {
          onError(err.message || "Error");
        }
      });
  }

  return (
    <div>
      <Paragraph
        bodyStyle={4}
        className="ua-display-block ua-margin-none ua-padding-sm-top ua-text-center ua-width-100"
        style={{ height: "30px" }}
      >
        <ButtonInline
          className={`button-inline__alt shopping-benefit--hide ${visibilityClass}`}
          disable={inProgress}
          onClick={handleHide}
        >
          {inProgress ? "Hiding…" : "Hide perk"}
        </ButtonInline>
      </Paragraph>
    </div>
  );
}

ShoppingBenefitHideButton.propTypes = {
  id: number.isRequired,
  name: string,
  onError: func.isRequired,
  show: bool,
};
