import { useMemo } from "react";

import ROUTES from "../../data/constants/routes";

function getBenefitRootPath() {
  const path = ROUTES.benefits.path;
  return !!path ? path.split("/:")[0] : "";
}

export default function useBenefitRootPath() {
  const rootPath = useMemo(() => getBenefitRootPath(), []);
  return rootPath;
}
