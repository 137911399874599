import { useState } from "react";
import { func, string } from "prop-types";
import { motion } from "framer-motion";
import Icon from "@mdi/react";
import { mdiClose, mdiMagnify } from "@mdi/js";

import HtmlContent from "../html-content/HtmlContent";

import { Typography } from "@uhc-tempo/components";
const { H1 } = Typography;

export default function FlipCard({ content, image, title, onFlipStart }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  function handleFlip() {
    if (!isAnimating) {
      setIsFlipped(!isFlipped);
      setIsAnimating(true);
    }

    // Callback for analytics tracking, etc.
    if (!isFlipped && !!onFlipStart) {
      onFlipStart();
    }
  }

  return (
    <div className="flip-card">
      <motion.div
        className="flip-card__inner"
        initial={false}
        animate={{ rotateY: isFlipped ? 180 : 360 }}
        transition={{ duration: 0.5, animationDuration: "normal" }}
        onAnimationComplete={() => setIsAnimating(false)}
      >
        <div
          className="flip-card__front ua-display-flex ua-align-items-flex-end"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 38, 119, 0.10) 31.14%, #002677 100%), url(${image})`,
          }}
        >
          <div className="ua-width-100 ua-display-flex ua-grid-gap-3-sm ua-align-items-flex-end ua-justify-content-space-between">
            <H1
              headingLevel={4}
              className="flip-card__front-title ua-margin-none ua-text-primary-three"
            >
              {title}
            </H1>
            <div>
              <button className="flip-card__icon-btn" onClick={handleFlip}>
                <Icon path={mdiMagnify} size="34px" />
              </button>
            </div>
          </div>
        </div>
        <div className="flip-card__back ua-bg-primary-one">
          <button className="flip-card__close-btn" onClick={handleFlip}>
            <Icon path={mdiClose} size="32px" />
          </button>
          <div>
            <H1 headingLevel={4} className="ua-text-primary-three">
              {title}
            </H1>
            <div className="ua-text-primary-three">
              <HtmlContent rawHtml={content} />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

FlipCard.propTypes = {
  content: string.isRequired,
  image: string.isRequired,
  onFlipStart: func,
  title: string.isRequired,
};
