import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import UA_API, {
  UA_API_SHOPPING_EP_DATA,
  UA_API_SHOPPING_EP_METHOD,
  UA_API_SHOPPING_EP_SELECTIONS,
} from "../../data/ua-api/ua-api";
import { isLogout } from "../auth/AuthRedux";
import normalizeShoppingDataResponse from "./normalize-shopping-data-response";
import normalizeShoppingHiddenResponse from "./normalize-shopping-hidden-response";
import normalizeShoppingSelectionsResponse from "./normalize-shopping-selections-response";

const initialState = {
  benefits: [],
  benefitTypes: [],
  dateClosed: null,
  pointsAvailable: null,
};

const initialStateMethod = null; // choose
const initialStateShoppingRequired = false;
const initialStateBonusShoppingRequired = false;
const initialStateHidden = [];
const initialStateSelections = [];
const initialFilters = {
  filterBy: null,
  sortBy: "",
};

export const getShoppingData = createAsyncThunk(
  "shopping/data",
  async function fetchShoppingData(_, { getState, rejectWithValue }) {
    try {
      const { bonusShoppingRequired } = getState();
      const bonusParam = bonusShoppingRequired ? "true" : "false";
      const response = await UA_API.get(
        `${UA_API_SHOPPING_EP_DATA}?bonus=${bonusParam}`
      );
      let [shoppingDataStatus, shoppingData, message] =
        normalizeShoppingDataResponse(response);

      if (shoppingDataStatus === "error") {
        throw new Error(message);
      }

      return shoppingData;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const setBonusShoppingRequired = createAction("shopping/bonus");

export const setShoppingRequired = createAction("shopping/required");

export const updateShoppingHidden = createAsyncThunk(
  "shopping/hidden",
  async function putShoppingHidden(
    hiddenBenefits,
    { getState, rejectWithValue }
  ) {
    try {
      const { shoppingSelections: selections = [] } = getState();

      const response = await UA_API.put(UA_API_SHOPPING_EP_SELECTIONS, {
        hiddenBenefits,
        selections,
      });

      let [hiddenStatus, hiddenFromServer, message] =
        normalizeShoppingHiddenResponse(response);

      if (hiddenStatus === "error") {
        throw new Error(message);
      }

      return hiddenFromServer;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateShoppingMethod = createAsyncThunk(
  "shopping/method",
  async function postShoppingMethod(method, { rejectWithValue }) {
    try {
      const { data = {} } = await UA_API.put(UA_API_SHOPPING_EP_METHOD, {
        method,
      });

      if (
        !data ||
        !data.status ||
        (!!data.status && data.status !== "success")
      ) {
        throw new Error("Incorrect response format");
      }

      return method;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const updateShoppingSelections = createAsyncThunk(
  "shopping/selections",
  async function putShoppingSelections(selections, { rejectWithValue }) {
    try {
      const response = await UA_API.put(UA_API_SHOPPING_EP_SELECTIONS, {
        selections,
      });
      let [selectionsStatus, selectionsFromServer, message] =
        normalizeShoppingSelectionsResponse(response);

      if (selectionsStatus === "error") {
        throw new Error(message);
      }

      return selectionsFromServer;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const shoppingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getShoppingData.fulfilled, (state, action) => ({
      ...state,
      benefits: action.payload?.benefits || [],
      benefitTypes: action.payload?.benefitTypes || [],
      dateClosed: action.payload?.dateClosed,
      pointsAvailable: action.payload?.pointsAvailable,
    }))
    .addMatcher(isLogout, (state, action) => initialState);
});

export const bonusShoppingRequiredReducer = createReducer(
  initialStateBonusShoppingRequired,
  (builder) => {
    builder
      .addCase(
        setBonusShoppingRequired,
        (state, action) => !!action.payload || false
      )
      .addMatcher(
        isLogout,
        (state, action) => initialStateBonusShoppingRequired
      );
  }
);

export const shoppingHiddenReducer = createReducer(
  initialStateHidden,
  (builder) => {
    builder
      .addCase(
        getShoppingData.fulfilled,
        (state, action) => action.payload?.hiddenBenefits || []
      )
      .addCase(
        updateShoppingHidden.fulfilled,
        (state, action) => action.payload || []
      )
      .addMatcher(isLogout, (state, action) => initialStateHidden);
  }
);

export const shoppingMethodReducer = createReducer(
  initialStateMethod,
  (builder) => {
    builder
      .addCase(
        updateShoppingMethod.fulfilled,
        (state, action) => action.payload
      )
      .addCase(
        getShoppingData.fulfilled,
        (state, action) => action.payload?.method
      )
      .addMatcher(isLogout, (state, action) => initialStateMethod);
  }
);

export const shoppingRequiredReducer = createReducer(
  initialStateShoppingRequired,
  (builder) => {
    builder
      .addCase(setShoppingRequired, (state, action) => ({
        required: !!action.payload || false,
      }))
      .addMatcher(isLogout, (state, action) => ({
        required: initialStateShoppingRequired,
      }));
  }
);

export const shoppingSelectionsReducer = createReducer(
  initialStateSelections,
  (builder) => {
    builder
      .addCase(
        getShoppingData.fulfilled,
        (state, action) => action.payload?.selections || []
      )
      .addCase(
        updateShoppingSelections.fulfilled,
        (state, action) => action.payload || []
      )
      .addMatcher(isLogout, (state, action) => initialStateSelections);
  }
);

export const setFilterBy = createAction("shopping/filterBy");
export const setSortBy = createAction("shopping/sortBy");
export const shoppingFilterSortReducer = createReducer(
  initialFilters,
  (builder) => {
    builder
      .addCase(setFilterBy, (state, action) => ({
        ...state,
        filterBy: action.payload || null,
      }))
      .addCase(setSortBy, (state, action) => ({
        ...state,
        sortBy: action.payload || "",
      }))
      .addMatcher(isLogout, (state, action) => initialFilters);
  }
);
