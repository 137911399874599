import { useEffect, useMemo, useState } from "react";
import { string } from "prop-types";

import tokens from "@uhc-tempo/components/tokens/token_modules/colors";

export default function AgencyLogo({ className = "", name, src, ...rest }) {
  const [display, setDisplay] = useState(!!src);

  function handleError() {
    setDisplay(false);
  }

  // Reset display state if image source changes
  useEffect(() => {
    setDisplay(!!src);
  }, [src]);

  if (!display && !name) {
    return null;
  }

  if (!display && !!name) {
    const color = tokens.color.primary.one.value;
    const height = 164;
    const width = 344;

    return (
      <svg
        className={`agency-logo-fallback ${className}`}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Text
          fill={color}
          fontSize={28}
          height={height}
          text={name}
          textAnchor="middle"
          x={width / 2}
          y={0}
        />
      </svg>
    );
  }

  return (
    <img
      {...rest}
      alt={`${name} logo`}
      className={className}
      onError={handleError}
      src={src}
    />
  );
}

function Text({ height, fontSize, text, width, x, y, ...rest }) {
  const lineHeight = fontSize * 1.10344828;
  const maxLines = Math.floor(height / lineHeight);
  const offset = height - maxLines * lineHeight;

  const [lines, count] = useMemo(() => {
    const l = text.match(/\b[^ ]+(\s+[^ ]+)?/g).slice(0, maxLines);
    return [l, l.length];
  }, [text, maxLines]);

  const yFirst = lineHeight + (maxLines - count) * (lineHeight / 2) - offset;

  return (
    <text {...rest} fontSize={fontSize} x={x} y={y}>
      {lines.map((line, index) => (
        <tspan
          alignmentBaseline="auto"
          key={`${line} ${index}`}
          textAnchor="middle"
          x={x}
          y={!index ? yFirst : null}
          dy={!!index ? lineHeight : null}
        >
          {line}
        </tspan>
      ))}
    </text>
  );
}

AgencyLogo.propTypes = {
  agencyName: string,
  className: string,
  src: string,
};
