import { useSelector } from "react-redux";

export default function useUserProfile() {
  let user = useSelector(({ user }) => user);

  const userProfile = {
    id: user.id || 0,
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    agency_role: user.agency_role || "",
    email: user.email || "",
    phone: user.phone || "",
    mobile_phone: user.mobile_phone || "",
  };

  return userProfile;
}
