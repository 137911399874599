import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

import useAuthStatus from "../auth/use-auth-status";
import MainMenu from "./MainMenu";
import ProfileMenu from "./ProfileMenu";
import { Typography, UtilityIcon } from "@uhc-tempo/components";
import useClickOutside from "../../components/popup-menu/use-click-outside";
const { Paragraph } = Typography;

export default function MobileNavigation() {
  const drawerRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const [hasAuth] = useAuthStatus();

  function openMenu() {
    setMenuOpen(true);
    document.body.classList.add("drawer-open");
  }

  function closeMenu() {
    setMenuOpen(false);
    document.body.classList.remove("drawer-open");
  }

  const location = useLocation();
  const currentPath = useRef(location.pathname);

  useEffect(() => {
    // close the drawer if we've changed location
    if (currentPath.current) {
      if (currentPath.current.pathname !== location.pathname) {
        closeMenu();
      }
    }
  }, [location, currentPath]);

  useClickOutside(drawerRef, closeMenu);

  return hasAuth ? (
    <div className="drawer ua-flex-grow ua-display-flex ua-align-items-center ua-justify-content-flex-end">
      <button className="drawer--menu-button" onClick={openMenu}>
        <UtilityIcon
          cursor="pointer"
          icon={MenuIcon}
          color="primaryLight"
          title="Menu"
          description="Opens the menu"
          size="large"
        />
        <Paragraph
          bodyStyle={4}
          className="ua-text-primary-three tds-margin-none-bottom"
        >
          Menu
        </Paragraph>
      </button>
      <div
        className="drawer--container"
        aria-expanded={menuOpen}
        ref={drawerRef}
      >
        <div className="drawer--header">
          <Paragraph className="tds-margin-none-bottom">
            Navigation Menu
          </Paragraph>
          <button className="drawer--close" onClick={closeMenu}>
            <UtilityIcon
              icon={CloseIcon}
              color="primaryCTA"
              title="Close menu"
              size="medium"
            />
          </button>
        </div>
        <div className="drawer--content">
          <div className="drawer--profile tds-padding-xxlg">
            <UtilityIcon
              className="tds-margin-xsm-right"
              icon={AccountCircle}
              color="primary"
              title="Profile"
              size="medium"
            />
            <Paragraph
              bodyStyle={3}
              useBold
              className="ua-display-inline ua-text-gray-seven"
            >
              {user?.first_name} {user?.last_name}
            </Paragraph>
            <Paragraph
              bodyStyle={4}
              className="tds-margin-none-bottom tds-margin-xxlg-right"
            >
              {user?.agency?.name}
            </Paragraph>
            <Paragraph
              bodyStyle={4}
              className="tds-margin-none-bottom tds-margin-xxlg-right"
            >
              UA Agency ID: {user?.agency?.ra_number}
            </Paragraph>
          </div>
          <MainMenu className="mobile-nav" />
          <ProfileMenu className="mobile-nav" />
        </div>
      </div>
      {menuOpen && <div className="drawer--backdrop"></div>}
    </div>
  ) : null;
}
