import { string } from "prop-types";

import ResourceDownloadsButton from "./ResourceDownloadsButton";

import { Typography } from "@uhc-tempo/components";
const { H4, Paragraph } = Typography;

export default function ResourceDownloadsItem({ description, link, title }) {
  return (
    <div className="resource-list--downloads--item">
      <H4 className="ua-margin-xsm-bottom tds-text__paragraph-large">
        {title}
      </H4>
      <Paragraph bodyStyle={1}>{description}</Paragraph>
      <ResourceDownloadsButton link={link} title={title} />
    </div>
  );
}

ResourceDownloadsItem.propTypes = {
  description: string,
  link: string,
  title: string,
};
