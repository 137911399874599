import { useRef, useState } from "react";
import { string } from "prop-types";
import { useNavigate } from "react-router";

import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import validateInput from "../../utilities/validate-input";

import { Button, Form } from "@uhc-tempo/components";
const { ErrorMessage, Group, Input, Label, LargeErrorMessage } = Form;

const initData = { id: "" };

export default function BeginRegistrationForm({ className = "" }) {
  const [data, setData] = useState(initData);
  const [errors, setErrors] = useState(initData);
  const [formError, setFormError] = useState(null);

  const navigate = useNavigate();
  const formErrorAlert = useRef();

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }

    const error = validateInput(input);
    setErrors((prevErrors) => ({ ...prevErrors, [input.name]: error }));
  }

  function displayError(errorMessage) {
    setFormError(errorMessage);
    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 50);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    navigate(`/register/register-user?ra_number=${data.id}`);
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  return (
    <Form className={className} noValidate onSubmit={handleSubmit}>
      {!!formError && (
        <div ref={formErrorAlert}>
          <LargeErrorMessage className="tds-margin-xxlg-bottom">
            {formError}
          </LargeErrorMessage>
        </div>
      )}
      <Group
        className="tds-margin-xxlg-bottom"
        groupId="id"
        state={getFormElementState(errors.id)}
      >
        <Label>United Advantage ID*</Label>
        <Input
          autoCapitalize="none"
          autoCorrect="off"
          className="ua-display-block ua-width-100"
          name="id"
          onBlur={handleInput}
          onChange={handleInput}
          required
          type="text"
          value={data.id}
        />
        {!!errors.id && <ErrorMessage>{errors.id}</ErrorMessage>}
      </Group>
      <div className="tds-padding-xxlg-top">
        <Button
          buttonType="primary-one"
          className="tds-margin-xxlg-bottom ua-display-block ua-width-100"
          type="submit"
        >
          Begin registration
        </Button>
      </div>
    </Form>
  );
}

BeginRegistrationForm.propTypes = {
  className: string,
};
