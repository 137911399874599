import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const selectBenefits = createSelector(
  (state) => state.shopping?.benefits || [],
  (state) => state.shopping?.pointsAvailable || 0,
  (state) => state.shoppingSelections || [],
  (benefits, pointsAvailable, selections) => {
    const { selectedBenefits, pointsRemaining } = benefits.reduce(
      ({ selectedBenefits, pointsRemaining }, benefit) => {
        const selection = selections.find((s) => s.id === benefit.id);

        if (!!selection) {
          const selectedValue =
            benefit.allowMultiple === "amount"
              ? selection.qty // sometimes qty means amount :shrug:
              : benefit.points * selection.qty;

          selectedBenefits.push({
            ...benefit,
            selectedValue,
            qty: selection.qty,
          });
          pointsRemaining = pointsRemaining - selectedValue;
        }

        return { selectedBenefits, pointsRemaining };
      },
      { selectedBenefits: [], pointsRemaining: pointsAvailable }
    );
    return { selections, selectedBenefits, pointsRemaining };
  }
);

export default function useSelectedBenefits() {
  const { selections, selectedBenefits, pointsRemaining } = useSelector(
    (state) => selectBenefits(state)
  );
  return { selections, selectedBenefits, pointsRemaining };
}
