import { string } from "prop-types";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";

import { Card, Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function StatusNotFound({ logo, agencyName }) {
  return (
    <div className="ua-margin-auto ua-margin-jumbo-bottom ua-max-width-90 ua-padding-xxxxlg-top">
      <Card
        border
        layout="custom"
        className="ua-text-center ua-border-radius-sm ua-box-shadow ua-padding-xlg-top ua-status-message"
      >
        <AgencyLogo
          className="ua-display-block ua-margin-auto"
          name={agencyName}
          src={logo}
          width={288}
          height={137}
        />
        <div className="ua-padding-xxxlg-bottom ua-padding-xlg-top">
          <Paragraph bodyStyle={1} className="ua-text-primary-one">
            For your agency’s latest{" "}
            <span className="ua-text-nowrap">
              UA status projection contact your
            </span>{" "}
            <span className="ua-text-nowrap">
              UnitedHealthcare sales rep today!
            </span>
          </Paragraph>
        </div>
      </Card>
    </div>
  );
}

StatusNotFound.propTypes = {
  logo: string,
  agencyName: string,
};
