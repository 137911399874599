export default function normalizeAgencyUsersResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    let users = data?.users || data?.data;

    if ((!users || !Array.isArray(users)) && Object.keys(data).length > 3) {
      users = Object.keys(data)
        .filter(
          (key) => !["message", "status", "success", "data"].includes(key)
        )
        .map((key) => ({
          id: parseInt(key, 10),
          name: data[key],
        }));
    }

    if (!users || !Array.isArray(users) || !users.length) {
      throw new Error("No users found");
    }

    let message = data.message || "Success!";

    return ["success", users, message];
  } catch (err) {
    return ["error", [], err.message || "Unexpected response"];
  }
}
