import { useSelector } from "react-redux";
import { shape, string } from "prop-types";

import HtmlContent from "../../components/html-content/HtmlContent";
import NotFound from "../../pages/not-found";
import PageLoadingIndicator from "../../components/page-loading-indicator/PageLoadingIndicator";
import { Typography } from "@uhc-tempo/components";
const { H1 } = Typography;

export default function StaticPage({ page }) {
  const { content, description, title } = page || {};
  const loading = useSelector((state) => state.staticPagesLoading);

  if (loading) {
    return <PageLoadingIndicator />;
  }

  if (!loading && !page) {
    return <NotFound />;
  }

  return (
    <section className="fluid-container ua-padding-xxxxlg-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-1-12-sm ua-col-span-2-10-md">
          {!!title && (
            <H1>
              <HtmlContent rawHtml={title} />
            </H1>
          )}
          {!!description && <HtmlContent rawHtml={description} />}
          {!!content && <HtmlContent rawHtml={content} />}
        </div>
      </div>
    </section>
  );
}

StaticPage.propTypes = {
  page: shape({
    content: string,
    description: string,
    title: string,
  }),
};
