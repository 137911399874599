import { shape, string } from "prop-types";
import dayjs from "dayjs";

import { Typography } from "@uhc-tempo/components";

const { Paragraph } = Typography;

export default function TrainingDate({ className = "", training = {} }) {
  let displayDate = null;
  if (training.date_override) {
    displayDate = training.date_override;
  } else if (training.start_date && training.end_date) {
    displayDate =
      training.start_date === training.end_date
        ? dayjs(training.end_date).format("MMM D, YYYY")
        : `${dayjs(training.start_date).format("MMM D")} -
      ${dayjs(training.end_date).format("MMM D, YYYY")}`;
  } else if (training.start_date && !training.end_date) {
    displayDate = dayjs(training.start_date).format("MMM D, YYYY");
  } else {
    displayDate = "Anytime";
  }

  return (
    <div className={className}>
      <Paragraph
        className="ua-text-primary-one tds-margin-none-bottom"
        bodyStyle={2}
        useBold
      >
        {displayDate}
      </Paragraph>
      {training.topic && <Paragraph>{training.topic}</Paragraph>}
      {training.status === "closed" && (
        <Paragraph bodyStyle={4} useItalic>
          Registration closed
        </Paragraph>
      )}
      {training.status === "full" && (
        <Paragraph bodyStyle={4} useItalic>
          Class full
        </Paragraph>
      )}
    </div>
  );
}

TrainingDate.propTypes = {
  training: shape({
    date_override: string,
    end_date: string,
    start_date: string,
    status: string,
    topic: string,
  }),
};
