import { useEffect } from "react";

export default function useMetaTag({ name, property, content }) {
  useEffect(() => {
    let tag;

    try {
      const selector = !!property ? `property="${property}"` : `name="${name}"`;
      tag = document.querySelector(`meta[${selector}]`);

      if (!tag) {
        tag = document.createElement("meta");

        if (!!property) {
          tag.property = `${property}`;
        } else {
          tag.name = `${name}`;
        }

        document.getElementsByTagName("head")[0].appendChild(tag);
      }

      tag.setAttribute("content", `${content}`);
    } catch (err) {}

    return () => {
      if (!!tag) {
        try {
          tag.parentNode.removeChild(tag);
        } catch (err) {}
      }
    };
  }, [name, property, content]);
}
