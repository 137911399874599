import {
  UA_API_URL_STORAGE_DIR,
  UA_API_URL_STORAGE,
} from "../data/ua-api/ua-api-definitions";

export default function getApiStorageUrl(url) {
  if (!url || typeof url !== "string") {
    return "";
  }

  if (url.includes("http")) {
    return url;
  }

  const storageDir = new RegExp(`^(${UA_API_URL_STORAGE_DIR}|/)`);
  const safeUrl = url.replace(storageDir, "");

  return `${UA_API_URL_STORAGE}/${safeUrl}`;
}
