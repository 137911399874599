import { onCLS, onFID, onFCP, onINP, onLCP, onTTFB } from "web-vitals";
import { HOST_ENV } from "../../data/constants/urls";

export default function reportWebVitals(onPerfEntry) {
  if (
    HOST_ENV !== "development" &&
    onPerfEntry &&
    onPerfEntry instanceof Function
  ) {
    onCLS(onPerfEntry);
    onFID(onPerfEntry);
    onFCP(onPerfEntry);
    onINP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
}
