export default function processResponse(response) {
  let { data = {}, message } = { ...response };

  if (typeof data === "string" && data === "") {
    data = {};
  }

  if (!!data?.data && !Array.isArray(data.data)) {
    data = { ...data, ...data.data };
    delete data.data;
  }

  if (!data.status) {
    data.status = "success";
  }

  if (!data.message || (typeof data === "string" && data !== "")) {
    data.message = message || "Success";
  }

  return { ...response, data };
}
