import { Typography } from "@uhc-tempo/components";
import trackLinkClick from "../../features/tracking/track-link-click";

import HomePageHeroX1 from "../../assets/img/home-hero.png";
import HomePageHeroX2 from "../../assets/img/home-hero@2x.png";
import IllustrationMedal from "../../assets/img/illustration-medal.svg";

const { H1, H2, H4, Paragraph } = Typography;

export default function Home() {
  return (
    <div className="home">
      <div className="home__wrapper ua-margin-auto ua-full-height ">
        <div className="home__hero-wrapper ua-bg-primary-one ua-display-flex">
          <div className="ua-display-flex ua-flex-column ua-flex-row-md ua-grid-gap-4-md">
            <div className="home__hero-img ua-display-flex ua-flex-column ua-justify-content-end ua-order-1 ua-order-0-md">
              <img
                alt="Best-in-class service & perks."
                src={HomePageHeroX1}
                srcSet={`${HomePageHeroX1} 1x, ${HomePageHeroX2} 2x`}
                width={650}
              />
            </div>
            <div className="home__hero-copy ua-order-0 ua-order-1-md ua-align-self-center">
              <H1 className="ua-text-primary-three home__heading">
                Best-in-class service & perks.
              </H1>
              <H4 className="home__hero-subtitle ua-text-primary-three ua-font-weight-normal ua-margin-xxxxlg-bottom">
                UnitedHealthcare celebrates top agencies with{" "}
                <strong className="ua-text-nowrap">
                  United Advantage&reg;
                </strong>{" "}
                status and benefits.
              </H4>
              <H4 className="home__hero-subtitle-2 ua-text-supporting-one ua-font-weight-normal ua-margin-none">
                <strong className="ua-font-weight-bold">
                  Agency scorecards are now being emailed.
                </strong>{" "}
                Didn’t get yours?{" "}
                <span className="ua-text-nowrap">
                  Email{" "}
                  <a
                    className="ua-text-supporting-one"
                    href="mailto:unitedadvantage@uhc.com"
                    onClick={() =>
                      trackLinkClick({
                        event: "email_support",
                        label: "Email",
                        link: "mailto:unitedadvantage@uhc.com",
                        triggered_from: "home-logged-out",
                      })
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    unitedadvantage@uhc.com
                  </a>
                </span>{" "}
                to request yours.
              </H4>
            </div>
          </div>
        </div>

        <div className="home__content-wrapper ua-bg-supporting-one-opacity-ten ua-display-flex ua-flex-column ua-align-items-center">
          <H2 className="ua-font-sans ua-text-center ua-font-size-h5 ua-font-size-h1-md ua-width-100 ua-margin-xxxxlg-bottom">
            Have questions about your <strong>United&nbsp;Advantage</strong>
            &nbsp;status?
          </H2>

          <div className="home__content-blurb ua-display-flex ua-flex-column ua-bg-primary-three ua-border-radius-sm ua-padding-xxxxlg-vertical ua-padding-jumbo-horizontal ua-align-items-center ua-justify-content-center">
            <img
              alt="medal illustration"
              className="ua-margin-lg-bottom"
              height={100}
              src={IllustrationMedal}
              width={100}
            />
            <Paragraph className="home__content-blurb-heading ua-margin-none">
              Let&apos;s connect.
            </Paragraph>
            <Paragraph className="home__content-blurb-copy ua-text-center ua-margin-none">
              Contact your local UHC sales team or&nbsp;
              <a
                className="ua-text-gray-six"
                href="mailto:unitedadvantage@uhc.com"
                onClick={() =>
                  trackLinkClick({
                    event: "email_support",
                    label: "Email",
                    link: "mailto:unitedadvantage@uhc.com",
                    triggered_from: "home-logged-out",
                  })
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                email us
              </a>{" "}
              your questions.
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
}
