import { arrayOf, object } from "prop-types";

import ResourceMetaItem from "./ResourceMetaItem";

export default function ResourceMeta({ meta }) {
  if (!meta || !meta.length) {
    return null;
  }

  return (
    <div>
      {meta.map((item, i) => (
        <ResourceMetaItem key={`meta-${i}`} {...item} />
      ))}
    </div>
  );
}

ResourceMeta.propTypes = {
  meta: arrayOf(object),
};
