import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export default function ScrollRestoration() {
  let navigationType = useNavigationType();
  let { pathname } = useLocation();

  useEffect(() => {
    // Scroll back to top of page on navigation event
    // if the user didn't use the browser forward/back button
    // or their browser doesn't have native scroll restoration
    if (navigationType === "PUSH" || !window.history?.scrollRestoration) {
      if (window.scrollY > 0) {
        window.scrollTo(0, 0);
      }
    }
  }, [navigationType, pathname]);

  return null;
}
