import { bool, func, string } from "prop-types";

import { Button, Typography, PopupTip } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function ShoppingBenefitSelectButton({
  allowMultiple,
  disabled,
  isSelected,
  loading,
  onRemove,
  onSelect,
  size = "md",
  text = "Select",
  unavailable,
}) {
  const isMultiple = allowMultiple === "quantity" || allowMultiple === "amount";

  if (isSelected && isMultiple) {
    return (
      <Button
        buttonType={disabled ? "disabled" : "secondary-one"}
        disabled={disabled}
        loading={loading}
        onClick={onRemove}
        size={size}
      >
        Edit
      </Button>
    );
  }

  if (isSelected && !isMultiple) {
    return (
      <Button
        buttonType={disabled ? "disabled" : "secondary-one"}
        disabled={disabled}
        loading={loading}
        onClick={onRemove}
        size={size}
      >
        {loading ? "Removing…" : "Remove"}
      </Button>
    );
  }

  if (unavailable) {
    return (
      <PopupTip
        className="ua-bg-gray-two ua-border ua-border-color-gray-two ua-padding-lg-horizontal ua-padding-sm-vertical ua-text-left"
        labelText={
          <span className="tds-text__paragraph--bold ua-text-gray-five">
            Unavailable
          </span>
        }
        placement="bottom"
        pointer={1}
        size={size}
        title="Not enough points remaining"
      >
        <Paragraph bodyStyle={3} className="tds-margin-none">
          Remove one or more of your selected perks in order to select this
          perk.
        </Paragraph>
      </PopupTip>
    );
  }

  if (!isSelected && isMultiple) {
    return (
      <Button
        buttonType={disabled ? "disabled" : "primary-one"}
        disabled={disabled}
        loading={loading}
        onClick={onSelect}
        size={size}
      >
        {text}
      </Button>
    );
  }

  return (
    <Button
      buttonType={disabled ? "disabled" : "primary-one"}
      disabled={disabled}
      loading={loading}
      onClick={onSelect}
      size={size}
    >
      {loading ? "Selecting…" : text}
    </Button>
  );
}

ShoppingBenefitSelectButton.propTypes = {
  allowMultiple: string,
  disabled: bool,
  isSelected: bool,
  loading: bool,
  onRemove: func.isRequired,
  onSelect: func.isRequired,
  text: string,
  unavailable: bool,
};
