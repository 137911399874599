import { useState } from "react";
import { func } from "prop-types";
import { useDispatch } from "react-redux";
import { ListAlt } from "@mui/icons-material";

import trackEvent from "../tracking/track-event";
import { updateShoppingHidden } from "./ShoppingRedux";
import useHiddenBenefits from "./use-hidden-benefits";

import ButtonInline from "../../components/button-inline/ButtonInline";

import { Typography, UtilityIcon } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function ShoppingBenefitsUnhideButton({ onError }) {
  const [inProgress, setInProgress] = useState(false);
  const [hidden, hiddenCount] = useHiddenBenefits();

  const dispatch = useDispatch();

  function handleShow() {
    setInProgress(true);

    dispatch(updateShoppingHidden([]))
      .unwrap()
      .then((response) => {
        setInProgress(false);

        trackEvent({
          action: "benefit_show_hidden",
          params: { hidden: hidden.join(", "), hidden_count: hiddenCount },
        });
      })
      .catch((err) => {
        setInProgress(false);
        if (!!onError) {
          onError(err.message || "Error");
        }
      });
  }

  function getLabel() {
    if (inProgress) {
      return "Showing hidden perks…";
    } else {
      return (
        <>
          <span className="button-inline__partial--underline ua-text-nowrap">
            Show hidden perks
          </span>
          <span className="ua-text-no-underline"> ({hiddenCount})</span>
        </>
      );
    }
  }

  if (!hiddenCount) {
    return null;
  }

  return (
    <Paragraph className="ua-align-self-center ua-margin-none ua-padding-lg-left">
      <ButtonInline
        className="button-inline__partial ua-display-flex ua-align-items-center"
        disabled={inProgress}
        onClick={handleShow}
      >
        <UtilityIcon
          className="ua-margin-xsm-right"
          color="currentColor"
          icon={ListAlt}
        />
        {getLabel()}
      </ButtonInline>
    </Paragraph>
  );
}

ShoppingBenefitsUnhideButton.propTypes = {
  onError: func.isRequired,
};
