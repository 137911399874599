import { Typography } from "@uhc-tempo/components";

import {
  SCREEN_MEDIUM,
  SCREEN_LARGE,
} from "../../data/constants/media-queries";
import useUserAgency from "../../features/user/use-user-agency";
import useMatchMedia from "../../features/page-layout/use-match-media";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";
import HomeBenefitsCards from "../../features/dashboard/HomeBenefitsCards";

const { H2 } = Typography;

export default function Dashboard() {
  const { logo, name } = useUserAgency();
  const isScreenMd = useMatchMedia(SCREEN_MEDIUM);
  const isScreenLg = useMatchMedia(SCREEN_LARGE);

  return (
    <section className="fluid-container home--bg-ribbon">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12 ua-align-items-center home--section">
        <div className="ua-col-span-1-12-sm ua-col-span-1-4-md">
          <AgencyLogo
            className="home--logo"
            name={name}
            src={logo}
            width={344}
            height={164}
          />
        </div>
        <div className="ua-col-span-1-12-sm ua-col-span-8-5-md ua-col-span-8-5-lg">
          <H2 className="ua-margin-none" headingStyle={isScreenLg ? 2 : 3}>
            Exclusive perks and insights for our top-performing
            {isScreenMd && <br />} partner agencies
          </H2>
        </div>
      </div>
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <HomeBenefitsCards />
      </div>
    </section>
  );
}
