import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../../data/constants/routes";
import { setBonusShoppingRequired } from "../../../features/shopping/ShoppingRedux";
import trackEvent from "../../../features/tracking/track-event";
import ThumbsUpIllustration from "../../../assets/img/thumbs-up-illustration.svg";

import { Button, Typography } from "@uhc-tempo/components";
const { H1, Paragraph } = Typography;

export default function ShoppingBonusConfirmationNow(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clicked = useRef(false);

  const handleClick = useCallback(
    ({ skipRedirect = false }) => {
      clicked.current = true;
      dispatch(setBonusShoppingRequired(false));
      trackEvent({ action: "go_to_live_site_from_bonus_now" });

      if (!skipRedirect) {
        navigate(ROUTES.default.path);
      }
    },
    [dispatch, navigate]
  );

  // Just in case the user doesn't use the CTA button
  // to navigate away from this page
  useEffect(() => {
    return () => {
      if (!clicked.current) {
        handleClick({ skipRedirect: true });
      }
    };
  }, [handleClick]);

  return (
    <section className="fluid-container page-main__fill ua-padding-jumbo-vertical ua-bg-primary-one-extra-light">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="ua-col-span-1-12 ua-padding-xxlg-horizontal">
          <div className="ua-bg-primary-three ua-border ua-border-radius-sm ua-border-color-gray-four ua-padding-xxxxlg-top ua-padding-jumbo-bottom ua-text-center">
            <div className="ua-width-70 ua-margin-auto ua-padding-md-bottom ua-padding-xlg-top">
              <img
                src={ThumbsUpIllustration}
                alt="Thumbs up"
                width="130"
                height="95"
              />
              <H1
                className="ua-margin-xlg-bottom ua-margin-xxxxlg-top"
                headingStyle={2}
              >
                Start using your perks{" "}
                <u className="ua-text-underline-light">immediately</u>.
              </H1>
              <Paragraph
                bodyStyle={1}
                className="ua-margin-xxxxlg-bottom ua-display-text"
              >
                You’re just a click away from your
                <br />
                agency’s custom United Advantage website portal.
              </Paragraph>
              <Paragraph>
                <Button type="button" onClick={handleClick}>
                  Visit your site now
                </Button>
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
