import dayjs from "dayjs";

import getLevelName from "../../utilities/get-level-name";
import getLevelClass from "../../utilities/get-level-class";

export default function normalizeStatusDataResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    const { message, status, ...rawData } = data;

    if (!rawData.CurrentUATier || !rawData.ProjectedUATier) {
      throw new Error("Unexpected response format");
    }

    // Group and format raw data
    let statusData = Object.keys(rawData).reduce(
      (acc, key) => {
        // Group tier-specific values, if applicable
        const found = key.match(
          /^(Gold|Platinum|Diamond|Premier\+|Premier|Preferred)(.+)$/i
        );

        if (found) {
          const tierKey = getLevelClass(found[1]);
          const formatted = getFormatted(found[2], rawData[key]);
          acc.tiers[tierKey] = { ...acc.tiers[tierKey], ...formatted };

          // Update tiers available to this agency
          if (
            [
              "ReqMembers",
              "ReqMedicalNetChangePercent",
              "ReqSpecialtyNetChangePercent",
            ].includes(found[2]) &&
            !acc.availableTiers.includes(tierKey) &&
            !!acc.tiers[tierKey][found[2]]
          ) {
            acc.availableTiers.push(tierKey);
          }
        }

        // Otherwise, add formatted data
        else {
          const formatted = getFormatted(key, rawData[key]);
          acc = { ...acc, ...formatted };
        }

        return acc;
      },
      { availableTiers: [], tiers: { level_1: {}, level_2: {}, level_3: {} } }
    );

    if (!statusData.availableTiers.length) {
      throw new Error("Missing available tiers");
    }

    return ["success", statusData, message || "success"];
  } catch (err) {
    return ["error", null, err.message || "Unexpected response"];
  }
}

function getFormatted(key, value) {
  // Handle "NULL" strings
  if (`${value}`.toUpperCase() === "NULL") {
    value = null;
  }

  // Convert numeric strings to numbers
  if (typeof value === "string" && !isNaN(value)) {
    value = value.trim();

    // Make sure value isn't an empty string
    // before converting to a number
    const float = parseFloat(value);

    if (!isNaN(float)) {
      value = float;
    }
  }

  let formatted = { [key]: value };

  // Lowercase tier names to make easier to use in class names
  // and add current tier label
  if (/^.+UATier$/i.test(key)) {
    formatted[`${key}Class`] = getLevelClass(value);
    formatted[`${key}Label`] = getLevelName(value);
  }

  if (key === "DataDate" && !!value) {
    const dateObj = dayjs(value);
    formatted[`${key}Full`] = dateObj.format("MMMM D, YYYY");
    formatted[`${key}Slash`] = dateObj.format("M/D/YYYY");
  }

  // Add formatted strings for percents, which are actually ratios,
  // and deal with multiplying by floating point decimals
  if (/^.+Percent$/i.test(key) && !!value && !isNaN(value)) {
    const percent = Math.round(value * 100);
    formatted[`${key}Formatted`] = `${percent}%`;
    formatted[`${key}Delta`] = `${percent - 100}%`;
  }

  // Round values that are not percentages down
  // to the nearest whole number
  if (/^.+(Members|NetChange)$/i.test(key) && !!value && !isNaN(value)) {
    formatted[key] = Math.floor(value);
  }

  return formatted;
}
