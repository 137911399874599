import { useMemo } from "react";
import { useSelector } from "react-redux";

export const sortOptionsObj = {
  customOrder: { label: "Relevance", value: "custom_order" },
  pointsHigh: { label: "Points: high to low", value: "points_high" },
  pointsLow: { label: "Points: low to high", value: "points_low" },
  popular: { label: "Most popular", value: "popular" },
};

function getFilteredBenefits(benefits = [], filterBy) {
  return !!filterBy
    ? benefits.filter(({ benefit_type_id }) => benefit_type_id === filterBy)
    : benefits;
}

function getSortedBenefits(benefits = [], sortBy) {
  return [...benefits].sort(sortBenefits(sortBy));
}

function getVisibleBenefits(benefits = [], hidden = []) {
  return !!benefits.length
    ? benefits.filter(({ id }) => !hidden.includes(id))
    : benefits;
}

function sortBenefits(sortBy) {
  switch (sortBy) {
    case sortOptionsObj.pointsHigh.value:
      return sortByPointsHigh;
    case sortOptionsObj.pointsLow.value:
      return sortByPointsLow;
    case sortOptionsObj.popular.value:
      return sortByPopular;
    case sortOptionsObj.customOrder.value:
    default:
      return sortByCustomOrder;
  }
}

function sortByCustomOrder(a, b) {
  if (a.sort_order === b.sort_order) {
    return sortByTitle(a, b);
  } else if (!a.sort_order && a.sort_order !== 0) {
    return 1;
  } else if (!b.sort_order && b.sort_order !== 0) {
    return -1;
  } else {
    return a.sort_order - b.sort_order;
  }
}

function sortByPointsHigh(a, b) {
  return b.points - a.points;
}

function sortByPointsLow(a, b) {
  return a.points - b.points;
}

function sortByPopular(a, b) {
  if (a.most_popular === b.most_popular) {
    return sortByTitle(a, b);
  } else {
    return a.most_popular ? -1 : 1;
  }
}

function sortByTitle(a, b) {
  // Intl.Collator makes case-insensitive
  return new Intl.Collator("en", { sensitivity: "base" }).compare(
    a.title,
    b.title
  );
}

export default function useFilteredAndSortedBenefits({ sortBy, filterBy }) {
  const benefits = useSelector((state) => state.shopping?.benefits || []);
  const hidden = useSelector((state) => state.shoppingHidden || []);
  const visibleBenefits = useMemo(
    () => getVisibleBenefits(benefits, hidden),
    [benefits, hidden]
  );
  const sortedBenefits = useMemo(
    () => getSortedBenefits(visibleBenefits, sortBy),
    [visibleBenefits, sortBy]
  );
  const filteredBenefits = useMemo(
    () => getFilteredBenefits(sortedBenefits, filterBy),
    [sortedBenefits, filterBy]
  );

  return filteredBenefits;
}
