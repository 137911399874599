import { string } from "prop-types";
import { NavLink, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import ROUTES from "../../data/constants/routes";
import { usePageLayoutContext } from "../page-layout/PageLayout";

// const { restrictedToLevels: coachLevels } = ROUTES?.coach || {};

// const showCoachSelector = createSelector(
//   ({ user }) => !!user?.agency && user.agency?.level,
//   (agencyLevel) =>
//     !coachLevels || (!!agencyLevel && coachLevels.includes(agencyLevel))
// );

const showTrainingSelector = createSelector(
  ({ user }) => !!user?.hasTraining,
  ({ user }) => user?.role,
  (hasTraining, userRole) => hasTraining && userRole === "User"
);

export default function MainMenu({ className = "" }) {
  const isHome = useMatch(ROUTES.dashboard.path);
  const isBonus = useMatch({
    path: ROUTES.shoppingBonusIntro.path,
    end: false,
  });

  const { bonusShoppingRequired, catalogYearNext, shoppingMethod, showNav } =
    usePageLayoutContext();

  // const showCoach = useSelector((state) => showCoachSelector(state));
  const showTraining = useSelector((state) => showTrainingSelector(state));

  return showNav ? (
    <div className={className}>
      <ul
        className="main-nav__menu tds-margin-none-top tds-margin-none-bottom"
        role="menubar"
      >
        <li role="none">
          <NavLink
            className={({ isActive }) =>
              isActive || isHome ? "main-nav__link active" : "main-nav__link"
            }
            end
            to={ROUTES.default.path}
            role="menuitem"
          >
            Home
          </NavLink>
        </li>
        {bonusShoppingRequired && (
          <li role="none">
            <NavLink
              className={({ isActive }) =>
                isActive || isBonus ? "main-nav__link active" : "main-nav__link"
              }
              to={
                shoppingMethod === "bonus"
                  ? ROUTES.shoppingBonusChoose.path
                  : ROUTES.shoppingBonusIntro.path
              }
              role="menuitem"
            >
              {ROUTES.shoppingBonusIntro.title}
            </NavLink>
          </li>
        )}
        {showTraining && (
          <li role="none">
            <NavLink
              className="main-nav__link"
              to={ROUTES.training.path}
              role="menuitem"
            >
              {ROUTES.training.title}
            </NavLink>
          </li>
        )}
        <li role="none">
          <NavLink
            className="main-nav__link"
            to={ROUTES.status.path}
            role="menuitem"
          >
            {catalogYearNext} {ROUTES.status.title}
          </NavLink>
        </li>
        {/* {showCoach && (
          <li role="none">
            <NavLink
              className="main-nav__link"
              to={ROUTES.coach.path}
              role="menuitem"
            >
              {ROUTES.coach.title}
            </NavLink>
          </li>
        )} */}
      </ul>
    </div>
  ) : null;
}

MainMenu.propTypes = {
  className: string,
};
