import { Link } from "react-router-dom";

import ROUTES from "../../data/constants/routes";
import { SCREEN_MEDIUM } from "../../data/constants/media-queries";
import LogoutButton from "../auth/LogoutButton";
import { usePageLayoutContext } from "../page-layout/PageLayout";
import useMatchMedia from "../page-layout/use-match-media";

export default function ProfileMenu({ className = "" }) {
  const isScreenMd = useMatchMedia(SCREEN_MEDIUM);
  const { canInvite, showNav } = usePageLayoutContext();

  if (!showNav && !isScreenMd) {
    return null;
  }

  return (
    <ul role="menu" tabIndex="-1" className={`profile--menu ${className}`}>
      {showNav && (
        <li role="none">
          <Link
            to={ROUTES.profile.path}
            className="profile--menu__item"
            role="menuitem"
          >
            {ROUTES.profile.title}
          </Link>
        </li>
      )}
      {showNav && canInvite && (
        <li role="none">
          <Link
            to={ROUTES.invite.path}
            className="profile--menu__item"
            role="menuitem"
          >
            {ROUTES.invite.title}
          </Link>
        </li>
      )}
      {isScreenMd && (
        <li
          role="none"
          className={showNav ? "profile--menu__item-logout" : null}
        >
          <LogoutButton
            className="profile--menu__item"
            role="menuitem"
            label="Logout"
          />
        </li>
      )}
    </ul>
  );
}
