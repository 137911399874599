import { bool, func } from "prop-types";

import Portal from "../../components/portal/Portal";
import ThumbsUpIllustration from "../../assets/img/thumbs-up-illustration.svg";

import { Button, Modal, Typography } from "@uhc-tempo/components";
const { Content, Footer, Header } = Modal;
const { H2 } = Typography;

export default function ShoppingUsedAllPoints({
  handleClose,
  handleSubmit,
  open,
}) {
  return (
    <Portal id="shopping-used-all">
      {open && (
        <Modal contentId="shopping-used-all-modal" handleClose={handleClose}>
          <Header />
          <Content className="ua-text-center ua-max-width-100 ua-padding-xlg-top">
            <img
              src={ThumbsUpIllustration}
              alt="Thumbs up"
              width="130"
              height="95"
            />
            <H2 headingStyle={3} className="ua-margin-xxxxlg-top">
              Nice work. You’ve used all the points.
            </H2>
          </Content>
          <Footer className="ua-justify-content-center ua-margin-xxxlg-bottom">
            <Button onClick={handleSubmit}>Review and submit</Button>
          </Footer>
        </Modal>
      )}
    </Portal>
  );
}

ShoppingUsedAllPoints.propTypes = {
  open: bool,
  handleClose: func.isRequired,
  handleSubmit: func.isRequired,
};
