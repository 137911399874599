export default function validateInput(input) {
  const { validity } = input;

  // Skip if this is not a real input
  if (!validity) {
    return undefined;
  }

  // Is required and has no value
  if (validity.valueMissing) {
    return "Required";
  }

  if (validity.patternMismatch) {
    if (input.type === "email") {
      return "Enter a valid email address";
    } else if (input.type === "tel") {
      return "Enter a valid phone number";
    } else if (input.classList.contains("zip")) {
      return "Enter a valid ZIP code";
    } else {
      return "Enter a valid format";
    }
  }

  if (
    validity.tooShort ||
    (!!input.minLength && input.value.length < input.minLength)
  ) {
    if (input.type === "password") {
      return "Enter a longer password";
    } else {
      return "Enter a longer value";
    }
  }

  const textAreaValidity = validateTextAreaPattern(input);

  if (!!textAreaValidity) {
    return textAreaValidity;
  }
}

export const validationPatterns = {
  email: "[^\\s@]+@[^\\s@]+\\.[^\\s@]+",
  emailList:
    "^[^\\s@,]+@[^\\s@,]+\\.[^\\s@,]+(,\\s*[^\\s@,]+@[^\\s@,]+\\.[^\\s@,]+)*$",
  tel: "\\(?[0-9]{3}\\)?[ -.]?[0-9]{3}[-.]?[0-9]{4}([,x]\\d*)?",
  zip: "^\\d{5}(?:[- ]\\d{4})?$",
};

function validateTextAreaPattern(input) {
  try {
    const { dataset = {}, tagName = "", value = "" } = input;

    if (!tagName || tagName.toUpperCase() !== "TEXTAREA" || !dataset?.pattern) {
      return;
    }

    let customValidity = "";

    if (!value.match(dataset.pattern)) {
      if (dataset.pattern === validationPatterns.emailList) {
        customValidity =
          "Enter a comma-separated list of valid email addresses";
      } else {
        customValidity = "Enter a valid format";
      }
    }

    input.setCustomValidity(customValidity);

    return customValidity;
  } catch (err) {
    return;
  }
}
