export default function normalizeCatalogYearsResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    let catalog_years = data?.catalog_years || data?.data;

    if (
      (!catalog_years || !Array.isArray(catalog_years)) &&
      Object.keys(data).length > 3
    ) {
      catalog_years = Object.keys(data)
        .filter(
          (key) => !["message", "status", "success", "data"].includes(key)
        )
        .map((key) => {
          const year = parseInt(data[key], 10);
          return {
            id: year,
            name: year,
          };
        })
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    if (
      !catalog_years ||
      !Array.isArray(catalog_years) ||
      !catalog_years.length
    ) {
      throw new Error("No catalog years found");
    }

    let message = data.message || "Success!";

    return ["success", catalog_years, message];
  } catch (err) {
    return ["error", [], err.message || "Unexpected response"];
  }
}
