import useMetaTag from "../../utilities/use-meta-tag";

import MastheadAlt from "../../components/masthead-alt/MastheadAlt";
import VimeoPlayer from "../../components/vimeo-player/VimeoPlayer";

const recapVideoUrl =
  "https://player.vimeo.com/video/902361742?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export default function Expectations() {
  useMetaTag({ name: "robots", content: "noindex, nofollow" });

  return (
    <div className="ua-recap ua-bg-primary-one ua-full-height">
      <header className="ua-recap--header">
        <MastheadAlt className="ua-recap--header--masthead" />
      </header>
      <div className="ua-recap--content">
        <VimeoPlayer vimeoUrl={recapVideoUrl} />
      </div>
    </div>
  );
}
