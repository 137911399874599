export default function getEnvironment(origin) {
  if (!!import.meta.env.VITE_HOST_ENV) {
    return import.meta.env.VITE_HOST_ENV;
  } else {
    switch (origin) {
      case import.meta.env.VITE_URL_PRODUCTION:
        return "production";
      default:
        return import.meta.env.MODE !== "development"
          ? "staging"
          : "development";
    }
  }
}
