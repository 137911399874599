import { useMemo } from "react";
import { string } from "prop-types";

import getLevelName from "../../utilities/get-level-name";

import PerkCarouselX1 from "../../assets/img/status-perk-carousel.jpg";
import PerkCarouselX2 from "../../assets/img/status-perk-carousel@2x.jpg";

export default function Access({ tier }) {
  const accessTierClass = tier === "level_3" ? "level_3" : "level_2";
  const accessTierLabel = useMemo(
    () => getLevelName(accessTierClass),
    [accessTierClass]
  );

  return (
    <section className="fluid-container ua-access">
      <h2 className="ua-access--headline">
        <span className={`ua-text-${accessTierClass}`}>{accessTierLabel}</span>{" "}
        gives you access to perk shopping.
      </h2>
      <p className="ua-access--lead">
        Over 20+ perks to choose from and the list is growing!
      </p>
      <img
        alt="Technology subsidies, Travel awards, Advertising packages"
        src={PerkCarouselX1}
        srcSet={`${PerkCarouselX1} 1x, ${PerkCarouselX2} 2x`}
        width={1149}
        height={380}
      />
    </section>
  );
}

Access.propTypes = {
  tier: string,
};
