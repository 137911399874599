import { arrayOf, shape, string } from "prop-types";
import ResourceList from "./ResourceList";

export default function ResourceLibrary({ library = [] }) {
  if (!library || !library.length) {
    return null;
  }

  return (
    <div className="ua-padding-md-top">
      {library.map((item) => (
        <ResourceList key={item.slug} {...item} />
      ))}
    </div>
  );
}

ResourceLibrary.propTypes = {
  library: arrayOf(
    shape({
      slug: string,
    })
  ),
};
