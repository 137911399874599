import AgencyLogo from "../../components/agency-logo/AgencyLogo";
import Requirement from "./Requirement";

import { Typography } from "@uhc-tempo/components";
const { H2, Paragraph } = Typography;

export default function TierRequirementsAlt({
  active,
  activeLabel,
  agencyName,
  className,
  currentYear,
  logo,
  nextYear,
  status,
}) {
  const activeData = (!!status && status?.tiers[active]) || {};

  const baseClass = "ua-req";
  let sectionClass = baseClass;

  if (!!className) {
    sectionClass += ` ${className}`;
  }

  return (
    <>
      <div
        aria-describedby="tier-active"
        aria-live="polite"
        className={sectionClass}
        id="tier-requirements"
        role="region"
      >
        <div className={`${baseClass}--inner`}>
          <H2 className="ua-req--headline" id="tier-headline">
            Tier Requirement(s)
          </H2>
          <div className="ua-req--tier">
            <div className="ua-req--tier--status ua-req--tier--status__center">
              <h3
                id="tier-active"
                className={`ua-req--tier--level ua-text-${active}`}
              >
                <strong>{activeLabel}</strong> Status
              </h3>
            </div>
          </div>
          <div className="ua-req--has">
            <AgencyLogo name={agencyName} src={logo} width={240} height={114} />
            <Paragraph className="ua-req--date" id="tier-has">
              your agency <strong>HAS</strong>{" "}
              <em className="ua-text-gray-six">as of {status.DataDateFull}</em>
            </Paragraph>
          </div>
          <div className="ua-req--needs">
            <Paragraph className="ua-req--date" id="tier-needs">
              your agency <strong>NEEDS</strong>{" "}
              <em>by January 1{!!nextYear && `, ${nextYear}`}</em>
            </Paragraph>
          </div>
          <Requirement
            current={status.MedicalMembers}
            hideIndex
            index={0}
            label="membership"
            labelCurrent="inforce"
            labelTarget="inforce"
            remaining={activeData.NeedMembers}
            target={activeData.ReqMembers}
            tier={active}
          >
            UHC Membership
          </Requirement>
        </div>
      </div>
    </>
  );
}
