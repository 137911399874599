import { useEffect, useRef, useState } from "react";
import { string } from "prop-types";

import UA_API, {
  UA_API_EP_REGISTER,
  UA_API_EP_AGENCY,
} from "../../data/ua-api/ua-api";

import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import trackEvent from "../tracking/track-event";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";
import { AGENCY_ROLES } from "../../data/constants/agency-roles";

import { Button, Form, Notification, Typography } from "@uhc-tempo/components";
const { ErrorMessage, Group, Input, Label, LargeErrorMessage, Select } = Form;
const { Paragraph, H2, H3 } = Typography;

const initData = {
  title: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  mobile_phone: "",
  role: "",
  password: "",
  confirm: "",
};

export default function RegisterUserForm({
  className = "",
  agency_ra_number = "0",
  agency_name = "",
}) {
  const [data, setData] = useState({ ...initData, agency_ra_number });
  const [errors, setErrors] = useState(initData);
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [agencyName, setAgencyName] = useState(agency_name);
  const [isAgencyLoading, setIsAgencyLoading] = useState(false);
  const raNumber = useRef(null);

  const formErrorAlert = useRef();
  const formSuccessAlert = useRef();

  useEffect(() => {
    async function fetchAgencyName() {
      try {
        if (
          !agency_name &&
          !!agency_ra_number &&
          agency_ra_number !== raNumber.current
        ) {
          setIsAgencyLoading(true);

          const response = await UA_API.get(
            `${UA_API_EP_AGENCY}/${agency_ra_number}`
          );

          if (!!response.data && response.data.status === "success") {
            setAgencyName(response.data.name);
          } else {
            displayError("Error fetching agency name");
          }

          setIsAgencyLoading(false);
        }
      } catch (err) {
        displayError(err.message || "Error");
        setIsAgencyLoading(false);
      }
    }

    fetchAgencyName();
  }, [agency_ra_number, agency_name, setIsAgencyLoading]);

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }

    if (field === "confirm" && input.value !== data.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm: "Passwords do not match",
      }));
    } else {
      const error = validateInput(input);
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    }
  }

  function displayError(errorMessage) {
    setFormError([errorMessage]);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function displaySuccess(successMessage) {
    trackEvent({ action: "register_user" });
    setData(initData);
    setFormError(null);
    setFormSuccess(successMessage);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formSuccessAlert.current);
    }, 50);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    setInProgress(true);

    try {
      const response = await UA_API.post(UA_API_EP_REGISTER, data);

      if (!!response.data && response.data.status === "success") {
        displaySuccess(response.data.message || "Success!");
      } else {
        displayError("Unexpected response");
      }
    } catch (err) {
      displayError(err.message || "Error");
    }
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 50);
    }
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  function dismissSuccessNotification() {
    setFormSuccess(null);
    setFormError(null);
  }

  return (
    <>
      {!isAgencyLoading && agencyName && (
        <H2 className="ua-text-center tds-margin-none-bottom" headingStyle={5}>
          {agencyName}
        </H2>
      )}
      {agency_ra_number && (
        <H3
          className="ua-text-center tds-margin-xxlg-bottom tds-padding-md-bottom"
          headingStyle={6}
        >
          AGENCY ID: {agency_ra_number}
        </H3>
      )}
      <Form className={className} noValidate onSubmit={handleSubmit}>
        {!!formError && (
          <div ref={formErrorAlert}>
            <LargeErrorMessage className="tds-margin-xxlg-bottom">
              {formError}
            </LargeErrorMessage>
          </div>
        )}
        {!!formSuccess && (
          <div ref={formSuccessAlert}>
            <Notification
              className="tds-margin-xxlg-bottom"
              dismissClickHandler={() => dismissSuccessNotification()}
              notificationType="success"
            >
              <Paragraph className="tds-margin-none">{formSuccess}</Paragraph>
            </Notification>
          </div>
        )}
        <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
          <Group
            className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="first_name"
            state={getFormElementState(errors.first_name)}
          >
            <Label>First name*</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="first_name"
              onBlur={handleInput}
              onChange={handleInput}
              required
              type="text"
              value={data.first_name}
            />
            {!!errors.first_name && (
              <ErrorMessage>{errors.first_name}</ErrorMessage>
            )}
          </Group>
          <Group
            className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="last_name"
            state={getFormElementState(errors.last_name)}
          >
            <Label>Last name*</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="last_name"
              onBlur={handleInput}
              onChange={handleInput}
              required
              type="text"
              value={data.last_name}
            />
            {!!errors.last_name && (
              <ErrorMessage>{errors.last_name}</ErrorMessage>
            )}
          </Group>
        </div>
        <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
          <Group
            className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="phone"
            state={getFormElementState(errors.phone)}
          >
            <Label>Business phone*</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="phone"
              onBlur={handleInput}
              onChange={handleInput}
              required
              pattern={validationPatterns.phone}
              type="text"
              value={data.phone}
            />
            {!!errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
          </Group>
          <Group
            className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="mobile_phone"
            state={getFormElementState(errors.mobile_phone)}
          >
            <Label>Mobile phone</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="mobile_phone"
              onBlur={handleInput}
              onChange={handleInput}
              pattern={validationPatterns.tel}
              type="tel"
              value={data.mobile_phone}
            />
            {!!errors.mobile_phone && (
              <ErrorMessage>{errors.mobile_phone}</ErrorMessage>
            )}
          </Group>
        </div>
        <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between">
          <Group
            className="tds-margin-sm-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="email"
            state={getFormElementState(errors.email)}
          >
            <Label>Email*</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="email"
              onBlur={handleInput}
              onChange={handleInput}
              required
              pattern={validationPatterns.email}
              type="email"
              value={data.email}
            />
            {!!errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </Group>
          <Group
            className="tds-margin-xxlg-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="role"
            state={getFormElementState(errors.role)}
          >
            <Label>Select a role*</Label>
            <Select
              containerProps={{
                className: "tds-form__select ua-display-block ua-width-100",
              }}
              name="role"
              onBlur={handleInput}
              onChange={handleInput}
              required
              value={data.role}
            >
              <Select.Option value="">Select a role</Select.Option>
              {AGENCY_ROLES.map((value, i) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Group>
        </div>
        <div className="ua-display-flex ua-flex-wrap ua-justify-content-space-between ua-border-top ua-border-bottom tds-padding-xxlg-top tds-margin-xxlg-bottom">
          <Group
            className="tds-margin-xxlg-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="password"
            state={getFormElementState(errors.password)}
          >
            <Label>New password*</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="password"
              onBlur={handleInput}
              onChange={handleInput}
              required
              type="password"
              value={data.password}
            />
            {!!errors.password && (
              <ErrorMessage>{errors.password}</ErrorMessage>
            )}
          </Group>
          <Group
            className="tds-margin-xxlg-bottom ua-flex-col-1-up-sm ua-flex-col-2-up-md"
            groupId="confirm"
            state={getFormElementState(errors.confirm)}
          >
            <Label>Confirm password*</Label>
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              className="ua-display-block ua-width-100"
              name="confirm"
              onBlur={handleInput}
              onChange={handleInput}
              required
              type="password"
              value={data.confirm}
            />
            {!!errors.confirm && <ErrorMessage>{errors.confirm}</ErrorMessage>}
          </Group>
        </div>
        <div className="tds-padding-xxlg-top">
          <Button
            buttonType={inProgress ? "disabled" : "primary-one"}
            className="tds-margin-xxlg-bottom ua-display-block ua-width-50 ua-margin-auto"
            loading={inProgress}
            type="submit"
          >
            {inProgress ? "Processing..." : "Submit registration request"}
          </Button>
        </div>
      </Form>
    </>
  );
}

RegisterUserForm.propTypes = {
  className: string,
  agency_name: string,
  agency_ra_number: string,
};
