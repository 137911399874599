import { number } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import BenefitsFooterAddToCartButton from "./BenefitsFooterAddToCartButton";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";
import ROUTES from "../../data/constants/routes";
import trackEvent from "../tracking/track-event";
import useFilteredAndSortedBenefits from "../shopping/use-filtered-and-sorted-benefits";
import useBenefitRootPath from "./use-benefit-root-path";
import { Link, Typography } from "@uhc-tempo/components";
const { H3 } = Typography;

export default function BenefitDetailsFooter({
  benefit,
  benefitIndex,
  handleBackTracking,
}) {
  const navigate = useNavigate();
  const benefitRootPath = useBenefitRootPath();
  const filterBy = useSelector((state) => state.shoppingFilterSort?.filterBy);
  const sortBy = useSelector((state) => state.shoppingFilterSort?.sortBy);

  const sortedAndFilteredBenefits = useFilteredAndSortedBenefits({
    filterBy,
    sortBy,
  });

  const nextBenefitSlug =
    benefitIndex + 1 > sortedAndFilteredBenefits.length - 1
      ? sortedAndFilteredBenefits[0].slug
      : sortedAndFilteredBenefits[benefitIndex + 1].slug;
  const prevBenefitsSlug =
    benefitIndex - 1 < 0
      ? sortedAndFilteredBenefits[sortedAndFilteredBenefits.length - 1].slug
      : sortedAndFilteredBenefits[benefitIndex - 1].slug;

  const handlePagination = (direction) => {
    if (direction === "prev") {
      navigate(`${benefitRootPath}/${prevBenefitsSlug}`);
      trackEvent({
        action: "benefit_pagination_prev",
        relatedId: benefit.id,
        params: {
          prevBenefitsSlug,
        },
      });
    } else {
      navigate(`${benefitRootPath}/${nextBenefitSlug}`);
      trackEvent({
        action: "benefit_pagination_next",
        relatedId: benefit.id,
        params: {
          nextBenefitSlug,
        },
      });
    }
  };

  return (
    <div className="ua-display-flex ua-bg-supporting-one-opacity-ten perks-footer">
      <button
        className="perks-footer__pagination perks-footer__pagination-prev"
        onClick={() => handlePagination("prev")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="61"
          viewBox="0 0 33 61"
        >
          <path d="M31.6186 0.685996C32.0404 1.10828 32.2773 1.68071 32.2773 2.27754C32.2773 2.87437 32.0404 3.4468 31.6186 3.86908L5.43328 30.0544L31.6186 56.2398C32.0165 56.6667 32.233 57.2314 32.2227 57.8149C32.2125 58.3984 31.9761 58.9551 31.5634 59.3677C31.1508 59.7803 30.5941 60.0167 30.0106 60.027C29.4272 60.0373 28.8625 59.8207 28.4356 59.4229L0.658658 31.646C0.236898 31.2237 -1.28407e-06 30.6513 -1.31016e-06 30.0544C-1.33625e-06 29.4576 0.236898 28.8852 0.658657 28.4629L28.4356 0.685996C28.8578 0.264235 29.4303 0.0273425 30.0271 0.0273424C30.6239 0.0273424 31.1964 0.264235 31.6186 0.685996Z" />
        </svg>
        <div className="ua-font-sans perks-footer__pagination-text">Prev</div>
      </button>
      <div className="ua-flex-grow ua-text-center perks-footer__content">
        <H3 headingStyle={1} className="ua-margin-lg-bottom">
          Want this perk?
        </H3>
        <BenefitsFooterAddToCartButton benefit={benefit} />
        <TempoRouterLink
          className="ua-text-nowrap ua-font-weight-normal perks-footer__back-link ua-text-underline"
          component={Link}
          onClick={handleBackTracking}
          text="Back to all perks"
          to={ROUTES.shoppingChoose.path}
        />
      </div>
      <button
        className="perks-footer__pagination perks-footer__pagination-next"
        onClick={() => handlePagination("next")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="61"
          viewBox="0 0 33 61"
        >
          <path d="M1.38136 59.3687C0.959596 58.9464 0.722699 58.374 0.7227 57.7771C0.7227 57.1803 0.959596 56.6079 1.38136 56.1856L27.5667 30.0002L1.38136 3.81489C0.983532 3.38795 0.766956 2.82326 0.777252 2.23979C0.787548 1.65632 1.02391 1.09963 1.43655 0.686991C1.84919 0.274352 2.40588 0.0379879 2.98935 0.0276933C3.57282 0.0173987 4.13751 0.233977 4.56445 0.631803L32.3413 28.4087C32.7631 28.831 33 29.4034 33 30.0002C33 30.5971 32.7631 31.1695 32.3413 31.5918L4.56444 59.3687C4.14216 59.7904 3.56973 60.0273 2.9729 60.0273C2.37607 60.0273 1.80364 59.7904 1.38136 59.3687Z" />
        </svg>
        <div className="ua-font-sans perks-footer__pagination-text">Next</div>
      </button>
    </div>
  );
}

BenefitDetailsFooter.propTypes = {
  benefitIndex: number.isRequired,
};
