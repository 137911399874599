export default function formatUserProperties(user) {
  try {
    if (!user) {
      throw new Error("User not defined");
    }

    let userProperties = {};

    const {
      agency,
      agency_role,
      hasPickedBenefits,
      hasTraining,
      id,
      role,
      title,
    } = user;

    if (!!agency) {
      const { level, market, name, ra_number } = agency;

      if (!!level) {
        userProperties.level = level;
      }

      if (!!ra_number) {
        userProperties.agency_id = ra_number;
      }

      if (!!name) {
        userProperties.agency_name = name;
      }

      if (!!market?.name) {
        userProperties.market = market.name;
      }

      if (!!market?.region) {
        userProperties.region = market.region;
      }
    }

    if (!!agency_role) {
      userProperties.agency_role = agency_role;
    }

    userProperties.has_picked_benefits = hasPickedBenefits || 0;
    userProperties.has_training = hasTraining || false;

    if (!!id) {
      userProperties.ua_user_id = id;
    }

    if (!!role) {
      userProperties.user_role = role;
    }

    if (!!title) {
      userProperties.job_title = title;
    }

    if (
      !!userProperties.agency_name &&
      (`${userProperties.agency_name}`.toLowerCase().includes("test") ||
        `${userProperties.agency_name}`.toLowerCase().includes("acme") ||
        `${userProperties.agency_name}`.toLowerCase().includes("sketchfolio"))
    ) {
      userProperties.user_type = "internal";
    } else if (
      !!userProperties.market &&
      userProperties.market.includes("Beta")
    ) {
      userProperties.user_type = "internal";
    } else if (
      !!userProperties.agency_id &&
      /^[uU].+$/.test(`${userProperties.agency_id}`)
    ) {
      // UHC RA numbers begin with "U" or "u"
      userProperties.user_type = "internal";
    } else {
      userProperties.user_type = "external";
    }

    const eventUserProperties = Object.entries(userProperties).reduce(
      (reKeyed, [key, value]) => {
        reKeyed[`event_user_${key}`] = value;
        return reKeyed;
      },
      {}
    );

    return [userProperties, eventUserProperties];
  } catch (err) {
    return [null, null];
  }
}
