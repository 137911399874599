import { useRef, useState } from "react";
import { string } from "prop-types";

import UA_API, { UA_API_EP_AUTH_RESET } from "../../data/ua-api/ua-api";

import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import trackEvent from "../tracking/track-event";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";

import { Button, Form, Notification, Typography } from "@uhc-tempo/components";
const { ErrorMessage, Group, Input, Label, LargeErrorMessage } = Form;
const { Paragraph } = Typography;

const initData = {
  email: "",
  password: "",
  password_confirmation: "",
  resetToken: "",
};

export default function ResetPasswordForm({
  className = "",
  resetToken = "",
  email = "",
}) {
  const [data, setData] = useState({ ...initData, email, resetToken });
  const [errors, setErrors] = useState(initData);
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const formErrorAlert = useRef();
  const formSuccessAlert = useRef();

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }

    const error = validateInput(input);
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
  }

  function displayError(errorMessage) {
    setFormError(errorMessage);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function displaySuccess(successMessage) {
    trackEvent({ action: "reset_password" });
    setData(initData);
    setFormError(null);
    setFormSuccess(successMessage);
    setInProgress(false);

    setTimeout(() => {
      scrollIfNeeded(formSuccessAlert.current);
    }, 50);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    setInProgress(true);

    try {
      const response = await UA_API.post(UA_API_EP_AUTH_RESET, {
        ...data,
        token: resetToken,
      });

      if (!!response.data && response.data.status === "success") {
        displaySuccess(response.data.message || "Success!");
      } else {
        displayError("Unexpected response");
      }
    } catch (err) {
      displayError(err.message || "Error");
    }
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 50);
    }
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  function dismissSuccessNotification() {
    setFormSuccess(null);
    setFormError(null);
  }

  return (
    <Form className={className} noValidate onSubmit={handleSubmit}>
      {!!formError && (
        <div ref={formErrorAlert}>
          <LargeErrorMessage className="tds-margin-xxlg-bottom">
            {formError}
          </LargeErrorMessage>
        </div>
      )}
      {!!formSuccess && (
        <div ref={formSuccessAlert}>
          <Notification
            className="tds-margin-xxlg-bottom"
            dismissClickHandler={() => dismissSuccessNotification()}
            notificationType="success"
          >
            <Paragraph className="tds-margin-none">{formSuccess}</Paragraph>
          </Notification>
        </div>
      )}
      <Group
        className="tds-margin-xxlg-bottom"
        groupId="email"
        state={getFormElementState(errors.email)}
      >
        <Label>Email*</Label>
        <Input
          autoCapitalize="none"
          autoCorrect="off"
          className="ua-display-block ua-width-100"
          name="email"
          onBlur={handleInput}
          onChange={handleInput}
          required
          pattern={validationPatterns.email}
          type="email"
          value={data.email}
        />
        {!!errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      </Group>
      <Group
        className="tds-margin-xxlg-bottom"
        groupId="password"
        state={getFormElementState(errors.password)}
      >
        <Label>New password*</Label>
        <Input
          autoCapitalize="none"
          autoCorrect="off"
          className="ua-display-block ua-width-100"
          name="password"
          onBlur={handleInput}
          onChange={handleInput}
          required
          type="password"
          value={data.password}
        />
        {!!errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      </Group>
      <Group
        className="tds-margin-xxlg-bottom"
        groupId="password_confirmation"
        state={getFormElementState(errors.password_confirmation)}
      >
        <Label>Confirm password*</Label>
        <Input
          autoCapitalize="none"
          autoCorrect="off"
          className="ua-display-block ua-width-100"
          name="password_confirmation"
          onBlur={handleInput}
          onChange={handleInput}
          required
          type="password"
          value={data.password_confirmation}
        />
        {!!errors.password_confirmation && (
          <ErrorMessage>{errors.password_confirmation}</ErrorMessage>
        )}
      </Group>
      <div className="tds-padding-xxlg-top">
        <Button
          buttonType={inProgress ? "disabled" : "primary-one"}
          className="tds-margin-xxlg-bottom ua-display-block ua-width-100"
          loading={inProgress}
          type="submit"
        >
          {inProgress ? "Processing..." : "Reset password"}
        </Button>
      </div>
    </Form>
  );
}

ResetPasswordForm.propTypes = {
  className: string,
  email: string,
  resetToken: string.isRequired,
};
