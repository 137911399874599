import { UA_API_HOST } from "../data/ua-api/ua-api-definitions";

/**
 * Admin WYSIWYG editors create a relative URL for
 * File Manager uploads, so prepend the domain to
 * make an absolute URL or return an empty string
 *
 * @param {string} url
 * @returns string
 */
export default function getCleanUploadUrl(url) {
  if (!!url && /^\/uploads\/.+$/.test(url)) {
    return UA_API_HOST + url;
  }

  return "";
}
