import { createContext, useContext, useMemo, useState } from "react";
import { node } from "prop-types";
import { useSelector } from "react-redux";

import { checkLevel } from "../../app/AllowedComponent";
import { level2AndUp } from "../../data/constants/routes";
import { SCREEN_MEDIUM } from "../../data/constants/media-queries";
import useMatchMedia from "./use-match-media";
import useCanInvite from "../auth/use-can-invite";

import PageHeader from "./PageHeader";
import PageHeaderReverse from "./PageHeaderReverse";
import PageFooter from "./PageFooter";
// import UpdateProfileOverlay from "../user/UpdateProfileOverlay";

const PageLayoutContext = createContext();

export default function PageLayout({ children = null, ...rest }) {
  const [bgBleed, setBgBleed] = useState(false);
  const [forceHideHeader, setForceHideHeader] = useState(false);
  const [forceHideNav, setForceHideNav] = useState(false);
  const [reverseHeader, setReverseHeader] = useState(false);

  const agencyLevel = useSelector(
    ({ user }) => !!user?.agency && user.agency?.level
  );
  const { capabilities, hasAuth } = useSelector((state) => state.auth || {});
  const { next: catalogYearNext } = useSelector(
    (state) => state.catalogYearCurrent || {}
  );
  const bonusShoppingRequired = useSelector(
    (state) => state.bonusShoppingRequired
  );
  const shoppingMethod = useSelector((state) => state.shoppingMethod);
  const shoppingRequired = useSelector(
    ({ shoppingRequired }) => shoppingRequired?.required
  );
  const showComingSoon = useSelector(({ user }) => user.showComingSoon);

  const isScreenMd = useMatchMedia(SCREEN_MEDIUM);

  const canImpersonate = useMemo(
    () => !!capabilities && capabilities.includes("canImpersonate"),
    [capabilities]
  );

  const canInvite = useCanInvite();

  const [isBgBleed, layoutClass] = useMemo(() => {
    const isBgBleed =
      bgBleed === true ||
      bgBleed === "true" ||
      (isScreenMd && bgBleed === "md");
    let layoutClass = "page-layout";

    if (isBgBleed) {
      layoutClass += " page-layout--bg-bleed";
    }

    return [isBgBleed, layoutClass];
  }, [bgBleed, isScreenMd]);

  const hideHeader = useMemo(
    () => !!forceHideHeader === true || isBgBleed,
    [forceHideHeader, isBgBleed]
  );

  const showNav = useMemo(
    () =>
      (!forceHideNav &&
        hasAuth &&
        !showComingSoon &&
        !shoppingRequired &&
        checkLevel(agencyLevel, level2AndUp)) ||
      shoppingMethod === "bonus",
    [
      agencyLevel,
      forceHideNav,
      hasAuth,
      shoppingRequired,
      showComingSoon,
      shoppingMethod,
    ]
  );

  return (
    <PageLayoutContext.Provider
      value={{
        bonusShoppingRequired,
        canImpersonate,
        canInvite,
        catalogYearNext,
        hasAuth,
        hideFooter: isBgBleed,
        hideHeader,
        reverseHeader,
        setBgBleed,
        setForceHideNav,
        setForceHideHeader,
        setReverseHeader,
        shoppingMethod,
        showNav,
      }}
    >
      <div className={layoutClass}>
        <main className="page-main">{children}</main>

        {reverseHeader ? <PageHeaderReverse /> : <PageHeader />}

        <PageFooter />
        {/* {hasAuth && !canImpersonate && <UpdateProfileOverlay />} */}
      </div>
    </PageLayoutContext.Provider>
  );
}

export function usePageLayoutContext() {
  return useContext(PageLayoutContext) || {};
}

PageLayout.propTypes = {
  children: node,
};
