import normalizeShoppingSelections from "./normalize-shopping-selections";
import getApiStorageUrl from "../../utilities/get-api-storage-url";
import stripHtml from "../../utilities/strip-html";

export default function normalizeShoppingDataResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    let { status, message, ...shoppingData } = data;

    let benefits = shoppingData?.benefits || [];

    if (!Array.isArray(benefits)) {
      if (benefits.constructor.name === "Object") {
        benefits = Object.entries(benefits).map(([key, value]) => value);
      } else {
        benefits = [];
      }
    }

    if (benefits.length) {
      shoppingData.benefits = benefits.map((benefit) => {
        benefit.id = parseInt(benefit.id, 10);

        if (!!benefit.home_excerpt) {
          benefit.home_excerpt = stripHtml(benefit.home_excerpt);
        }

        // format max quantity as number
        if (!!benefit.maxQuantity) {
          const quantity = parseInt(benefit.maxQuantity, 10);

          if (!!quantity && !isNaN(quantity)) {
            benefit.maxQuantity = quantity;
          } else {
            benefit.maxQuantity = null;
          }
        }

        // format multiple amount options as numbers
        if (!!benefit.multipleAmounts) {
          const amounts = benefit.multipleAmounts
            .split(",")
            .reduce((clean, amount) => {
              amount = parseInt(amount, 10);

              if (!!amount && !isNaN(amount)) {
                clean.push(amount);
              }

              return clean;
            }, []);

          if (!!amounts.length) {
            benefit.multipleAmounts = amounts;

            // overwrite points with first amount option
            // so we don't have to rewrite sort by points
            benefit.points = amounts[0];
          } else {
            benefit.multipleAmounts = null;
          }
        }

        // Just in case the old allowMultiple field values sneak through
        if (benefit.allowMultiple === "0") {
          benefit.allowMultiple = "none";
        } else if (benefit.allowMultiple === "1") {
          if (!!benefit.maxQuantity) {
            benefit.allowMultiple = "quantity";
          } else if (!!benefit.multipleAmounts) {
            benefit.allowMultiple = "amount";
          } else {
            benefit.allowMultiple = "none";
          }
        }

        // Parse options_card JSON string
        if (
          !!benefit.options_cards &&
          typeof benefit.options_cards === "string"
        ) {
          benefit.options_cards = JSON.parse(benefit.options_cards);
        }

        // Make sure media URLs are correct
        try {
          if (!!benefit.image) {
            benefit.image = getApiStorageUrl(benefit.image);
          }

          if (!!benefit.main_image) {
            benefit.main_image = getApiStorageUrl(benefit.main_image);
          }

          if (!!benefit.resource_pdf) {
            benefit.resource_pdf = getApiStorageUrl(benefit.resource_pdf);
          }

          if (!!benefit.share_pdf) {
            benefit.share_pdf = getApiStorageUrl(benefit.share_pdf);
          }
        } catch (err) {}

        return benefit;
      });
    } else {
      throw new Error("Missing benefits");
    }

    if (!isValidPointsAvailable(shoppingData)) {
      throw new Error("Invalid points available value");
    }

    const [selectionsStatus, selections, selectionsMessage] =
      normalizeShoppingSelections(shoppingData.selections);

    if (selectionsStatus === "error") {
      throw new Error(selectionsMessage);
    }

    shoppingData.selections = selections;

    if (
      !!shoppingData.hiddenBenefits &&
      typeof shoppingData.hiddenBenefits === "string"
    ) {
      shoppingData.hiddenBenefits = JSON.parse(shoppingData.hiddenBenefits);
    }

    if (
      !!shoppingData.hiddenBenefits &&
      Array.isArray(shoppingData.hiddenBenefits)
    ) {
      shoppingData.hiddenBenefits.map((benefitId) => parseInt(benefitId, 10));
    }

    if (
      !!shoppingData?.dateFeedback &&
      typeof shoppingData.dateFeedback === "object" &&
      !!shoppingData.dateFeedback?.date
    ) {
      shoppingData.dateFeedback = shoppingData.dateFeedback.date;
    }

    return ["success", shoppingData, message || "Success"];
  } catch (err) {
    return ["error", null, err.message || "Unexpected response"];
  }
}

function isValidPointsAvailable({
  benefits = [],
  pointsAvailable = 0,
  selections = [],
}) {
  try {
    pointsAvailable = parseInt(pointsAvailable, 10);

    if (isNaN(pointsAvailable)) {
      throw new Error("Points available not a number");
    }

    const pointsRemaining = benefits.reduce((pointsRemaining, benefit) => {
      const selection = selections.find((s) => s.id === benefit.id);

      if (!!selection) {
        const selectedValue =
          benefit.allowMultiple === "amount"
            ? selection.qty // sometimes qty means amount :shrug:
            : benefit.points * selection.qty;
        pointsRemaining = pointsRemaining - selectedValue;
      }

      return pointsRemaining;
    }, pointsAvailable);
    return pointsRemaining >= 0;
  } catch (err) {
    return false;
  }
}
