const USER_ROLES = [
  { name: "Super Admin", capabilities: ["canImpersonate", "canInvite"] },
  { name: "UHC Admin", capabilities: ["canImpersonate", "canInvite"] },
  { name: "UHC Market Leader", capabilities: ["canImpersonate", "canInvite"] },
  { name: "User - Agency Primary", capabilities: ["canInvite"] },
  { name: "UHC User", capabilities: [] },
  { name: "User", capabilities: [] },
];

export default USER_ROLES;
