import axios from "axios";

import {
  UA_API_REQUEST_MAX_SIZE,
  UA_API_REQUEST_MAX_TIME,
  UA_API_URL_BASE,
} from "./ua-api-definitions";

import processError from "./process-error";
import processResponse from "./process-response";

const UA_API = axios.create({
  baseURL: UA_API_URL_BASE,
  timeout: UA_API_REQUEST_MAX_TIME,
  maxContentLength: UA_API_REQUEST_MAX_SIZE,
  headers: {
    "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
    Pragma: "no-cache",
    Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
  },
});

export function UA_API_AUTHORIZE(authToken) {
  if (!!authToken) {
    UA_API.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  } else {
    UA_API_DEAUTHORIZE();
  }
}

export function UA_API_DEAUTHORIZE() {
  if (UA_API.defaults.headers.common.hasOwnProperty("Authorization")) {
    delete UA_API.defaults.headers.common.Authorization;
  }
}

export const UA_API_RESPONSE_INTERCEPTOR = UA_API.interceptors.response.use(
  processResponse,
  processError
);

export const CancelToken = axios.CancelToken;

export * from "./ua-api-definitions";

export default UA_API;
