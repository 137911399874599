import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const selectHiddenBenefits = createSelector(
  (state) => state?.shoppingHidden || [],
  (hidden) => [hidden, hidden.length]
);

export default function useHiddenBenefits() {
  const [hidden, hiddenCount] = useSelector((state) =>
    selectHiddenBenefits(state)
  );
  return [hidden, hiddenCount];
}
