import { useMemo } from "react";
import { string } from "prop-types";

import getCleanStorageUrl from "../../utilities/get-clean-storage-url";

import Portal from "../portal/Portal";

export default function VimeoBackground({
  id = "",
  poster = "",
  vimeoUrl = "",
}) {
  const bgImage = useMemo(() => getCleanStorageUrl(poster), [poster]);

  return (
    <Portal className="ua-vimeo-bg" id={`vimeo-bg-${id}`}>
      <div
        className="ua-vimeo-bg__inner"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        {!!vimeoUrl && (
          <iframe
            src={vimeoUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="Background"
          ></iframe>
        )}
      </div>
    </Portal>
  );
}

VimeoBackground.propTypes = {
  id: string,
  poster: string,
  vimeoUrl: string,
};
