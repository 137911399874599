import { arrayOf, string } from "prop-types";

import Icon from "@mdi/react";
import { mdiBook, mdiMonitor, mdiPlayCircle, mdiSchool } from "@mdi/js";

import { Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function TrainingType({ trainingType = [] }) {
  return (
    <div className="ua-display-flex ua-align-self-flex-end ua-align-self-center-md ua-align-items-center ua-flex-wrap">
      {trainingType.length &&
        trainingType.map((type) => {
          switch (type) {
            case "0":
              return (
                <TrainingTypeLabel
                  key={type}
                  iconColor="#333333"
                  iconPath={mdiBook}
                  labelText="Classroom"
                />
              );
            case "1":
              return (
                <TrainingTypeLabel
                  key={type}
                  iconColor="#196ecf"
                  iconPath={mdiMonitor}
                  labelText="Virtual"
                  style={{ transform: "scale(0.9)" }}
                />
              );
            case "2":
              return (
                <TrainingTypeLabel
                  key={type}
                  iconColor="#d14600"
                  iconPath={mdiPlayCircle}
                  labelText="OnDemand"
                />
              );
            case "3":
              return (
                <TrainingTypeLabel
                  key={type}
                  iconColor="#007000"
                  iconPath={mdiSchool}
                  labelText="CE Credit"
                />
              );
            default:
              return null;
          }
        })}
    </div>
  );
}

function TrainingTypeLabel({ iconColor, iconPath, labelText, style }) {
  return (
    <div className="ua-display-flex ua-align-items-center">
      <Icon
        className="tds-margin-xsm-right"
        color={iconColor}
        path={iconPath}
        size="20px"
        style={style}
        title={labelText}
      />
      <Paragraph
        bodyStyle={4}
        className="ua-display-inline-block tds-margin-lg-right tds-margin-none-bottom ua-text-nowrap"
      >
        <strong>{labelText}</strong>
      </Paragraph>
    </div>
  );
}

TrainingType.propTypes = {
  trainingType: arrayOf(string),
};
