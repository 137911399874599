import { func, number, oneOfType, shape, string } from "prop-types";
import dayjs from "dayjs";

import {
  UA_API_EP_TRAINING_CONTACT,
  UA_API_EP_WAITLISTS,
} from "../../data/ua-api/ua-api-definitions";
import trackEvent from "../tracking/track-event";
import useModalScrollbarOffset from "../../utilities/use-modal-scrollbar-offset";

import CallToActionModalForm from "../call-to-action/CallToActionModalForm";

import { Button } from "@uhc-tempo/components";

export default function TrainingCallToActionModalForm({
  buttonType = "primary-one",
  catalogYearId,
  id,
  label = "Register",
  name,
  trackClick,
  trainingSession,
}) {
  const [modalOpen, toggleModalOpen] = useModalScrollbarOffset(false);

  const { action, linkFormatted, linkType, start_date, topic } =
    trainingSession || {};
  let training_name = name;

  if (!!topic) {
    training_name += ` ${topic}`;
  }

  if (!!start_date) {
    training_name += ` (${dayjs(start_date).format("YYYY-MM-DD")})`;
  }

  const modalData = {
    catalog_year_id: catalogYearId,
    custom_email: linkType === "email" ? linkFormatted : null,
    notify: action === "notify",
    training: trainingSession,
    training_id: id,
    training_name,
    waitlist: action === "waitlist",
  };

  const submitUrl = ["notify", "waitlist"].includes(action)
    ? UA_API_EP_WAITLISTS
    : UA_API_EP_TRAINING_CONTACT;

  function handleModalOpen() {
    toggleModalOpen();
    trackClick();
  }

  function handleSuccess() {
    trackEvent({
      action: "training_sendgetstarted",
      params: { training_id: id },
    });
  }

  return (
    <>
      <Button buttonType={buttonType} onClick={handleModalOpen} type="button">
        {label}
      </Button>
      <CallToActionModalForm
        active={modalOpen}
        onClose={toggleModalOpen}
        onSuccess={handleSuccess}
        modalData={modalData}
        name={name}
        submitUrl={submitUrl}
      />
    </>
  );
}

TrainingCallToActionModalForm.propTypes = {
  buttonType: string,
  catalogYearId: oneOfType([number, string]),
  id: oneOfType([number, string]),
  label: string,
  name: string,
  trainingSession: shape({
    action: string,
    date_override: string,
    end_date: string,
    link: string,
    linkFormatted: string,
    linkType: string,
    start_date: string,
    status: string,
  }),
  trackClick: func.isRequired,
};
