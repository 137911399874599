import {
  createAction,
  createReducer,
  createAsyncThunk,
} from "@reduxjs/toolkit";

import UA_API, { UA_API_EP_TRAINING } from "../../data/ua-api/ua-api";
import { isLogout } from "../auth/AuthRedux";
import normalizeTrainingResponse from "./normalize-training-response";

const initialState = [];

export const getTraining = createAsyncThunk(
  "training/get",
  async function fetchTraining(
    { agency_id, catalog_year },
    { getState, rejectWithValue }
  ) {
    try {
      const { user } = getState();
      const response = await UA_API.get(
        `${UA_API_EP_TRAINING}?agency_id=${agency_id}&catalog_year=${catalog_year}`
      );
      const [trainingStatus, trainings, message] = normalizeTrainingResponse(
        response,
        user
      );

      if (trainingStatus === "error") {
        throw new Error(message);
      }

      return trainings;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const resetTraining = createAction("training/reset");

export const trainingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTraining.fulfilled, (state, action) => action.payload)
    .addCase(resetTraining, (state, action) => initialState)
    .addMatcher(isLogout, (state, action) => initialState);
});

export const trainingErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getTraining.pending, (state, action) => null)
    .addCase(getTraining.fulfilled, (state, action) => null)
    .addCase(getTraining.rejected, (state, action) => action.payload);
});

export const trainingLoadingReducer = createReducer(true, (builder) => {
  builder
    .addCase(getTraining.pending, (state, action) => true)
    .addCase(getTraining.fulfilled, (state, action) => false)
    .addCase(getTraining.rejected, (state, action) => false);
});
