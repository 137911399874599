import getUrlFromLocation from "../../utilities/get-url-from-location";
import pathAuthorized from "../../utilities/path-authorized";
import trackEvent from "./track-event";

let currentPageUrl;

export default function trackPageView(location, authenticated) {
  const isAuthorized = pathAuthorized(location.pathname, authenticated);

  if (!isAuthorized) {
    return;
  }

  const url = getUrlFromLocation(location);

  if (url === currentPageUrl) {
    return;
  }

  currentPageUrl = url;

  let params = {
    page_title: document.title,
    page_location: url,
  };

  trackEvent({ action: "page_view", params });
}
