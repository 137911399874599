export const AGENCY_ROLES = [
  "Principal / Owner",
  "Vice President",
  "Director / Manager",
  "Account Executive / Producer",
  "Account Manager",
  "Finance / Analytics",
  "Service",
  "Marketing",
  "Administrative",
  "Other",
];
