import { number, string } from "prop-types";

import formatNumber from "../../utilities/format-number";

import { IconBarChart } from "@uhc-tempo/icons";
import { BrandIcon, Modal, Typography } from "@uhc-tempo/components";
const { Footer } = Modal;
const { Paragraph } = Typography;

export default function NetChangeExample({
  delta,
  formatted,
  enrolled,
  ratio,
  tier,
  type,
}) {
  const total = Math.round(enrolled * ratio); // round to mitigate floating point decimals
  const requirement = total - enrolled;

  return (
    <Footer className="ua-footer-info">
      <div className="ua-footer-info--explanation">
        <BrandIcon
          icon={IconBarChart}
          size="EXTRA_SMALL"
          styleVariation="TWO_TONE_LIGHT_CIRCLE"
        />
        <div className="ua-footer-info--explanation--text">
          <Paragraph className="ua-text-primary-one">
            The {type} membership requirement to achieve {tier} status is a{" "}
            <strong className="tds-text__paragraph--bold">
              {delta} increase.
            </strong>{" "}
            We call this {formatted} net change.
          </Paragraph>
        </div>
      </div>
      <div className="ua-footer-info--example">
        <Paragraph
          bodyStyle={3}
          className="ua-margin-xsm-bottom ua-text-gray-five"
          useBold={true}
        >
          This is an example.
        </Paragraph>
        <div className="ua-footer-info--example--content">
          <div className="ua-footer-info--example--calc">
            <div className="ua-footer-info--example--calc--grid">
              <strong>{formatNumber(enrolled)}</strong>
              <small>{type} enrolled</small>
              <strong className="ua-display-flex ua-justify-content-space-between">
                <span>&times;</span> {ratio}
              </strong>
              <small>net change requirement ({formatted})</small>
              <hr />
              <strong>{formatNumber(total)}</strong>
              <small>total requirement</small>
            </div>
          </div>
          <div className="ua-footer-info--example--increase">
            <div className="ua-footer-info--example--increase--content">
              <div className="ua-footer-info--example--increase--content--diff">
                <span>{formatNumber(requirement)}</span>
              </div>
              <small>
                {delta} increase
                <br /> requirement
              </small>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  );
}

NetChangeExample.propTypes = {
  delta: string.isRequired,
  formatted: string.isRequired,
  enrolled: number.isRequired,
  ratio: number.isRequired,
  tier: string.isRequired,
  type: string.isRequired,
};
