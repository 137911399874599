import { useMemo, useState } from "react";
import { bool, func, number, oneOfType, string } from "prop-types";
import debounce from "lodash.debounce";

import getItemClass from "./get-item-class";
import ShoppingBenefitHideButton from "./ShoppingBenefitHideButton";
import ShoppingBenefitPoints from "./ShoppingBenefitPoints";
import ShoppingBenefitSelectButton from "./ShoppingBenefitSelectButton";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";
import useBenefitRootPath from "../benefits/use-benefit-root-path";

import { Button, Typography } from "@uhc-tempo/components";
const { H2, Paragraph } = Typography;

const itemClassBase = "shopping-benefit--list";

export default function ShoppingBenefitListItem({
  allowMultiple,
  id,
  points,
  most_popular,
  home_excerpt,
  name,
  title,
  disabled,
  inProgress,
  isSelected,
  onError,
  onRemove,
  onSelect,
  onViewDetail,
  unavailable,
  slug,
  ...rest
}) {
  const [isHovered, setIsHovered] = useState(false);
  const isMultiple = allowMultiple === "quantity" || allowMultiple === "amount";

  const benefitRootPath = useBenefitRootPath();

  const itemClass = useMemo(
    () => getItemClass(itemClassBase, isHovered, isSelected),
    [isHovered, isSelected]
  );
  const handleHovered = useMemo(
    () => debounce((hovered) => setIsHovered(hovered), 100),
    []
  );

  function handleOut() {
    handleHovered(false);
  }

  function handleOver() {
    handleHovered(true);
  }

  return (
    <div
      className={`ua-grid ua-grid-gap-2-sm ua-grid-cols-9 ${itemClassBase} ${itemClass}`}
      onBlur={handleOut}
      onFocus={handleOver}
      onMouseOut={handleOut}
      onMouseOver={handleOver}
    >
      <div className="ua-col-auto-span-12-sm ua-col-auto-span-7-md ua-col-auto-span-6-lg">
        <div className="ua-display-flex ua-grid-gap-2-md">
          <div style={{ minWidth: "111px" }}>
            <ShoppingBenefitPoints points={points} starting={isMultiple} />
          </div>
          <div className="ua-display-flex ua-align-items-flex-start">
            <div className="ua-overflow-hidden">
              <TempoRouterLink
                className={`tds-margin-xsm-bottom ua-cursor-pointer ua-text-underline-hover `}
                component={H2}
                headingStyle={6}
                to={`${benefitRootPath}/${slug}`}
              >
                {title || name}
              </TempoRouterLink>
              <Paragraph
                bodyStyle={3}
                className="ua-display-flex ua-grid-gap-1-sm tds-margin-none ua-overflow-wrap-anywhere ua-align-items-flex-start"
              >
                <span>
                  <span className="ua-line-clamp-sm-3">{home_excerpt}</span>
                </span>
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className="ua-text-right ua-col-auto-span-12-sm ua-col-auto-span-2-md ua-col-auto-span-3-lg">
        <div className="ua-display-inline-flex ua-flex-column ua-flex-row-lg ua-grid-gap-1-md ua-grid-gap-2-lg">
          <TempoRouterLink
            buttonType={`secondary-one`}
            className="ua-text-nowrap"
            component={Button}
            onClick={onViewDetail}
            to={`${benefitRootPath}/${slug}`}
          >
            Learn more
          </TempoRouterLink>
          <div className="ua-display-inline-flex ua-flex-column ua-justify-content-flex-end">
            <ShoppingBenefitSelectButton
              allowMultiple={allowMultiple}
              disabled={disabled}
              loading={inProgress}
              isSelected={isSelected}
              onRemove={onRemove}
              onSelect={onSelect}
              text="Add to cart"
              unavailable={unavailable}
            />
            <ShoppingBenefitHideButton
              id={id}
              name={name}
              onError={onError}
              show={!inProgress && !isSelected && isHovered}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ShoppingBenefitListItem.propTypes = {
  allowMultiple: string,
  id: number,
  points: number,
  most_popular: oneOfType([bool, number]),
  home_excerpt: string,
  name: string,
  title: string,
  disabled: bool,
  inProgress: bool,
  isSelected: bool,
  onError: func.isRequired,
  onRemove: func.isRequired,
  onSelect: func.isRequired,
  onViewDetail: func.isRequired,
  unavailable: bool,
  slug: string,
};
