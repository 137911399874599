import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const canInviteSelector = createSelector(
  ({ auth }) => auth?.capabilities,
  ({ user }) => !!user?.hasTraining,
  ({ shoppingRequired }) => shoppingRequired?.required,
  (capabilities, hasTraining, shoppingRequired) =>
    !!capabilities &&
    capabilities.includes("canInvite") &&
    hasTraining &&
    !shoppingRequired
);

export default function useCanInvite() {
  const canInvite = useSelector((state) => canInviteSelector(state));
  return canInvite;
}
