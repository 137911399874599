import { useEffect, useRef, useState } from "react";
import { element, object, string } from "prop-types";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { useLocation } from "react-router-dom";

import { Typography, UtilityIcon } from "@uhc-tempo/components";
import useClickOutside from "./use-click-outside";

const { Paragraph } = Typography;

export default function PopupMenu({ children, label = "", icon }) {
  const popupMenuRef = useRef(null);
  const [expandMenu, setExpandMenu] = useState(false);

  const location = useLocation();
  const currentPath = useRef(location.pathname);

  function handleBlur() {
    setTimeout(() => {
      if (
        !!popupMenuRef.current &&
        !popupMenuRef.current.contains(document.activeElement) &&
        expandMenu
      ) {
        toggleMenu();
      }
    }, 50);
  }

  function toggleMenu() {
    setExpandMenu(!expandMenu);
  }

  function closeMenu() {
    setExpandMenu(false);
  }

  function onKeyPress({ keyCode }) {
    // close menu on escape keypress
    if (keyCode === 27) {
      closeMenu();
    }
  }

  useEffect(() => {
    // close the menu if we've changed location
    if (currentPath.current) {
      if (currentPath.current.pathname !== location.pathname) {
        closeMenu();
      }
    }
  }, [location, currentPath]);

  useEffect(() => {
    window.addEventListener("keydown", onKeyPress);

    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useClickOutside(popupMenuRef, closeMenu);

  return (
    <div className="popup" ref={popupMenuRef} onBlur={handleBlur}>
      <button
        aria-expanded={expandMenu}
        aria-haspopup="true"
        className="popup__button ua-cursor-pointer tds-padding-none"
        onClick={toggleMenu}
        tabIndex={0}
      >
        {icon && (
          <UtilityIcon
            className="tds-margin-xsm-right"
            color="primary"
            icon={icon}
            size="medium"
            title="Profile"
          />
        )}
        <Paragraph
          bodyStyle={3}
          useBold
          className="ua-text-gray-seven tds-margin-none-bottom tds-margin-xsm-right"
        >
          {label}
        </Paragraph>
        <UtilityIcon
          color="primaryCTA"
          description="Toggles the menu"
          icon={expandMenu ? KeyboardArrowUp : KeyboardArrowDown}
          size="small"
          title="Toggle menu"
        />
      </button>
      <div aria-expanded={expandMenu} className="popup-container ua-text-left">
        {children}
      </div>
    </div>
  );
}

PopupMenu.propTypes = {
  children: element,
  icon: object,
  label: string,
};
