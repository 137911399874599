import USER_ROLES from "../data/constants/user-roles";

export default function getUserRole(roles) {
  if (!roles || !Array.isArray(roles)) {
    return null;
  }

  const userRole = USER_ROLES.find((role) =>
    roles.some(({ name }) => name === role.name)
  );

  return userRole;
}
