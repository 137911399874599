import { useSelector } from "react-redux";

import getLevelName from "../../utilities/get-level-name";

export default function useUserAgency() {
  let agency = useSelector(({ user }) => user.agency);

  const userAgency = {
    id: agency?.id || 0,
    name: agency?.name || "",
    marketId: agency?.market_id || 0,
    region: agency?.region || "",
    ra_number: agency?.ra_number || "",
    phone: agency?.phone || "",
    address1: agency?.address_1 || "",
    address2: agency?.address_2 || "",
    city: agency?.city || "",
    state: agency?.state || "",
    zip: agency?.zip || "",
    logo: agency?.logo || "",
    level: agency?.level || "",
    levelName: agency?.level ? getLevelName(agency?.level) : "",
    scorecardData: agency?.scorecardData || {},
  };

  return userAgency;
}
