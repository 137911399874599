import { element, func, node, oneOfType, string } from "prop-types";

import UHCLogo from "../../assets/img/UHC_logo_stacked_light.svg";

export default function MastheadAlt({
  className = "",
  component: Component = "div",
}) {
  return (
    <Component className={`ua-masthead-alt ${className}`}>
      <div className="ua-masthead-alt--brand">
        <img src={UHCLogo} width="177" height="55" alt="United Healthcare" />
      </div>
      <div className="ua-masthead-alt--brand2">
        <span className="tds-text__paragraph--bold">United</span> Advantage&reg;
      </div>
    </Component>
  );
}

MastheadAlt.propTypes = {
  className: string,
  component: oneOfType([element, func, node]),
};
