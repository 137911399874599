import ROUTES from "../../data/constants/routes";

import useStaticPage from "../../features/static-pages/use-static-page";
import StaticPage from "../../features/static-pages/StaticPage";

export default function Privacy() {
  const page = useStaticPage(ROUTES.privacy.path);

  return <StaticPage page={page} />;
}
