import ROUTES from "../../data/constants/routes";

import RequestAgencyIdForm from "../../features/register/RequestAgencyIdForm";
import TempoRouterLink from "../../components/tempo-router-link/TempoRouterLink";

import { Typography } from "@uhc-tempo/components";
const { H1, Link, Paragraph } = Typography;

export default function RequestAgencyId(props) {
  return (
    <section className="fluid-container page-main__fill ua-bg-supporting-one-opacity-ten ua-padding-jumbo-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12">
        <div className="auth-content ua-col-span-3-8-lg ua-col-span-2-10-md ua-col-span-1-12-sm">
          <H1 className="ua-text-center" headingStyle={2}>
            {ROUTES.requestAgencyId.title}
          </H1>
          <div className="auth-content__inner-wide tds-padding-xxlg-top">
            <RequestAgencyIdForm />
            <footer className="ua-text-center">
              <Paragraph>
                <TempoRouterLink
                  className="tds-margin-xlg-horizontal"
                  component={Link}
                  to={ROUTES.login.path}
                >
                  Back to login page
                </TempoRouterLink>
              </Paragraph>
            </footer>
          </div>
        </div>
      </div>
    </section>
  );
}
