import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import rootReducer from "./root-reducer";
import { HOST_ENV } from "../constants/urls";
import { UA_API_AUTHORIZE } from "../ua-api/ua-api";
import setHotjarIdentity from "../../features/tracking/set-hotjar-identity";
import setUserProperties from "../../features/tracking/set-user-properties";

function initStore(preloadedState) {
  const store = configureStore({
    devTools: HOST_ENV !== "production",
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        // Works around "non-serializable value" error thrown by redux-persist
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
    reducer: rootReducer,
  });

  const persistor = persistStore(
    store,
    null,
    function rehydrateApiAuthorization() {
      let { auth = {} } = store.getState();
      UA_API_AUTHORIZE(auth.access_token);

      if (!!auth?.userProperties) {
        setHotjarIdentity(auth.userProperties);
        setUserProperties(auth.userProperties);
      }
    }
  );

  if (import.meta.env.MODE !== "production" && import.meta.hot) {
    import.meta.hot.accept("./root-reducer", () =>
      store.replaceReducer(rootReducer)
    );
  }

  return { store, persistor };
}

export const { store, persistor } = initStore();
