import { string } from "prop-types";

import { OndemandVideo } from "@mui/icons-material";
import Schedule from "@mui/icons-material/Schedule";

import { Typography, UtilityIcon } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function ResourceMetaItem({ icon, label }) {
  if (!label) {
    return null;
  }

  return (
    <Paragraph bodyStyle={1} className="ua-display-flex ua-margin-xsm-bottom">
      <Icon icon={icon} />
      <span className="ua-display-block">{label}</span>
    </Paragraph>
  );
}

function Icon({ icon }) {
  if (!icon) {
    return null;
  }

  return (
    <UtilityIcon
      className="ua-margin-sm-right"
      color="primaryCTA"
      icon={getIcon(icon)}
      size="medium"
      title={icon}
    />
  );
}

function getIcon(icon) {
  switch (icon) {
    case "time":
      return Schedule;
    case "video":
      return OndemandVideo;
    default:
      return null;
  }
}

ResourceMetaItem.propTypes = {
  icon: string,
  label: string,
};
