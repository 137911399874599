import getHostname from "./get-hostname";
import { URL_PRODUCTION } from "../data/constants/urls";

export default function isInternalLink(url) {
  try {
    if (/^#/.test(url)) {
      return false;
    }

    const hostname = getHostname(url);
    const productionHostname = getHostname(URL_PRODUCTION);

    return hostname === productionHostname || hostname === "localhost";
  } catch (err) {
    return true;
  }
}
