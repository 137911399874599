import { NETSTUDY_TOKEN } from "../../data/constants/site";

import getPathFromUrl from "../../utilities/get-path-from-url";
import isInternalLink from "../../utilities/is-internal-link";

export default function getFormattedCallToActionLink(link, user) {
  if (!!link && isEmailLink(link)) {
    return ["email", formatEmailAddress(link)];
  }

  if (!!link && isInternalLink(link)) {
    return ["internal", getPathFromUrl(link)];
  }

  if (!!link && isNetStudyLink(link)) {
    return ["external", formatNetStudyUrl(link, user)];
  }

  if (!!link) {
    return ["external", link];
  }

  return [null, null];
}

function formatEmailAddress(link) {
  // Email links will not be handled as hyperlinks,
  // but rather as a parameter passed to the contact form,
  // so the `mailto:` protocol is removed
  return !!link && link.replace(/mailto:/gi, "");
}

function formatNetStudyUrl(url, user) {
  if (!!url && !!user) {
    const { email, first_name, last_name, agency = {} } = user;
    let firstSymbol = url.includes("?") ? "&" : "?";
    return encodeURI(
      `${url}${firstSymbol}firstName=${first_name}&lastName=${last_name}&email=${email}&src=${NETSTUDY_TOKEN}&UHCReportCode=${agency?.ra_number}`
    );
  }
  return url;
}

function isEmailLink(link) {
  // Performing a more flexible check than the `isMailLink` utility
  // because admins are not always careful about including the
  // `mailto:` protocol
  return !!link && /^(mailto:)?[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(link);
}

function isNetStudyLink(link) {
  return !!link && link.includes("https://www.netstudy.com/engine/softportal");
}
