import { HOST_ENV } from "../constants/urls";

export const UA_API_REQUEST_MAX_SIZE =
  parseInt(import.meta.env.VITE_UA_API_REQUEST_MAX_SIZE, 10) || 5 * 1024 * 1024;

export const UA_API_REQUEST_MAX_TIME =
  parseInt(import.meta.env.VITE_UA_API_REQUEST_MAX_TIME, 10) || 5 * 60 * 1000;

export const UA_API_HOST =
  HOST_ENV === "production"
    ? import.meta.env.VITE_UA_API_HOST_PRODUCTION
    : import.meta.env.VITE_UA_API_HOST_STAGING;

export const UA_API_URL_BASE =
  UA_API_HOST + import.meta.env.VITE_UA_API_VERSION;
export const UA_API_URL_STORAGE_DIR = import.meta.env.VITE_UA_API_STORAGE;
export const UA_API_URL_STORAGE = UA_API_HOST + UA_API_URL_STORAGE_DIR;

/**
 * API Endpoints
 */
export const UA_API_EP_AGENCIES = "/agencies";
export const UA_API_EP_AGENCY = "/agency";

export const UA_API_EP_AUTH = "/auth";
export const UA_API_EP_AUTH_FORGOT = "/forgot-password";
export const UA_API_EP_AUTH_LOGIN = `${UA_API_EP_AUTH}/login`;
export const UA_API_EP_AUTH_LOGOUT = `${UA_API_EP_AUTH}/logout`;
export const UA_API_EP_AUTH_RESET = "/reset-password";

export const UA_API_EP_BENEFITS = "/benefits";
export const UA_API_EP_BENEFITS_SHARE = `${UA_API_EP_BENEFITS}/share`;

export const UA_API_EP_CATALOGS = "/catalogs";
export const UA_API_EP_CATALOG_YEARS = "/catalog-years";

export const UA_API_EP_CONTACT = "/contact";
export const UA_API_EP_HOMEPAGE = "/homepage";
export const UA_API_EP_MARKETING = "/marketing-page";
export const UA_API_EP_MARKETS = "/markets";
export const UA_API_EP_STATIC_PAGES = "/pages";

export const UA_API_EP_REGISTER = "/register";
export const UA_API_EP_REGISTER_AGENCY = `${UA_API_EP_REGISTER}/request-agency-id`;

export const UA_API_EP_SCORECARD = "/scorecard";
export const UA_API_EP_SETTINGS = "/settings";

export const UA_API_SHOPPING_EP = "/shopping";
export const UA_API_SHOPPING_EP_DATA = `${UA_API_SHOPPING_EP}/data`;
export const UA_API_SHOPPING_EP_FINALIZE = `${UA_API_SHOPPING_EP}/finalize`;
export const UA_API_SHOPPING_EP_METHOD = `${UA_API_SHOPPING_EP}/method`;
export const UA_API_SHOPPING_EP_SELECTIONS = `${UA_API_SHOPPING_EP}/selections`;

export const UA_API_EP_TRACK = "/track";

export const UA_API_EP_TRAINING = "/trainings";
export const UA_API_EP_TRAINING_CONTACT = `${UA_API_EP_TRAINING}/contact`;

export const UA_API_EP_USERS = "/users";
export const UA_API_EP_USERS_INVITE = `${UA_API_EP_USERS}/invite`;
export const UA_API_EP_USERS_UPLOAD = `${UA_API_EP_USERS}/upload`;

export const UA_API_EP_WAITLISTS = "/waitlists";
