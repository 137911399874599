import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import UA_API, { UA_API_EP_STATIC_PAGES } from "../../data/ua-api/ua-api";

import normalizeStaticPagesResponse from "./normalize-static-pages-response";

const initialState = [];

export const getStaticPages = createAsyncThunk(
  "staticPages/get",
  async function fetchStaticPages(_, { rejectWithValue }) {
    try {
      const response = await UA_API.get(UA_API_EP_STATIC_PAGES);
      let [staticPagesStatus, staticPages] =
        normalizeStaticPagesResponse(response);

      if (staticPagesStatus === "error") {
        throw new Error("Unexpected response");
      }

      return staticPages || initialState;
    } catch (err) {
      return rejectWithValue(err?.message || "Error");
    }
  }
);

export const staticPagesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getStaticPages.fulfilled, (state, action) => [
    ...action.payload,
  ]);
});

export const staticPagesErrorReducer = createReducer(null, (builder) => {
  builder
    .addCase(getStaticPages.pending, (state, action) => null)
    .addCase(getStaticPages.fulfilled, (state, action) => null)
    .addCase(getStaticPages.rejected, (state, action) => action.payload);
});

export const staticPagesLoadingReducer = createReducer(true, (builder) => {
  builder
    .addCase(getStaticPages.pending, (state, action) => true)
    .addCase(getStaticPages.fulfilled, (state, action) => false)
    .addCase(getStaticPages.rejected, (state, action) => false);
});
