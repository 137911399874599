import { func, object, string } from "prop-types";

export default function Embed({ onLoad, style, title = "embed", url }) {
  if (!url) {
    return null;
  }

  return (
    <div className="ua-responsive-embed">
      <iframe
        className="ua-responsive-embed--item"
        title={title}
        src={url}
        allowFullScreen
        onLoad={onLoad}
        style={style}
      />
    </div>
  );
}

Embed.propTypes = {
  onLoad: func,
  style: object,
  title: string,
  url: string,
};
