import { useEffect, useState } from "react";
import { RECAPTCHA_SITE_KEY } from "../../data/constants/site";

const callbackName = "uaRecaptchaCallback";

export default function useRecaptcha() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!window && !!window.grecaptcha) {
      setError(null);
      setLoading(false);
    } else {
      try {
        if (!!window && !window[callbackName]) {
          window[callbackName] = () => {
            setError(null);
            setLoading(false);
          };
        }
        loadRecaptcha();
      } catch (err) {
        setError(err.message || "Could not load reCAPTCHA");
        setLoading(false);
      }
    }

    return function cleanupRecaptcha() {
      if (!!window && !!window[callbackName]) {
        delete window[callbackName];
      }
    };
  }, []);

  function getToken(action = "submit") {
    return new Promise((resolve, reject) => {
      try {
        if (
          !window ||
          (!!window && !window.grecaptcha) ||
          !RECAPTCHA_SITE_KEY
        ) {
          reject("reCAPTCHA not loaded");
        }

        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(RECAPTCHA_SITE_KEY, { action })
            .then((token) => {
              resolve(token);
            })
            .catch((err) => {
              reject(err || "reCAPTCHA error");
            });
        });
      } catch (err) {
        reject(err.message || "reCAPTCHA error");
      }
    });
  }

  return [loading, error, getToken];
}

function loadRecaptcha() {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}&onload=${callbackName}`;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
}

export { default as RecaptchaDisclaimer } from "./RecaptchaDisclaimer";
