export default function normalizeMarketsResponse(response) {
  try {
    const { data = {} } = response;

    if (!data || !data.status || (!!data.status && data.status !== "success")) {
      throw new Error("Incorrect response format");
    }

    let markets = data?.markets || data?.data;

    if ((!markets || !Array.isArray(markets)) && Object.keys(data).length > 3) {
      markets = Object.keys(data)
        .filter(
          (key) => !["message", "status", "success", "data"].includes(key)
        )
        .map((key) => ({
          id: parseInt(key, 10),
          name: data[key],
        }));
    }

    if (!markets || !Array.isArray(markets) || !markets.length) {
      throw new Error("No markets found");
    }

    markets = markets.sort(sortByName);

    let message = data.message || "Success!";

    return ["success", markets, message];
  } catch (err) {
    return ["error", [], err.message || "Unexpected response"];
  }
}

function sortByName(a, b) {
  // Intl.Collator makes case-insensitive
  return new Intl.Collator("en", { sensitivity: "base" }).compare(
    a.name,
    b.name
  );
}
