import { func, object } from "prop-types";

export default function ErrorView({ error, clearError }) {
  return (
    <section className="fluid-container ua-padding-jumbo-vertical ua-text-center">
      <h1 className="tds-header__h1">Something went&nbsp;wrong</h1>
      <p className="tds-text__paragraph tds-text__paragraph-large">
        Please refresh the browser and try again. If&nbsp;the problem persists
        please notify&nbsp;us.
      </p>
    </section>
  );
}

ErrorView.propTypes = {
  error: object,
  clearError: func,
};
