import { useEffect, useRef, useState } from "react";
import { func, string } from "prop-types";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { login } from "./AuthRedux";

import getFormElementState from "../../utilities/get-form-element-state";
import scrollIfNeeded from "../../utilities/scroll-if-needed";
import validateInput, {
  validationPatterns,
} from "../../utilities/validate-input";

import { Button, Form } from "@uhc-tempo/components";
const { ErrorMessage, Group, Input, Label, LargeErrorMessage } = Form;

const initData = { email: "", password: "" };

export default function LoginForm({ authMessage, className, onSuccess }) {
  const [data, setData] = useState(initData);
  const [errors, setErrors] = useState(initData);
  const [forcePrompt, setForcePrompt] = useState(false);
  const [forceBonusShopping, setForceBonusShopping] = useState(undefined);
  const [forceShoppingRequired, setForceShoppingRequired] = useState(undefined);
  const [forceShowComingSoon, setForceShowComingSoon] = useState(false);
  const [formError, setFormError] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  let { search: locationSearch } = useLocation();

  const dispatch = useDispatch();
  const formErrorAlert = useRef();

  function checkInput(input) {
    const field = input.name;

    if (!field) {
      return;
    }

    const error = validateInput(input);
    setErrors((prevErrors) => ({ ...prevErrors, [input.name]: error }));
  }

  function displayError(errorMessage) {
    setFormError(errorMessage);
    setTimeout(() => {
      scrollIfNeeded(formErrorAlert.current);
    }, 50);
  }

  function handleInput(event) {
    const { target: input } = event;
    const field = input.name;

    if (!field) {
      return;
    }

    const value = !!input ? input.value : "";
    setData((prevData) => ({ ...prevData, [field]: value }));

    if (event.type === "blur") {
      setTimeout(() => {
        checkInput(input);
      }, 500);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!isFormValid(event.target)) {
      displayError("Check form for errors");
      return;
    }

    setInProgress(true);

    dispatch(
      login({
        data,
        forceBonusShopping,
        forcePrompt,
        forceShoppingRequired,
        forceShowComingSoon,
      })
    )
      .unwrap()
      .then(() => {
        if (!!onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        displayError(`${err}`);
        setInProgress(false);
      });
  }

  function isFormValid(form) {
    if (!form.checkValidity()) {
      [...form.elements].forEach(checkInput);
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(locationSearch);
    const forcePrompt = urlParams.get("forcePrompt") || "";
    const forceShowComingSoon = urlParams.get("forceShowComingSoon") || "";

    let forceBonusShopping = urlParams.get("forceBonusShopping") || undefined;

    if (!!forceBonusShopping) {
      forceBonusShopping = parseInt(forceBonusShopping, 10);

      if (isNaN(forceBonusShopping)) {
        forceBonusShopping = 5000;
      }
    }

    let forceShoppingRequired =
      urlParams.get("forceShoppingRequired") || undefined;

    if (!!forceShoppingRequired) {
      forceShoppingRequired = forceShoppingRequired === "true" ? true : false;
    }

    setForceBonusShopping(forceBonusShopping);
    setForcePrompt(forcePrompt ? true : false);
    setForceShoppingRequired(forceShoppingRequired);
    setForceShowComingSoon(forceShowComingSoon ? true : false);
  }, [locationSearch]);

  return (
    <Form className={className} noValidate onSubmit={handleSubmit}>
      {!!formError && formError !== authMessage && (
        <div ref={formErrorAlert}>
          <LargeErrorMessage className="tds-margin-xxlg-bottom">
            {formError}
          </LargeErrorMessage>
        </div>
      )}
      <Group
        className="tds-margin-xxlg-bottom"
        groupId="email"
        state={getFormElementState(errors.email)}
      >
        <Label>Email*</Label>
        <Input
          autoCapitalize="none"
          autoCorrect="off"
          className="ua-display-block ua-width-100"
          name="email"
          onBlur={handleInput}
          onChange={handleInput}
          required
          pattern={validationPatterns.email}
          type="email"
          value={data.email}
        />
        {!!errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
      </Group>
      <Group
        className="tds-margin-xxlg-bottom"
        groupId="password"
        state={getFormElementState(errors.password)}
      >
        <Label>Password*</Label>
        <Input
          autoCapitalize="none"
          autoCorrect="off"
          className="ua-display-block ua-width-100"
          name="password"
          onBlur={handleInput}
          onChange={handleInput}
          required
          type="password"
          value={data.password}
        />
        {!!errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      </Group>
      <div className="tds-padding-xxlg-top">
        <Button
          buttonType={inProgress ? "disabled" : "primary-one"}
          className="tds-margin-xxlg-bottom ua-display-block ua-width-100"
          loading={inProgress}
          type="submit"
        >
          {inProgress ? "Logging in…" : "Login"}
        </Button>
      </div>
    </Form>
  );
}

LoginForm.propTypes = {
  authMessage: string,
  className: string,
  onSuccess: func,
};
