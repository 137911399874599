import { useSelector } from "react-redux";

export default function useCoachSettings() {
  const settings = useSelector(({ settings }) => settings);

  const coachSettings = {
    email: settings?.advantage_coach_site_email || "",
    shopping_link: settings?.advantage_coach_shopping_link || "",
    site_link: settings?.advantage_coach_site_link || "",
    phone: settings?.advantage_coach_site_phone || "",
  };

  return coachSettings;
}
