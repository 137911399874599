import { useEffect, useRef, useState } from "react";
import { bool, number, object, shape, string } from "prop-types";
import { useInView } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

export default function LottiePlayer({
  animationData,
  options,
  seekPosition = 0,
  style,
}) {
  const [lottieLoaded, setLottieLoaded] = useState(false);
  const wrapperRef = useRef(null);
  const playerRef = useRef(null);
  const isInView = useInView(wrapperRef, {
    amount: 1,
  });

  useEffect(() => {
    if (lottieLoaded) {
      if (isInView) {
        playerRef?.current.setSeeker(seekPosition, true);
        playerRef?.current.play();
      }
    }
  }, [isInView, lottieLoaded, seekPosition]);

  return (
    <div ref={wrapperRef}>
      <Player
        ref={playerRef}
        onEvent={(event) => {
          event === "instanceSaved" && setLottieLoaded(true);
        }}
        src={animationData}
        style={style}
        {...options}
      />
    </div>
  );
}
// Refer to Lottie Player docs for options reference:
// https://docs.lottiefiles.com/lottie-player/components/lottie-react/properties
LottiePlayer.propTypes = {
  animationData: object.isRequired,
  options: shape({
    autoplay: bool,
    background: string,
    controls: bool,
    direction: number,
    hover: bool,
    loop: bool,
    keepLastFrame: bool,
    speed: number,
  }),
  seekPosition: number,
  style: object,
};
