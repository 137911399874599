import { useMemo } from "react";
import { oneOfType, number, string } from "prop-types";
import Embed from "../embed/embed";
import VimeoPlayer from "../vimeo-player/VimeoPlayer";

export default function VideoEmbed({ benefitId, label, videoUrl }) {
  const isVimeo = useMemo(
    () => !!videoUrl && videoUrl.toString().includes("vimeo"),
    [videoUrl]
  );

  if (!videoUrl) {
    return null;
  }

  if (isVimeo) {
    return (
      <VimeoPlayer benefitId={benefitId} label={label} vimeoUrl={videoUrl} />
    );
  }

  return <Embed title={label} url={videoUrl} />;
}

VideoEmbed.propTypes = {
  benefitId: oneOfType([number, string]),
  label: string,
  videoUrl: string,
};
