import { forwardRef } from "react";
import {
  bool,
  element,
  func,
  node,
  object,
  oneOfType,
  string,
} from "prop-types";
import { useHref, useLinkClickHandler } from "react-router-dom";

import isModifiedEvent from "../../utilities/is-modified-event";

function WithLinkNavigation(
  {
    component: Component,
    onClick,
    replace = false,
    state,
    target,
    to,
    ...rest
  },
  ref
) {
  let href = useHref(to);
  let routeClick = useLinkClickHandler(to, { replace, state, target });

  function handleClick(event) {
    if (onClick) {
      onClick(event);
    }

    if (
      !event.defaultPrevented &&
      event.button === 0 &&
      (!target || target === "_self") &&
      !isModifiedEvent(event)
    ) {
      routeClick(event);
    }
  }

  return (
    <Component
      {...rest}
      href={href}
      onClick={handleClick}
      ref={ref}
      target={target}
    />
  );
}

const TempoRouterLink = forwardRef(WithLinkNavigation);

TempoRouterLink.propTypes = {
  component: oneOfType([element, func, node]),
  onClick: func,
  replace: bool,
  state: object,
  target: string,
  to: oneOfType([object, string]),
};

export default TempoRouterLink;
