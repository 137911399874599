import { Link } from "react-router-dom";

import ROUTES from "../../data/constants/routes";
import { usePageLayoutContext } from "./PageLayout";

import { Footer, Typography } from "@uhc-tempo/components";
const { Footnote } = Typography;

const year = new Date().getFullYear();

export default function PageFooter() {
  const { bonusShoppingRequired, hasAuth, hideFooter, showNav } =
    usePageLayoutContext();
  let links = [ROUTES.privacy, ROUTES.termsUse];

  if (hasAuth) {
    links = [ROUTES.termsConditions, ...links];
  }

  const target = showNav || bonusShoppingRequired ? null : "_blank";

  if (hideFooter) {
    return null;
  }

  return (
    <Footer className="page-footer tds-padding-xxlg-bottom">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12 ua-footer-grid">
        <div className="ua-col-span-1-12-sm ua-col-auto-span-5-md">
          <Footnote
            className="tds-margin-xlg-right tds-margin-none-bottom"
            useLight
          >
            ©{year} United HealthCare Services, Inc. All rights reserved.
          </Footnote>
        </div>
        <div className="ua-col-span-1-12-sm ua-col-span-6-8-md ua-display-flex-md ua-justify-content-flex-end-md">
          {links.map((route) => (
            <FootnoteLink key={route.path} route={route} target={target} />
          ))}
        </div>
      </div>
    </Footer>
  );
}

function FootnoteLink({ route, target }) {
  return (
    <Footnote
      className="tds-margin-sm-right tds-margin-none-bottom ua-footer-link"
      useLight
    >
      <Link
        className="tds-footer__footnote-link"
        to={route.path}
        target={target}
        rel={target ? "noopener noreferrer" : null}
      >
        {route.title}
      </Link>
    </Footnote>
  );
}
