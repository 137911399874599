import MastheadAlt from "../../components/masthead-alt/MastheadAlt";

import heroImgX1 from "../../assets/img/hero-dcsm.jpg";
import heroImgX2 from "../../assets/img/hero-dcsm@2x.jpg";
import overviewImgX1 from "../../assets/img/dcsm-overview.jpg";
import overviewImgX2 from "../../assets/img/dcsm-overview@2x.jpg";

import { IconCare, IconTennisShoe } from "@uhc-tempo/icons";
import { BrandIcon, Typography } from "@uhc-tempo/components";
const { H5, Paragraph } = Typography;

export default function Dcsm() {
  return (
    <div className="ua-bg-gray-two">
      <header className="ua-teaser--header">
        <MastheadAlt className="ua-teaser--header--masthead" />

        <h1 className="ua-teaser--header--heading">
          <span className="ua-teaser--header--heading--small">
            The new star on your team
          </span>{" "}
          <span className="ua-teaser--header--heading--large">
            Dedicated Client Service Manager (DCSM)
          </span>
        </h1>

        <div className="ua-teaser--header--lead">
          <h2 className="ua-teaser--header--lead--heading">
            More than just a point of contact…
          </h2>
          <div className="ua-teaser--header--lead--content">
            <p>
              <strong>the keys to the kingdom.</strong>
            </p>
            <ul>
              <li>Reduce your team’s workload</li>
              <li>Fast, service-related responses</li>
            </ul>
          </div>
        </div>

        <div className="ua-teaser--header--image-bg">
          <img
            alt="Dedicated Client Service Manager (DCSM)"
            src={heroImgX1}
            srcSet={`${heroImgX1} 1x, ${heroImgX2} 2x`}
            width={1440}
            height={520}
          />
        </div>
      </header>

      <section className="ua-teaser--overview">
        <h2 className="ua-teaser--overview--heading">
          When we say “dedicated” we mean it.
        </h2>
        <div className="ua-teaser--overview--cards">
          <div className="ua-teaser--overview--cards--text">
            <div className="ua-teaser--overview--cards--text--content">
              <BrandIcon
                className="ua-teaser--overview--cards--text--content--icon"
                icon={IconCare}
                size="EXTRA_SMALL"
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <div>
                <H5 headingLevel={3}>Our promise</H5>
                <Paragraph>
                  You have our promise your DCSM will deliver an exceptional
                  service experience for you and your UnitedHealthcare clients.
                </Paragraph>
                <Paragraph className="ua-margin-none">
                  Our promise to understand your unique business needs.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="ua-teaser--overview--cards--text">
            <div className="ua-teaser--overview--cards--text--content">
              <BrandIcon
                className="ua-teaser--overview--cards--text--content--icon"
                icon={IconTennisShoe}
                size="EXTRA_SMALL"
                styleVariation="TWO_TONE_DARK_CIRCLE"
              />
              <div>
                <H5 headingLevel={3}>The perfect pairing</H5>
                <Paragraph className="ua-margin-none">
                  Experience and training. Every DCSM completes a rigorous,
                  12-week training program before they begin working with any
                  agencies.
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="ua-teaser--overview--cards--photos ua-teaser--slider">
            <div className="ua-teaser--slider--slides">
              <figure className="ua-teaser--slider--slides--slide">
                <img
                  alt=""
                  src={overviewImgX1}
                  srcSet={`${overviewImgX1} 1x, ${overviewImgX2} 2x`}
                />
                <figcaption>
                  <span className="ua-teaser--slider--slides--slide--points">
                    <strong>Valued Support</strong>
                  </span>{" "}
                  <span className="ua-teaser--slider--slides--slide--caption">
                    Frees up your team’s time!
                  </span>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="ua-teaser--countdown ua-teaser--countdown__alt">
        <div className="ua-teaser--countdown--content">
          <h2 className="ua-teaser--countdown--heading">
            New to United Advantage?
            <br /> DCSM introductions scheduled for next week – stay tuned!
          </h2>
        </div>
      </section>
    </div>
  );
}
