import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

// The shopping API response includes all benefit types,
// but the shopping frontend only needs the types that are
// found in the array of benefits available for selection.
const selectShoppingBenefitTypes = createSelector(
  (state) => state.shopping?.benefits || [],
  (state) => state.shopping?.benefitTypes || [],
  (benefits, benefitTypes) =>
    benefitTypes
      .filter(({ id }) =>
        benefits.some(({ benefit_type_id }) => benefit_type_id === id)
      )
      .sort(sortByOrder)
);

function sortByOrder(a, b) {
  return a.order - b.order;
}

export default function useShoppingBenefitTypes() {
  const shoppingBenefitTypes = useSelector((state) =>
    selectShoppingBenefitTypes(state)
  );
  return shoppingBenefitTypes;
}
