import { number, string } from "prop-types";

import VideoEmbed from "../../../components/video-embed/VideoEmbed";
import LottiePlayer from "../../../components/lottie-player/lottie-player";
import LottiePlayHeading from "../../../assets/lottie/play.json";

import { Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function PerkHighlightVideo({ id, videoLabel, videoUrl }) {
  return (
    <div className="ua-col-span-1-6-sm ua-col-span-1-6-md">
      <div className="ua-display-flex ua-grid-gap-3-sm ua-align-items-center">
        <LottiePlayer
          animationData={LottiePlayHeading}
          options={{ keepLastFrame: true }}
          style={{ height: "56px", marginBottom: "40px" }}
        />
      </div>
      <Paragraph className="perk-details__bottom-spacing perk-details__blurb-large">
        {videoLabel}
      </Paragraph>
      <div className="ua-margin-auto perk-details__video perk-details__bottom-spacing">
        <VideoEmbed benefitId={id} label={videoLabel} videoUrl={videoUrl} />
      </div>
    </div>
  );
}

PerkHighlightVideo.propTypes = {
  id: number,
  label: string,
  videoUrl: string,
};
